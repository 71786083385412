import NoData from '../components/display/no-data';

import svg from '../assets/images/label.svg';

import { useAppContext } from '~/components/AppContext';

function NoLabels() {
  const { isProjectAdmin } = useAppContext();
  return (
    <NoData
      svg={svg}
      svgAlt="group"
      title="whatIsLabel"
      description="labelDescription"
      linkTo={isProjectAdmin ? '/labels/new' : undefined}
      buttonText="createLabel"
    />
  );
}

export default NoLabels;
