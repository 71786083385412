import PropTypes from 'prop-types';

import { formatNumber } from '../../utils/display';
import { useAppContext } from '~/components/AppContext';

/**
 * Wrapper around Intl.NumberFormat using stored locale
 */
export function NumberDisplay({ children, options = undefined }) {
  const { locale } = useAppContext();
  return formatNumber(children, locale, options);
}

NumberDisplay.propTypes = {
  /**
   * The number to display
   */
  children: PropTypes.number.isRequired,

  /**
   * Specific configuration in the formating of the number, see
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat for more info
   */
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.object,
};

export default NumberDisplay;
