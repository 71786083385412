import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { scaleBand, scaleLinear, scaleOrdinal } from 'd3-scale';

import {
  Graph,
  useGraphDimensions,
} from '@feetme/d3act';

import { getLeftColor, getRightColor } from '~/utils/palette';
import { KlingonBlack, StarfleetBlue } from '~/utils/colors';
import SingleSupportPercentage from '~/utils/metrics/single-support-percentage';
import Translate from '~/components/display/translate';
import MetricValue from '~/components/MetricValue';

function SingleSupportGraph({
  phases,
  means,
  stds,
  height = 90,
  width = 0,
}) {
  const [ref, dimensions] = useGraphDimensions({
    height,
    width,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 20,
    marginBottom: 20,
  });

  const groups = [...Array(means.length).keys()];
  const subgroups = ['left', 'right'];

  const xGroupScale = scaleBand()
    .domain(groups)
    .range([0, dimensions.boundedWidth])
    .paddingInner(0.1);

  const xSubGroupScale = scaleBand()
    .domain(subgroups)
    .range([0, xGroupScale.bandwidth()])
    .paddingInner(0.1);

  const yScale = scaleLinear()
    .domain([
      0,
      Math.max(...means.map((d, i) => [
        d.left + stds[i].left,
        d.right + stds[i].right,
      ]).flat(2)),
    ])
    .range([dimensions.boundedHeight, 0])
    .nice();

  const color = scaleOrdinal()
    .domain(subgroups)
    .range([getLeftColor(), getRightColor()]);

  const hasData = index => means[index].left !== 0 || stds[index].left !== 0
    || means[index].right !== 0 || stds[index].right !== 0;

  return (
    <div ref={ref} style={{ position: 'relative', height }}>
      <Graph dimensions={dimensions}>
        {means.map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <g key={i} transform={`translate(${xGroupScale(i)}, 0)`}>
            {subgroups.map((side) => {
              if (!hasData(i)) {
                return null;
              }
              return (
                <Fragment key={side}>
                  <rect
                    x={xSubGroupScale(side)}
                    y={yScale(means[i][side])}
                    width={xSubGroupScale.bandwidth()}
                    height={yScale(0) - yScale(means[i][side])}
                    fill={color(side)}
                  />
                  <line
                    x1={xSubGroupScale(side) + xSubGroupScale.bandwidth() / 2}
                    x2={xSubGroupScale(side) + xSubGroupScale.bandwidth() / 2}
                    y1={yScale(means[i][side])}
                    y2={yScale(means[i][side] + stds[i][side])}
                    stroke={StarfleetBlue[100]}
                    strokeWidth={2}
                  />
                  <line
                    x1={xSubGroupScale(side)}
                    x2={xSubGroupScale(side) + xSubGroupScale.bandwidth()}
                    y1={yScale(means[i][side] + stds[i][side])}
                    y2={yScale(means[i][side] + stds[i][side])}
                    stroke={StarfleetBlue[100]}
                    strokeWidth={2}
                  />
                  <text
                    transform={`translate(${xSubGroupScale(side) + xSubGroupScale.bandwidth() / 2}, 0)`}
                    style={{
                      fill: KlingonBlack[600],
                      strokeWidth: 0,
                      textAnchor: 'middle',
                      fontSize: '12px',
                    }}
                  >
                    <MetricValue metric={SingleSupportPercentage}>{means[i][side]}</MetricValue>
                  </text>
                </Fragment>
              );
            })}
            { means.length > 1 && (
              <text
                transform={`translate(${xSubGroupScale.bandwidth()}, ${dimensions.boundedHeight + 15})`}
                style={{
                  fill: KlingonBlack[600],
                  strokeWidth: 0,
                  textAnchor: 'middle',
                  fontSize: '12px',
                }}
              >
                {phases[i]}
                {!hasData(i) && (
                  <>
                    {' ('}
                    <Translate>noDataAvailable</Translate>
                    )
                  </>
                )}
              </text>
            )}
          </g>
        ))}
      </Graph>
    </div>
  );
}

SingleSupportGraph.propTypes = {
  phases: PropTypes.arrayOf(PropTypes.string).isRequired,
  means: PropTypes.arrayOf(PropTypes.shape({
    left: PropTypes.number,
    right: PropTypes.number,
  })).isRequired,
  stds: PropTypes.arrayOf(PropTypes.shape({
    left: PropTypes.number,
    right: PropTypes.number,
  })).isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default SingleSupportGraph;
