import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import ButtonProgress from '~/components/display/button-progress';
import Translate from '~/components/display/translate';
import CSVIcon from '~/components/icons/csv';
import PDFIcon from '~/components/icons/pdf';
import DownloadIcon from '~/components/icons/download';

import { useAppContext } from '~/components/AppContext';
import { generateReportPdf, generateStridesCSV } from '~/firebase/functions';
import { getMetricsUrl, getPressuresUrl } from '~/firebase/storage';
import { translate } from '~/utils/i18n';
import { toISOFormat, toFileDateFormat } from '~/utils/date';
import { hasStrides, hasPressures } from '~/utils/record';
import { StarfleetBlue, KlingonBlack } from '~/utils/colors';

function DownloadButtons({ record, recordId }) {
  const { locale, file } = useAppContext();
  const anchorRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePDFDownload = async () => {
    setIsLoading(true);
    handleClose();
    // configure the <a> tag
    anchorRef.current.download = `${translate('filenameReportRecord', file)}_${toISOFormat(new Date(record.startTime))}.pdf`;
    anchorRef.current.type = 'application/pdf';
    // generate the pdf via the firebase function
    const pdfBuffer = (await generateReportPdf({
      recordId,
      locale,
      footerTxt: translate('pdfFooter', file),
    })).data;
    // set the data url and click the <a> tag
    const dataUrl = `data:application/pdf;base64,${atob(pdfBuffer)}`;
    anchorRef.current.href = dataUrl;
    anchorRef.current.click();
    setIsLoading(false);
  };

  const handleStridesDownload = async () => {
    setIsLoading(true);
    handleClose();
    // configure the <a> tag
    anchorRef.current.download = `${toFileDateFormat(new Date(record.startTime))}-metrics.csv`;
    anchorRef.current.type = 'text/csv';
    // try to retrieve the file on storage
    // if it doesn't exist, generate it via a cloud function
    let url;
    try {
      url = await getMetricsUrl(recordId);
    } catch (error) {
      if (error.code === 'storage/object-not-found') {
        await generateStridesCSV({ recordId });
        url = await getMetricsUrl(recordId);
      } else {
        setIsLoading(false);
        return;
      }
    }
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const blob = xhr.response;
      anchorRef.current.href = URL.createObjectURL(blob);
      anchorRef.current.click();
    };
    xhr.open('GET', url);
    xhr.send();
    setIsLoading(false);
  };

  const handlePressuresDownload = async () => {
    setIsLoading(true);
    handleClose();
    // configure the <a> tag
    anchorRef.current.download = `${toFileDateFormat(new Date(record.startTime))}-pressures.zip`;
    anchorRef.current.type = 'application/zip';
    const url = await getPressuresUrl(recordId);
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const blob = xhr.response;
      anchorRef.current.href = URL.createObjectURL(blob);
      anchorRef.current.click();
    };
    xhr.open('GET', url);
    xhr.send();
    setIsLoading(false);
  };

  return (
    <div>
      <ButtonProgress
        id="download-button"
        aria-controls={open ? 'download-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={!isLoading ? <DownloadIcon /> : null}
        color="primary"
        inProgress={isLoading}
      >
        <Translate>download</Translate>
      </ButtonProgress>
      <Menu
        id="download-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'download-button',
          sx: {
            '& .MuiTypography-root': {
              fontSize: '0.8125rem',
              fontWeight: 600,
            },
            '& .MuiMenuItem-root:hover': {
              color: 'white',
              bgcolor: StarfleetBlue[400],
              '& .MuiSvgIcon-root': {
                color: 'white',
              },
            },
          },
        }}
      >
        <ListSubheader
          sx={{
            textTransform: 'uppercase',
            fontSize: '0.6875rem',
            color: KlingonBlack[500],
            letterSpacing: '0.03438rem',
          }}
        >
          <Translate>downloadFormat</Translate>
        </ListSubheader>
        <MenuItem onClick={handlePDFDownload}>
          <ListItemIcon>
            <PDFIcon color="primary" />
          </ListItemIcon>
          <ListItemText><Translate>downloadReport</Translate></ListItemText>
          <DownloadIcon sx={{ color: 'white' }} />
        </MenuItem>
        { hasStrides(record) && (
          <MenuItem onClick={handleStridesDownload}>
            <ListItemIcon>
              <CSVIcon color="primary" />
            </ListItemIcon>
            <ListItemText><Translate>downloadStrides</Translate></ListItemText>
            <DownloadIcon sx={{ color: 'white' }} />
          </MenuItem>
        )}
        { hasPressures(record) && (
          <MenuItem onClick={handlePressuresDownload}>
            <ListItemIcon>
              <CSVIcon color="primary" />
            </ListItemIcon>
            <ListItemText><Translate>downloadPressures</Translate></ListItemText>
            <DownloadIcon sx={{ color: 'white' }} />
          </MenuItem>
        )}
      </Menu>
      <a ref={anchorRef} style={{ display: 'none' }} href="/">
        Download
      </a>
    </div>
  );
}

DownloadButtons.propTypes = {
  record: PropTypes.shape({
    startTime: PropTypes.number.isRequired,
  }).isRequired,
  recordId: PropTypes.string.isRequired,
};

export default DownloadButtons;
