import PropTypes from 'prop-types';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Radio from './radio';
import { KlingonBlack, PhaserRed } from '../../utils/colors';

function RadioBlock({
  name,
  value,
  label = undefined,
  onChange,
  radios,
  required = false,
}) {
  return (
    <FormControl component="fieldset" fullWidth>
      { label && (
        <FormLabel
          sx={{
            fontWeight: 500,
            fontSize: '0.8125rem',
            pb: 1.5,
            color: KlingonBlack[900],
            '&.Mui-focused': {
              color: 'inherit',
            },
            '& .MuiFormLabel-asterisk': {
              color: `${PhaserRed[700]} !important`,
            },
          }}
          component="legend"
          required={required}
        >
          {label}
        </FormLabel>
      )}
      <RadioGroup
        row
        aria-label={name}
        name={name}
        value={value}
        onChange={onChange}
        sx={{ justifyContent: 'center' }}
      >
        { radios.map(radio => (
          <FormControlLabel
            key={radio.key}
            sx={[
              {
                flexGrow: 1,
                '& .MuiFormControlLabel-label': {
                  fontSize: '0.8125rem',
                  fontWeight: 400,
                },
              },
              (value === radio.key) && {
                '& .MuiFormControlLabel-label': {
                  fontWeight: 600,
                },
              },
            ]}
            value={radio.key}
            label={radio.label}
            control={<Radio />}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

RadioBlock.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  value: PropTypes.string.isRequired,
  radios: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  })).isRequired,
  required: PropTypes.bool,
};

export default RadioBlock;
