import { createElement } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import Translate from '~/components/display/translate';
import MetricValue from '~/components/MetricValue';
import { StarfleetBlue } from '~/utils/colors';
import BaseMetric from '~/utils/metrics/base';
import Tooltip from '~/components/display/tooltip';

import useUnit from '~/utils/hooks/useUnit';

import MetricRecordView from './MetricRecordView';

function MetricRecord({
  metric,
  value,
  showUnit = false,
  std = null,
  isPdf = false,
  ...props
}) {
  const unit = useUnit(metric);

  return (
    <MetricRecordView
      title={(
        <>
          <Translate>{metric.name}</Translate>
          { showUnit && ` (${unit})`}
        </>
      )}
      tooltip={(std !== null) && (
        <Tooltip
          title={(
            <>
              <Translate>standardDeviation</Translate>
              {': '}
              <MetricValue metric={metric} showUnit>{std}</MetricValue>
            </>
          )}
        >
          <Avatar
            sx={{
              bgcolor: StarfleetBlue[100],
              color: StarfleetBlue[500],
              fontSize: '0.875rem',
              fontWeight: 500,
              height: 18,
              width: 18,
            }}
          >
            σ
          </Avatar>
        </Tooltip>
      )}
      icon={metric.icon !== undefined && createElement(metric.icon)}
      {...props}
    >
      <Typography sx={{ fontSize: isPdf ? '1.5rem' : '2.5rem', fontWeight: 600 }}>
        <MetricValue metric={metric}>{value}</MetricValue>
      </Typography>
      {(std !== null && isPdf) && (
        <Typography sx={{ fontSize: '0.44269rem', fontWeight: 500 }}>
          {'σ: '}
          <MetricValue metric={metric}>{std}</MetricValue>
        </Typography>
      )}
    </MetricRecordView>
  );
}

MetricRecord.propTypes = {
  value: PropTypes.number.isRequired,
  metric: PropTypes.instanceOf(BaseMetric).isRequired,
  showUnit: PropTypes.bool,
  std: PropTypes.number,
  isPdf: PropTypes.bool,
};

export default MetricRecord;
