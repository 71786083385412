import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import NoData from '~/components/display/no-data';

import svg from '~/assets/images/patient.svg';

function NoProgram({ onClick }) {
  return (
    <Box sx={{ mb: 6 }}>
      <NoData
        svg={svg}
        svgAlt="no-program"
        title="patientHasNoProgram"
        description="patientHasNoProgramDescription"
        buttonText="addProgram"
        onClick={onClick}
      />
    </Box>
  );
}

NoProgram.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default NoProgram;
