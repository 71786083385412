import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Translate from '~/components/display/translate';
import MetricValue from '~/components/MetricValue';
import MetricValueComparisonIcon from '~/components/MetricValueComparisonIcon';

import BaseMetric from '~/utils/metrics/base';
import useUnit from '~/utils/hooks/useUnit';

import { KlingonBlack } from '~/utils/colors';

function MetricDisplay({
  metric,
  means,
  std,
}) {
  const unit = useUnit(metric);

  return (
    <Stack sx={{ px: 2 }}>
      <Typography sx={{ fontSize: '1.25rem', fontWeight: 600 }}>
        <Translate>{metric.key}</Translate>
        <span style={{ fontWeight: 400 }}>
          &nbsp;
          <Translate>meanSD</Translate>
        </span>
      </Typography>
      <Typography
        sx={{
          color: KlingonBlack[600],
          fontSize: '0.8125rem',
          fontWeight: 500,
        }}
      >
        <Translate canFail>{unit}</Translate>
      </Typography>
      <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
        <Typography
          sx={{
            fontSize: '1.42881rem',
            fontWeight: 600,
            py: 3,
          }}
        >
          <MetricValue metric={metric}>{means.at(-1)}</MetricValue>
          {' ± '}
          <MetricValue metric={metric}>{std}</MetricValue>
        </Typography>
        <MetricValueComparisonIcon metric={metric} values={means} />
      </Stack>
    </Stack>
  );
}

MetricDisplay.propTypes = {
  metric: PropTypes.instanceOf(BaseMetric).isRequired,
  means: PropTypes.arrayOf(PropTypes.number).isRequired,
  std: PropTypes.number.isRequired,
};

export default MetricDisplay;
