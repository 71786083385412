import { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import RefreshIcon from '@mui/icons-material/Refresh';

import Button from '../display/button';
import Translate from '../display/translate';
import TextField from '../display/textfield';
import PasscodeResetStatus from './passcode-reset-status';

import usePasscodeReset from '~/utils/hooks/usePasscodeReset';
import { createPasscodeReset } from '@/firebase/functions';

function PasscodeReset({ userId }) {
  const [isPending, setIsPending] = useState(false);
  const [passcodeReset] = usePasscodeReset(userId, [userId, isPending]);

  const handleClick = async () => {
    setIsPending(true);
    await createPasscodeReset({ userId });
    setIsPending(false);
  };

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
      <Grid item>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
          <Grid item>
            <TextField
              value={passcodeReset ? passcodeReset.passcodeValue : ''}
              disabled
              disablePlaceholderTranslate
              placeholder="000000"
            />
          </Grid>
          <Grid item>
            <Button
              color="primary"
              onClick={handleClick}
              startIcon={<RefreshIcon />}
              disabled={isPending}
            >
              <Translate>generate</Translate>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      { passcodeReset && (
        <Grid item sx={{ mt: 2.5 }}>
          <PasscodeResetStatus
            createdAt={passcodeReset.createdAt}
            isValid={passcodeReset.isValid}
          />
        </Grid>
      )}
    </Grid>
  );
}

PasscodeReset.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default PasscodeReset;
