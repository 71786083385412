import { shortSide } from './side';
import { getPathAssets } from './assets';

function getLeft(insoles) {
  return insoles.find(insole => insole.side === 'left');
}

function getRight(insoles) {
  return insoles.find(insole => insole.side === 'right');
}

function shortName(insole) {
  if (insole === undefined) {
    return '';
  }

  return `${insole.number}${shortSide(insole.side)}`;
}

function getInsoleDataUrl(size) {
  return `${getPathAssets()}/insoles/${size}.json`;
}

function getInputCoordinateUrl(size) {
  return `${getPathAssets()}/inputCoordinates/${size}.json`;
}

export {
  getLeft,
  getRight,
  shortName,
  getInsoleDataUrl,
  getInputCoordinateUrl,
};
