import { fetchPressures } from '~/firebase/firestore';
import useFirestore from './useFirestore';

function usePressures(recordId, strideId) {
  return useFirestore(
    () => {
      if (strideId === undefined) {
        return Promise.resolve({});
      }
      return fetchPressures(recordId, strideId);
    },
    {},
    [strideId],
  );
}

export default usePressures;
