import NoData from '~/components/display/no-data';

import svg from '~/assets/images/no-strides.svg';

function NoStrides() {
  return (
    <NoData
      svg={svg}
      svgAlt="no-strides-illustration"
      title="recordHasNoStrides"
      description="eitherNoStridesOrUnusable"
    />
  );
}

export default NoStrides;
