import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { KlingonBlack } from '../../utils/colors';

function SectionHeaderPaper({ title = undefined, subtitle = undefined }) {
  return (
    <Box sx={{ my: 3 }}>
      { title && (
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 600, fontSize: '1rem', color: KlingonBlack[900] }}>
          { title }
        </Typography>
      )}
      { subtitle && (
        <Typography sx={{ fontWeight: 400, fontSize: '0.8rem', color: KlingonBlack[600] }}>
          { subtitle }
        </Typography>
      )}
    </Box>
  );
}

SectionHeaderPaper.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default SectionHeaderPaper;
