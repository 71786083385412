import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import Translate from '../../display/translate';
import SectionHeaderPaper from '../../display/section-header-paper';
import ButtonsForm from '../buttons';
import Email from '../../field/email';
import MemberName from '../../field/member-name';
import Organisations from '../../field/organisations';
import Labels from '../../field/labels';
import Role from '../../field/role';
import Status from '../../field/status';
import InfoBox from '../../display/info-box';
import PasscodeReset from '../../passcode/passcode-reset';
import Invitation from '~/components/Invitation';

import { useAppContext } from '~/components/AppContext';

function Common({
  onCancel,
  onSubmit,
  isCreate,
  userId = '',
  showInvitation = false,
  lastInvitation = undefined,
  email: emailInit = '',
  name: nameInit = '',
  organisationsId: organisationsIdInit = [],
  labelsId: labelsIdInit = [],
  role: roleInit = 'member',
  status: statusInit = 'active',
}) {
  const [email, setEmail] = useState(emailInit);
  const [name, setName] = useState(nameInit);
  const [organisationsId, setOrganisationsId] = useState(organisationsIdInit);
  const [labelsId, setLabelsId] = useState(labelsIdInit);
  const [role, setRole] = useState(roleInit);
  const [status, setStatus] = useState(statusInit);
  const [inProgress, setInProgress] = useState(false);
  const [isUniqueEmail, setIsUniqueEmail] = useState(true);
  const [errors, setErrors] = useState({});
  const { isStudyProject } = useAppContext();

  useEffect(() => {
    setEmail(emailInit);
    setName(nameInit);
    setOrganisationsId(organisationsIdInit);
    setLabelsId(labelsIdInit);
    setRole(roleInit);
    setStatus(statusInit);
  }, [
    emailInit,
    nameInit,
    organisationsIdInit.length,
    labelsIdInit.length,
    roleInit,
    statusInit,
  ]);

  // return true if the form is not valid, false otherwise
  const validate = () => {
    // check each field passed its own valdation
    if (Object.values(errors).find(i => i === true)) {
      return true;
    }

    // check the initial state
    if (!name) return true;
    if (!email) return true;

    return false;
  };

  const handleChange = (change) => {
    setErrors({ ...errors, [change.name]: change.error });
    switch (change.name) {
      case 'email':
        setEmail(change.value);
        break;
      case 'name':
        setName(change.value);
        break;
      case 'organisationsId':
        setOrganisationsId(change.value);
        break;
      case 'labelsId':
        setLabelsId(change.value);
        break;
      case 'role':
        setRole(change.value);
        break;
      case 'status':
        setStatus(change.value);
        break;
      default:
        throw new Error(`Unknown change type: ${change.name}`);
    }
  };

  const handleSubmit = async () => {
    if (validate()) {
      return;
    }

    setInProgress(true);
    setIsUniqueEmail(true);
    try {
      await onSubmit({
        email,
        name,
        organisations: organisationsId,
        labels: labelsId,
        role,
        status,
      });
    } catch (err) {
      if (err.code === 'functions/already-exists') {
        setErrors({ ...errors, email: true });
        setIsUniqueEmail(false);
      } else {
        throw err;
      }
    }
    if (isCreate) {
      setEmail('');
      setName('');
      setOrganisationsId([]);
      setLabelsId([]);
    }
    setInProgress(false);
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        <Grid item>
          <SectionHeaderPaper title={<Translate>information</Translate>} />
        </Grid>
        <Grid item>
          <MemberName
            required
            value={name}
            error={errors.name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item>
          <Email
            required
            error={errors.email}
            value={email}
            disabled={isCreate === false && showInvitation === false}
            onChange={handleChange}
            helperText={isUniqueEmail === false
              ? <Translate>emailAlreadyExists</Translate> : undefined}
          />
        </Grid>
        { (showInvitation && lastInvitation) && (
          <Grid item>
            <Invitation {...lastInvitation} userId={userId} email={email} />
          </Grid>
        )}
        { isStudyProject && (
          <Grid item>
            <InfoBox>
              <Translate>studyAddSiteOrGroups</Translate>
            </InfoBox>
          </Grid>
        )}
        <Grid item>
          <SectionHeaderPaper title={<Translate>organisationSelection</Translate>} />
        </Grid>
        <Grid item>
          <Organisations organisationsId={organisationsId} onChange={handleChange} />
        </Grid>
        { isStudyProject && (
          <>
            <Grid item>
              <SectionHeaderPaper title={<Translate>labelSelection</Translate>} />
            </Grid>
            <Grid item>
              <Labels labelsId={labelsId} onChange={handleChange} />
            </Grid>
          </>
        )}
        <Grid item>
          <Role value={role} onChange={handleChange} />
        </Grid>
        { (isCreate === false) && (
          <React.Fragment>
            <Grid item>
              <SectionHeaderPaper title={<Translate>status</Translate>} />
            </Grid>
            <Grid item>
              <Status value={status} onChange={handleChange} />
            </Grid>
          </React.Fragment>
        )}
        { (isCreate === false && isStudyProject) && (
          <React.Fragment>
            <Grid item>
              <SectionHeaderPaper
                title={<Translate>resetAccessToMobileApp</Translate>}
                subtitle={<Translate>investigatorWillNeedInternetConnection</Translate>}
              />
            </Grid>
            <Grid item>
              <PasscodeReset userId={userId} />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
      <ButtonsForm
        inProgress={inProgress}
        disabled={validate()}
        isCreate={isCreate}
        onCancel={() => onCancel()}
        onSubmit={() => handleSubmit()}
        sticky
      />
    </form>
  );
}

Common.propTypes = {
  email: PropTypes.string,
  name: PropTypes.string,
  organisationsId: PropTypes.arrayOf(PropTypes.string),
  labelsId: PropTypes.arrayOf(PropTypes.string),
  role: PropTypes.oneOf(['orgaAdmin', 'member']),
  status: PropTypes.string,
  userId: PropTypes.string,
  showInvitation: PropTypes.bool,
  lastInvitation: PropTypes.object,
  isCreate: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default Common;
