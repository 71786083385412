import { useMemo } from 'react';

function useStaticRotation(array, insoleMesh, isStatic) {
  return useMemo(() => {
    if (!isStatic || insoleMesh === undefined) {
      return array;
    }

    if (array.length === 0) {
      return array;
    }

    // we only use the left rotation. The rotation to the right is symmetrical
    // and will be done in the InsoleView component from
    // `@feetme/insole-view-react`
    return insoleMesh.staticRotations(array, 'left');
  }, [array.length, array[0], insoleMesh !== undefined, isStatic]);
}

export default useStaticRotation;
