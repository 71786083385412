import { useMemo } from 'react';
import PropTypes from 'prop-types';

import Translate from '../../display/translate';
import TableHead from '../head';

const headers = [
  { key: 'name', label: (<Translate>nameOrId</Translate>), order: true },
  { key: 'organisation', label: (<Translate>organisation</Translate>), order: true },
  { key: 'complianceLevel', label: (<Translate>complianceLevel</Translate>), order: true },
  { key: 'criticalityLevel', label: (<Translate>criticalityLevel</Translate>), order: true },
  { key: 'lastRecordStartTime', label: (<Translate>lastRecord</Translate>), order: true },
  { key: 'lastImportStartTime', label: (<Translate>lastImport</Translate>), order: true },
];

function Head({
  orderBy = undefined,
  order,
  onRequestSort,
  showCompliance,
  showOrga,
  ...rest
}) {
  const filteredHeaders = useMemo(() => {
    let newHeaders = headers;

    if (!showCompliance) {
      newHeaders = newHeaders.filter(i => i.key !== 'complianceLevel' && i.key !== 'criticalityLevel');
    }

    if (!showOrga) {
      newHeaders = newHeaders.filter(i => i.key !== 'organisation');
    }

    return newHeaders;
  }, [showCompliance, showOrga]);

  return (
    <TableHead
      {...rest}
      headers={filteredHeaders}
      orderBy={orderBy}
      order={order}
      onRequestSort={onRequestSort}
    />
  );
}

Head.propTypes = {
  orderBy: PropTypes.string,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  onRequestSort: PropTypes.func.isRequired,
  showCompliance: PropTypes.bool.isRequired,
  showOrga: PropTypes.bool.isRequired,
};

export default Head;
