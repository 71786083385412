import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Translate from '../components/display/translate';
import StrideLengthHistogramGraph from '../components/graph/stride-length-histogram';
import CardHeader from '../components/CardHeader';

import useUnit from '~/utils/hooks/useUnit';
import { StrideLength } from '../utils/metrics';

function StrideLengthHistogram({ data, timeZone, isPdf = false }) {
  const unit = useUnit(StrideLength);

  const convertedData = useMemo(() => data.map(i => ({
    x: i.x,
    y: StrideLength.convert(i.y, unit),
  })), [data.length]);

  return (
    <Card sx={{ width: 1 }}>
      <CardHeader
        title={<Translate>strideLengthVariation</Translate>}
        subheader={(
          <>
            <Translate>{StrideLength.name}</Translate>
            {' '}
            <Translate canFail>{unit}</Translate>
          </>
        )}
        isPdf={isPdf}
      />
      <CardContent sx={[isPdf && { padding: 0 }]}>
        <StrideLengthHistogramGraph data={convertedData} timeZone={timeZone} isPdf />
      </CardContent>
    </Card>
  );
}

StrideLengthHistogram.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  })).isRequired,
  timeZone: PropTypes.string.isRequired,
  isPdf: PropTypes.bool,
};

export default StrideLengthHistogram;
