import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Typography } from '@mui/material';

function StrideSlider({ maxNbStrides, index, setIndex }) {
  return (
    <Stack>
      <Slider
        value={index}
        min={0}
        max={maxNbStrides}
        onChange={(_, newValue) => setIndex(newValue)}
      />
      <Stack direction="row" justifyContent="space-between">
        <Typography>{index}</Typography>
        <Typography>{maxNbStrides}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="center" spacing={4}>
        <IconButton aria-label="previous" disabled={index === 0} onClick={() => setIndex(index - 1)}>
          <ChevronLeftIcon />
        </IconButton>
        <IconButton aria-label="next" disabled={index === maxNbStrides} onClick={() => setIndex(index + 1)}>
          <ChevronRightIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
}

StrideSlider.propTypes = {
  maxNbStrides: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  setIndex: PropTypes.func.isRequired,
};

export default StrideSlider;
