import { initializeApp, deleteApp, getApp } from 'firebase/app';
import { getPerformance } from 'firebase/performance';

import { FIREBASE_CONFIG } from '../utils/env';

let lastProjectId;
let lastCloudFunctionsLocation;
const coreApp = initializeApp(JSON.parse(FIREBASE_CONFIG));

function initializeComponent(config, cloudFunctionsLocation, projectId) {
  lastProjectId = projectId;
  lastCloudFunctionsLocation = cloudFunctionsLocation;
  initializeApp(config, projectId);
}

async function deleteComponentApp() {
  if (lastProjectId !== undefined) {
    await deleteApp(getApp(lastProjectId));
  }
  lastProjectId = undefined;
  lastCloudFunctionsLocation = undefined;
}

function getProjectId() {
  return lastProjectId;
}

function getCloudFunctionsLocation() {
  return lastCloudFunctionsLocation;
}

function getComponentApp() {
  return getApp(lastProjectId);
}

// init perf object as soon as possible
// instead of doing it in src/firebase/performance.js which might not be called
const perf = getPerformance(coreApp);

export {
  coreApp,
  getComponentApp,
  initializeComponent,
  deleteComponentApp,
  getProjectId,
  getCloudFunctionsLocation,
  perf,
};
