import Avatar from '@mui/material/Avatar';

import ProjectAdminShield from '~/components/icons/project-admin-shield';
import CrossIcon from '~/components/icons/cross';

import { useAppContext } from '~/components/AppContext';
import { OrionTeal, StarfleetBlue } from '~/utils/colors';

function ProjectAdminAvatar() {
  const { isHealthProject } = useAppContext();

  if (isHealthProject) {
    return (
      <Avatar sx={{ bgcolor: StarfleetBlue['000'] }}>
        <CrossIcon sx={{ color: StarfleetBlue[400] }} fill />
      </Avatar>
    );
  }

  return (
    <Avatar sx={{ bgcolor: OrionTeal[100] }}>
      <ProjectAdminShield />
    </Avatar>
  );
}

export default ProjectAdminAvatar;
