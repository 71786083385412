import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import CriticalityPanel from '../CriticalityPanel/CriticalityPanel';
import CompliancePhase from '../CompliancePhase/CompliancePhase';
import ComplianceObservance from '../ComplianceObservance/ComplianceObservance';

import { computeCriticalityLevel } from '../../utils/metrics/criticality-level';
import { getDisplayPhase, getPhases, getThresholdPhase } from '../../utils/program';

function TabObservance({ user, program }) {
  const level = computeCriticalityLevel(program);
  const thresholds = getThresholdPhase(program);

  const phases = getPhases(program);
  const [phase, setPhase] = useState(getDisplayPhase(program));

  const handlePhaseUpdate = (ev) => {
    setPhase(phases.find(i => i.name === ev.target.value));
  };

  return (
    <React.Fragment>
      { level !== null && (
        <Box sx={{ mb: 5 }}>
          <CriticalityPanel level={level} thresholds={thresholds} />
        </Box>
      )}
      <Box sx={{ mb: 2 }}>
        <CompliancePhase onPhaseUpdate={handlePhaseUpdate} phases={phases} phase={phase} />
      </Box>
      <ComplianceObservance
        phase={phase}
        phases={phases}
        user={user}
        program={program}
      />
    </React.Fragment>
  );
}

TabObservance.propTypes = {
  user: PropTypes.object.isRequired,
  program: PropTypes.object.isRequired,
};

export default TabObservance;
