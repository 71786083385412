import Grid from '@mui/material/Grid';
import { Link as RouterLink } from 'react-router-dom';

import Breadcrumbs from '~/components/Breadcrumbs';
import Button from '../components/display/button';
import Translate from '../components/display/translate';
import PageProgress from '../components/display/page-progress';
import NoOrganisationsAdmin from './no-organisations-admin';
import NoOrganisations from './no-organisations';
import ListOrganisations from './list';

import useOrganisations from '../utils/hooks/useOrganisations';
import { useAppContext } from '~/components/AppContext';

function Organisations() {
  const [organisations, isLoading] = useOrganisations();
  const { isProjectAdmin } = useAppContext();

  const renderPage = () => {
    if (isLoading) {
      return (<PageProgress />);
    }

    if (organisations.length === 0) {
      if (isProjectAdmin) {
        return (<NoOrganisationsAdmin />);
      }

      return (<NoOrganisations />);
    }

    return (<ListOrganisations />);
  };

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Grid item>
          <Breadcrumbs
            links={[
              { type: 'organisation', to: '/organisations/' },
            ]}
          />
        </Grid>
        { (isProjectAdmin && organisations.length > 0) && (
          <Grid item>
            <Button color="primary" component={RouterLink} to="/organisations/new">
              <Translate>new</Translate>
            </Button>
          </Grid>
        )}
      </Grid>
      { renderPage() }
    </>
  );
}

export default Organisations;
