import { Route, Routes } from 'react-router-dom';

import Login from '../login/index';
import App from '../app/index';
import LinkCheck from '../link/index';

import Watermark from './watermark';
import PrivateRoute from './private-route';

function Root() {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/links/:linkId" element={<LinkCheck />} />
        <Route
          path="/*"
          element={(
            <PrivateRoute>
              <App />
            </PrivateRoute>
          )}
        />
      </Routes>
      <Watermark />
    </>
  );
}

export default Root;
