import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Table from '../index';
import Head from './head';
import Row from './row';

import { translate } from '../../../utils/i18n';
import { useAppContext } from '~/components/AppContext';

function Organisations({
  organisations,
  showCompliance = false,
  ...props
}) {
  const navigate = useNavigate();
  const { file } = useAppContext();
  const translatedPlaceholder = translate('search', file);

  const handleClick = orgaId => navigate(`/organisations/${orgaId}`);

  return (
    <Table
      {...props}
      defaultOrderBy="name"
      placeholder={translatedPlaceholder}
      Head={Head}
      Row={Row}
      HeadProps={{ showCompliance }}
      RowProps={{ onClick: handleClick, showCompliance }}
      data={organisations}
      hasPagination
    />
  );
}

Organisations.propTypes = {
  organisations: PropTypes.arrayOf(PropTypes.object).isRequired,
  showCompliance: PropTypes.bool,
};

export default Organisations;
