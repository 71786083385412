import PropTypes from 'prop-types';

import DownloadButtons from '~/components/DownloadButtons';
import ReportLayout from '~/components/ReportLayout';
import Translate from '~/components/display/translate';
import PageProgress from '~/components/display/page-progress';

import Dynamic from './Dynamic';
import Metric from './Metric';
import Header from './Header';
import NoStrides from './NoStrides';

import useRecordProcess from '~/utils/hooks/useRecordProcess';

function RecordWithStrides({ record, listRecordsPath }) {
  const [recordData, recordProcessingLoading, recordProcessingError] = useRecordProcess(record.key);

  const hasStrides = recordData?.generalMetrics?.strideCounter > 0;

  const getChild = () => {
    if (recordProcessingLoading) {
      return <PageProgress />;
    }

    if (recordProcessingError) {
      return null;
    }

    if (!hasStrides) {
      return <NoStrides />;
    }

    if (record.type === 'dynamic') {
      return <Dynamic recordData={recordData} record={record} />;
    }

    return <Metric recordData={recordData} record={record} />;
  };

  return (
    <ReportLayout
      backTo={listRecordsPath}
      buttonBackChildren={<Translate>backToRecords</Translate>}
      Header={<Header record={record} />}
      DownloadButton={hasStrides && <DownloadButtons record={record} recordId={record.key} />}
    >
      { getChild() }
    </ReportLayout>
  );
}

RecordWithStrides.propTypes = {
  record: PropTypes.shape({
    key: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  listRecordsPath: PropTypes.string.isRequired,
};

export default RecordWithStrides;
