import PropTypes from 'prop-types';

import PageProgress from '../display/page-progress';

import NoPatients from './NoPatients';
import List from './List';

import useNumberPatients from './useNumberPatients';

function PatientList({ orgaId = undefined, blockedPatients = false }) {
  const [nbPatients, isLoading] = useNumberPatients(orgaId, blockedPatients);

  if (isLoading) {
    return (<PageProgress />);
  }

  if (nbPatients === 0) {
    return (
      <NoPatients
        linkTo={orgaId ? `/organisations/${orgaId}/patients/new` : '/patients/new'}
        blockedPatients={blockedPatients}
      />
    );
  }

  return (<List orgaId={orgaId} blockedPatients={blockedPatients} />);
}

PatientList.propTypes = {
  blockedPatients: PropTypes.bool,
  orgaId: PropTypes.string,
};

export default PatientList;
