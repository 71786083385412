import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';

import MetricRecord from '~/components/MetricRecord';
import Translate from '~/components/display/translate';
import CardHeader from '~/components/CardHeader';

import Velocity from '~/utils/metrics/velocity';
import MaxVelocity from '~/utils/metrics/max-velocity';
import Cadence from '~/utils/metrics/cadence';
import StrideLength from '~/utils/metrics/stride-length';
import MaxStrideLength from '~/utils/metrics/max-stride-length';

function WalkingMetricsCard({
  velocity,
  maxVelocity,
  stdVelocity,
  cadence,
  stdCadence,
  strideLength,
  maxStrideLength,
  stdStrideLength,
  isPdf = false,
}) {
  return (
    <Card sx={{ width: 1 }}>
      <CardHeader title={<Translate>walkingMetrics</Translate>} isPdf={isPdf} />
      <CardContent sx={[isPdf && { padding: 0, paddingTop: '1.5rem' }]}>
        <Grid container direction="row" justifyContent="space-around" flexWrap="wrap" rowSpacing={7} columnSpacing={2}>
          <Grid>
            <MetricRecord
              value={velocity}
              std={stdVelocity}
              metric={Velocity}
              showUnit
              isPdf={isPdf}
            />
          </Grid>
          <Grid>
            <MetricRecord value={maxVelocity} metric={MaxVelocity} showUnit isPdf={isPdf} />
          </Grid>
          <Grid>
            <MetricRecord
              value={cadence}
              std={stdCadence}
              metric={Cadence}
              showUnit
              isPdf={isPdf}
            />
          </Grid>
          <Grid>
            <MetricRecord
              value={strideLength}
              std={stdStrideLength}
              metric={StrideLength}
              showUnit
              isPdf={isPdf}
            />
          </Grid>
          <Grid>
            <MetricRecord value={maxStrideLength} metric={MaxStrideLength} showUnit isPdf={isPdf} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

WalkingMetricsCard.propTypes = {
  velocity: PropTypes.number.isRequired,
  maxVelocity: PropTypes.number.isRequired,
  stdVelocity: PropTypes.number.isRequired,
  cadence: PropTypes.number.isRequired,
  stdCadence: PropTypes.number.isRequired,
  strideLength: PropTypes.number.isRequired,
  maxStrideLength: PropTypes.number.isRequired,
  stdStrideLength: PropTypes.number.isRequired,
  isPdf: PropTypes.bool,
};

export default WalkingMetricsCard;
