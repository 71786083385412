import PropTypes from 'prop-types';

import Select from '~/components/display/select';
import Translate from '~/components//display/translate';

function Role({ value = 'member', onChange }) {
  return (
    <Select
      label={<Translate>roleOrganisation</Translate>}
      value={value}
      onChange={ev => onChange({ name: 'role', value: ev.target.value })}
      options={[
        { key: 'orgaAdmin', label: (<Translate>orgaAdmin</Translate>) },
        { key: 'member', label: (<Translate>member</Translate>) },
      ]}
      helperText={<Translate>managerCanInviteMembersToJoin</Translate>}
    />
  );
}

Role.propTypes = {
  value: PropTypes.oneOf(['orgaAdmin', 'member']),
  /**
   * onChanged will be called with ({ name: 'role', data, error })
   * when the data has changed
   */
  onChange: PropTypes.func.isRequired,
};

export default Role;
