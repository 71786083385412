import PropTypes from 'prop-types';

import Translate from '~/components/display/translate';
import SectionHeaderPaper from '~/components/display/section-header-paper';
import ButtonsForm from '~/components/form/buttons';

function Common(props) {
  const {
    isCreate,
    disabled,
    inProgress,
    onSubmit,
    onCancel,
    children,
  } = props;

  return (
    <form onSubmit={onSubmit} style={{ width: '100%' }}>
      <SectionHeaderPaper title={<Translate>information</Translate>} />
      { children }
      <ButtonsForm
        inProgress={inProgress}
        disabled={disabled}
        isCreate={isCreate}
        onCancel={onCancel}
        onSubmit={onSubmit}
        sticky
      />
    </form>
  );
}

Common.propTypes = {
  isCreate: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  inProgress: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Common;
