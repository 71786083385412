function ellipseArea(rx, ry) {
  return Math.PI * (rx / 100) * (ry / 100);
}

function ellipseMedioLateralRange(medioLateralWidth) {
  return medioLateralWidth / 100;
}

function ellipseAnteroPosteriorRange(anteroPosteriorWidth) {
  return anteroPosteriorWidth / 100;
}

export {
  ellipseArea,
  ellipseMedioLateralRange,
  ellipseAnteroPosteriorRange,
};
