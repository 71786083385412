import BaseMetric from './base';

function computePhase(phase) {
  const { nbPeriodsDone, nbPeriodsToValidate } = phase;

  if (nbPeriodsToValidate === 0) {
    return null;
  }

  return (Math.min(nbPeriodsDone, nbPeriodsToValidate) * 100) / nbPeriodsToValidate;
}

function computeUser(program) {
  if (program === undefined) {
    return null;
  }

  const today = new Date();

  const phases = program.phases.filter(phase => today >= new Date(phase.startDate));

  if (phases.length === 0) {
    return null;
  }

  const [nbPeriodsDone, nbPeriodsToValidate] = phases.reduce((acc, cur) => [
    // prevent the `nbPeriodsDone` to exceed `nbPeriodsToValidate`
    acc[0] + Math.min(cur.nbPeriodsDone, cur.nbPeriodsToValidate),
    acc[1] + cur.nbPeriodsToValidate,
  ], [0, 0]);

  if (nbPeriodsToValidate === 0) {
    return null;
  }

  return (nbPeriodsDone * 100) / nbPeriodsToValidate;
}

function computeSite(programs) {
  const today = new Date();
  const phases = programs.flatMap((program) => {
    if (program === undefined) {
      return [];
    }

    return program.phases;
  }).filter(phase => today >= new Date(phase.startDate));

  if (phases.length === 0) {
    return null;
  }

  const [nbPeriodsDone, nbPeriodsToValidate] = phases.reduce((acc, cur) => [
    // prevent the `nbPeriodsDone` to exceed `nbPeriodsToValidate`
    acc[0] + Math.min(cur.nbPeriodsDone, cur.nbPeriodsToValidate),
    acc[1] + cur.nbPeriodsToValidate,
  ], [0, 0]);

  if (nbPeriodsToValidate === 0) {
    return null;
  }

  return (nbPeriodsDone * 100) / nbPeriodsToValidate;
}

export default new BaseMetric({
  name: 'complianceLevel',
  defaultUnit: '%',
  formatOptions: () => ({ maximumFractionDigits: 0 }),
});

export {
  computePhase as computeComplianceLevelPhase,
  computeUser as computeComplianceLevelUser,
  computeSite as computeComplianceLevelSite,
};
