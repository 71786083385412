import SvgIcon from '@mui/material/SvgIcon';

import CrossIcon from './cross';

import { useAppContext } from '~/components/AppContext';

function Member(props) {
  const { isHealthProject } = useAppContext();

  if (isHealthProject) {
    return <CrossIcon {...props} />;
  }

  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor">
        <path d="M12.2 21C12.2 21 19.4 17.4 19.4 12V5.7L12.2 3L5 5.7V12C5 17.4 12.2 21 12.2 21Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  );
}

export default Member;
