import { useNavigate, useParams } from 'react-router-dom';

import Breadcrumbs from '~/components/Breadcrumbs';
import PaperPage from '../components/display/paper-page';
import HeaderPaper from '../components/display/header-paper';
import Edit from '../components/form/member/edit';
import Translate from '../components/display/translate';

import useMember from '../utils/hooks/useMember';
import { useAppContext } from '~/components/AppContext';

function EditMember() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [member, loading] = useMember(userId);
  const { isStudyProject } = useAppContext();

  const handleCancel = () => navigate('/members');
  const handleDone = () => navigate('/members');

  if (loading === true) {
    return null;
  }

  return (
    <>
      <Breadcrumbs
        links={[
          { type: 'member', to: '/members/' },
          { type: 'id', value: member.name, to: `/members/${userId}` },
        ]}
      />
      <PaperPage disableBottomPadding>
        <HeaderPaper
          title={(
            <>
              <Translate>{ isStudyProject ? 'investigator' : 'member' }</Translate>
              { ' ' }
              {member.name}
            </>
          )}
        />
        <Edit
          member={member}
          onCancel={handleCancel}
          onDone={handleDone}
        />
      </PaperPage>
    </>
  );
}

export default EditMember;
