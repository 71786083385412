import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Radio from '~/components/display/radio';
import Translate from '~/components/display/translate';
import HipIcon from '~/components/icons/hip';
import AnkleIcon from '~/components/icons/ankle';
import KneeIcon from '~/components/icons/knee';

import { KlingonBlack, StarfleetBlue } from '~/utils/colors';
import useUniqueId from '~/utils/hooks/useUniqueId';

function LimbSelectionField({ onChange, value }) {
  const labelId = useUniqueId('radio-limb');

  const getIcon = (limb) => {
    const sx = [
      { fontSize: 50, color: KlingonBlack[500] },
      value === limb && { color: StarfleetBlue[400] },
    ];
    if (limb === 'hip') return <HipIcon sx={sx} />;
    if (limb === 'ankle') return <AnkleIcon sx={sx} />;
    if (limb === 'knee') return <KneeIcon sx={sx} />;
    return null;
  };

  return (
    <FormControl component="fieldset" fullWidth>
      <FormLabel
        id={labelId}
        sx={{
          fontWeight: 500,
          fontSize: '0.8125rem',
          pb: 1.5,
          color: KlingonBlack[900],
          '&.Mui-focused': {
            color: 'inherit',
          },
        }}
        component="legend"
      >
        <Translate>limbOfTheBody</Translate>
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby={labelId}
        name="radio-buttons-limb"
        value={value}
        onChange={ev => onChange({ name: 'limb', value: ev.target.value })}
        sx={{ justifyContent: 'center' }}
      >
        <Stack direction="row" justifyContent="space-around" sx={{ width: 1 }}>
          { ['hip', 'ankle', 'knee'].map(limb => (
            <Stack
              key={limb}
              alignItems="center"
              sx={[
                {
                  border: '0.15rem solid',
                  borderColor: KlingonBlack[300],
                  borderRadius: '3px',
                  pt: 2,
                },
                (value === limb) && {
                  borderColor: StarfleetBlue[100],
                },
              ]}
            >
              { getIcon(limb) }
              <FormControlLabel
                sx={[
                  {
                    flexGrow: 1,
                    '& .MuiFormControlLabel-label': {
                      fontSize: '0.8125rem',
                      fontWeight: 400,
                    },
                  },
                  (value === limb) && {
                    '& .MuiFormControlLabel-label': {
                      fontWeight: 600,
                    },
                  },
                ]}
                value={limb}
                label={<Translate>{limb}</Translate>}
                labelPlacement="top"
                control={<Radio />}
              />
            </Stack>
          ))}
        </Stack>
      </RadioGroup>
    </FormControl>
  );
}

LimbSelectionField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf(['hip', 'ankle', 'knee', '']).isRequired,
};

export default LimbSelectionField;
