import PropTypes from 'prop-types';

import Translate from '../../display/translate';
import TableHead from '../head';

// note: some fields are added/modified in `components/table/records/index.jsx`
const headers = [
  { key: 'nameTranslated', label: (<Translate>name</Translate>), order: true },
  {
    key: 'recordType',
    label: (<Translate>type</Translate>),
    order: false,
    align: 'center',
  },
  {
    key: 'parametersTranslated',
    label: (<Translate>parameters</Translate>),
    order: true,
    align: 'center',
  },
  {
    key: 'organisation',
    label: (<Translate>organisation</Translate>),
    order: true,
    align: 'center',
  },
  {
    key: 'startTime',
    label: (<Translate>date</Translate>),
    order: true,
    align: 'center',
  },
  {
    key: 'duration',
    label: (<Translate>duration</Translate>),
    order: true,
    align: 'center',
  },
  {
    key: 'recordedByTranslated',
    label: (<Translate>recordedBy</Translate>),
    order: true,
    align: 'center',
  },
];

function Head({
  orderBy,
  order,
  onRequestSort,
  showOrganisation = false,
  ...rest
}) {
  return (
    <TableHead
      {...rest}
      headers={showOrganisation === true
        ? headers
        : headers.filter(({ key }) => key !== 'organisation')}
      orderBy={orderBy}
      order={order}
      onRequestSort={onRequestSort}
    />
  );
}

Head.propTypes = {
  orderBy: PropTypes.oneOf(headers.filter(i => i.order === true).map(i => i.key)).isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  onRequestSort: PropTypes.func.isRequired,
  showOrganisation: PropTypes.bool,
};

export default Head;
