import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function ProgramSelector({ program, onSelectProgram, programs = [] }) {
  const { name } = program;
  const index = programs.findIndex(p => p.key === program.key);

  return (
    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
      <IconButton
        disabled={index === -1 || index === 0}
        aria-label="previous program"
        onClick={() => onSelectProgram(programs[index - 1])}
      >
        <ChevronLeftIcon />
      </IconButton>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 600 }}>
        { name }
      </Typography>
      <IconButton
        disabled={index === -1 || index === programs.length - 1}
        aria-label="previous program"
        onClick={() => onSelectProgram(programs[index + 1])}
      >
        <ChevronRightIcon />
      </IconButton>
    </Stack>
  );
}

ProgramSelector.propTypes = {
  program: PropTypes.shape({
    name: PropTypes.string,
    key: PropTypes.string,
  }).isRequired,
  programs: PropTypes.arrayOf(PropTypes.object),
  onSelectProgram: PropTypes.func.isRequired,
};

export default ProgramSelector;
