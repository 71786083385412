import PropTypes from 'prop-types';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import TextField from '~/components/display/textfield';
import Translate from '~/components/display/translate';
import { translate } from '~/utils/i18n';
import { useAppContext } from '~/components/AppContext';

const filter = createFilterOptions();

const interventions = [
  { label: 'arthoscopy', value: 'arthoscopy' },
  { label: 'arthroplasty', value: 'arthroplasty' },
  { label: 'ligamentoplasty', value: 'ligamentoplasty' },
  { label: 'osteotomy', value: 'osteotomy' },
];

const interventionsWithProsthesis = [
  'arthroplasty',
];

function InterventionTypeField({ error = false, value = null, onChange }) {
  const { file } = useAppContext();

  let selected = interventions.find(i => i.value === value);
  if (selected === undefined) {
    if (value === '' || value === null) {
      selected = null;
    } else {
      selected = { label: value, value };
    }
  }

  return (
    <Autocomplete
      value={selected}
      onChange={(_, option) => {
        let newValue;
        if (typeof option === 'string' || option === null) {
          newValue = option;
        } else {
          newValue = option.value;
        }
        onChange({ name: 'interventionType', value: newValue, error: !newValue });
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(option => inputValue === option.value);
        if (inputValue !== '' && !isExisting) {
          filtered.push({ label: inputValue, value: inputValue });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      options={interventions}
      renderOption={({ key, ...others }, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li key={key} {...others}>
          { interventions.find(i => i.value === option.value)
            ? (<Translate>{option.label}</Translate>)
            : (
              <div>
                <Translate>add</Translate>
                {` "${option.label}"`}
              </div>
            )}
        </li>
      )}
      freeSolo
      getOptionLabel={(option) => {
        if (typeof option === 'string' || option === null) {
          return '';
        }
        return interventions.find(i => i.value === option.value)
          ? translate(option.label, file)
          : option.label;
      }}
      renderInput={params => (
        <TextField
          {...params}
          required
          error={error}
          label={<Translate>interventionType</Translate>}
        />
      )}
    />
  );
}

InterventionTypeField.propTypes = {
  error: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default InterventionTypeField;

export {
  interventionsWithProsthesis,
};
