import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import TextField from '../../display/textfield';
import Translate from '../../display/translate';
import ButtonsForm from '../buttons';
import Organisations from '../../field/organisations';
import SectionHeaderPaper from '../../display/section-header-paper';

function Common({
  onCancel,
  onSubmit,
  isCreate,
  name: nameInit = '',
  description: descriptionInit = '',
  organisationsId: organisationsIdInit = [],
}) {
  const [name, setName] = useState(nameInit);
  const [description, setDescription] = useState(descriptionInit);
  const [organisationsId, setOrganisationsId] = useState(organisationsIdInit);
  const [inProgress, setInProgress] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setName(nameInit);
    setDescription(descriptionInit);
    setOrganisationsId(organisationsIdInit);
  }, [nameInit, descriptionInit, ...organisationsIdInit]);

  // return true if the form is not valid, false otherwise
  const validate = () => {
    // check each field passed its own valdation
    if (Object.values(errors).find(i => i === true)) {
      return true;
    }

    // check the initial state
    if (!name) return true;

    return false;
  };

  const handleChange = (change) => {
    setErrors({ ...errors, [change.name]: change.error });
    switch (change.name) {
      case 'name':
        setName(change.value);
        break;
      case 'description':
        setDescription(change.value);
        break;
      case 'organisationsId':
        setOrganisationsId(change.value);
        break;
      default:
        throw new Error(`Unknown change type: ${change.name}`);
    }
  };

  const handleSubmit = async () => {
    if (validate()) {
      return;
    }

    setInProgress(true);
    await onSubmit({
      name,
      description,
      organisationsId,
    });
    if (isCreate) {
      setName('');
      setDescription('');
    }
    setInProgress(false);
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        <Grid item>
          <SectionHeaderPaper title={<Translate>information</Translate>} />
        </Grid>
        <Grid item>
          <TextField
            required
            autoFocus
            error={errors.name}
            value={name}
            placeholder="enterName"
            onChange={ev => (
              handleChange({ name: 'name', value: ev.target.value, error: !ev.target.value })
            )}
            label={<Translate>labelName</Translate>}
            helperText={<Translate>labelNameExample</Translate>}
          />
        </Grid>
        <Grid item>
          <TextField
            multiline
            rows={4}
            error={errors.description}
            maxLength={200}
            value={description}
            placeholder="aboutLabel"
            onChange={ev => handleChange({ name: 'description', value: ev.target.value, error: !!ev.target.value && ev.target.value.length > 200 })}
            label={<Translate>description</Translate>}
            helperText={<Translate input={{ NB: 200 }}>xCharactersMaximum</Translate>}
          />
        </Grid>
        <Grid item>
          <SectionHeaderPaper title={<Translate>orgaSelection</Translate>} />
        </Grid>
        <Grid item>
          <Organisations
            organisationsId={organisationsId}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <ButtonsForm
        inProgress={inProgress}
        disabled={validate()}
        isCreate={isCreate}
        onCancel={() => onCancel()}
        onSubmit={() => handleSubmit()}
        sticky
      />
    </form>
  );
}

Common.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  organisationsId: PropTypes.arrayOf(PropTypes.string),
  isCreate: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default Common;
