import PropTypes from 'prop-types';

import TextField from '../display/textfield';
import Translate from '../display/translate';

import { useAppContext } from '~/components/AppContext';

function MemberName({ onChange, ...props }) {
  const { isStudyProject } = useAppContext();

  return (
    <TextField
      minLength={2}
      placeholder={isStudyProject ? 'enterInvestigatorName' : 'enterMemberName'}
      onChange={ev => onChange({
        name: 'name',
        value: ev.target.value,
        error: !ev.target.value || ev.target.value.length < 2,
      })}
      label={<Translate>{ isStudyProject ? 'investigatorName' : 'memberName' }</Translate>}
      {...props}
    />
  );
}

MemberName.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default MemberName;
