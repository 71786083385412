import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { scaleLinear } from 'd3-scale';
import {
  Graph,
  Rects,
  MouseContainer,
  useGraphDimensions,
} from '@feetme/d3act';

import MetricValue from '~/components/MetricValue';
import Axis from '../axis';
import Translate from '../../display/translate';
import SingleSupportPercentageLines from './single-support-percentage-lines';
import SupportPhaseLines from './support-phase-lines';
import OscillationPhaseLines from './oscillation-phase-lines';
import TextBox from './text-box';
import TextHeader from './text-header';

import useUniqueId from '../../../utils/hooks/useUniqueId';
import { SingleSupportPercentage, SupportPhase, OscillationPhase } from '../../../utils/metrics';
import { CadetOrange, KlingonBlack, StarfleetBlue } from '../../../utils/colors';

const BOX_MARGIN_TOP = 27;
const BOX_MARGIN_BOTTOM = 27;
const BOX_INTER_MARGIN = 2;

function GaitCycle({ lines, height = 325, isPdf = false }) {
  const [ref, dimensions] = useGraphDimensions({
    marginTop: 90,
    marginLeft: isPdf ? 20 : 60,
    marginRight: isPdf ? 100 : 60,
    height,
  });
  const [mouseHover, setOnMouseHover] = useState(false);
  const patternId = useUniqueId('pattern');

  const boxHeight = (dimensions.boundedHeight
    - BOX_MARGIN_TOP - BOX_MARGIN_BOTTOM
    - (lines.length - 1) * BOX_INTER_MARGIN)
    / lines.length;

  const xScale = scaleLinear()
    .domain([-5, 105])
    .range([0, dimensions.boundedWidth]);

  const widthAccessorScaled = d => xScale(d.width) - xScale(0);
  const xAccessorScaled = d => xScale(d.x);
  const yAccessorScaled = (d, i) => BOX_MARGIN_TOP + (i * boxHeight) + (i - 1) * BOX_INTER_MARGIN;

  // keep track of line to draw the vertical lines
  const singleSupportPercentageLine = lines
    .find(line => line.main.metric.key === SingleSupportPercentage.key);
  const supportPhaseLine = lines.find(line => line.main.metric.key === SupportPhase.key);
  const oscillationPhaseLine = lines.find(line => line.main.metric.key === OscillationPhase.key);

  return (
    <div ref={ref} style={{ position: 'relative', height }}>
      <Graph dimensions={dimensions}>
        <defs>
          <pattern id={patternId} patternUnits="userSpaceOnUse" width="10" height="10">
            <path
              d="M 0,10 l 10,-10 M -2.5,2.5 l 5,-5 M 7.5,12.5 l 5,-5"
              strokeWidth={2}
              stroke={KlingonBlack[300]}
              shapeRendering="auto"
              strokeLinecap="square"
            />
          </pattern>
        </defs>
        <g>
          {[0, 30, 60, 100].map(i => (
            <TextHeader
              key={i}
              index={i}
              x={xScale(i)}
              isPdf={isPdf}
            />
          ))}
        </g>
        <rect
          width={dimensions.boundedWidth}
          height={dimensions.boundedHeight}
          strokeWidth={1}
          stroke={KlingonBlack[400]}
          fill="none"
        />
        <Axis
          dimension="x"
          scale={xScale}
          keyAccessor={d => d}
          formatTick={d => `${d}%`}
          fullTick
          strokeDasharray="1, 10"
          strokeWidth={2}
          textTickStyle={(d) => {
            if ([0, 60, 100].indexOf(d) > -1) {
              return {
                fontWeight: 500,
                fill: KlingonBlack[700],
              };
            }

            return {};
          }}
          tickValues={[...Array(11).keys()].map(i => i * 10)}
        />
        { singleSupportPercentageLine && (
          <SingleSupportPercentageLines
            main={singleSupportPercentageLine.main}
            error={singleSupportPercentageLine.error}
            xScale={xScale}
            yAccessor={yAccessorScaled}
            boxHeight={boxHeight}
          />
        )}
        { supportPhaseLine && (
          <SupportPhaseLines
            main={supportPhaseLine.main}
            xScale={xScale}
            yAccessor={yAccessorScaled}
            maxHeight={dimensions.boundedHeight}
            hasSingleSupportPercentageLine={singleSupportPercentageLine !== undefined}
          />
        )}
        { oscillationPhaseLine && (
          <OscillationPhaseLines
            main={oscillationPhaseLine.main}
            error={oscillationPhaseLine.error}
            xScale={xScale}
            yAccessor={yAccessorScaled}
            maxHeight={dimensions.boundedHeight}
            hasSingleSupportPercentageLine={singleSupportPercentageLine !== undefined}
          />
        )}
        <Rects
          data={lines.map(i => i.main)}
          keyAccessor={d => `main${d.x}${d.width}`}
          xAccessor={xAccessorScaled}
          yAccessor={yAccessorScaled}
          widthAccessor={widthAccessorScaled}
          heightAccessor={() => boxHeight}
          fillAccessor={d => (d.isSupport ? StarfleetBlue[400] : StarfleetBlue[100])}
        />
        <Rects
          data={lines.map(i => i.error)}
          keyAccessor={d => `error${d.x}${d.width}`}
          xAccessor={xAccessorScaled}
          yAccessor={yAccessorScaled}
          widthAccessor={widthAccessorScaled}
          heightAccessor={() => boxHeight}
          fillAccessor={d => (d.isGreater ? CadetOrange[700] : `url(#${patternId})`)}
        />
        <g>
          { !isPdf && lines.map(i => i.main).map((d, i) => (
            <TextBox
              key={d.x}
              opacity={mouseHover ? 0 : 1}
              fill={d.isSupport ? 'white' : StarfleetBlue[700]}
              x={xAccessorScaled(d) + widthAccessorScaled(d) / 2}
              y={yAccessorScaled(d, i) + boxHeight / 2}
            >
              <Translate>{d.metric.name}</Translate>
            </TextBox>
          ))}
          { !isPdf && lines.map(i => i.main).map((d, i) => (
            <TextBox
              key={d.x}
              opacity={mouseHover ? 1 : 0}
              fill={d.isSupport ? 'white' : StarfleetBlue[700]}
              x={xAccessorScaled(d) + widthAccessorScaled(d) / 2}
              y={yAccessorScaled(d, i) + boxHeight / 2}
            >
              <React.Fragment>
                <MetricValue metric={d.metric}>{d.value}</MetricValue>
                {'% [σ: '}
                <MetricValue metric={d.metric}>{d.deviation}</MetricValue>
                {']'}
              </React.Fragment>
            </TextBox>
          ))}
          { isPdf && lines.map(i => i.main).map((d, i) => (
            <TextBox
              key={d.x}
              fill={d.isSupport ? 'white' : StarfleetBlue[700]}
              x={xAccessorScaled(d) + widthAccessorScaled(d) / 2}
              y={yAccessorScaled(d, i) + boxHeight / 2}
              fontSize={8}
            >
              <React.Fragment>
                <Translate>{d.metric.name}</Translate>
                {' '}
                <MetricValue metric={d.metric}>{d.value}</MetricValue>
                {'% [σ: '}
                <MetricValue metric={d.metric}>{d.deviation}</MetricValue>
                {']'}
              </React.Fragment>
            </TextBox>
          ))}
        </g>
        { !isPdf && (
          <MouseContainer
            rootRef={ref}
            onMouseEnter={() => setOnMouseHover(true)}
            onMouseLeave={() => setOnMouseHover(false)}
          />
        )}
      </Graph>
    </div>
  );
}

GaitCycle.propTypes = {
  lines: PropTypes.arrayOf(PropTypes.shape({
    main: PropTypes.shape({
      x: PropTypes.number,
      width: PropTypes.number,
      isSupport: PropTypes.bool,
      value: PropTypes.number,
      deviation: PropTypes.number,
      metric: PropTypes.oneOf([SingleSupportPercentage, SupportPhase, OscillationPhase]).isRequired,
    }),
    error: PropTypes.shape({
      x: PropTypes.number,
      width: PropTypes.number,
      isGreater: PropTypes.bool,
    }),
  })).isRequired,
  height: PropTypes.number,
  isPdf: PropTypes.bool,
};

export default GaitCycle;
