import PropTypes from 'prop-types';

import Select from '~/components/display/select';
import Translate from '~/components//display/translate';

import { useAppContext } from '~/components/AppContext';

function Status({ value = 'active', onChange }) {
  const { isStudyProject } = useAppContext();

  return (
    <Select
      label={<Translate>status</Translate>}
      value={value}
      onChange={ev => onChange({ name: 'status', value: ev.target.value })}
      options={[
        { key: 'active', label: (<Translate>active</Translate>) },
        { key: 'inactive', label: (<Translate>{ isStudyProject ? 'blocked' : 'archived' }</Translate>) },
      ]}
      helperText={(
        <Translate>{ isStudyProject ? 'ifBlockedAccessBlocked' : 'ifArchivedAccessBlocked' }</Translate>
      )}
    />
  );
}

Status.propTypes = {
  value: PropTypes.string,
  /**
   * onChanged will be called with ({ name: 'status', data, error })
   * when the data has changed
   */
  onChange: PropTypes.func.isRequired,
};

export default Status;
