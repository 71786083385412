import { useMemo } from 'react';
import PropTypes from 'prop-types';

import Translate from '../../display/translate';
import TableHead from '../head';

const headers = [
  { key: 'name', label: (<Translate>name</Translate>), order: true },
  { key: 'role', label: (<Translate>role</Translate>), order: true },
  { key: 'label', label: (<Translate>label</Translate>), order: false },
  { key: 'site', label: (<Translate>organisation</Translate>), order: false },
  {
    key: 'createdAt',
    label: (<Translate>joinedAt</Translate>),
    order: true,
    align: 'right',
  },
];

function Head(props) {
  const {
    orderBy,
    order,
    onRequestSort,
    blockedMembers,
    ...rest
  } = props;

  const filteredHeaders = useMemo(() => {
    let newHeaders = headers;
    if (blockedMembers) {
      newHeaders = newHeaders.filter(i => i.key !== 'role' && i.key !== 'createdAt');
    }

    if (!blockedMembers) {
      newHeaders = newHeaders.filter(i => i.key !== 'label' && i.key !== 'site');
    }

    return newHeaders;
  }, [blockedMembers]);

  return (
    <TableHead
      {...rest}
      headers={filteredHeaders}
      orderBy={orderBy}
      order={order}
      onRequestSort={onRequestSort}
    />
  );
}

Head.propTypes = {
  orderBy: PropTypes.oneOf(headers.filter(i => i.order === true).map(i => i.key)).isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  onRequestSort: PropTypes.func.isRequired,
  blockedMembers: PropTypes.bool.isRequired,
};

export default Head;
