import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import Table from '../components/table/labels/index';
import WarningDialog from '../components/dialog/warning';
import Translate from '../components/display/translate';

import useLabels from '../utils/hooks/useLabels';
import useOrganisations from '../utils/hooks/useOrganisations';
import useFuzzy from '../utils/hooks/useFuzzy';
import { useAppContext } from '~/components/AppContext';

import { fetchUsersFromLabel, deleteLabel } from '@/firebase/firestore';
import { translate } from '../utils/i18n';

// the labels collection has not information on the organisation
// We've fetch the organisation collection and we inject the organisation data
// for each label document when necessary
function loadOrganisations(labels, organisations) {
  return labels.map((label) => {
    const orgaWithLabel = organisations.filter((orga) => {
      if (orga.labels === undefined) {
        return false;
      }

      return orga.labels.includes(label.key);
    }).map(orga => ({
      key: orga.key,
      name: orga.name,
    }));

    return ({
      ...label,
      organisations: orgaWithLabel,
    });
  });
}

function List() {
  const [open, setOpen] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(0);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { file } = useAppContext();
  const [labels, isLoading] = useLabels(forceRefresh);
  const [organisations] = useOrganisations();
  const [filteredLabels] = useFuzzy(labels, search, 'name');

  const handleEdit = label => navigate(`/labels/${label.key}/edit`);
  const handleDelete = async (label) => {
    if (label.organisations && label.organisations.length > 0) {
      setOpen(true);
      return;
    }

    const usersWithLabel = await fetchUsersFromLabel(label.key);
    if (usersWithLabel.length > 0) {
      setOpen(true);
      return;
    }

    await deleteLabel(label.key);
    enqueueSnackbar(translate('labelDeleted', file), { variant: 'success' });
    setForceRefresh(forceRefresh + 1);
  };

  const handleChange = (change) => {
    if (change.name === 'search') {
      setSearch(change.value);
    }
  };

  return (
    <React.Fragment>
      <Table
        hasSearch
        labels={loadOrganisations(filteredLabels, organisations)}
        inProgress={isLoading}
        onDelete={handleDelete}
        onEdit={handleEdit}
        onChange={handleChange}
      />
      <WarningDialog
        open={open}
        onClick={() => setOpen(false)}
        title={<Translate>deleteLabel</Translate>}
        description={<Translate>orgaOrMemberAreLinkedToTheLabel</Translate>}
      />
    </React.Fragment>
  );
}

export default List;
