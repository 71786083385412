import SvgIcon from '@mui/material/SvgIcon';

function RecordTypeStatic(props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor">
        <path d="M 7.1698417,1.1320753 H 3.5471998 c -0.6405555,0 -1.2548377,0.2544453 -1.7077585,0.707366 -0.4529207,0.4529208 -0.7073661,1.067203 -0.7073661,1.7077585 v 3.6226419 m 21.7358498,0 V 3.5471998 c 0,-0.6405555 -0.2544,-1.2548377 -0.707321,-1.7077585 C 21.707683,1.3865206 21.093374,1.1320752 20.4528,1.1320752 h -3.622642 m 0,21.7358498 H 20.4528 c 0.640574,0 1.254883,-0.254491 1.707804,-0.707321 0.452921,-0.452921 0.707321,-1.06723 0.707321,-1.707804 v -3.622642 m -21.7358498,0 V 20.4528 c 0,0.640574 0.2544454,1.254883 0.7073661,1.707804 0.4529207,0.45283 1.0672029,0.707321 1.7077585,0.707321 h 3.6226419" strokeWidth="2.26415" strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="12" cy="12" r="2.7169812" fill="currentColor" strokeWidth="0.90566" />
      </svg>
    </SvgIcon>
  );
}

export default RecordTypeStatic;
