import {
  coreApp,
  getComponentApp,
  getProjectId,
  deleteComponentApp,
  initializeComponent,
} from './index';
import { refreshFirebaseApp as refreshFirestore } from './firestore';
import { authDispatch } from './functions';
import { componentAuth, signOutApp } from './auth';

async function signOutComponent() {
  if (getProjectId() !== undefined) {
    await signOutApp(getComponentApp());
  }
  await deleteComponentApp();
}

function connectFirebaseComponent(config, cloudFunctionsLocation, projectId, token) {
  initializeComponent(
    { ...config, appId: config.appId.dial, apiKey: config.apiKey.dial },
    cloudFunctionsLocation,
    projectId,
  );

  refreshFirestore();
  return componentAuth(token);
}

async function switchFirebaseComponent(selectedProjectId) {
  const { data } = await authDispatch({ projectId: selectedProjectId });
  if (!data.auth) {
    return Promise.reject();
  }

  await signOutComponent();

  const {
    projectId,
    token,
    cloudFunctionsLocation,
    config,
  } = data.auth;
  return connectFirebaseComponent(config, cloudFunctionsLocation, projectId, token);
}

async function signOutAll() {
  await signOutComponent();
  await signOutApp(coreApp);
}

export {
  connectFirebaseComponent,
  switchFirebaseComponent,
  signOutAll,
};
