import PropTypes from 'prop-types';

import DynamicHeatmapCard from '~/components/DynamicHeatmapCard';
import BaseMetric from './BaseMetric';

import { getInterpolationState } from '~/utils/record';
import useProcessedRecord from '~/utils/hooks/useProcessedRecord';
import useInsoles from '~/utils/hooks/useInsoles';

function Dynamic({ recordData, record }) {
  const [insoles, insolesPending] = useInsoles(record.key);

  const [
    isInterpolationPending,
    isInterpolationReady,
    hasInterpolationTimeout,
  ] = getInterpolationState(record);

  const [
    processedRecord,
    isProcessedRecordPending,
  ] = useProcessedRecord(record.key, [isInterpolationReady]);

  return (
    <BaseMetric recordData={recordData} record={record}>
      <DynamicHeatmapCard
        record={record}
        insoles={insoles}
        stridePressureIndexes={recordData.dynamicHeatmap.stridePressureIndexes}
        isInterpolationPending={isInterpolationPending}
        isInterpolationReady={isInterpolationReady}
        hasInterpolationTimeout={hasInterpolationTimeout}
        processedRecord={processedRecord}
        isProcessedRecordPending={isProcessedRecordPending
          || Object.keys(processedRecord).length === 0
          || insolesPending}
      />
    </BaseMetric>
  );
}

Dynamic.propTypes = {
  record: PropTypes.shape({
    key: PropTypes.string,
  }).isRequired,
  recordData: PropTypes.shape({
    dynamicHeatmap: PropTypes.shape({
      stridePressureIndexes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    }),
  }).isRequired,
};

export default Dynamic;
