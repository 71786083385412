import { useParams } from 'react-router-dom';

import Button from '~/components/display/button';

import ReportLayout from '~/components/ReportLayout';
import Translate from '~/components/display/translate';
import PageProgress from '~/components/display/page-progress';

import Header from './Header';
import Report from './Report';

import usePatient from '~/utils/hooks/usePatient';
import usePhaseProcess from '~/utils/hooks/usePhaseProcess';
import { getPhases } from '~/utils/program';
import { useAppContext } from '~/components/AppContext';

function ComplianceReport() {
  const {
    orgaId,
    patientResourceId,
    programId,
    phaseIndex,
  } = useParams();
  const { isHealthProject } = useAppContext();
  const [{ program }, isPatientPending] = usePatient(orgaId, patientResourceId);
  const [phaseData, processingPending, processingError] = usePhaseProcess(
    orgaId,
    patientResourceId,
    programId,
    [parseInt(phaseIndex, 10)],
  );

  // is there any records available in the phase
  // TODO check w/ phaseData
  const hasRecords = false;

  const getChild = () => {
    if (processingPending) {
      return <PageProgress />;
    }

    if (processingError) {
      return null;
    }

    return <Report data={phaseData} />;
  };

  if (isPatientPending) {
    return (<PageProgress />);
  }

  const phases = getPhases(program);
  const { endDate } = phases.at(-1);

  return (
    <ReportLayout
      backTo={`/organisations/${orgaId}/patients/${patientResourceId}`}
      buttonBackChildren={<Translate>backToOverview</Translate>}
      Header={(
        <Header
          startDate={phases[0].startDate}
          endDate={endDate}
          phaseName={phases[parseInt(phaseIndex, 10)].name}
        />
      )}
      DownloadButton={(
        isHealthProject && (
          <Button disabled={!hasRecords || new Date() < new Date(endDate)}>download</Button>
        )
      )}
    >
      { getChild() }
    </ReportLayout>
  );
}

export default ComplianceReport;
