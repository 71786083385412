import { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { scaleLinear } from 'd3-scale';
import { interpolateRgbBasis } from 'd3-interpolate';

import {
  Graph,
  Gradient,
  useGraphDimensions,
} from '@feetme/d3act';

import Translate from '~/components/display/translate';
import MetricValue from '~/components/MetricValue';

import {
  CadetOrange,
  ContinuumYellow,
  KlingonBlack,
  OrionTeal,
  PhaserRed,
  StarfleetBlue,
} from '~/utils/colors';
import Limping from '~/utils/metrics/limping';

import useUniqueId from '~/utils/hooks/useUniqueId';

function LimpingIndexGraph({ values, width = 0, height = 100 }) {
  const [ref, dimensions] = useGraphDimensions({
    height,
    width,
    marginTop: 25,
    marginBottom: 40,
    marginLeft: 0,
    marginRight: 0,
  });
  const gradientId = useUniqueId('gradient');

  const colorInterpolation = interpolateRgbBasis([
    PhaserRed[500],
    CadetOrange[300],
    ContinuumYellow[300],
    OrionTeal[200],

    OrionTeal[200],
    ContinuumYellow[300],
    CadetOrange[300],
    PhaserRed[500],
  ]);
  const scale = scaleLinear().domain([-100, 100]).range([0, 1]);
  const valueScale = scaleLinear()
    .domain([-100, 100])
    .range([0, dimensions.boundedWidth])
    .clamp(true);
  const colorScale = i => colorInterpolation(scale(i));

  return (
    <Box ref={ref} sx={{ position: 'relative', height }}>
      <Graph dimensions={dimensions}>
        <defs>
          <Gradient
            id={gradientId}
            stops={[
              -100,
              -75,
              -50,
              -25,
              -10,
              0,
              10,
              25,
              50,
              75,
              100,
            ].map(i => ({ offset: `${scale(i)}`, color: colorScale(i), opacity: '1' }))}
          />
        </defs>
        <rect
          x={0}
          y={0}
          rx={5}
          ry={5}
          width={dimensions.boundedWidth}
          height={dimensions.boundedHeight}
          fill={`url(#${gradientId})`}
        />
        <rect
          x={dimensions.boundedWidth / 2 - 1}
          y={0}
          width={2}
          height={dimensions.boundedHeight}
          fill={KlingonBlack[200]}
        />
        <text
          transform={`translate(0, ${dimensions.boundedHeight + 20})`}
          style={{
            fill: KlingonBlack[600],
            strokeWidth: 0,
          }}
        >
          <tspan x="0" dy="0em"><Translate>Left</Translate></tspan>
          <tspan x="0" dy="1.2em">100%</tspan>
        </text>
        <text
          transform={`translate(${dimensions.boundedWidth}, ${dimensions.boundedHeight + 20})`}
          style={{
            fill: KlingonBlack[600],
            strokeWidth: 0,
            textAnchor: 'end',
          }}
        >
          <tspan x="0" dy="0em"><Translate>Right</Translate></tspan>
          <tspan x="0" dy="1.2em">100%</tspan>
        </text>
        {values.map((d, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={i}>
            <circle
              cx={valueScale(d)}
              cy={(() => {
                if (values.length === 2) {
                  if (i === 0) {
                    return dimensions.boundedHeight / 4;
                  }
                  return (dimensions.boundedHeight / 4) * 3;
                }
                return dimensions.boundedHeight / 2;
              })()}
              r={5}
              fill="white"
              strokeWidth={2}
              style={{ display: d === null && 'none' }}
              stroke={i === 0 ? StarfleetBlue[400] : StarfleetBlue[900]}
            />
            <text
              // eslint-disable-next-line react/no-array-index-key
              x={valueScale(d)}
              y={i === 0 ? -10 : dimensions.boundedHeight + 20}
              textAnchor="middle"
              style={{
                fill: (i === 0 ? StarfleetBlue[400] : StarfleetBlue[900]),
                strokeWidth: 0,
                display: d === null && 'none',
              }}
            >
              <MetricValue metric={Limping} showUnit>{Math.abs(d)}</MetricValue>
            </text>
          </Fragment>
        ))}
      </Graph>
    </Box>
  );
}

LimpingIndexGraph.propTypes = {
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default LimpingIndexGraph;
