import PropTypes from 'prop-types';

import SvgIcon from '@mui/material/SvgIcon';
import { ContinuumYellow } from '../../utils/colors';

function Incomplete({ color = ContinuumYellow[700], ...props }) {
  return (
    <SvgIcon {...props}>
      <path d="m 23,16.250642 -1.107807,-1.107829 -2.820676,2.820766 -2.820796,-2.820766 -1.107807,1.107829 2.820675,2.820765 -2.820675,2.820765 L 16.250721,23 19.071517,20.179235 21.892193,23 23,21.892172 20.179325,19.071407 Z M 10.428601,19.85715 c -2.5006182,0 -4.8988265,-0.993431 -6.6670304,-2.761564 C 1.9933667,15.327331 1,12.929219 1,10.428563 1,7.9279546 1.9933667,5.5297586 3.7615706,3.7615641 5.5297745,1.9933697 7.9279828,1 10.428601,1 Z M 13.434757,3.1717172 c 0.952344,0.3956495 1.817838,0.9743963 2.547316,1.7034255 L 17.091452,3.7657131 C 16.216294,2.8908174 15.177823,2.1962169 14.034984,1.7212894 Z m 6.422408,7.2568458 C 19.857527,9.1906385 19.612676,7.9649064 19.136746,6.82214 L 17.68552,7.42242 c 0.396568,0.9525722 0.60059,1.9742925 0.600228,3.006143 z" fill={color} strokeWidth="1.21054" />
    </SvgIcon>
  );
}

Incomplete.propTypes = {
  color: PropTypes.string,
};

export default Incomplete;
