import { useMemo } from 'react';
import {
  Link,
  matchPath,
  useLocation,
  useParams,
  Navigate,
} from 'react-router-dom';
import Grid from '@mui/material/Grid';

import Breadcrumbs from '~/components/Breadcrumbs';
import Tabs from '../components/display/tabs';
import Tab from '../components/display/tab';
import TabPanel from '../components/display/tab-panel';
import Translate from '../components/display/translate';
import PageProgress from '../components/display/page-progress';

import { TabComplianceOverview, TabPatientEdit } from '~/components/Patient';
import TabObservance from '../components/TabObservance';
import TabRecord from './tab-record';

import useOrganisation from '../utils/hooks/useOrganisation';
import usePatient from '../utils/hooks/usePatient';

function Patient() {
  const { orgaId, patientResourceId } = useParams();
  const { pathname } = useLocation();
  const [organisation] = useOrganisation(orgaId);
  const [{
    patient,
    user,
    auth,
    program,
  }, isPatientPending] = usePatient(orgaId, patientResourceId, false);
  const match = useMemo(() => ([
    ['overview', matchPath({ path: '/organisations/:id/patients/:prid/overview', end: true }, pathname)],
    ['observance', matchPath({ path: '/organisations/:id/patients/:prid/observance', end: true }, pathname)],
    ['records', matchPath({ path: '/organisations/:id/patients/:prid/records/', end: true }, pathname)],
    ['edit', matchPath({ path: '/organisations/:id/patients/:prid/edit', end: true }, pathname)],
  ].find(i => i[1] !== null)[0]), [pathname]);

  if (isPatientPending) {
    return (<PageProgress />);
  }

  const showComplianceBoard = (user.status === 'active' && 'programDocumentId' in user);

  if (matchPath({ path: '/organisations/:id/patients/:prid/observance', end: true }, pathname) || matchPath({ path: '/organisations/:id/patients/:prid/overview', end: true }, pathname)) {
    if (!showComplianceBoard) {
      return (<Navigate to="records" replace />);
    }
  }

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Grid item>
          <Breadcrumbs
            links={[
              { type: 'organisation', to: '/organisations/' },
              { type: 'id', value: organisation.name, to: `/organisations/${orgaId}` },
              { type: 'id', value: patient.name, to: `/organisations/${orgaId}/patients/${patientResourceId}` },
            ]}
          />
        </Grid>
      </Grid>
      <Tabs value={match}>
        { showComplianceBoard && (
          <Tab
            label={<Translate>overview</Translate>}
            value="overview"
            to={`/organisations/${orgaId}/patients/${patientResourceId}/overview/`}
            component={Link}
          />
        )}
        { showComplianceBoard && (
          <Tab
            label={<Translate>observance</Translate>}
            value="observance"
            to={`/organisations/${orgaId}/patients/${patientResourceId}/observance/`}
            component={Link}
          />
        )}
        <Tab
          label={<Translate>record</Translate>}
          value="records"
          to={`/organisations/${orgaId}/patients/${patientResourceId}/records/`}
          component={Link}
        />
        <Tab
          label={<Translate>profile</Translate>}
          value="edit"
          to={`/organisations/${orgaId}/patients/${patientResourceId}/edit`}
          component={Link}
        />
      </Tabs>
      { showComplianceBoard && (
        <>
          <TabPanel value={match} active="overview" index={0}>
            <TabComplianceOverview
              userId={user.key}
              program={{ ...program, key: user.programDocumentId }}
            />
          </TabPanel>
          <TabPanel value={match} active="observance" index={1}>
            <TabObservance user={user} program={program || {}} />
          </TabPanel>
        </>
      )}
      <TabPanel value={match} active="records" index={2}>
        <TabRecord orgaId={orgaId} patientResourceId={patientResourceId} />
      </TabPanel>
      <TabPanel value={match} active="edit" index={3}>
        <TabPatientEdit
          orgaId={orgaId}
          patientResourceId={patientResourceId}
          patient={patient}
          user={user}
          auth={auth}
          program={program}
        />
      </TabPanel>
    </>
  );
}

export default Patient;
