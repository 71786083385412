import PropTypes from 'prop-types';

import PageProgress from '~/components/display/page-progress';

import NoMembers from './NoMembers';
import List from './List';

import useMembers from '~/utils/hooks/useMembers';

function MemberList({ orgaId = undefined, blockedMembers = false }) {
  const [members, isPending] = useMembers([orgaId], blockedMembers);

  if (isPending) {
    return (<PageProgress />);
  }

  if (members.length === 0) {
    return (<NoMembers />);
  }

  return (<List orgaId={orgaId} blockedMembers={blockedMembers} members={members} />);
}

MemberList.propTypes = {
  orgaId: PropTypes.string,
  blockedMembers: PropTypes.bool,
};

export default MemberList;
