import { fetchLabels } from '@/firebase/firestore';
import useFirestore from './useFirestore';

import { useAppContext } from '~/components/AppContext';

function useLabels(forceRefresh) {
  const { isProjectAdmin } = useAppContext();
  return useFirestore(() => fetchLabels(isProjectAdmin), [], [forceRefresh]);
}

export default useLabels;
