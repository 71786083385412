import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import { KlingonBlack } from '../../utils/colors';

function Tooltip({ opacity, transform, children }) {
  return (
    <Box
      style={{ opacity, transform }}
      sx={{
        position: 'absolute',
        left: 0,
        transition: 'all 0.2s ease-out',
        pointerEvents: 'none',
        zIndex: 1201,
        backgroundColor: KlingonBlack[800],
        color: KlingonBlack[500],
        borderRadius: '6px',
        fontWeight: 500,
        fontSize: '0.875rem',
        padding: 1.5,
        '&:before': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: '50%',
          width: 12,
          height: 12,
          backgroundColor: KlingonBlack[800],
          transform: 'translate(-50%, 50%) rotate(45deg)',
          transformOrigin: 'center center',
        },
      }}
    >
      { children }
    </Box>
  );
}

Tooltip.propTypes = {
  opacity: PropTypes.number.isRequired,
  transform: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.element,
  ]).isRequired,
};

export default Tooltip;
