import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import MenuAccount from './menu-account';

import { StarfleetBlue, VulcanIndigo } from '../utils/colors';
import researchLogo from '../assets/images/research-logo.svg';

function CustomAppBar() {
  return (
    <AppBar
      position="fixed"
      sx={{
        border: 'none',
        borderRadius: 0,
        backgroundColor: 'white',
        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.15)',
      }}
    >
      <Toolbar>
        <img style={{ width: '32px', height: '32px', marginRight: '12px' }} src={researchLogo} alt="FeetMe Research Logo" />
        <Typography
          sx={{
            color: VulcanIndigo[600],
            fontWeight: 600,
            display: { xs: 'none', sm: 'block' },
          }}
          component="h1"
          noWrap
        >
          FeetMe
        </Typography>
        <Box
          sx={{
            background: StarfleetBlue[100],
            width: '1px',
            height: 24,
            mx: '8px',
          }}
        />
        <Typography sx={{ color: StarfleetBlue[400] }} noWrap>
          Dashboard
        </Typography>
        <Box sx={{ flex: 1 }} />
        <MenuAccount />
      </Toolbar>
    </AppBar>
  );
}

export default CustomAppBar;
