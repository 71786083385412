import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';

import WalkingParametersCard from '~/components/WalkingParametersCard';
import LimpingIndexCard from '~/components/LimpingIndexCard';
import FreeWalkingParametersCard from '~/components/FreeWalkingParametersCard';
import NormativeGaitParametersCard from '~/components/NormativeGaitParametersCard';
import StaticHeatmapComplianceCard from '~/components/StaticHeatmapComplianceCard';

function Report({ data }) {
  return (
    <Stack justifyContent="stretch" alignItems="flex-start" spacing={3}>
      <WalkingParametersCard
        phases={data.phases}
        nbDays={data.walkingParameters.nbDays}
        doubleSupportMeans={data.walkingParameters.doubleSupportMeans}
        doubleSupportStds={data.walkingParameters.doubleSupportStds}
        singleSupportMeans={data.walkingParameters.singleSupportMeans}
        singleSupportStds={data.walkingParameters.singleSupportStds}
      />
      <LimpingIndexCard
        phases={data.phases}
        stanceValues={data.limpingIndex.stanceValues}
        loadingValues={data.limpingIndex.loadingValues}
        propulsionValues={data.limpingIndex.propulsionValues}
      />
      <FreeWalkingParametersCard
        phases={data.phases}
        means={data.freeWalkingParameters.means}
        stds={data.freeWalkingParameters.stds}
        startDate={new Date(data.freeWalkingParameters.startDate)}
        steps={data.freeWalkingParameters.steps}
        weekends={data.freeWalkingParameters.weekends}
      />
      <NormativeGaitParametersCard
        phases={data.phases}
        means={data.normativeGaitParameters.means}
        stds={data.normativeGaitParameters.stds}
        ages={data.normativeGaitParameters.ages}
        gender={data.normativeGaitParameters.gender}
      />
      <StaticHeatmapComplianceCard
        phases={data.phases}
        insoles={data.staticHeatmap.insoles}
        interpolationInfo={data.staticHeatmap.interpolationInfo}
        processedRecords={data.staticHeatmap.processedRecords}
        bipodalCops={data.staticHeatmap.bipodalCops}
      />
    </Stack>
  );
}

Report.propTypes = {
  data: PropTypes.shape({
    phases: PropTypes.arrayOf(PropTypes.string),
    walkingParameters: PropTypes.shape({
      nbDays: PropTypes.number,
      doubleSupportMeans: PropTypes.arrayOf(PropTypes.number),
      doubleSupportStds: PropTypes.arrayOf(PropTypes.number),
      singleSupportMeans: PropTypes.arrayOf(PropTypes.object),
      singleSupportStds: PropTypes.arrayOf(PropTypes.object),
    }),
    limpingIndex: PropTypes.shape({
      stanceValues: PropTypes.arrayOf(PropTypes.number),
      loadingValues: PropTypes.arrayOf(PropTypes.number),
      propulsionValues: PropTypes.arrayOf(PropTypes.number),
    }),
    freeWalkingParameters: PropTypes.shape({
      means: PropTypes.arrayOf(PropTypes.number),
      stds: PropTypes.arrayOf(PropTypes.number),
      startDate: PropTypes.string,
      steps: PropTypes.arrayOf(PropTypes.number),
      weekends: PropTypes.arrayOf(PropTypes.number),
    }),
    normativeGaitParameters: PropTypes.shape({
      means: PropTypes.arrayOf(PropTypes.shape({
        Cadence: PropTypes.number,
        Velocity: PropTypes.number,
        StrideLength: PropTypes.number,
      })),
      stds: PropTypes.arrayOf(PropTypes.shape({
        Cadence: PropTypes.number,
        Velocity: PropTypes.number,
        StrideLength: PropTypes.number,
      })),
      ages: PropTypes.arrayOf(PropTypes.number),
      gender: PropTypes.oneOf(['male', 'female']),
    }),
    staticHeatmap: PropTypes.shape({
      interpolationInfo: PropTypes.arrayOf(PropTypes.object),
      processedRecords: PropTypes.arrayOf(PropTypes.object),
      bipodalCops: PropTypes.arrayOf(PropTypes.object),
      insoles: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
};

export default Report;
