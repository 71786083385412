import { useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';

import Translate from '~/components/display/translate';
import CardHeader from '~/components/CardHeader';
import MetricDisplay from './MetricDisplay';
import MetricSelection from './MetricSelection';
import NormativeGaitParametersGraph from './NormativeGaitParametersGraph';
import PhasesLegend from '~/components/Graph/PhasesLegend';

import Cadence from '~/utils/metrics/cadence';
import Velocity from '~/utils/metrics/velocity';
import StrideLength from '~/utils/metrics/stride-length';

function NormativeGaitParametersCard({
  phases,
  means,
  stds,
  ages,
  gender,
  isPdf = false,
}) {
  const [selectedMetric, setSelectedMetric] = useState(Cadence);

  return (
    <Card sx={{ width: 1 }}>
      <CardHeader
        title={<Translate>normativeGaitParameters</Translate>}
        subheader={<Translate>parametersOfHealtyVolunteers</Translate>}
        isPdf={isPdf}
      />
      <CardContent sx={[isPdf && { padding: 0, paddingTop: '1.5rem' }]}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
          <Grid container direction="column" justifyContent="center" alignItems="center" xs={2}>
            <Grid sx={{ width: 1 }}>
              <MetricDisplay
                metric={selectedMetric}
                means={means.map(i => i[selectedMetric.key])}
                std={stds.at(-1)[selectedMetric.key]}
              />
            </Grid>
            <Grid>
              <MetricSelection
                metric={selectedMetric}
                onSelectMetric={metric => setSelectedMetric(metric)}
              />
            </Grid>
          </Grid>
          <Grid xs>
            <Stack>
              <NormativeGaitParametersGraph
                metric={selectedMetric}
                data={means.map((i, j) => ({ value: i[selectedMetric.key], age: ages[j] }))}
                gender={gender}
              />
              { phases.length > 1 && (
                <PhasesLegend
                  phases={phases}
                  hasFirstPhase={means[0][Cadence.key] !== 0 || stds[0][Cadence.key] !== 0
                    || means[0][Velocity.key] !== 0 || stds[0][Velocity.key] !== 0
                    || means[0][StrideLength.key] !== 0 || stds[0][StrideLength.key] !== 0}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

NormativeGaitParametersCard.propTypes = {
  phases: PropTypes.arrayOf(PropTypes.string).isRequired,
  means: PropTypes.arrayOf(PropTypes.shape({
    [Cadence.key]: PropTypes.number,
    [Velocity.key]: PropTypes.number,
    [StrideLength.key]: PropTypes.number,
  })).isRequired,
  stds: PropTypes.arrayOf(PropTypes.shape({
    [Cadence.key]: PropTypes.number,
    [Velocity.key]: PropTypes.number,
    [StrideLength.key]: PropTypes.number,
  })).isRequired,
  ages: PropTypes.arrayOf(PropTypes.number).isRequired,
  gender: PropTypes.oneOf(['male', 'female']).isRequired,
  isPdf: PropTypes.bool,
};

export default NormativeGaitParametersCard;
