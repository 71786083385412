import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import MuiTableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';

import TableSearch from './search';

function TableContainer({
  inProgress,
  hasSearch = false,
  placeholder = undefined,
  onChange = undefined,
  children,
}) {
  return (
    <Paper variant="outlined" sx={{ width: 1 }}>
      { inProgress && (
        <LinearProgress sx={{ borderRadius: '8px' }} />
      )}
      <MuiTableContainer>
        { hasSearch && (
          <TableSearch placeholder={placeholder} onChange={onChange} />
        )}
        <Table sx={{ minWidth: 700 }}>
          { children }
        </Table>
      </MuiTableContainer>
    </Paper>
  );
}

TableContainer.propTypes = {
  inProgress: PropTypes.bool.isRequired,
  hasSearch: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.bool])).isRequired,
};

export default TableContainer;
