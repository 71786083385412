import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import Translate from '~/components/display/translate';
import CardHeader from '~/components/CardHeader';
import PhasesLegend from '~/components/Graph/PhasesLegend';
import LimpingIndexGraph from './LimpingIndexGraph';
import LimpingIndexLegend from './LimpingIndexLegend';

import { StarfleetBlue, KlingonBlack } from '~/utils/colors';

function LimpingIndexCard({
  phases,
  stanceValues,
  loadingValues,
  propulsionValues,
}) {
  return (
    <Card sx={{ width: 1 }}>
      <CardHeader title={<Translate>limpingIndex</Translate>} />
      <CardContent>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
          <Grid xs={3}>
            <Box sx={{ borderLeft: `2px solid ${StarfleetBlue[100]}`, pl: 2 }}>
              <Typography
                sx={{
                  mb: 3,
                  color: KlingonBlack[700],
                  fontSize: '1.2rem',
                  fontWeight: 500,
                }}
              >
                <Translate>stanceAsymmetry</Translate>
              </Typography>
              <LimpingIndexGraph values={stanceValues} />
            </Box>
          </Grid>
          <Grid xs>
            <Box sx={{ borderLeft: `2px solid ${StarfleetBlue[100]}`, pl: 2 }}>
              <Typography sx={{ color: KlingonBlack[700], fontSize: '1.2rem', fontWeight: 500 }}>
                <Translate>groundReactionForceAsymmetry</Translate>
              </Typography>
              <Stack direction="row" gap={4}>
                <Box sx={{ width: 0.5 }}>
                  <Typography sx={{ color: KlingonBlack[700], fontSize: '0.9rem', fontWeight: 500 }}>
                    <Translate>loadingPhase</Translate>
                  </Typography>
                  <LimpingIndexGraph values={loadingValues} />
                </Box>
                <Box sx={{ width: 0.5 }}>
                  <Typography sx={{ color: KlingonBlack[700], fontSize: '0.9rem', fontWeight: 500 }}>
                    <Translate>propulsionPhase</Translate>
                  </Typography>
                  <LimpingIndexGraph values={propulsionValues} />
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={4} sx={{ pt: 4 }}>
          <LimpingIndexLegend />
          { phases.length > 1 && (
            <PhasesLegend
              phases={phases}
              hasFirstPhase={stanceValues[0] !== null
                || loadingValues[0] !== null
                || propulsionValues[0] !== null}
            />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

LimpingIndexCard.propTypes = {
  phases: PropTypes.arrayOf(PropTypes.string).isRequired,
  stanceValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  loadingValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  propulsionValues: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default LimpingIndexCard;
