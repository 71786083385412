import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import TableBase from './base';

import { stableSort, getComparator } from '../../utils/list-sort';

function Table({
  data,
  defaultOrderBy,
  defaultOrder = 'asc',
  hasPagination = false,
  rowsPerPage = 10,
  onSelectAll = undefined,
  onChange = undefined,
  ...otherProps
}) {
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [order, setOrder] = useState(defaultOrder);

  const handleRequestSort = (newOrderBy, newOrder) => {
    setOrderBy(newOrderBy);
    setOrder(newOrder);
  };

  const listData = useMemo(() => {
    const sorted = stableSort(data, getComparator(order, orderBy));
    if (!hasPagination) {
      return sorted;
    }

    return sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [data.length, data, page, rowsPerPage, order, orderBy, hasPagination]);

  const handleSelectAll = (ev) => {
    if (onSelectAll === undefined) {
      return;
    }

    if (ev.target.checked) {
      onSelectAll(listData);
    } else {
      onSelectAll([]);
    }
  };

  const handleChange = (change) => {
    if (change.name === 'page') {
      setPage(change.value);
    } else if (onChange !== undefined) {
      if (change.name === 'search') {
        setPage(0);
      }
      onChange(change);
    }
  };

  return (
    <TableBase
      {...otherProps}
      data={listData}
      hasPagination={hasPagination}
      onSelectAll={handleSelectAll}
      onChange={handleChange}
      rowsPerPage={rowsPerPage}
      order={order}
      orderBy={orderBy}
      page={page}
      total={data.length}
      onRequestSort={handleRequestSort}
    />
  );
}

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
  })).isRequired,

  defaultOrderBy: PropTypes.string.isRequired,
  defaultOrder: PropTypes.string,
  hasPagination: PropTypes.bool,

  rowsPerPage: PropTypes.number,
  onSelectAll: PropTypes.func,
  onChange: PropTypes.func,
};

export default Table;
