import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

function StickyWrapper({ sticky = false, children }) {
  const ref = useRef(null);

  useEffect(() => {
    // see https://css-tricks.com/how-to-detect-when-a-sticky-element-gets-pinned/
    const element = ref.current;
    // `element` will be null if the `sticky` props is `false`
    if (element === null) {
      return () => {};
    }

    const observer = new IntersectionObserver(
      ([e]) => e.target.classList.toggle('is-pinned', e.intersectionRatio < 1),
      { threshold: 1 },
    );

    observer.observe(element);

    return () => observer.unobserve(element);
  }, []);

  if (sticky === false) {
    return children;
  }

  return (
    <Box
      ref={ref}
      sx={[
        {
          position: 'sticky',
          bottom: '-1px',
          py: 2,
          zIndex: 2,
          mx: -3,
          px: 3,
        },
        {
          '&.is-pinned': {
            boxShadow: '2px -2px 6px 0px rgba(0, 0, 0, 0.03)',
            bgcolor: 'white',
          },
        },
      ]}
    >
      { children }
    </Box>
  );
}

StickyWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  sticky: PropTypes.bool,
};

export default StickyWrapper;
