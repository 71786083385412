import { firestoreTimestampToDate } from '~/utils/date';

const EXO_RECORD_TYPES = [{
  // support transfer
  key: 'posturofeedback',
  label: 'posturoFeedback',
}, {
  // rhythmic stimulation
  key: 'clockedwalk',
  label: 'rhythmicWalking',
}, {
  // heel strike (fr: déroulé du pas) - endurance
  key: 'heelstrike',
  label: 'heelstrikeDetection',
}, {
  // stabilization
  key: 'stabilisation',
  label: 'stabilization',
}, {
  // balance & speed
  key: 'velocity',
  label: 'staticStabilityAndSpeed',
}, {
  // plantar flexion
  key: 'plantarflexion',
  label: 'plantarFlexion',
}, {
  // big steps (fr: marche à grands pas)
  key: 'stridelength',
  label: 'bigSteps',
}, {
  // free walking
  key: 'walkingtest',
  label: 'freeWalking',
}, {
  // heel strike (fr: déroulé du pas) - gait quality
  key: 'heelstrikegait',
  label: 'heelstrikeGaitQuality',
}];

function getRecordName(record) {
  return record.name || record.recordType;
}

function hasPressures(record) {
  return record.type === 'static' || record.type === 'dynamic';
}

function hasStrides(record) {
  return record.type === 'dios' || record.type === 'dynamic';
}

// return an array with three value:
// - is the interpolation pending
// - is the interpolation ready
// - has the interpolation timeout
function getInterpolationState(record) {
  if (!('interpolation' in record)) {
    return [true, false, false];
  }

  if ('updated' in record.interpolation) {
    return [false, true, false];
  }

  const createdAt = firestoreTimestampToDate(record.interpolation.createdAt);
  const now = new Date();
  // set the interpolation as timeout after 6 minutes
  if (now.getTime() > (createdAt.getTime() + 6 * 60 * 1000)) {
    return [false, false, true];
  }

  return [true, false, false];
}

export {
  EXO_RECORD_TYPES,

  getRecordName,
  hasPressures,
  hasStrides,
  getInterpolationState,
};
