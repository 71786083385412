import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import TagRoundedIcon from '@mui/icons-material/TagRounded';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import ComplianceMetric from '../ComplianceMetric';
import Translate from '../display/translate';
import NumberDisplay from '../display/number';
import DateDisplay from '../display/date';

function ComplianceImport({ nbSynchro, lastImportStartTime, lastImportTimeZone = undefined }) {
  return (
    <Grid container direction="column" sx={{ width: 1 }}>
      <Grid>
        <Typography sx={{ fontWeight: 600, fontSize: '16px', mb: 6 }}>
          <Translate>standaloneImportCompliance</Translate>
        </Typography>
      </Grid>
      <Grid container justifyContent="space-evenly">
        <Grid>
          <ComplianceMetric
            Icon={TagRoundedIcon}
            subtitle={<Translate>nbImports</Translate>}
          >
            <NumberDisplay>{nbSynchro}</NumberDisplay>
          </ComplianceMetric>
        </Grid>
        <Grid>
          <ComplianceMetric
            Icon={CalendarTodayIcon}
            subtitle={<Translate>lastImport</Translate>}
          >
            <DateDisplay options={{ timeZone: lastImportTimeZone }}>
              {lastImportStartTime}
            </DateDisplay>
          </ComplianceMetric>
        </Grid>
      </Grid>
    </Grid>
  );
}

ComplianceImport.propTypes = {
  nbSynchro: PropTypes.number.isRequired,
  lastImportStartTime: PropTypes.number.isRequired,
  lastImportTimeZone: PropTypes.string,
};

export default ComplianceImport;
