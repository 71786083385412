import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import MetricValue from '~/components/MetricValue';

import { LoadPercentage } from '~/utils/metrics';
import { KlingonBlack } from '~/utils/colors';

function LoadPercentageDisplay({ value = undefined }) {
  if (value === undefined) {
    return null;
  }

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
      <Grid>
        <MetricValue metric={LoadPercentage}>{value}</MetricValue>
      </Grid>
      <Grid>
        <Typography sx={{ color: KlingonBlack[600], fontSize: '0.875rem', fontWeight: 500 }}>
          {LoadPercentage.unit}
        </Typography>
      </Grid>
    </Grid>
  );
}

LoadPercentageDisplay.propTypes = {
  value: PropTypes.number,
};

export default LoadPercentageDisplay;
