import { Fragment, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { extent, min, max } from 'd3-array';
import { scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';

import {
  Area,
  Graph,
  useGraphDimensions,
} from '@feetme/d3act';

import Axis from '~/components/graph/axis';
import Path from '~/components/graph/path';
import Translate from '~/components/display/translate';
import MetricValue from '~/components/MetricValue';
import Tooltip from '~/components/graph/tooltip';

import { StarfleetBlue, KlingonBlack } from '~/utils/colors';
import BaseMetric from '~/utils/metrics/base';
import useUnit from '~/utils/hooks/useUnit';

import cadenceNormativeData from './cadence.json';
import velocityNormativeData from './velocity.json';
import strideLengthNormativeData from './stride-length.json';

function NormativeGaitParametersGraph({
  metric,
  data,
  gender,
  height = 250,
  width = 0,
}) {
  const unit = useUnit(metric);
  const [ref, dimensions] = useGraphDimensions({ height, width });
  const [tooltipOpacity, setTooltipOpacity] = useState(0);
  const [tooltipTransform, setTooltipTransform] = useState('');
  const [tooltipValue, setTooltipValue] = useState(0);

  const normativeData = useMemo(() => {
    let selectedNormativeData;
    if (metric.key === 'cadence') {
      selectedNormativeData = cadenceNormativeData;
    } else if (metric.key === 'velocity') {
      selectedNormativeData = velocityNormativeData;
    } else {
      selectedNormativeData = strideLengthNormativeData;
    }

    return selectedNormativeData
      .filter(d => d.gender === gender)
      .sort((a, b) => a.age - b.age);
  }, [metric.key, gender]);

  const xAccessor = d => d.age;

  const xScale = scaleLinear()
    .domain(extent([...normativeData, ...data], xAccessor))
    .range([0, dimensions.boundedWidth]);

  const yScale = scaleLinear()
    .domain([
      min([
        ...normativeData,
        ...data.filter(i => i.value !== 0).map(i => ({ lowerBound: i.value })),
      ], d => d.lowerBound),
      max([
        ...normativeData,
        ...data.filter(i => i.value !== 0).map(i => ({ upperBound: i.value })),
      ], d => d.upperBound),
    ])
    .range([dimensions.boundedHeight, 0])
    .nice();

  const xAccessorScaled = d => xScale(xAccessor(d));

  const handleMouseEnter = (d) => {
    const target = select(d.target);
    const [cx, cy] = [target.attr('cx'), target.attr('cy')];
    const value = yScale.invert(cy);
    const x = xScale(xScale.invert(cx)) + dimensions.marginLeft;
    const y = yScale(yScale.invert(cy)) + dimensions.marginTop;

    setTooltipValue(value);
    setTooltipTransform(`translate(calc(-50% + ${x}px), calc(-100% + ${y}px - 15px))`);
    setTooltipOpacity(1);
  };

  const handleMouseLeave = () => {
    setTooltipOpacity(0);
  };

  const yTickValues = yScale.ticks(5);

  const hasData = index => data[index].value !== 0;

  return (
    <div ref={ref} style={{ position: 'relative', height }}>
      <Tooltip opacity={tooltipOpacity} transform={tooltipTransform}>
        <Translate>{metric.name}</Translate>
        {' '}
        <span style={{ color: 'white' }}>
          <MetricValue metric={metric} showUnit>{tooltipValue}</MetricValue>
        </span>
      </Tooltip>
      <Graph dimensions={dimensions}>
        <Axis
          dimension="x"
          scale={xScale}
          keyAccessor={d => d}
          showDomainLine
        />
        <text
          transform={`translate(${dimensions.boundedWidth + 5}, ${dimensions.boundedHeight})`}
          style={{
            fill: KlingonBlack[600],
            strokeWidth: 0,
          }}
        >
          <Translate>year</Translate>
        </text>
        <Axis
          dimension="y"
          fullTick={false}
          scale={yScale}
          keyAccessor={d => d}
          tickValues={yTickValues}
        />
        { yTickValues.map((d, i) => {
          if (i === 0) {
            return null;
          }
          return (
            <line
              key={d}
              x1={0}
              x2={dimensions.boundedWidth}
              y1={yScale(d)}
              y2={yScale(d)}
              stroke={KlingonBlack[500]}
              strokeDasharray="1,10"
            />
          );
        })}
        <text
          transform="translate(0, -20)"
          style={{
            fill: KlingonBlack[600],
            strokeWidth: 0,
            textAnchor: 'middle',
          }}
        >
          <Translate canFail>{unit}</Translate>
        </text>
        <Area
          data={normativeData}
          xAccessor={xAccessorScaled}
          y0Accessor={d => yScale(d.lowerBound)}
          y1Accessor={d => yScale(d.upperBound)}
          fill={StarfleetBlue[100]}
          fillOpacity={0.5}
        />
        <Path
          data={normativeData}
          stroke={StarfleetBlue[400]}
          xAccessor={xAccessorScaled}
          yAccessor={d => yScale(d.mean)}
          strokeWidth={1}
        />
        {data.map((d, i) => {
          if (!hasData(i)) {
            return null;
          }

          return (
            <Fragment key={d.value}>
              <line
                x1={xScale.range()[0]}
                x2={xScale.range()[1]}
                y1={yScale(d.value)}
                y2={yScale(d.value)}
                stroke={(i === 0 ? StarfleetBlue[400] : StarfleetBlue[900])}
                strokeWidth={0.7}
                strokeDasharray="3, 3"
              />
              <line
                x1={xScale(d.age)}
                x2={xScale(d.age)}
                y1={yScale.range()[0]}
                y2={yScale.range()[1]}
                stroke={(i === 0 ? StarfleetBlue[400] : StarfleetBlue[900])}
                strokeWidth={0.7}
                strokeDasharray="3, 3"
              />
            </Fragment>
          );
        })}
        {data.map((d, i) => {
          if (!hasData(i)) {
            return null;
          }

          return (
            <circle
              key={d.value}
              cx={xAccessorScaled(d)}
              cy={yScale(d.value)}
              r={4}
              fill="white"
              strokeWidth={2}
              stroke={(i === 0 ? StarfleetBlue[400] : StarfleetBlue[900])}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          );
        })}
      </Graph>
    </div>
  );
}

NormativeGaitParametersGraph.propTypes = {
  metric: PropTypes.instanceOf(BaseMetric).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    age: PropTypes.number,
    value: PropTypes.number,
  })).isRequired,
  gender: PropTypes.oneOf(['male', 'female']).isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default NormativeGaitParametersGraph;
