import PropTypes from 'prop-types';
import { scaleBand, scaleLinear } from 'd3-scale';
import { timeFormat } from 'd3-time-format';
import { select } from 'd3-selection';
import textures from 'textures';

import {
  Graph,
  Rects,
  useGraphDimensions,
} from '@feetme/d3act';

import Axis from '~/components/graph/axis';

import { VoyagerPurple, StarfleetBlue, KlingonBlack } from '~/utils/colors';

const ONE_DAY_MS = 24 * 60 * 60 * 1000;

function DailyDistributionGraph({
  startDate,
  steps,
  weekends,
  height = 250,
  width = 0,
}) {
  const [ref, dimensions] = useGraphDimensions({ height, width });

  const xAccessor = (_, i) => i;
  const yAccessor = d => d;

  const xScale = scaleBand()
    .domain([...Array(steps.length).keys()])
    .range([0, dimensions.boundedWidth])
    .paddingInner([0.2]);

  const yScale = scaleLinear()
    .domain([0, Math.max(...steps)])
    .range([dimensions.boundedHeight, 0])
    .nice()
    .clamp(true);

  const xAccessorScaled = (d, i) => xScale(xAccessor(d, i));
  const yAccessorScaled = d => yScale(yAccessor(d));

  const baseDateTime = (new Date(startDate)).getTime();

  const texture = textures.lines().stroke(VoyagerPurple['000']).background(StarfleetBlue[400]);
  const svg = select(ref.current).select('svg');
  svg.call(texture);

  const yTickValues = [0, ...yScale.ticks(3)];

  return (
    <div ref={ref} style={{ position: 'relative', height }}>
      <Graph dimensions={dimensions}>
        <Axis
          dimension="y"
          scale={yScale}
          fullTick={false}
          keyAccessor={(_, i) => i}
          strokeDasharray="1,10"
          tickValues={yTickValues}
          textTickStyle={() => ({ fontSize: '10px' })}
        />
        {yTickValues.map((d) => {
          // we manually add the `fullTick` to prevent adding a tick line on
          // the 0 value
          if (d === 0) {
            return null;
          }
          return (
            <line
              key={d}
              x1={0}
              x2={dimensions.boundedWidth}
              y1={yScale(d)}
              y2={yScale(d)}
              stroke={KlingonBlack[500]}
              strokeDasharray="1,10"
            />
          );
        })}
        <Rects
          data={steps}
          keyAccessor={(_, i) => i}
          xAccessor={xAccessorScaled}
          yAccessor={yAccessorScaled}
          widthAccessor={() => xScale.bandwidth()}
          heightAccessor={d => yScale(0) - yAccessorScaled(d)}
          fillAccessor={(_, i) => (weekends.includes(i) ? texture.url() : StarfleetBlue[400])}
        />
        <Axis
          dimension="x"
          scale={xScale}
          showDomainLine
          keyAccessor={(_, i) => i}
          tickValues={[...Array(steps.length).keys()]}
          formatTick={(_, i) => timeFormat('%d/%m')(new Date(baseDateTime + ONE_DAY_MS * i))}
          textTickStyle={() => ({ fontSize: '10px' })}
        />
      </Graph>
    </div>

  );
}

DailyDistributionGraph.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  steps: PropTypes.arrayOf(PropTypes.number).isRequired,
  weekends: PropTypes.arrayOf(PropTypes.number).isRequired,

  height: PropTypes.number,
  width: PropTypes.number,
};

export default DailyDistributionGraph;
