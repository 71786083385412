import { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2';

import Translate from '~/components/display/translate';
import PatientId from '~/components/field/patient-id';
import Language from '~/components/field/language';
import SectionHeaderPaper from '~/components/display/section-header-paper';
import PasscodeReset from '~/components/passcode/passcode-reset';
import Status from '~/components/field/status';
import QrCodePatient from '~/components/QRCodePatient';
import Common from './Common';

import useOrgaLocales from '~/utils/hooks/useOrgaLocales';
import { useAppContext } from '~/components/AppContext';

import { KlingonBlack } from '~/utils/colors';

function Study({
  orgaId,
  isCreate,
  onCancel,
  onSubmit,
  userId = '',
  patientId: patientIdInit = '',
  patientResourceId = '',
  locale: localeInit = null,
  status: statusInit = 'active',
  inProgress: inProgressInit = false,
}) {
  const [patientId, setPatientId] = useState(patientIdInit);
  const [locale, setLocale] = useState(localeInit);
  const [status, setStatus] = useState(statusInit);
  const [inProgress, setInProgress] = useState(inProgressInit);
  const [errors, setErrors] = useState({});

  const { patientIdExamples } = useAppContext();
  const [orgaLocales] = useOrgaLocales(orgaId);

  // return true if the form is not valid, false otherwise
  const validate = () => {
    // check each field passed its own validation
    if (Object.values(errors).find(i => i === true)) {
      return true;
    }

    // check for initial state
    if (!patientId) return true;
    if (!locale) return true;

    return false;
  };

  const handleSubmit = async () => {
    if (validate()) {
      return;
    }

    setInProgress(true);

    await onSubmit({
      patient: {
        isAnonymous: true,
        anonymous: patientId,
        patientId,
      },
      locale,
      status,
    });

    setInProgress(false);
  };

  const handleChange = (change) => {
    setErrors({ ...errors, [change.name]: change.error });

    switch (change.name) {
      case 'patientId':
        setPatientId(change.value);
        break;
      case 'locale':
        setLocale(change.value);
        break;
      case 'status':
        setStatus(change.value);
        break;
      default:
        throw new Error(`Unknown change type: ${change.name}`);
    }
  };

  return (
    <Common
      isCreate={isCreate}
      disabled={validate()}
      inProgress={inProgress}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    >
      <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        <Grid>
          <PatientId
            required
            autoFocus
            disabled={isCreate === false}
            error={errors.patientId}
            value={patientId}
            onChange={handleChange}
            showHelperText={isCreate && patientIdExamples !== null}
          />
        </Grid>
        { (isCreate && patientIdExamples !== null) && (
          <Grid style={{ paddingTop: 0 }}>
            <ul
              style={{
                fontWeight: 400,
                fontSize: '0.75rem',
                color: KlingonBlack[600],
                marginTop: 0,
              }}
            >
              { patientIdExamples.map(example => (
                <li key={example}>
                  {example}
                </li>
              ))}
            </ul>
          </Grid>
        )}
        <Grid>
          <SectionHeaderPaper title={<Translate>configuration</Translate>} />
        </Grid>
        <Grid>
          <Language
            required
            options={orgaLocales}
            value={locale}
            onChange={handleChange}
          />
        </Grid>
        { (isCreate === false) && (
          <>
            <Grid>
              <SectionHeaderPaper
                title={<Translate>patientInvitation</Translate>}
                subtitle={<Translate>generateAndDownloadQrCode</Translate>}
              />
            </Grid>
            <Grid>
              <QrCodePatient
                orgaId={orgaId}
                patientId={patientId}
                patientResourceId={patientResourceId}
              />
            </Grid>
            <Grid>
              <SectionHeaderPaper
                title={<Translate>resetPatientAccessToMobileApp</Translate>}
                subtitle={<Translate>patientWillNeedInternetConnection</Translate>}
              />
            </Grid>
            <Grid>
              <PasscodeReset userId={userId} />
            </Grid>
            <Grid>
              <SectionHeaderPaper title={<Translate>status</Translate>} />
            </Grid>
            <Grid>
              <Status value={status} onChange={handleChange} />
            </Grid>

          </>
        )}
      </Grid>
    </Common>
  );
}

Study.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isCreate: PropTypes.bool.isRequired,
  orgaId: PropTypes.string.isRequired,

  // patientId references the displayed id
  patientId: PropTypes.string,
  // patientResourceId references the resource Patient Id (FHIR)
  patientResourceId: PropTypes.string,
  // userId references the patient user document Id
  userId: PropTypes.string,
  locale: PropTypes.string,
  status: PropTypes.string,
  inProgress: PropTypes.bool,
};

export default Study;
