import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';

import Checkbox from '../../display/checkbox';
import DateDisplay from '../../display/date';
import DurationDisplay from '../../display/duration';
import Organisation from '../../display/organisation';
import FullDateDisplay from '~/components/display/full-date';
import RecordType from '~/components/display/record-type';
import Tooltip from '~/components/display/tooltip';

function Row({
  elt,
  onClick,
  isSelected,
  onSelect = () => {},
  showOrganisation = false,
  hasCheckbox = false,
}) {
  return (
    <TableRow
      hover
      sx={{
        cursor: 'pointer',
        '&.Mui-selected': {
          bgcolor: '#fff8eb',
        },
      }}
      onClick={() => onClick(elt.key, elt)}
      selected={isSelected}
    >
      { hasCheckbox && (
        <TableCell padding="checkbox">
          <Checkbox
            onClick={(ev) => {
              ev.stopPropagation();
              onSelect(elt.key, elt);
            }}
            checked={isSelected}
          />
        </TableCell>
      )}
      <TableCell component="th" scope="row">{elt.nameTranslated}</TableCell>
      <TableCell align="center">
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <RecordType type={elt.recordType} isSmall />
        </Box>
      </TableCell>
      <TableCell align="center">{elt.parametersTranslated}</TableCell>
      { showOrganisation && <TableCell align="center"><Organisation value={elt.organisationName} /></TableCell> }
      <TableCell align="center">
        <Tooltip
          title={(
            <FullDateDisplay options={{ timeZone: elt.timeZone }}>{elt.startTime}</FullDateDisplay>
          )}
        >
          <span>
            <DateDisplay options={{ timeZone: elt.timeZone }}>{elt.startTime}</DateDisplay>
          </span>
        </Tooltip>
      </TableCell>
      <TableCell align="center"><DurationDisplay>{elt.stopTime - elt.startTime}</DurationDisplay></TableCell>
      <TableCell align="center">{elt.recordedByTranslated}</TableCell>
    </TableRow>
  );
}

Row.propTypes = {
  elt: PropTypes.shape({
    key: PropTypes.string,
    nameTranslated: PropTypes.string,
    recordType: PropTypes.string,
    parametersTranslated: PropTypes.string,
    startTime: PropTypes.number,
    stopTime: PropTypes.number,
    organisationName: PropTypes.string,
    timeZone: PropTypes.string,
    recordedByTranslated: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
  showOrganisation: PropTypes.bool,
  hasCheckbox: PropTypes.bool,
};

export default Row;
