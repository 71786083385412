import PropTypes from 'prop-types';
import ButtonGroup from '@mui/material/ButtonGroup';

import Translate from '~/components/display/translate';
import Button from '~/components/display/button';

import { StarfleetBlue, KlingonBlack } from '~/utils/colors';

function DisplaySelection({ showMean, onSelectMean }) {
  return (
    <ButtonGroup
      disableElevation
      orientation="vertical"
    >
      <Button
        variant="outlined"
        sx={{
          bgcolor: showMean === true ? StarfleetBlue['000'] : undefined,
          borderColor: StarfleetBlue[100],
          color: showMean === true ? StarfleetBlue[600] : KlingonBlack[900],
          justifyContent: 'flex-start',
        }}
        onClick={() => onSelectMean(true)}
      >
        <Translate>mean</Translate>
      </Button>
      <Button
        variant="outlined"
        sx={{
          bgcolor: showMean === false ? StarfleetBlue['000'] : undefined,
          borderColor: StarfleetBlue[100],
          color: showMean === false ? StarfleetBlue[600] : KlingonBlack[900],
          justifyContent: 'flex-start',
        }}
        onClick={() => onSelectMean(false)}
      >
        <Translate>byStride</Translate>
      </Button>
    </ButtonGroup>
  );
}

DisplaySelection.propTypes = {
  showMean: PropTypes.bool.isRequired,
  onSelectMean: PropTypes.func.isRequired,
};

export default DisplaySelection;
