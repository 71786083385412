import PropTypes from 'prop-types';
import MuiTableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Checkbox from '../display/checkbox';
import TableCell from './cell-header';

function TableHead({
  headers,
  orderBy,
  order,
  onRequestSort,
  hasCheckbox = false,
  numSelected = 0,
  rowCount = 0,
  onSelectAll,
}) {
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    onRequestSort(property, isAsc ? 'desc' : 'asc');
  };

  return (
    <MuiTableHead>
      <TableRow>
        { hasCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAll}
            />
          </TableCell>
        )}
        { headers.map(i => (
          (i.order === false)
            ? (<TableCell key={i.key} align={i.align}>{i.label}</TableCell>)
            : (
              <TableCell
                key={i.key}
                align={i.align}
                sortDirection={orderBy === i.key ? order : false}
              >
                <TableSortLabel
                  active={orderBy === i.key}
                  direction={orderBy === i.key ? order : 'asc'}
                  onClick={() => handleRequestSort(i.key)}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  {i.label}
                </TableSortLabel>
              </TableCell>
            )
        ))}
      </TableRow>
    </MuiTableHead>
  );
}

TableHead.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    order: PropTypes.bool,
    align: PropTypes.oneOf(['left', 'right', 'center']),
  })).isRequired,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  hasCheckbox: PropTypes.bool,
  numSelected: PropTypes.number,
  rowCount: PropTypes.number,
};

export default TableHead;
