import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';

function CustomChip({ sx = [], ...props }) {
  return (
    <Chip
      {...props}
      sx={[
        { fontWeight: 500 },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
}

CustomChip.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default CustomChip;
