import PropTypes from 'prop-types';

import TextField from '../display/textfield';
import Translate from '../display/translate';

import { useAppContext } from '~/components/AppContext';

function PatientId({ showHelperText = false, onChange, ...props }) {
  const { patientIdRegExp } = useAppContext();
  const re = new RegExp(patientIdRegExp);

  return (
    <TextField
      placeholder="enterPatientId"
      label={<Translate>patientId</Translate>}
      helperText={showHelperText && (
        <Translate>followNamingConvention</Translate>
      )}
      onChange={ev => onChange({
        name: 'patientId',
        value: ev.target.value,
        error: !ev.target.value || !re.test(ev.target.value),
      })}
      {...props}
    />
  );
}

PatientId.propTypes = {
  onChange: PropTypes.func.isRequired,
  showHelperText: PropTypes.bool,
};

export default PatientId;
