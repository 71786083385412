import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import Translate from '../display/translate';
import Button from '../display/button';

function Pagination({
  onChange,
  rowsPerPage,
  page,
  count = undefined,
}) {
  const needPagination = count > rowsPerPage;

  return (
    <TableRow
      sx={{
        borderBottom: 'none',
        '& > *': {
          borderBottom: 'none',
        },
      }}
    >
      <TableCell colSpan={50} sx={{ px: 3, py: 2 }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="caption">
              <Translate>showing</Translate>
              { needPagination
                && (
                  <React.Fragment>
                    <strong>{ ` ${page * rowsPerPage + 1} `}</strong>
                    <Translate>to</Translate>
                    <strong>{ ` ${Math.min(count, page * rowsPerPage + rowsPerPage)} `}</strong>
                    <Translate>of</Translate>
                  </React.Fragment>
                )}
              <strong>{ ` ${count} `}</strong>
              <Translate input={{ NB: (needPagination ? 2 : count) }}>results</Translate>
            </Typography>
          </Grid>
          { needPagination && (
            <Grid item>
              <Button
                variant="outlined"
                disabled={page <= 0}
                onClick={() => onChange({ name: 'page', value: page - 1 })}
              >
                <Translate>previous</Translate>
              </Button>
              <Button
                variant="outlined"
                disabled={((page + 1) * rowsPerPage) >= count}
                onClick={() => onChange({ name: 'page', value: page + 1 })}
                sx={{ ml: '12px' }}
              >
                <Translate>next</Translate>
              </Button>
            </Grid>
          )}
        </Grid>
      </TableCell>
    </TableRow>
  );
}

Pagination.propTypes = {
  onChange: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number,
};

export default Pagination;
