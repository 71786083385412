import Avatar from '@mui/material/Avatar';

import SiteAdminShield from '../icons/site-admin-shield';
import CrossIcon from '~/components/icons/cross';

import { useAppContext } from '~/components/AppContext';
import { OrionTeal } from '~/utils/colors';

function SiteAdminAvatar() {
  const { isHealthProject } = useAppContext();

  if (isHealthProject) {
    return (
      <Avatar sx={{ bgcolor: OrionTeal[100] }}>
        <CrossIcon sx={{ color: OrionTeal[400] }} fill />
      </Avatar>
    );
  }

  return (
    <Avatar sx={{ bgcolor: OrionTeal[100] }}>
      <SiteAdminShield />
    </Avatar>
  );
}

export default SiteAdminAvatar;
