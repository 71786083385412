import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Table from '../table/records/index';

function List({ records = [] }) {
  const navigate = useNavigate();

  const handleClick = recordId => navigate(`records/${recordId}`);

  return (
    <Table
      records={records}
      onClick={handleClick}
    />
  );
}

List.propTypes = {
  records: PropTypes.arrayOf(PropTypes.object),
};

export default List;
