import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Table from '~/components/table/members/index';

import useFuzzy from '~/utils/hooks/useFuzzy';
import useLabels from '~/utils/hooks/useLabels';
import useOrganisations from '~/utils/hooks/useOrganisations';

function List({ members, blockedMembers = false }) {
  const [search, setSearch] = useState('');
  const [labels] = useLabels();
  const [organisations] = useOrganisations();

  const membersWithLabelsAndOrgas = useMemo(() => members.map((member) => {
    const foundLabels = (member.labels || [])
      .map(labelId => labels.find(label => label.key === labelId))
      .filter(i => i !== undefined)
      .map(i => ({ key: i.key, name: i.name }));

    const foundOrganisations = (member.organisations || [])
      .map(orgaId => organisations.find(orga => orga.key === orgaId))
      .filter(i => i !== undefined)
      .map(i => ({ key: i.key, name: i.name }));

    return ({
      ...member,
      labels: foundLabels,
      organisations: foundOrganisations,
    });
  }), [members.length, labels.length, organisations.length]);

  const [filteredMembers] = useFuzzy(membersWithLabelsAndOrgas, search, 'name');

  const handleChange = (change) => {
    if (change.name === 'search') {
      setSearch(change.value);
    }
  };

  return (
    <Table
      hasSearch
      members={filteredMembers}
      onChange={handleChange}
      blockedMembers={blockedMembers}
    />
  );
}

List.propTypes = {
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
  blockedMembers: PropTypes.bool,
};

export default List;
