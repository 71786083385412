import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Translate from '../components/display/translate';
import Button from '../components/display/button';
import OrganisationIcon from '../components/icons/organisation';
import LabelIcon from '../components/icons/label';
import MemberIcon from '../components/icons/member';
import PatientIcon from '../components/icons/patient';

import { OrionTeal } from '~/utils/colors';
import useUniqueId from '../utils/hooks/useUniqueId';
import { useAppContext } from '~/components/AppContext';

function NewMenu({ orgaId }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const id = useUniqueId('button-create-new');
  const { isAdmin, isProjectAdmin, isStudyProject } = useAppContext();

  if (!isAdmin) {
    return (
      <Button color="primary" component={Link} to={`/organisations/${orgaId}/patients/new`}>
        <Translate>new</Translate>
      </Button>
    );
  }

  const handleClick = (ev) => {
    setAnchorEl(ev.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button
        aria-controls={id}
        aria-haspopup="true"
        color="primary"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        <Translate>new</Translate>
      </Button>
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        { isProjectAdmin && (
          <MenuItem onClick={() => navigate('/organisations/new')}>
            <ListItemIcon>
              <OrganisationIcon />
            </ListItemIcon>
            <Translate>newOrganisation</Translate>
          </MenuItem>
        )}
        { (isProjectAdmin && isStudyProject) && (
          <MenuItem onClick={() => navigate('/labels/new')}>
            <ListItemIcon>
              <LabelIcon />
            </ListItemIcon>
            <Translate>newLabel</Translate>
          </MenuItem>
        )}
        <MenuItem onClick={() => navigate('/members/new')}>
          <ListItemIcon>
            <MemberIcon sx={{ color: OrionTeal[400] }} />
          </ListItemIcon>
          <Translate>newMember</Translate>
        </MenuItem>
        <MenuItem onClick={() => navigate(`/organisations/${orgaId}/patients/new`)}>
          <ListItemIcon>
            <PatientIcon />
          </ListItemIcon>
          <Translate>newPatient</Translate>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

NewMenu.propTypes = {
  orgaId: PropTypes.string.isRequired,
};

export default NewMenu;
