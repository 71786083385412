import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

import { StarfleetBlue } from '~/utils/colors';

function Velocity({ colored = false, ...props }) {
  return (
    <SvgIcon sx={{ color: StarfleetBlue[400] }} {...props}>
      <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(1.3333333,0,0,1.3333333,0,1.0658561)">
          <rect x="7" y="1" width="11" height="2.5" rx="1.25" fill={colored ? '#ffd8a8' : undefined} />
          <rect y="7" width="16" height="2.5" rx="1.25" fill={colored ? '#ff922b' : undefined} x="0" />
          <rect x="5" y="13" width="8" height="2.5" rx="1.25" fill={colored ? '#e8590c' : undefined} />
        </g>
      </svg>
    </SvgIcon>
  );
}

Velocity.propTypes = {
  colored: PropTypes.bool,
};

export default Velocity;
