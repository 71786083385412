import PropTypes from 'prop-types';
import { scaleBand, scaleLinear } from 'd3-scale';
import {
  Graph,
  Rects,
  useGraphDimensions,
} from '@feetme/d3act';

import MetricValue from '~/components/MetricValue';
import DeviationLines from './deviation-lines';
import Translate from '../../display/translate';

import { SupportPhase, SingleSupportPercentage, DoubleSupportPercentage } from '../../../utils/metrics';
import { KlingonBlack, StarfleetBlue } from '../../../utils/colors';

function AsymmetryBox({
  metric,
  meanLeft = 0,
  meanRight = 0,
  stdLeft = 0,
  stdRight = 0,
  height = 250,
  isPdf = false,
}) {
  const [ref, dimensions] = useGraphDimensions({
    marginLeft: isPdf ? 20 : 60,
    marginRight: isPdf ? 20 : 60,
    height,
  });

  const xScale = scaleBand()
    .domain([0, 1])
    .range([0, dimensions.boundedWidth])
    .paddingInner([0.1])
    .paddingOuter([0.5])
    .align([0.5]);

  const yScale = scaleLinear()
    // we use `|| 0` to prevent undefined
    .domain([0, Math.max((meanLeft + stdLeft) || 0, (meanRight + stdRight) || 0)])
    .range([dimensions.boundedHeight, 0]);

  const xAccessorScaled = (d, i) => xScale(i);
  const yAccessorScaled = d => yScale(d);
  const heightAccessorScaled = d => yScale(0) - yAccessorScaled(d);

  return (
    <div ref={ref} style={{ position: 'relative', height }}>
      <Graph dimensions={dimensions}>
        <Rects
          data={[meanLeft, meanRight].filter(i => i !== null)}
          keyAccessor={(d, i) => i}
          xAccessor={xAccessorScaled}
          yAccessor={yAccessorScaled}
          widthAccessor={xScale.bandwidth()}
          heightAccessor={heightAccessorScaled}
          fillAccessor={(d, i) => (i === 0 ? StarfleetBlue[400] : StarfleetBlue[100])}
        />
        {[meanLeft, meanRight].filter(i => i !== null).map((d, i) => (
          <text
            key={d}
            fill={KlingonBlack[600]}
            textAnchor="middle"
            fontSize={isPdf ? 7 : 12}
            x={xAccessorScaled(d, i) + xScale.bandwidth() / 2}
            y={yScale(0) + 20}
          >
            <MetricValue metric={metric} showUnit>{d}</MetricValue>
          </text>
        ))}
        { (meanLeft && stdLeft) && (
          <DeviationLines
            x={xAccessorScaled(undefined, 0)}
            mean={meanLeft}
            std={stdLeft}
            width={xScale.bandwidth()}
            yAccessor={yAccessorScaled}
          />
        )}
        { (meanRight && stdRight) && (
          <DeviationLines
            x={xAccessorScaled(undefined, 1)}
            mean={meanRight}
            std={stdRight}
            width={xScale.bandwidth()}
            yAccessor={yAccessorScaled}
          />
        )}
        <text
          textAnchor="middle"
          fill={KlingonBlack[900]}
          fontSize={isPdf ? 8 : 13}
          fontWeight={600}
          x={dimensions.boundedWidth / 2}
          y={-25}
        >
          <Translate>{metric.name}</Translate>
        </text>
      </Graph>
    </div>
  );
}

AsymmetryBox.propTypes = {
  meanLeft: PropTypes.number,
  meanRight: PropTypes.number,
  stdLeft: PropTypes.number,
  stdRight: PropTypes.number,
  metric: PropTypes.oneOf([
    SupportPhase,
    SingleSupportPercentage,
    DoubleSupportPercentage,
  ]).isRequired,
  height: PropTypes.number,
  isPdf: PropTypes.bool,
};

export default AsymmetryBox;
