import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import Translate from '~/components/display/translate';
import DateDisplay from '~/components/display/date';
import RoleAvatar from '~/components/avatar/role';
import Label from '~/components/display/label';
import Organisation from '~/components/display/organisation';

import { useAppContext } from '~/components/AppContext';
import { convertFirestoreTimestampToDate } from '@/firebase/firestore';

function Row({ elt, onClick, blockedMembers }) {
  const { isAdmin } = useAppContext();

  const canEditUser = isAdmin && elt.role !== 'projectAdmin';

  return (
    <TableRow
      hover={canEditUser}
      sx={{ cursor: canEditUser && 'pointer' }}
      onClick={() => canEditUser && onClick(elt.key)}
    >
      <TableCell>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item sx={{ mr: 2 }}>
            <RoleAvatar role={elt.role} />
          </Grid>
          <Grid>
            { elt.name }
          </Grid>
        </Grid>
      </TableCell>
      { blockedMembers && (
        <>
          <TableCell sx={{ '& > *': { m: 0.25 } }}>
            { elt.labels.map(i => <Label key={i.key} labelId={i.key} value={i.name} />) }
          </TableCell>
          <TableCell sx={{ '& > *': { m: 0.25 } }}>
            { elt.organisations.map(i => <Organisation key={i.key} value={i.name} />) }
          </TableCell>
        </>
      )}
      { !blockedMembers && (
        <>
          <TableCell><Translate>{elt.role}</Translate></TableCell>
          <TableCell align="right">
            <DateDisplay>{convertFirestoreTimestampToDate(elt.createdAt)}</DateDisplay>
          </TableCell>
        </>
      )}
    </TableRow>
  );
}

Row.propTypes = {
  elt: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    role: PropTypes.oneOf(['projectAdmin', 'orgaAdmin', 'member']),
    createdAt: PropTypes.shape({
      seconds: PropTypes.number,
      nanoseconds: PropTypes.number,
    }),
    labels: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
    organisations: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  blockedMembers: PropTypes.bool.isRequired,
};

export default Row;
