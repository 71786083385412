import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import Status from '~/components/display/status-qr-code';
import DateDisplay from '~/components/display/date';
import Translate from '~/components/display/translate';

import { convertFirestoreTimestampToDate } from '@/firebase/firestore';
import { KlingonBlack } from '~/utils/colors';
import { isLinkValid } from '~/utils/link';

function QrCodeStatus({ link }) {
  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid>
        <Typography sx={{ fontWeight: 500 }}>
          <Translate>statusQrCode</Translate>
        </Typography>
        <Typography sx={{ color: KlingonBlack[600] }}>
          <React.Fragment>
            <Translate>issued</Translate>
            { ' ' }
            <DateDisplay>{convertFirestoreTimestampToDate(link.createdAt)}</DateDisplay>
          </React.Fragment>
        </Typography>
      </Grid>
      <Grid>
        <Status type={isLinkValid(link) ? 'active' : 'obsolete'} />
      </Grid>
    </Grid>
  );
}

QrCodeStatus.propTypes = {
  link: PropTypes.shape({
    createdAt: PropTypes.shape({
      seconds: PropTypes.number,
      nanoseconds: PropTypes.number,
    }),
  }).isRequired,
};

export default QrCodeStatus;
