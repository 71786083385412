import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { scaleLinear } from 'd3-scale';

import InsolesPressuresDynamic from '~/components/InsolesPressuresDynamic';
import ColormapPressure from '~/components/ColormapPressure';

import useStridePressureIndex from '~/utils/hooks/useStridePressureIndex';
import usePressureScale from '~/utils/hooks/usePressureScale';
import useUnit from '~/utils/hooks/useUnit';
import Load, { convertLoad } from '~/utils/metrics/load';

function DynamicHeatmapGraph({
  record,
  showMean,
  selectedGraph,
  insoles,
  stridePressureIndexes,
  strideIndex,
  processedRecord,
}) {
  const unit = useUnit(Load);
  const [
    leftPressureStride,
    rightPressureStride,
  ] = useStridePressureIndex(record.key, stridePressureIndexes, strideIndex);

  let leftPressures;
  let rightPressures;
  let maxDomain;
  if (selectedGraph === 'max-pressures') {
    if (showMean) {
      leftPressures = processedRecord.meanMaxPressures.left || [];
      rightPressures = processedRecord.meanMaxPressures.right || [];
    } else {
      leftPressures = leftPressureStride.maxPressures || [];
      rightPressures = rightPressureStride.maxPressures || [];
    }
  } else if (selectedGraph === 'pti') {
    if (showMean) {
      leftPressures = processedRecord.meanPTI.left || [];
      rightPressures = processedRecord.meanPTI.right || [];
    } else {
      leftPressures = leftPressureStride.PTI || [];
      rightPressures = rightPressureStride.PTI || [];
    }
  }

  if (showMean) {
    maxDomain = Math.max(...leftPressures, ...rightPressures);
  } else if (selectedGraph === 'max-pressures') {
    // eslint-disable-next-line prefer-destructuring
    maxDomain = processedRecord.domainMaxPressures[1];
  } else {
    // eslint-disable-next-line prefer-destructuring
    maxDomain = processedRecord.domainPTI[1];
  }
  const domain = [0.13, maxDomain];

  let leftLoadHeelStrike;
  let leftLoadToeOff;
  let rightLoadHeelStrike;
  let rightLoadToeOff;
  if (showMean) {
    leftLoadHeelStrike = processedRecord.meanLoadHeelStrike1?.left;
    leftLoadToeOff = processedRecord.meanLoadToeOff?.left;
    rightLoadHeelStrike = processedRecord.meanLoadHeelStrike1?.right;
    rightLoadToeOff = processedRecord.meanLoadToeOff?.right;
  } else {
    leftLoadHeelStrike = leftPressureStride.loadHeelStrike1 || undefined;
    leftLoadToeOff = leftPressureStride.loadToeOff || undefined;
    rightLoadHeelStrike = rightPressureStride.loadHeelStrike1 || undefined;
    rightLoadToeOff = rightPressureStride.loadToeOff || undefined;
  }

  const [, colorInterpolation] = usePressureScale(domain[0], domain[1]);

  return (
    <Stack>
      <InsolesPressuresDynamic
        insoles={insoles}
        scale={colorInterpolation}
        leftPressures={leftPressures}
        rightPressures={rightPressures}
        leftLoadHeelStrike={leftLoadHeelStrike}
        leftLoadToeOff={leftLoadToeOff}
        rightLoadHeelStrike={rightLoadHeelStrike}
        rightLoadToeOff={rightLoadToeOff}
        meshX={record.interpolation.meshX}
        meshY={record.interpolation.meshY}
      />
      <ColormapPressure
        colorInterpolation={colorInterpolation}
        ticks={scaleLinear().domain(domain).ticks(8).map((i) => {
          if (selectedGraph === 'pti') {
            return i;
          }

          return convertLoad(i, unit);
        })}
        domain={domain}
        unit={selectedGraph === 'max-pressures' ? unit : 'kg/cm² × ms'}
      />
    </Stack>
  );
}

DynamicHeatmapGraph.propTypes = {
  record: PropTypes.shape({
    key: PropTypes.string,
    interpolation: PropTypes.shape({
      meshX: PropTypes.number,
      meshY: PropTypes.number,
    }),
  }).isRequired,
  showMean: PropTypes.bool.isRequired,
  selectedGraph: PropTypes.oneOf(['max-pressures', 'pti']).isRequired,
  insoles: PropTypes.arrayOf(PropTypes.object).isRequired,
  stridePressureIndexes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  strideIndex: PropTypes.number.isRequired,
  processedRecord: PropTypes.shape({
    meanMaxPressures: PropTypes.shape({
      left: PropTypes.arrayOf(PropTypes.number),
      right: PropTypes.arrayOf(PropTypes.number),
    }),
    domainMaxPressures: PropTypes.arrayOf(PropTypes.number),
    meanPTI: PropTypes.shape({
      left: PropTypes.arrayOf(PropTypes.number),
      right: PropTypes.arrayOf(PropTypes.number),
    }),
    domainPTI: PropTypes.arrayOf(PropTypes.number),
    meanLoadHeelStrike1: PropTypes.shape({
      left: PropTypes.number,
      right: PropTypes.number,
    }),
    meanLoadToeOff: PropTypes.shape({
      left: PropTypes.number,
      right: PropTypes.number,
    }),
  }).isRequired,
};

export default DynamicHeatmapGraph;
