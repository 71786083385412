import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Button from '~/components/display/button';
import ScrollUpFAB from '~/components/ScrollUpFAB';

import { KlingonBlack } from '~/utils/colors';

function ReportLayout({
  backTo,
  buttonBackChildren,
  Header,
  DownloadButton = null,
  children,
}) {
  return (
    <>
      <Button variant="text" component={Link} to={backTo} startIcon={<ArrowBackIcon />} sx={{ color: KlingonBlack[600], fontSize: '0.875rem', fontWeight: 500 }}>
        {buttonBackChildren}
      </Button>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 2.5, mb: 5 }}>
        <Grid>
          {Header}
        </Grid>
        { DownloadButton && (
          <Grid>
            { DownloadButton }
          </Grid>
        )}
      </Grid>
      { children }
      <ScrollUpFAB />
    </>
  );
}

ReportLayout.propTypes = {
  backTo: PropTypes.string.isRequired,
  buttonBackChildren: PropTypes.node.isRequired,
  Header: PropTypes.node.isRequired,
  DownloadButton: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default ReportLayout;
