import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import PaperPage from '~/components/display/paper-page';
import HeaderPaper from '~/components/display/header-paper';
import Translate from '~/components/display/translate';
import Breadcrumbs from '~/components/Breadcrumbs';
import Create from '~/components/PatientForm/Create';
import Dialog from './Dialog';

import useOrganisation from '~/utils/hooks/useOrganisation';

function PatientNew() {
  const { orgaId } = useParams();
  const navigate = useNavigate();
  const [organisation] = useOrganisation(orgaId);

  const [open, setOpen] = useState(false);
  const [patientResourceId, setPatientResourceId] = useState('');

  const handleCancel = () => navigate(`/organisations/${orgaId}/patients/`);
  const handleDone = (id) => {
    setPatientResourceId(id);
    setOpen(true);
  };

  const handleDialogClose = () => navigate(`/organisations/${orgaId}/patients/`);
  const handleDialogAddProgram = () => navigate(`/organisations/${orgaId}/patients/${patientResourceId}/edit`);

  return (
    <>
      <Breadcrumbs
        links={[
          { type: 'organisation', to: '/organisations/' },
          { type: 'id', value: organisation.name, to: `/organisations/${orgaId}` },
          { type: 'createPatient', to: `/organisations/${orgaId}/patients/new` },
        ]}
      />
      <PaperPage disableBottomPadding>
        <HeaderPaper title={<Translate>profileInformation</Translate>} />
        <Create orgaId={orgaId} onCancel={handleCancel} onDone={handleDone} />
      </PaperPage>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        onAddProgram={handleDialogAddProgram}
      />
    </>
  );
}

export default PatientNew;
