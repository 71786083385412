const PhaserRed = {
  900: '#C92A2A',
  800: '#E03131',
  700: '#F03E3E',
  600: '#FA5252',
  500: '#FF6B6B',
  200: '#FFC9C9',
  100: '#FFE3E3',
  '000': '#FFF5F5',
};

const VoyagerPurple = {
  '000': '#F3F0FF',
};

const VulcanIndigo = {
  900: '#002487',
  600: '#1945B8',
  '000': '#F0F4FF',
};

const StarfleetBlue = {
  900: '#003B87',
  800: '#005EDA',
  700: '#0054C1',
  600: '#005EDA',
  500: '#0F70EE',
  400: '#2986FF',
  200: '#82BAFF',
  100: '#B9D8FF',
  '000': '#F0F6FF',
};

const TerranCyan = {
  500: '#22B8CF',
};

const OrionTeal = {
  600: '#12B886',
  400: '#38D9A9',
  200: '#96F2D7',
  100: '#C3FAE8',
  '000': '#E6FCF5',
};

const RomulanGreen = {
  900: '#2B8A3E',
  800: '#2F9E3E',
  700: '#37B24D',
  500: '#51CF66',
  400: '#69DB7C',
  100: '#D3F9D8',
  '000': '#EBFBEE',
};

const ContinuumYellow = {
  900: '#E67700',
  800: '#F08C00',
  700: '#F59F00',
  500: '#FCC419',
  300: '#FFE066',
  '000': '#FFF9DB',
};

const CadetOrange = {
  700: '#F76707',
  600: '#FD7E14',
  500: '#FF922B',
  300: '#FFC078',
  200: '#FFD8A8',
  100: '#FFE8CC',
  '000': '#FFF4E6',
};

const KlingonBlack = {
  900: '#212529',
  800: '#343A40',
  700: '#495057',
  600: '#868E96',
  500: '#ADB5BD',
  400: '#CED4DA',
  300: '#DEE2E6',
  200: '#E9ECEF',
  100: '#F1F3F5',
  '000': '#F8F9FA',
};

export {
  PhaserRed,
  VoyagerPurple,
  VulcanIndigo,
  StarfleetBlue,
  TerranCyan,
  OrionTeal,
  RomulanGreen,
  ContinuumYellow,
  CadetOrange,
  KlingonBlack,
};
