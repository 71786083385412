import { phaseProcess } from '@/firebase/functions';
import useFunction from './useFunction';

function useUsePhaseProcess(orgaId, patientResourceId, programId, phasesIndex) {
  return useFunction(() => phaseProcess({
    orgaId,
    patientResourceId,
    programId,
    phasesIndex,
  }), {});
}

export default useUsePhaseProcess;
