import React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';

import OrganisationIcon from '../components/icons/organisation';
import LabelIcon from '../components/icons/label';
import MemberIcon from '../components/icons/member';
import PatientIcon from '../components/icons/patient';
import ImportIcon from '../components/icons/file';
import SettingsIcon from '../components/icons/settings';
import Translate from '../components/display/translate';
import DrawerLink from './drawer-link';
import ProjectSelect from './project-select';

import { KlingonBlack, OrionTeal } from '~/utils/colors';
import { useAppContext } from '~/components/AppContext';

function CustomDrawer({
  onProjectSwitch,
  projectId = undefined,
  projectsInfo = [],
  minimal = false,
}) {
  const { isAdmin, isStudyProject } = useAppContext();
  const drawerWidth = 240;

  if (minimal && projectsInfo.length === 0) {
    return null;
  }

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          bgcolor: 'inherit',
          width: drawerWidth,
          border: 'none',
        },
      }}
      variant="permanent"
      PaperProps={{
        elevation: 0,
      }}
    >
      <Toolbar />
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        wrap="nowrap"
        sx={{ overflow: 'auto', height: 1 }}
      >
        <Grid item sx={{ pt: 7, px: 1 }}>
          <List
            subheader={(
              <ListSubheader component="div" sx={{ bgcolor: KlingonBlack['000'], color: KlingonBlack[500], textTransform: 'uppercase' }}>
                <Translate>project</Translate>
              </ListSubheader>
            )}
          />
          <ProjectSelect
            onProjectSwitch={onProjectSwitch}
            projectId={projectId}
            projectsInfo={projectsInfo}
          />
        </Grid>
        { !minimal && (
          <React.Fragment>
            <Grid item sx={{ pt: 5 }}>
              <List
                subheader={(
                  <ListSubheader component="div" sx={{ bgcolor: KlingonBlack['000'], color: KlingonBlack[500], textTransform: 'uppercase' }}>
                    <Translate>management</Translate>
                  </ListSubheader>
                )}
              >
                <DrawerLink to="/organisations/" text={<Translate>organisation</Translate>}>
                  <OrganisationIcon />
                </DrawerLink>
                { isStudyProject && (
                  <DrawerLink to="/labels/" text={<Translate>label</Translate>}>
                    <LabelIcon />
                  </DrawerLink>
                )}
                { isAdmin && (
                  <DrawerLink to="/members/" text={<Translate>{isStudyProject ? 'investigator' : 'medicalTeam'}</Translate>}>
                    <MemberIcon sx={{ color: OrionTeal[400] }} />
                  </DrawerLink>
                )}
                <DrawerLink to="/patients/" text={<Translate>patient</Translate>}>
                  <PatientIcon />
                </DrawerLink>
                <DrawerLink to="/import" text={<Translate>import</Translate>}>
                  <ImportIcon />
                </DrawerLink>
              </List>
            </Grid>
            <Grid item xs />
            <Grid item>
              <List
                subheader={(
                  <ListSubheader component="div" sx={{ bgcolor: KlingonBlack['000'], color: KlingonBlack[500], textTransform: 'uppercase' }}>
                    <Translate>general</Translate>
                  </ListSubheader>
                )}
              >
                <DrawerLink to="/settings" text={<Translate>settings</Translate>}>
                  <SettingsIcon />
                </DrawerLink>
              </List>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </Drawer>
  );
}

CustomDrawer.propTypes = {
  projectId: PropTypes.string,
  projectsInfo: PropTypes.arrayOf(PropTypes.object),
  onProjectSwitch: PropTypes.func.isRequired,
  minimal: PropTypes.bool,
};

export default CustomDrawer;
