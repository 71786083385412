import * as Sentry from '@sentry/browser';
import {
  signOut,
  getAuth,
  signInWithPopup,
  signInWithCustomToken,
  onAuthStateChanged,

  GoogleAuthProvider,
  OAuthProvider,
} from 'firebase/auth';

import { coreApp, getComponentApp } from './index';

let isSignedIn = false;

const coreAuth = getAuth(coreApp);

onAuthStateChanged(coreAuth, (user) => {
  if (user) {
    isSignedIn = true;
    Sentry.configureScope(scope => scope.setUser({ id: user.uid }));
  } else {
    isSignedIn = false;
    Sentry.configureScope(scope => scope.clear());
  }
});

function getCoreAuth() {
  return coreAuth;
}

function onCoreAuthStateChanged(callback) {
  return onAuthStateChanged(coreAuth, callback);
}

function signInProvider(provider) {
  return signInWithPopup(coreAuth, provider);
}

function componentAuth(token) {
  return signInWithCustomToken(getAuth(getComponentApp()), token);
}

function googleProvider() {
  return new GoogleAuthProvider();
}

function appleProvider() {
  return new OAuthProvider('apple.com');
}

function isLoggedIn() {
  const { current } = coreAuth;
  if (!current) {
    return isSignedIn;
  }

  return current;
}

function requiresAuth() {
  return !isLoggedIn();
}

function getCoreUser() {
  return coreAuth.currentUser;
}

function getComponentUserId() {
  return getAuth(getComponentApp()).currentUser.uid;
}

function getComponentIdToken(refresh) {
  return getAuth(getComponentApp()).currentUser.getIdToken(refresh);
}

function signOutApp(app) {
  return signOut(getAuth(app));
}

export {
  getCoreAuth,
  onCoreAuthStateChanged,
  signInProvider,
  componentAuth,

  googleProvider,
  appleProvider,

  isLoggedIn,
  requiresAuth,
  getCoreUser,
  getComponentUserId,
  getComponentIdToken,

  signOutApp,
};
