import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

import { StarfleetBlue, KlingonBlack } from '~/utils/colors';

function Left({ colored = false, ...props }) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
        <path d="M11.7818 6C11.7818 6 11.7818 5.1 11.7818 4C11.7818 2.9 11.7818 2 11.7818 2C12.8818 2 13.7818 2.9 13.7818 4C13.7818 5.1 12.8818 6 11.7818 6Z" fill={colored ? StarfleetBlue[100] : KlingonBlack[300]} />
        <path d="M12.7816 17.0001V21.0001C12.7816 21.5524 13.2294 22.0001 13.7816 22.0001C14.3339 22.0001 14.7816 21.5524 14.7816 21.0001V10.0001C14.7816 9.44786 15.2292 9.00233 15.7806 8.97057C17.0152 8.89946 18.6367 8.67967 19.7985 8.1C20.2817 7.85674 20.6724 7.44549 20.536 6.9C20.4327 6.48676 20.094 6.26055 19.697 6.22089C19.4082 6.19204 19.1227 6.28359 18.8408 6.35263C17.6582 6.64224 14.2817 7 11.7816 7.00015V16.0001C12.3339 16.0001 12.7816 16.4479 12.7816 17.0001Z" fill={colored ? StarfleetBlue[100] : KlingonBlack[300]} />
        <path d="M11.7816 6C11.7816 6 11.7816 5.1 11.7816 4C11.7816 2.9 11.7816 2 11.7816 2C10.6816 2 9.78163 2.9 9.78163 4C9.78163 5.1 10.6816 6 11.7816 6Z" fill={colored ? StarfleetBlue[400] : KlingonBlack[500]} />
        <path d="M10.7818 17.0001V21.0001C10.7818 21.5524 10.3341 22.0001 9.78183 22.0001C9.22954 22.0001 8.78183 21.5524 8.78183 21.0001V10.0001C8.78183 9.44786 8.33426 9.00233 7.78289 8.97057C6.54829 8.89946 4.92674 8.67967 3.76493 8.1C3.28173 7.85674 2.89112 7.44549 3.02749 6.9C3.1308 6.48676 3.46952 6.26055 3.86644 6.22089C4.15526 6.19204 4.44074 6.28359 4.72266 6.35263C5.9053 6.64224 9.28174 7 11.7818 7.00015V16.0001C11.2295 16.0001 10.7818 16.4479 10.7818 17.0001Z" fill={colored ? StarfleetBlue[400] : KlingonBlack[500]} />
      </svg>
    </SvgIcon>
  );
}

Left.propTypes = {
  colored: PropTypes.bool,
};

export default Left;
