import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

function Cross({ fill = false, ...props }) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill={fill ? 'currentColor' : 'none'} stroke="currentColor">
        <path d="M14.7692 3H9.23077V9.23077H3V14.7692H9.23077V21H14.7692V14.7692H21V9.23077H14.7692V3Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  );
}

Cross.propTypes = {
  fill: PropTypes.bool,
};

export default Cross;
