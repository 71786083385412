import PropTypes from 'prop-types';

import BaseMetric from './BaseMetric';

function Metric({ recordData, record }) {
  return <BaseMetric recordData={recordData} record={record} />;
}

Metric.propTypes = {
  record: PropTypes.object.isRequired,
  recordData: PropTypes.object.isRequired,
};

export default Metric;
