import { useEffect, useState } from 'react';
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
} from 'react-router-dom';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import Organisations from '../organisation-list/index';
import NewOrganisation from '../organisation-new/index';
import Organisation from '../organisation/index';

import Labels from '../label-list/index';
import NewLabel from '../label-new/index';
import EditLabel from '../label-edit/index';

import Members from '../member-list/index';
import NewMember from '../member-new/index';
import EditMember from '../member-edit/index';

import Patients from '../patient-list/index';
// import NewPatient from '../patient-new/index';
import PatientNew from '~/components/PatientNew';
import NewPatientRedirect from '../patient-new-redirect/index';
import Patient from '../patient/index';
import ComplianceReport from '~/components/ComplianceReport';

import Record from '~/components/Record';

import Import from '../import/index';

import Settings from '../settings/index';

import AppBar from './appbar';
import Drawer from './drawer';
import LandingPage from '../components/landing-page';
import ProtectedRoute from './protected-route';
import NoAccess from './no-access';

import { authDispatch } from '@/firebase/functions';
import { getComponentUserId } from '@/firebase/auth';
import { connectFirebaseComponent, switchFirebaseComponent } from '../firebase/apps';
import { useAppContext } from '~/components/AppContext';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

function getSwitch(isAdmin) {
  return (
    <Routes>
      <Route path="/organisations/" element={<Organisations />} />
      <Route path="/organisations/new" element={<NewOrganisation />} />
      <Route path="/organisations/:orgaId/patients/new" element={<PatientNew />} />
      <Route path="/organisations/:orgaId/patients/:patientResourceId/records/:recordId" element={<Record />} />
      <Route path="/organisations/:orgaId/patients/:patientResourceId/programs/:programId/phases/:phaseIndex" element={<ComplianceReport />} />
      <Route path="/organisations/:orgaId/patients/:patientResourceId" element={<Navigate to="overview" replace />} />
      <Route path="/organisations/:orgaId/patients/:patientResourceId/*" element={<Patient />} />
      <Route path="/organisations/:orgaId" element={<Navigate to="patients/" replace />} />
      <Route path="/organisations/:orgaId/*" element={<Organisation />} />
      <Route path="/labels/" element={<Labels />} />
      <Route path="/labels/new" element={<NewLabel />} />
      <Route path="/labels/:labelId/edit" element={<EditLabel />} />
      <Route path="/labels/:labelId" element={<Navigate to="edit" replace />} />

      <Route
        path="/members/"
        element={(
          <ProtectedRoute hasAccess={isAdmin}>
            <Members />
          </ProtectedRoute>
        )}
      />
      <Route
        path="/members/new"
        element={(
          <ProtectedRoute hasAccess={isAdmin}>
            <NewMember />
          </ProtectedRoute>
        )}
      />
      <Route
        path="/members/:userId/edit"
        element={(
          <ProtectedRoute hasAccess={isAdmin}>
            <EditMember />
          </ProtectedRoute>
        )}
      />
      <Route path="/members/:userid" element={<Navigate to="edit" replace />} />

      <Route path="/patients/new" element={<NewPatientRedirect />} />
      <Route path="/patients" element={<Navigate to="active" replace />} />
      <Route path="/patients/*" element={<Patients />} />

      <Route path="/settings" element={<Settings />} />
      <Route path="/import" element={<Import />} />
      <Route path="/unlinked" element={<Import />} />

      <Route path="/records/:recordId" element={<Record />} />

      <Route path="*" element={<Navigate to="/organisations/" replace />} />
    </Routes>
  );
}

function App() {
  const [isReady, setReady] = useState(false);
  const [projectId, setProjectId] = useState(undefined);
  const [projectsInfo, setProjectsInfo] = useState([]);
  const [hasAccess, setHasAccess] = useState(true);
  const {
    locale,
    isAdmin,
    isPatient,
    updateUserId,
    updateProjectId,
    flushContext,
  } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    async function loadAuth() {
      const { data } = await authDispatch({ useLastSaved: true });

      if (data.projects) {
        setProjectsInfo(data.projects);
      }

      if (!data.auth) {
        setReady(true);
        setHasAccess(false);
        return;
      }

      const {
        projectId: selectedProjectId,
        token,
        cloudFunctionsLocation,
        config,
      } = data.auth;
      try {
        await connectFirebaseComponent(config, cloudFunctionsLocation, selectedProjectId, token);
        setHasAccess(true);
      } catch (err) {
        setHasAccess(false);
        setReady(true);
      }
      setProjectId(selectedProjectId);
    }

    loadAuth();
  }, []);

  useEffect(() => {
    async function updateContext() {
      await Promise.all([
        updateProjectId(projectId),
        updateUserId(getComponentUserId()),
      ]);
      setReady(true);
    }

    if (projectId !== undefined && hasAccess) {
      updateContext();
    }
  }, [projectId]);

  const handleProjectSwitch = async (newProjectId) => {
    setReady(false);
    await switchFirebaseComponent(newProjectId);
    flushContext();
    navigate('/');
    setProjectId(newProjectId);
  };

  if (isReady === false) {
    return <LandingPage showText={locale !== undefined} />;
  }

  return (
    <Box sx={{ display: 'flex' }} component="div">
      <AppBar />
      <Drawer
        onProjectSwitch={handleProjectSwitch}
        projectId={projectId}
        projectsInfo={projectsInfo}
        minimal={!hasAccess}
      />
      <Box sx={{ flexGrow: 1, p: 5 }} component="main">
        <Offset />
        { (hasAccess && !isPatient) ? getSwitch(isAdmin) : <NoAccess /> }
      </Box>
    </Box>
  );
}

export default App;
