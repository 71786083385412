import { useAppContext } from '~/components/AppContext';

import Study from './Study';
import Health from './Health';

function Dispatcher(props) {
  const { isStudyProject } = useAppContext();

  if (isStudyProject) {
    return (<Study {...props} />);
  }

  return (<Health {...props} />);
}

export default Dispatcher;
