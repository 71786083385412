import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import Translate from '~/components/display/translate';
import Tooltip from '~/components/display/tooltip';
import { StarfleetBlue, KlingonBlack } from '~/utils/colors';
import { MetricRecordView } from '~/components/MetricRecord';
import MetricValue from '~/components/MetricValue';
import MetricValueComparisonIcon from '~/components/MetricValueComparisonIcon';
import DoubleSupportIcon from '~/components/icons/double-support';
import DoubleSupportPercentage from '~/utils/metrics/double-support-percentage';

function DoubleSupportParameter({ phases, means, stds }) {
  return (
    <MetricRecordView
      title={<Translate>doubleSupportMeanSD</Translate>}
      tooltip={(
        <Tooltip title={<Translate>lowerDoubleSupportHigherStability</Translate>}>
          <Avatar
            sx={{
              bgcolor: StarfleetBlue[100],
              color: 'white',
              fontSize: '0.875rem',
              fontWeight: 500,
              height: 18,
              width: 18,
            }}
          >
            ?
          </Avatar>
        </Tooltip>
      )}
      icon={<DoubleSupportIcon />}
      sx={{ width: 'auto' }}
    >
      <Stack direction="row" alignItems="baseline" spacing={4}>
        { phases.map((phase, index) => (
          <Stack direction="column" alignItems="center" key={phase}>
            { (means[index] !== 0 && stds[index] !== 0)
              ? (
                <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>
                  <MetricValue metric={DoubleSupportPercentage}>
                    { means[index] }
                  </MetricValue>
                  {' ± '}
                  <MetricValue metric={DoubleSupportPercentage}>
                    { stds[index] }
                  </MetricValue>
                </Typography>
              ) : (
                <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>
                  <Translate>noDataAvailable</Translate>
                </Typography>
              )}
            { phases.length > 1 && (
              <Typography sx={{ fontSize: '0.75rem', color: KlingonBlack[600] }}>
                { phase }
              </Typography>
            )}
          </Stack>
        ))}
        <MetricValueComparisonIcon metric={DoubleSupportPercentage} values={means} showColor />
      </Stack>
    </MetricRecordView>
  );
}

DoubleSupportParameter.propTypes = {
  phases: PropTypes.arrayOf(PropTypes.string).isRequired,
  means: PropTypes.arrayOf(PropTypes.number).isRequired,
  stds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default DoubleSupportParameter;
