import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

function PaperPage({ fullWidth = false, disableBottomPadding = false, children }) {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="flex-start">
      <Grid item xs={12} md={fullWidth ? 12 : 8}>
        <Paper
          variant="outlined"
          sx={[
            { p: 3 },
            disableBottomPadding && {
              pb: 0,
            },
          ]}
        >
          { children }
        </Paper>
      </Grid>
    </Grid>
  );
}

PaperPage.propTypes = {
  fullWidth: PropTypes.bool,
  disableBottomPadding: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default PaperPage;
