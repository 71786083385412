import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

import FullDateDisplay from '~/components/display/full-date';
import Translate from '~/components/display/translate';
import RecordType from '~/components/display/record-type';

import { fetchUserProfile } from '~/firebase/firestore';
import { getRecordName } from '~/utils/record';
import { KlingonBlack } from '~/utils/colors';

function Header({ record }) {
  const [recordedBy, setRecordedBy] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function load() {
      setIsLoading(true);
      const user = await fetchUserProfile(record.authorId);
      setRecordedBy(user.role === 'patient' ? 'patient' : user.name);
      setIsLoading(false);
    }
    load();
  }, [record.authorId]);

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
      <Grid item>
        <RecordType type={record.type === 'static' ? 'static' : record.recordType} />
      </Grid>
      <Grid item>
        <Grid container direction="column" justifyContent="center" alignItems="flex-start">
          <Grid item>
            <Typography
              component="span"
              sx={{
                fontSize: '1.5rem',
                fontWeight: 600,
                color: KlingonBlack[900],
                verticalAlign: 'middle',
              }}
            >
              <Translate>{getRecordName(record)}</Translate>
            </Typography>
            { (record.parameters && record.parameters.length > 0) && (
              <>
                <HorizontalRuleIcon sx={{ color: KlingonBlack[400], verticalAlign: 'middle', mx: 1 }} />
                <Typography
                  component="span"
                  sx={{
                    fontSize: '1.5rem',
                    fontWeight: 600,
                    color: KlingonBlack[700],
                    verticalAlign: 'middle',
                  }}
                >
                  { record.parameters
                    .map(param => (<Translate>{param.value}</Translate>))
                    .join(' & ') }
                </Typography>

              </>
            )}
          </Grid>
          <Grid item>
            <Typography sx={{ color: KlingonBlack[600], fontWeight: 500, fontSize: '0.875rem' }}>
              <FullDateDisplay options={{ timeZone: record.timeZone }}>
                {record.startTime}
              </FullDateDisplay>
              {(!isLoading && recordedBy !== undefined) && (
                <>
                  {' '}
                  <Translate>by</Translate>
                  {' '}
                  { recordedBy === 'patient' ? (<Translate>patient</Translate>) : recordedBy }
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  record: PropTypes.shape({
    type: PropTypes.string,
    authorId: PropTypes.string,
    recordType: PropTypes.string,
    startTime: PropTypes.number,
    timeZone: PropTypes.string,
    parameters: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })),
  }).isRequired,
};

export default Header;
