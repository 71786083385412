import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';

import Button from '../components/display/button';
import Breadcrumbs from '~/components/Breadcrumbs';
import Tabs from '~/components/display/tabs';
import Tab from '~/components/display/tab';
import TabPanel from '~/components/display/tab-panel';
import Translate from '~/components/display/translate';
import MemberList from '~/components/MemberList';

import MemberIcon from '~/components/icons/member';

import { useAppContext } from '~/components/AppContext';

function Members() {
  const { isAdmin, isStudyProject } = useAppContext();
  const [tabSelected, setTabSelected] = useState('active');

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Grid item>
          <Breadcrumbs links={[{ type: 'member', to: '/members/' }]} />
        </Grid>
        { isAdmin && (
          <Grid item>
            <Button
              color="primary"
              component={Link}
              to="/members/new"
              startIcon={<MemberIcon />}
            >
              <Translate>new</Translate>
            </Button>
          </Grid>
        )}
      </Grid>
      <Tabs value={tabSelected} onChange={(_, value) => setTabSelected(value)}>
        <Tab
          label={<Translate>memberActive</Translate>}
          value="active"
        />
        <Tab
          label={<Translate>{ isStudyProject ? 'memberBlocked' : 'memberArchived' }</Translate>}
          value="blocked"
        />
      </Tabs>
      <TabPanel value={tabSelected} active="active" index={0}>
        <MemberList />
      </TabPanel>
      <TabPanel value={tabSelected} active="blocked" index={1}>
        <MemberList blockedMembers />
      </TabPanel>
    </>
  );
}

export default Members;
