import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import Button from './button';
import Translate from './translate';

import { KlingonBlack } from '../../utils/colors';

function NoData({
  svg,
  svgAlt,
  title = undefined,
  description = undefined,
  linkTo = undefined,
  buttonText = 'startHere',
  onClick = undefined,
}) {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={3}
      sx={{
        mt: 17,
        maxWidth: 400,
        mx: 'auto',
      }}
    >
      <Grid item>
        <img src={svg} alt={svgAlt} />
      </Grid>
      { title && (
        <Grid item>
          <Typography sx={{ fontSize: '1.25rem', fontWeight: 600 }}>
            <Translate>{ title }</Translate>
          </Typography>
        </Grid>
      )}
      { description && (
        <Grid item>
          <Typography
            sx={{
              fontSize: '0.875rem',
              fontWeight: 400,
              textAlign: 'center',
              color: KlingonBlack[700],
            }}
          >
            <Translate>{ description }</Translate>
          </Typography>
        </Grid>
      )}
      { linkTo && (
        <Grid item>
          <Button color="primary" component={Link} to={linkTo}>
            <Translate>{ buttonText }</Translate>
          </Button>
        </Grid>
      )}
      { onClick && (
        <Grid item>
          <Button color="primary" onClick={onClick}>
            <Translate>{ buttonText }</Translate>
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

NoData.propTypes = {
  svg: PropTypes.string.isRequired,
  svgAlt: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  linkTo: PropTypes.string,
  onClick: PropTypes.func,
};

export default NoData;
