import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import {
  ContinuumYellow,
  KlingonBlack,
  PhaserRed,
  OrionTeal,
} from '../../utils/colors';

function CriticalityLevel({ level = null, thresholds = null }) {
  if (level === null || Number.isNaN(level) || thresholds === null) {
    return (
      <Box
        sx={{
          width: 22,
          height: 22,
          borderRadius: '6px',
          bgcolor: KlingonBlack[300],
        }}
      />
    );
  }

  return (
    <Box
      sx={[
        {
          width: 22,
          height: 22,
          borderRadius: '6px',
        },
        (level < thresholds[0]) && {
          bgcolor: KlingonBlack[700],
        },
        (level >= thresholds[0] && level < thresholds[1]) && {
          bgcolor: PhaserRed[900],
        },
        (level >= thresholds[1] && level < thresholds[2]) && {
          bgcolor: ContinuumYellow[500],
        },
        (level >= thresholds[2]) && {
          bgcolor: OrionTeal[600],
        },
      ]}
    />
  );
}

CriticalityLevel.propTypes = {
  level: PropTypes.number,
  thresholds: PropTypes.arrayOf(PropTypes.number),
};

export default CriticalityLevel;
