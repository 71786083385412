import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';

import { StarfleetBlue } from '../../utils/colors';

import RecordTypeStandardIcon from '~/components/icons/record-type-standard';
import RecordTypeStaticIcon from '~/components/icons/record-type-static';
import RecordTypeSynchroIcon from '~/components/icons/record-type-synchro';
import Report from '~/components/icons/report';

function RecordType({ type, isSmall = false }) {
  const fontSize = isSmall ? '14px' : '24px';
  return (
    <Avatar
      sx={{
        bgcolor: StarfleetBlue[400],
        color: 'white',
        fontSize,
        fontWeight: 600,
        width: isSmall ? 32 : 64,
        height: isSmall ? 32 : 64,
        borderRadius: isSmall ? '8px' : '16px',
      }}
      variant="rounded"
    >
      { type === '2min' && 2 }
      { type === '6min' && 6 }
      { type === '10meters' && 10 }
      { type === '400meters' && 400 }
      { type === '25foot' && 25 }
      { type === 'standard' && <RecordTypeStandardIcon sx={{ fontSize }} /> }
      { type === 'synchro' && <RecordTypeSynchroIcon sx={{ fontSize }} />}
      { type === 'compliance-report' && <Report sx={{ fontSize }} />}
      { type === 'static' && <RecordTypeStaticIcon sx={{ fontSize }} />}
    </Avatar>
  );
}

RecordType.propTypes = {
  type: PropTypes.oneOf([
    'standard',
    '2min',
    '6min',
    '10meters',
    '400meters',
    '25foot',
    'synchro',
    'compliance-report',
    'static',
  ]).isRequired,
  isSmall: PropTypes.bool,
};

export default RecordType;
