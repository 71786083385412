import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { KlingonBlack } from '../../utils/colors';

function HeaderPaper({ title = undefined, subtitle = undefined }) {
  return (
    <Box sx={{ mb: 2 }}>
      { title && (
        <Typography variant="h3" gutterBottom sx={{ color: KlingonBlack[900], fontSize: '1.25rem', fontWeight: 600 }}>
          { title }
        </Typography>
      )}
      { subtitle && (
        <Typography sx={{ color: KlingonBlack[600], fontSize: '0.8rem', fontWeight: 400 }}>
          { subtitle }
        </Typography>
      )}
    </Box>
  );
}

HeaderPaper.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default HeaderPaper;
