import { useMemo } from 'react';
import {
  Link,
  matchPath,
  useLocation,
} from 'react-router-dom';
import Grid from '@mui/material/Grid';

import Breadcrumbs from '~/components/Breadcrumbs';
import Button from '../components/display/button';
import Tabs from '../components/display/tabs';
import Tab from '../components/display/tab';
import TabPanel from '../components/display/tab-panel';
import Translate from '../components/display/translate';
import PatientList from '../components/PatientList';
import Users from '../components/icons/users';

function Patients() {
  const { pathname } = useLocation();
  const match = useMemo(() => ([
    ['active', matchPath({ path: '/patients/active', end: true }, pathname)],
    ['blocked', matchPath({ path: '/patients/blocked', end: true }, pathname)],
  ].find(i => i[1] !== null)[0]), [pathname]);

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Grid item>
          <Breadcrumbs links={[{ type: 'patient', to: '/patients/' }]} />
        </Grid>
        <Grid item>
          <Button
            color="primary"
            component={Link}
            to="/patients/new"
            startIcon={<Users />}
          >
            <Translate>newPatient</Translate>
          </Button>
        </Grid>
      </Grid>
      <Tabs value={match}>
        <Tab
          label={<Translate>patientActive</Translate>}
          value="active"
          to="/patients/active"
          component={Link}
        />
        <Tab
          label={<Translate>patientBlocked</Translate>}
          value="blocked"
          to="/patients/blocked"
          component={Link}
        />
      </Tabs>
      <TabPanel value={match} active="active" index={0}>
        <PatientList />
      </TabPanel>
      <TabPanel value={match} active="blocked" index={1}>
        <PatientList blockedPatients />
      </TabPanel>
    </>
  );
}

export default Patients;
