import { useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import ButtonGroup from '@mui/material/ButtonGroup';

import Translate from '~/components/display/translate';
import CardHeader from '~/components/CardHeader';
import Button from '~/components/display/button';
import InterpolationLoadingWrapper from '~/components/InterpolationLoadingWrapper';
import Ellipse from '~/components/Ellipse';
import StaticHeatmapGraph from '~/components/StaticHeatmapGraph';

function StaticHeatmapComplianceCard({
  phases,
  interpolationInfo,
  processedRecords,
  insoles,
  bipodalCops,
}) {
  // 'mean-pressures' or 'cop'
  const [selectedGraph, setSelectedGraph] = useState('mean-pressures');

  return (
    <Card sx={{ width: 1 }}>
      <CardHeader
        title={(
          <Translate>
            { selectedGraph === 'mean-pressures' ? 'pressureDistribution' : 'globalCenterOfPressure'}
          </Translate>
        )}
        subheader={(
          <Translate>
            { selectedGraph === 'mean-pressures' ? 'pressureDistributionSubheader' : 'globalCenterOfPressureSubheader'}
          </Translate>
        )}
        action={phases.length > 1 && (
          <ButtonGroup>
            <Button
              color={selectedGraph === 'mean-pressures' ? 'primary' : undefined}
              variant={selectedGraph === 'mean-pressures' ? 'contained' : 'outlined'}
              onClick={() => setSelectedGraph('mean-pressures')}
            >
              <Translate>distribution</Translate>
            </Button>
            <Button
              color={selectedGraph === 'cop' ? 'primary' : undefined}
              variant={selectedGraph === 'cop' ? 'contained' : 'outlined'}
              onClick={() => setSelectedGraph('pti')}
            >
              <Translate>COP</Translate>
            </Button>
          </ButtonGroup>
        )}
      />
      <CardContent>
        <Stack direction="row" justifyContent="space-around">
          { phases.map((phase, index) => (
            <InterpolationLoadingWrapper
              isRecordAvailable={interpolationInfo[index] !== null}
              isInterpolationPending={false}
              hasInterpolationTimeout={false}
              key={phase}
            >
              { selectedGraph === 'mean-pressures' && (
                <StaticHeatmapGraph
                  insoles={insoles[index]}
                  processedRecord={processedRecords[index]}
                  mesxX={interpolationInfo[index]?.meshX}
                  meshY={interpolationInfo[index]?.meshY}
                />
              )}
              { selectedGraph === 'cop' && (
                <Ellipse
                  bipodalCops={bipodalCops[index]}
                  ellipse={processedRecords[index]?.ellipseBipodalCop}
                />
              )}
            </InterpolationLoadingWrapper>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}

StaticHeatmapComplianceCard.propTypes = {
  phases: PropTypes.arrayOf(PropTypes.string).isRequired,
  interpolationInfo: PropTypes.arrayOf(PropTypes.shape({
    isInterpolationPending: PropTypes.bool,
    hasInterpolationTimeout: PropTypes.bool,
    meshX: PropTypes.number,
    meshY: PropTypes.number,
  })).isRequired,
  processedRecords: PropTypes.arrayOf(PropTypes.object).isRequired,
  insoles: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
  bipodalCops: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default StaticHeatmapComplianceCard;
