import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Radio from '~/components/display/radio';
import Translate from '~/components/display/translate';
import LeftIcon from '~/components/icons/left';
import RightIcon from '~/components/icons/right';

import { KlingonBlack, StarfleetBlue } from '~/utils/colors';
import useUniqueId from '~/utils/hooks/useUniqueId';

function SideSelectionField({ onChange, value }) {
  const labelId = useUniqueId('radio-side');

  return (
    <FormControl component="fieldset" fullWidth>
      <FormLabel
        id={labelId}
        sx={{
          fontWeight: 500,
          fontSize: '0.8125rem',
          pb: 1.5,
          color: KlingonBlack[900],
          '&.Mui-focused': {
            color: 'inherit',
          },
        }}
        component="legend"
      >
        <Translate>sideOfTheBody</Translate>
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby={labelId}
        name="radio-buttons-side"
        value={value}
        onChange={ev => onChange({ name: 'side', value: ev.target.value })}
        sx={{ justifyContent: 'center' }}
      >
        <Stack direction="row" justifyContent="space-around" sx={{ width: 1 }}>
          { ['left', 'right'].map(side => (
            <Stack
              key={side}
              alignItems="center"
              sx={[
                {
                  border: '0.15rem solid',
                  borderColor: KlingonBlack[300],
                  borderRadius: '3px',
                  pt: 2,
                },
                (value === side) && {
                  borderColor: StarfleetBlue[100],
                },
              ]}
            >
              { side === 'left' && <LeftIcon sx={{ fontSize: 50 }} colored={value === side} /> }
              { side === 'right' && <RightIcon sx={{ fontSize: 50 }} colored={value === side} /> }
              <FormControlLabel
                sx={[
                  {
                    flexGrow: 1,
                    '& .MuiFormControlLabel-label': {
                      fontSize: '0.8125rem',
                      fontWeight: 400,
                    },
                  },
                  (value === side) && {
                    '& .MuiFormControlLabel-label': {
                      fontWeight: 600,
                    },
                  },
                ]}
                value={side}
                label={<Translate>{side === 'left' ? 'Left' : 'Right'}</Translate>}
                labelPlacement="top"
                control={<Radio />}
              />
            </Stack>
          ))}
        </Stack>
      </RadioGroup>
    </FormControl>
  );
}

SideSelectionField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf(['left', 'right', '']).isRequired,
};

export default SideSelectionField;
