const {
  VITE_VERSION_ENV: VERSION,
  VITE_COMMIT_HASH: COMMIT_HASH,
  VITE_SENTRY_DSN: SENTRY_DSN,
  VITE_FIREBASE_CONFIG: FIREBASE_CONFIG,
  MODE: TARGET,
  PROD,
  DEV,
  BASE_URL,
} = import.meta.env;

function isDevTarget() {
  return TARGET === 'dev';
}

function isProdTarget() {
  return TARGET === 'prod';
}

function getLongTargetName() {
  // eslint-disable-next-line no-undef
  switch (TARGET) {
    case 'prod':
      return 'production';
    case 'sandbox':
      return 'sandbox';
    case 'valid':
      return 'validation';
    case 'dev':
      return 'development';
    default:
      return 'development';
  }
}

function isProductionBuild() {
  return PROD;
}

function isDevelopmentBuild() {
  return DEV;
}

export {
  VERSION,
  COMMIT_HASH,
  SENTRY_DSN,
  FIREBASE_CONFIG,
  TARGET,
  BASE_URL,

  isDevTarget,
  isProdTarget,
  getLongTargetName,
  isProductionBuild,
  isDevelopmentBuild,
};
