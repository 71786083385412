import { getPatient } from '@/firebase/functions';
import useFunction from './useFunction';

function usePatient(orgaId, patientId) {
  return useFunction(
    () => getPatient({ orgaId, patientId }),
    { patient: {} },
  );
}

export default usePatient;
