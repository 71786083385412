import React from 'react';
import PropTypes from 'prop-types';

import Translate from '../../display/translate';
import GaitCycle0 from '../../../assets/images/gait-cycle-0.svg';
import GaitCycle30 from '../../../assets/images/gait-cycle-30.svg';
import GaitCycle60 from '../../../assets/images/gait-cycle-60.svg';
import GaitCycle100 from '../../../assets/images/gait-cycle-100.svg';

import { KlingonBlack } from '../../../utils/colors';

function getTitle(index) {
  if (index === 60) {
    return 'toeOff';
  }

  return 'initiateStride';
}

function getAnchor(index) {
  if (index === 60) {
    return 'middle';
  }

  if (index === 100) {
    return 'end';
  }

  return 'start';
}

function getHref(index) {
  if (index === 0) {
    return GaitCycle0;
  }

  if (index === 30) {
    return GaitCycle30;
  }

  if (index === 60) {
    return GaitCycle60;
  }

  return GaitCycle100;
}

function TextHeader({ index, x, isPdf = false }) {
  return (
    <React.Fragment>
      { index !== 30 && (
        <text
          fontWeight={500}
          textAnchor={getAnchor(index)}
          fill={KlingonBlack[700]}
          fontSize={isPdf ? 8 : 12}
          x={x}
          y={isPdf ? -65 : -70}
        >
          <Translate>{getTitle(index)}</Translate>
        </text>
      )}
      { (index === 0 || index === 100) && (
        <text
          fontWeight={400}
          fill={KlingonBlack[600]}
          textAnchor={getAnchor(index)}
          fontSize={isPdf ? 8 : 12}
          x={x}
          y={-50}
        >
          <Translate>heelStrike</Translate>
        </text>
      )}
      <image x={x} y={-30} style={{ transform: 'translateX(-12px)' }} href={getHref(index)} />
    </React.Fragment>
  );
}

TextHeader.propTypes = {
  index: PropTypes.oneOf([0, 30, 60, 100]).isRequired,
  x: PropTypes.number.isRequired,
  isPdf: PropTypes.bool,
};

export default TextHeader;
