import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import Translate from '../display/translate';

import { translate } from '../../utils/i18n';
import { useAppContext } from '~/components/AppContext';

function Languages({ options, values, onChange }) {
  const { file } = useAppContext();

  return (
    <Autocomplete
      multiple
      required
      fullWidth
      value={values}
      options={options}
      getOptionLabel={option => translate(option, file)}
      onChange={(ev, value) => onChange({ name: 'locales', value })}
      filterSelectedOptions
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label={<Translate>language</Translate>}
          helperText={(
            <Translate>select one or more languages available to patient</Translate>
          )}
        />
      )}
    />
  );
}

Languages.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Languages;
