import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

import { StarfleetBlue } from '../../utils/colors';

function Step({ color = StarfleetBlue[400], ...props }) {
  return (
    <SvgIcon {...props}>
      <path d="m 10.376365,16.766395 -3.42584,-3.470899 c -0.4056938,-0.405659 -1.08185,-0.135151 -1.08185,0.450817 v 2.208708 H 1.1356008 v 2.479246 H 5.868675 v 2.208828 c 0,0.540833 0.6761562,0.811376 1.0367729,0.450702 l 3.4258361,-3.425774 c 0.270463,-0.315552 0.270463,-0.676226 0.04504,-0.901634 z" fill={color} />
      <path d="M 4.0201525,10.765671 1.2704643,8.0159834 c -0.36061904,-0.3606167 -0.36061904,-0.9916959 0,-1.3523147 L 4.9216885,3.0124445 c 0.3606166,-0.360619 0.9916957,-0.360619 1.3523147,0 l 1.7129201,1.7129199 c 0,0 0.8564532,0.9916844 1.3973847,0.9916844 0.5409133,0 2.524328,0 2.524328,0 0.811376,0.045031 1.712886,0.8113875 2.07356,1.6678497 0,0 2.929944,5.5444595 3.51591,6.3107275 0.360675,0.450816 1.758015,1.262187 2.524374,1.487594 l 2.028432,0.946641 c 0.405682,0.225294 0.99165,0.856385 0.946642,2.028433 0,0.766241 -0.811377,2.434118 -2.1637,2.434118 -1.803141,0 -5.814877,0 -5.814877,0 0,0 -1.983421,0 -2.975189,-1.126915" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" fill="transparent" stroke={color} />
      <path d="m 15.244961,10.086985 h -2.83992" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" fill="transparent" stroke={color} />
      <path d="M 17.723628,14.008051 H 15.109231" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" fill="transparent" stroke={color} />
    </SvgIcon>
  );
}

Step.propTypes = {
  color: PropTypes.string,
};

export default Step;
