import PropTypes from 'prop-types';

import PageProgress from '~/components/display/page-progress';
import Dispatcher from './Dispatcher';

import { updatePatient } from '@/firebase/functions';
import { updateUser } from '@/firebase/firestore';

import useLastInvitation from '~/utils/hooks/useLastInvitation';

function Edit({
  orgaId,
  patientResourceId,
  patient,
  user,
  auth = undefined,
  onCancel,
  onDone,
}) {
  const [lastInvitation, isInvitationPending] = useLastInvitation(user.key);

  const handleSubmit = async ({ patient: newPatient, locale, status }) => {
    await updatePatient({ orgaId, patientId: patientResourceId, patient: newPatient });

    const newUser = { status };
    // Health patient don't have the `locale` field
    if (locale !== undefined) {
      newUser.locale = locale;
    }
    await updateUser(user.key, newUser);

    onDone();
  };

  const showInvitation = (auth === undefined && Boolean(lastInvitation));

  if (isInvitationPending) {
    return <PageProgress />;
  }

  return (
    <Dispatcher
      isCreate={false}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      patientId={patient.anonymous}
      name={patient.name}
      birthdate={patient.birthdate}
      gender={patient.gender}
      patientResourceId={patientResourceId}
      userId={user.key}
      locale={user.locale}
      status={user.status}
      orgaId={orgaId}
      email={showInvitation ? lastInvitation.email : auth.email}
      showInvitation={showInvitation}
      lastInvitation={lastInvitation}
    />
  );
}

Edit.propTypes = {
  orgaId: PropTypes.string.isRequired,
  patientResourceId: PropTypes.string.isRequired,
  patient: PropTypes.shape({
    anonymous: PropTypes.string,
    name: PropTypes.string,
    gender: PropTypes.string,
    birthdate: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    key: PropTypes.string,
    status: PropTypes.string,
    locale: PropTypes.string,
    program: PropTypes.object,
  }).isRequired,
  auth: PropTypes.shape({
    email: PropTypes.string,
  }),
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
};

export default Edit;
