import PropTypes from 'prop-types';
import ButtonGroup from '@mui/material/ButtonGroup';

import Translate from '~/components/display/translate';
import Button from '~/components/display/button';

import CadenceIcon from '~/components/icons/cadence';
import VelocityIcon from '~/components/icons/velocity';
import StrideLengthIcon from '~/components/icons/stride-length';

import BaseMetric from '~/utils/metrics/base';
import Cadence from '~/utils/metrics/cadence';
import Velocity from '~/utils/metrics/velocity';
import StrideLength from '~/utils/metrics/stride-length';
import { StarfleetBlue, TerranCyan, KlingonBlack } from '~/utils/colors';

function MetricSelection({ metric, onSelectMetric }) {
  const getIcon = (metricKey) => {
    if (metricKey === Cadence.key) {
      return <CadenceIcon sx={{ color: TerranCyan[500] }} />;
    }

    if (metricKey === Velocity.key) {
      return <VelocityIcon colored />;
    }

    return <StrideLengthIcon />;
  };

  return (
    <ButtonGroup
      disableElevation
      orientation="vertical"
    >
      {[Cadence, Velocity, StrideLength].map(m => (
        <Button
          key={m.key}
          variant="outlined"
          sx={{
            bgcolor: metric.key === m.key ? StarfleetBlue['000'] : undefined,
            borderColor: StarfleetBlue[100],
            color: metric.key === m.key ? StarfleetBlue[600] : KlingonBlack[900],
            justifyContent: 'flex-start',
          }}
          onClick={() => onSelectMetric(m)}
          startIcon={getIcon(m.key)}
        >
          <Translate>{m.key}</Translate>
        </Button>
      ))}
    </ButtonGroup>
  );
}

MetricSelection.propTypes = {
  metric: PropTypes.instanceOf(BaseMetric).isRequired,
  onSelectMetric: PropTypes.func.isRequired,
};

export default MetricSelection;
