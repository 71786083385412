import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Translate from '~/components/display/translate';
import Tooltip from '~/components/display/tooltip';
import { StarfleetBlue } from '~/utils/colors';
import { getLeftColor, getRightColor } from '~/utils/palette';
import { MetricRecordView } from '~/components/MetricRecord';
import SingleSupportGraph from './SingleSupportGraph';
import SingleSupportIcon from '~/components/icons/single-support';
import SingleSupportPercentage from '~/utils/metrics/single-support-percentage';
import useUnit from '~/utils/hooks/useUnit';

function SingleSupportParameter({ phases, means, stds }) {
  const legendData = [
    { name: 'leftFoot', color: getLeftColor() },
    { name: 'rightFoot', color: getRightColor() },
  ];
  const unit = useUnit(SingleSupportPercentage);

  return (
    <MetricRecordView
      title={(
        <>
          <Translate>{SingleSupportPercentage.name}</Translate>
          { ` (${unit})`}
        </>
      )}
      tooltip={(
        <Tooltip
          title={(
            <>
              <Translate>significantDifferenceLRLimpingRisk</Translate>
              <Stack direction="column" alignItems="center" justifyContent="center">
                { legendData.map(({ name, color }) => (
                  <Stack key={name} direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '5.5rem' }}>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: '0.875rem',
                      }}
                    >
                      <Translate>{name}</Translate>
                    </Typography>
                    <Box
                      sx={{
                        width: '0.5rem',
                        height: '0.5rem',
                        bgcolor: color,
                        borderRadius: '0.1rem',
                      }}
                    />
                  </Stack>
                ))}
              </Stack>
            </>
          )}
        >
          <Avatar
            sx={{
              bgcolor: StarfleetBlue[100],
              color: 'white',
              fontSize: '0.875rem',
              fontWeight: 500,
              height: 18,
              width: 18,
            }}
          >
            ?
          </Avatar>
        </Tooltip>
      )}
      icon={<SingleSupportIcon />}
      sx={{ width: 'auto' }}
    >
      <SingleSupportGraph
        phases={phases}
        means={means}
        stds={stds}
      />
    </MetricRecordView>
  );
}

SingleSupportParameter.propTypes = {
  phases: PropTypes.arrayOf(PropTypes.string).isRequired,
  means: PropTypes.arrayOf(PropTypes.object).isRequired,
  stds: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SingleSupportParameter;
