import { listPatients } from '@/firebase/functions';
import useFunction from '~/utils/hooks/useFunction';

import { computeComplianceLevelUser } from '~/utils/metrics/compliance-level';
import { computeCriticalityLevel } from '~/utils/metrics/criticality-level';
import { getThresholdPhase } from '~/utils/program';

function usePatients(orgaId = undefined, blockedPatients = false) {
  return useFunction(
    () => listPatients({ orgaId }),
    [],
    [],
    patients => patients
      .filter(patient => patient.user.status === (blockedPatients ? 'inactive' : 'active'))
      .map(patient => ({
        complianceLevel: computeComplianceLevelUser(patient.program),
        criticalityLevel: computeCriticalityLevel(patient.program),
        criticalityThresholds: getThresholdPhase(patient.program),
        ...patient,
        ...patient.lastRecordInfo,
        // destruc the patient object to:
        // - ease the use of the search
        // - not break the previous logic (mostly search and row)
        ...patient.patient,
      })),
  );
}

export default usePatients;
