import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';

import InsolePressures from '~/components/InsolePressures';
import LoadPercentage from './LoadPercentage';

import { getLeft, getRight } from '~/utils/insoles';

function InsolesPressuresStatic({
  insoles,
  leftPressures = [],
  rightPressures = [],
  leftForeFootLoad = undefined,
  leftBackFootLoad = undefined,
  rightForeFootLoad = undefined,
  rightBackFootLoad = undefined,
  height = 350,
  ...otherProps
}) {
  const leftInsole = getLeft(insoles);
  const rightInsole = getRight(insoles);

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        gap: '30px',
      }}
    >
      <Grid container direction="column" justifyContent="flex-start" alignItems="center">
        <Grid>
          <LoadPercentage value={leftForeFootLoad} />
        </Grid>
        <Grid>
          <InsolePressures
            isStatic
            insole={leftInsole}
            pressures={leftPressures}
            height={height}
            {...otherProps}
          />
        </Grid>
        <Grid>
          <LoadPercentage value={leftBackFootLoad} />
        </Grid>
      </Grid>
      <Grid container direction="column" justifyContent="flex-start" alignItems="center">
        <Grid>
          <LoadPercentage value={rightForeFootLoad} />
        </Grid>
        <Grid>
          <InsolePressures
            isStatic
            insole={rightInsole}
            pressures={rightPressures}
            height={height}
            {...otherProps}
          />
        </Grid>
        <Grid>
          <LoadPercentage value={rightBackFootLoad} />
        </Grid>
      </Grid>
    </Box>
  );
}

InsolesPressuresStatic.propTypes = {
  insoles: PropTypes.arrayOf(PropTypes.object).isRequired,
  leftPressures: PropTypes.arrayOf(PropTypes.number),
  rightPressures: PropTypes.arrayOf(PropTypes.number),
  leftForeFootLoad: PropTypes.number,
  leftBackFootLoad: PropTypes.number,
  rightForeFootLoad: PropTypes.number,
  rightBackFootLoad: PropTypes.number,
  height: PropTypes.number,
};

export default InsolesPressuresStatic;
