import {
  Navigate,
  useLocation,
  useParams,
} from 'react-router-dom';
import { useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import LandingPage from '~/components/landing-page';
import LinkProcess from './process';

import { requiresAuth } from '@/firebase/auth';
import { useAppContext } from '~/components/AppContext';
import useLinkCheck from '~/utils/hooks/useLinkCheck';
import { translate } from '~/utils/i18n';

function Link() {
  const location = useLocation();
  const { linkId } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { file } = useAppContext();

  const [{
    email,
    isPatient,
    isLinkValid,
    isNewAccount,
  }, isLoading, err] = useLinkCheck(linkId);

  const action = key => (
    <IconButton onClick={() => closeSnackbar(key)} size="large">
      <CloseIcon style={{ color: 'white' }} />
    </IconButton>
  );

  if (isLoading) {
    return (<LandingPage />);
  }

  if (isPatient) {
    return (
      <div>
        show page for patient w/ link to apps
      </div>
    );
  }

  if (!err && !isLinkValid) {
    enqueueSnackbar(translate('expiredLinkInvitation', file), {
      variant: 'error',
      persist: true,
      action,
    });

    return <Navigate to="/" replace />;
  }

  if (requiresAuth()) {
    return <Navigate to="/login" replace state={{ from: location, isNewAccount, email }} />;
  }

  return (<LinkProcess linkId={linkId} />);
}

export default Link;
