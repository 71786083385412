import { endOfDay } from 'date-fns/endOfDay';

import { toISOFormat } from './date';

function getThresholdPhase(program) {
  if (program === undefined) {
    return null;
  }

  const today = new Date();
  const phase = program.phases.find(i => today > new Date(i.startDate)
    && today < endOfDay(new Date(i.endDate)));
  if (phase === undefined) {
    return null;
  }

  return phase.thresholds;
}

function getPhases(program) {
  if (program === undefined) {
    return [];
  }

  return program.phases;
}

// find the phase to display, either:
// - the current phase
// - the first phase if the program has not started
// - the previous phase if between two phases
// - the last phase if the program has ended
function getDisplayPhase(program) {
  const phases = getPhases(program);
  const today = new Date();

  let phase;
  // current phase
  phase = phases.find(i => today > new Date(i.startDate) && today < endOfDay(new Date(i.endDate)));
  if (phase !== undefined) {
    return phase;
  }

  // first phase
  if (new Date(phases[0].startDate) > today) {
    return phases[0];
  }

  // between two phases
  phase = phases.find((elt, i, arr) => {
    if (i + 1 >= phases.length) {
      return false;
    }

    return (today > endOfDay(new Date(elt.endDate)) && today < new Date(arr[i + 1].startDate));
  });
  if (phase !== undefined) {
    return phase;
  }

  // the last phase
  return phases[phases.length - 1];
}

function getNbActionsTodo(phase) {
  return phase.actions.reduce((acc, cur) => acc + cur.quantity, 0) * phase.nbPeriodsToValidate;
}

function addDays(refDate, days) {
  const ONE_DAY_MS = 24 * 60 * 60 * 1000;
  return toISOFormat(new Date(new Date(refDate).getTime() + days * ONE_DAY_MS));
}

function extractDaySuffix(relativeDate) {
  return parseInt(relativeDate.split('_')[1], 10);
}

function extractPhaseIndex(relativeDate) {
  return parseInt(relativeDate.split('_')[0].substring(2), 10);
}

function computePhaseStartDate(phase, startDates, programStartDate) {
  if (phase.relativeStartDate.startsWith('PRO_')) {
    return addDays(programStartDate, extractDaySuffix(phase.relativeStartDate));
  }

  // startsWith `PH`
  const phaseIndex = extractPhaseIndex(phase.relativeStartDate);
  return addDays(startDates[phaseIndex], extractDaySuffix(phase.relativeStartDate));
}

function computePhasesStartDate(phases, programStartDate) {
  const startDates = [];

  phases.forEach((phase) => {
    startDates.push(computePhaseStartDate(phase, startDates, programStartDate));
  });

  return startDates;
}

function computePhaseEndDate(phase, startDate) {
  return addDays(startDate, phase.duration - 1);
}

function programHasRTM(program) {
  return program.type === 'lowerLimbOrthopedicSurgery';
}

function programHasEnded(program) {
  if (program === undefined) {
    return true;
  }

  const today = new Date();

  return program.phases.every(({ endDate }) => endOfDay(endDate) < today);
}

export {
  getThresholdPhase,
  getPhases,
  getDisplayPhase,
  getNbActionsTodo,
  computePhaseStartDate,
  computePhasesStartDate,
  computePhaseEndDate,
  programHasRTM,
  programHasEnded,
};
