import SvgIcon from '@mui/material/SvgIcon';

import { StarfleetBlue } from '~/utils/colors';

function StrideLength(props) {
  return (
    <SvgIcon sx={{ color: StarfleetBlue[400] }} {...props}>
      <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.5 5.5C14.6 5.5 15.5 4.6 15.5 3.5C15.5 2.4 14.6 1.5 13.5 1.5C12.4 1.5 11.5 2.4 11.5 3.5C11.5 4.6 12.4 5.5 13.5 5.5ZM9.8 8.9L7.24 21.81C7.11 22.42 7.59 23 8.22 23H8.3C8.77 23 9.17 22.68 9.28 22.22L10.9 15L13 17V22C13 22.55 13.45 23 14 23C14.55 23 15 22.55 15 22V16.36C15 15.81 14.78 15.29 14.38 14.91L12.9 13.5L13.5 10.5C14.57 11.74 16.12 12.63 17.86 12.91C18.46 13 19 12.52 19 11.91C19 11.42 18.64 11.01 18.15 10.93C16.63 10.68 15.37 9.78 14.7 8.6L13.7 7C13.14 6.11 12.02 5.75 11.05 6.16L7.22 7.78C6.48 8.1 6 8.82 6 9.63V12C6 12.55 6.45 13 7 13C7.55 13 8 12.55 8 12V9.6L9.8 8.9Z" />
      </svg>
    </SvgIcon>
  );
}

export default StrideLength;
