import PropTypes from 'prop-types';

import { formatFullDate } from '../../utils/display';
import { useAppContext } from '~/components/AppContext';

function FullDateDisplay({ children, options = undefined }) {
  const { locale } = useAppContext();
  return formatFullDate(children, locale, options);
}

FullDateDisplay.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.object,
};

export default FullDateDisplay;
