import PropTypes from 'prop-types';

import Select from '~/components/display/select';
import Translate from '~/components/display/translate';

function ProsthesisManufacturerField({ value = '', onChange, ...props }) {
  return (
    <Select
      {...props}
      value={value}
      onChange={ev => onChange({
        name: 'prosthesisManufacturer',
        value: ev.target.value,
      })}
      options={[
        { key: '', label: 'None' },
        { key: 'arthrex', label: 'Arthrex' },
        { key: 'dePuySynthes', label: 'DePuy Synthes' },
        { key: 'nuVasive', label: 'NuVasive' },
        { key: 'orthofixMedical', label: 'Orthofix Medical' },
        { key: 'smithNephew', label: 'Smith & Nephew' },
        { key: 'stryker', label: 'Stryker' },
        { key: 'wrightMedicalGroup', label: 'Wright Medical Group' },
        { key: 'zimmerBiomet', label: 'Zimmer Biomet' },
      ]}
      label={<Translate>prosthesisManufacturer</Translate>}
    />
  );
}

ProsthesisManufacturerField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default ProsthesisManufacturerField;
