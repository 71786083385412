import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Translate from '~/components/display/translate';

import { KlingonBlack } from '~/utils/colors';
import { getCompliancePhaseColor } from '~/utils/palette';

function PhasesLegend({ phases, hasFirstPhase = true }) {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
      {phases.map((phase, index) => (
        <Stack key={phase} direction="row" gap={0.5} alignItems="center">
          <svg width="27" height="27">
            <circle cx="13" cy="13" r="7" fill="white" strokeWidth={3} stroke={getCompliancePhaseColor(index)} />
          </svg>
          <Typography>
            { phase }
          </Typography>
          { (!hasFirstPhase && index === 0) && (
            <Typography sx={{ color: KlingonBlack[600] }}>
              {' ('}
              <Translate>noDataAvailable</Translate>
              )
            </Typography>
          )}
        </Stack>
      ))}
    </Stack>
  );
}

PhasesLegend.propTypes = {
  phases: PropTypes.arrayOf(PropTypes.string).isRequired,
  hasFirstPhase: PropTypes.bool,
};

export default PhasesLegend;
