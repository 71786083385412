import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Table from '../index';
import Head from './head';
import Row from './row';

import { useAppContext } from '~/components/AppContext';

function Patients({ blockedPatients = false, showOrga, ...props }) {
  const { hasPrograms } = useAppContext();
  const navigate = useNavigate();

  const handleClick = (patientId, orgId) => navigate(`/organisations/${orgId}/patients/${patientId}`);

  return (
    <Table
      {...props}
      defaultOrderBy={hasPrograms ? 'criticalityLevel' : 'name'}
      defaultOrder="asc"
      placeholder="Patients…"
      hasSearch
      hasPagination
      Head={Head}
      Row={Row}
      rowKey={i => i.id}
      HeadProps={{ showCompliance: !blockedPatients && hasPrograms, showOrga }}
      RowProps={{ onClick: handleClick, showCompliance: !blockedPatients && hasPrograms, showOrga }}
    />
  );
}

Patients.propTypes = {
  blockedPatients: PropTypes.bool,
  showOrga: PropTypes.bool.isRequired,
};

export default Patients;
