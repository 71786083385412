import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import Translate from '../display/translate';

import { translate } from '../../utils/i18n';
import { useAppContext } from '~/components/AppContext';

function Language({
  options,
  value = null,
  onChange,
  ...rest
}) {
  const { file } = useAppContext();

  return (
    <Autocomplete
      {...rest}
      disableClearable
      fullWidth
      value={value}
      options={options}
      getOptionLabel={option => (option.length > 0 ? (translate(option, file)) : '')}
      onChange={(ev, v) => onChange({ name: 'locale', value: v })}
      filterSelectedOptions
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label={<Translate>language</Translate>}
        />
      )}
    />
  );
}

Language.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default Language;
