import { useParams, useLocation } from 'react-router-dom';

import PageProgress from '~/components/display/page-progress';

import Static from './Static';
import RecordWithStrides from './RecordWithStrides';

import useWatchRecord from '~/utils/hooks/useWatchRecord';

function Record() {
  const { pathname } = useLocation();
  const { recordId } = useParams();
  const listRecordsPath = `${pathname.split('/').slice(0, -1).join('/')}/`;

  const [record, recordLoading] = useWatchRecord(recordId);

  if (recordLoading) {
    return (<PageProgress />);
  }

  if (record.type === 'static') {
    return <Static record={record} listRecordPath={listRecordsPath} />;
  }

  return (<RecordWithStrides record={record} listRecordsPath={listRecordsPath} />);
}

export default Record;
