import SvgIcon from '@mui/material/SvgIcon';

function DoubleSupport(props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M2.02059 10.0642L3.36328 15.225C3.36328 15.225 3.91947 17.2997 3.32976 19.5805C2.91926 21.1686 3.27566 22.721 6.18466 22.5247C6.18466 22.5247 7.79916 22.4042 8.60175 22.2795C9.64612 22.1176 12.5766 22.2525 13.9599 22.6804C14.7588 22.8838 16.1385 23.0524 16.9302 22.7372C18.8915 21.9564 21.193 21.0472 21.193 21.0472C21.193 21.0472 21.8374 20.6601 21.416 20.0149C21.0057 19.3864 20.5002 19.3729 20.2992 19.336C19.8428 19.3156 19.4532 19.4678 18.9785 19.5513C18.4253 19.6485 17.8344 19.3897 17.5783 19.2733L12.953 17.4942C12.953 17.4942 10.0236 16.4749 9.28497 13.8824L5.96679 1.17918" stroke="#2986FF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  );
}

export default DoubleSupport;
