import { useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';

import Translate from '~/components/display/translate';
import CardHeader from '~/components/CardHeader';

import ProgramSelector from './ProgramSelector';
import PhaseReportProgress from './PhaseReportProgress';
import { useAppContext } from '~/components/AppContext';

import { getPhases, programHasRTM } from '~/utils/program';
import { formatDate } from '~/utils/display';
import useUserPrograms from '~/utils/hooks/useUserPrograms';

function ReportComplianceCard({ program: programInit, userId }) {
  const { locale } = useAppContext();
  const [programs] = useUserPrograms(userId);
  const [program, setProgram] = useState(programInit);

  // retrieve the key of the document, not the programId of the reference program
  const { key: programId } = program;
  const phases = getPhases(program);

  return (
    <Card sx={{ width: 1 }}>
      <CardHeader
        title={<Translate>reportCompliance</Translate>}
        subheader={(
          <Translate
            input={{
              FROM_DATE: formatDate(new Date(phases[0].startDate), locale),
              TO_DATE: formatDate(new Date(phases.at(-1).endDate), locale),
            }}
          >
            complianceProgramFromTo
          </Translate>
        )}
      />
      <CardContent>
        <Stack direction="column" justifyContent="center" alignItems="center">
          <ProgramSelector
            program={program}
            programs={programs}
            onSelectProgram={p => setProgram(p)}
          />
        </Stack>
        { phases.map((phase, index) => (
          <PhaseReportProgress
            key={phase.name}
            programId={programId}
            phase={phase}
            phaseIndex={index}
            showButtons={programHasRTM(program)}
          />
        ))}
      </CardContent>
    </Card>
  );
}

ReportComplianceCard.propTypes = {
  userId: PropTypes.string.isRequired,
  program: PropTypes.shape({
    programId: PropTypes.string,
  }).isRequired,
};

export default ReportComplianceCard;
