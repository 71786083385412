import SvgIcon from '@mui/material/SvgIcon';

function Report(props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor">
        <path d="M 13.256642,0.70913643 20.796497,8.2488535 V 23.328563 H 3.2035028 V 0.70913643 Z" strokeWidth="1.38057" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M 12.628148,0.70913643 V 7.6205901 h 8.168118" strokeWidth="0.690292" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M 8.2300728,18.304532 V 14.534488" strokeWidth="1.38057" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M 11.999884,18.30384 V 13.277269" strokeWidth="1.38057" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M 15.769928,18.303146 V 12.02005" strokeWidth="1.38057" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  );
}

export default Report;
