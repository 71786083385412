import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import TextField from '../../display/textfield';
import Translate from '../../display/translate';
import SectionHeaderPaper from '../../display/section-header-paper';
import ButtonsForm from '../buttons';
import Labels from '../../field/labels';
import Languages from '../../field/languages';
import Status from '../../field/status';

import { useAppContext } from '~/components/AppContext';

function Common({
  onCancel,
  onSubmit,
  isCreate,
  name: nameInit = '',
  description: descriptionInit = '',
  labelsId: labelsIdInit = [],
  locales: localesInit = [],
  status: statusInit = 'active',
}) {
  const [name, setName] = useState(nameInit);
  const [description, setDescription] = useState(descriptionInit);
  const [labelsId, setLabelsId] = useState(labelsIdInit);
  const [locales, setLocales] = useState(localesInit);
  const [status, setStatus] = useState(statusInit);
  const [inProgress, setInProgress] = useState(false);
  const [errors, setErrors] = useState({});

  const { isStudyProject, projectLocales } = useAppContext();

  useEffect(() => {
    setName(nameInit);
    setDescription(descriptionInit);
    setLabelsId(labelsIdInit);
    setLocales(localesInit);
    setStatus(statusInit);
  }, [nameInit, descriptionInit, labelsIdInit.length, localesInit.length, statusInit]);

  // return true if the form is not valid, false otherwise
  const validate = () => {
    // check each field passed its own valdation
    if (Object.values(errors).find(i => i === true)) {
      return true;
    }

    // check the initial state
    if (!name) return true;
    if (locales.length === 0) return true;

    return false;
  };

  const handleChange = (change) => {
    setErrors({ ...errors, [change.name]: change.error });
    switch (change.name) {
      case 'name':
        setName(change.value);
        break;
      case 'description':
        setDescription(change.value);
        break;
      case 'labelsId':
        setLabelsId(change.value);
        break;
      case 'locales':
        setLocales(change.value);
        break;
      case 'status':
        setStatus(change.value);
        break;
      default:
        throw new Error(`Unknown change type: ${change.name}`);
    }
  };

  const handleSubmit = async () => {
    if (validate()) {
      return;
    }

    setInProgress(true);
    await onSubmit({
      name,
      description,
      locales,
      labels: labelsId,
      status,
    });
    if (isCreate) {
      setName('');
      setDescription('');
      setLabelsId([]);
      setLocales([]);
    }
    setInProgress(false);
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        <Grid item>
          <SectionHeaderPaper title={<Translate>information</Translate>} />
        </Grid>
        <Grid item>
          <TextField
            required
            autoFocus
            error={errors.name}
            value={name}
            placeholder="enterName"
            onChange={ev => (
              handleChange({ name: 'name', value: ev.target.value, error: !ev.target.value })
            )}
            label={<Translate>name (or ID)</Translate>}
          />
        </Grid>
        <Grid item>
          <TextField
            multiline
            rows={4}
            value={description}
            placeholder="aboutOrganisation"
            onChange={ev => handleChange({ name: 'description', value: ev.target.value })}
            label={<Translate>description</Translate>}
          />
        </Grid>
        { isStudyProject && (
          <>
            <Grid item>
              <SectionHeaderPaper title={<Translate>configuration</Translate>} />
            </Grid>
            <Grid item>
              <Languages
                options={projectLocales}
                values={locales}
                onChange={handleChange}
              />
            </Grid>
            <Grid item>
              <SectionHeaderPaper title={<Translate>labelSelection</Translate>} />
            </Grid>
            <Grid item>
              <Labels
                labelsId={labelsId}
                onChange={handleChange}
              />
            </Grid>
          </>
        )}
        { !isCreate && (
          <React.Fragment>
            <Grid item>
              <SectionHeaderPaper title={<Translate>status</Translate>} />
            </Grid>
            <Grid item>
              <Status value={status} onChange={handleChange} />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
      <ButtonsForm
        inProgress={inProgress}
        disabled={validate()}
        isCreate={isCreate}
        onCancel={() => onCancel()}
        onSubmit={() => handleSubmit()}
        sticky
      />
    </form>
  );
}

Common.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.string,
  labelsId: PropTypes.arrayOf(PropTypes.string),
  locales: PropTypes.arrayOf(PropTypes.string),
  isCreate: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default Common;
