import PropTypes from 'prop-types';
import { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { scaleLinear } from 'd3-scale';

import InsolesPressuresStatic from '~/components/InsolesPressuresStatic';
import ColormapPressure from '~/components/ColormapPressure';

import usePressureScale from '~/utils/hooks/usePressureScale';
import useUnit from '~/utils/hooks/useUnit';
import Load, { convertLoad } from '~/utils/metrics/load';

function StaticHeatmapGraph({
  insoles = [],
  processedRecord = {},
  meshX = 0,
  meshY = 0,
}) {
  const unit = useUnit(Load);

  const leftPressures = processedRecord.maxPressures.left || [];
  const rightPressures = processedRecord.maxPressures.right || [];
  const domain = useMemo(
    () => [0.13, Math.max([...leftPressures, ...rightPressures])],
    [leftPressures.length, rightPressures.length],
  );

  const [, colorInterpolation] = usePressureScale(domain[0], domain[1]);

  return (
    <Stack>
      <InsolesPressuresStatic
        insoles={insoles}
        scale={colorInterpolation}
        leftPressures={leftPressures}
        rightPressures={rightPressures}
        leftForeFootLoad={processedRecord.meanForeFootLoadPercentage?.left}
        leftBackFootLoad={processedRecord.meanBackFootLoadPercentage?.left}
        rightForeFootLoad={processedRecord.meanForeFootLoadPercentage?.right}
        rightBackFootLoad={processedRecord.meanBackFootLoadPercentage?.right}
        meshX={meshX}
        meshY={meshY}
      />
      <ColormapPressure
        colorInterpolation={colorInterpolation}
        ticks={scaleLinear().domain(domain).ticks(8).map(i => convertLoad(i, unit))}
        domain={domain}
        unit={unit}
      />
    </Stack>
  );
}

StaticHeatmapGraph.propTypes = {
  insoles: PropTypes.arrayOf(PropTypes.object),
  processedRecord: PropTypes.shape({
    maxPressures: PropTypes.shape({
      left: PropTypes.arrayOf(PropTypes.number),
      right: PropTypes.arrayOf(PropTypes.number),
    }),
    meanForeFootLoadPercentage: PropTypes.shape({
      left: PropTypes.number,
      right: PropTypes.number,
    }),
    meanBackFootLoadPercentage: PropTypes.shape({
      left: PropTypes.number,
      right: PropTypes.number,
    }),
  }),
  meshX: PropTypes.number,
  meshY: PropTypes.number,
};

export default StaticHeatmapGraph;
