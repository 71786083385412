import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';

import EllipseGraph from './EllipseGraph';
import EllipseStats from './EllipseStats';

function Ellipse({ bipodalCops, ellipse }) {
  return (
    <Stack>
      <EllipseGraph data={bipodalCops} ellipse={ellipse} />
      <EllipseStats ellipse={ellipse} />
    </Stack>
  );
}

Ellipse.propTypes = {
  bipodalCops: PropTypes.arrayOf(PropTypes.object).isRequired,
  ellipse: PropTypes.object.isRequired,
};

export default Ellipse;
