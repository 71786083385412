import { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { select } from 'd3-selection';
import textures from 'textures';

import Translate from '~/components/display/translate';

import { StarfleetBlue, VoyagerPurple } from '~/utils/colors';

function WeekendLegend() {
  const ref = useRef(null);
  const texture = textures.lines().thicker().stroke(VoyagerPurple['000']).background(StarfleetBlue[400]);

  useEffect(() => {
    const svg = select(ref.current);
    svg.call(texture);
  }, []);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <svg width="20" height="20" ref={ref}>
        <rect width="20" height="20" fill={texture.url()} />
      </svg>
      <Typography>
        <Translate>weekends</Translate>
      </Typography>
    </Box>
  );
}

export default WeekendLegend;
