import { useState } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';

import PaperPage from '~/components/display/paper-page';
import HeaderPaper from '~/components/display/header-paper';
import FormSelection from './FormSelection';
import Edit from '~/components/PatientForm/Edit';
import ProgramForm from '~/components/ProgramForm';

import { useAppContext } from '~/components/AppContext';

function TabPatientEdit({
  orgaId,
  patientResourceId,
  patient,
  user,
  auth = undefined,
  program = undefined,
}) {
  const navigate = useNavigate();
  const [formType, setFormType] = useState('patient');
  const { hasPrograms } = useAppContext();

  const handleCancel = () => navigate(`/organisations/${orgaId}/patients/${patientResourceId}`);
  const handleDone = () => navigate(`/organisations/${orgaId}/patients/${patientResourceId}`);

  return (
    <Stack direction="row">
      { hasPrograms && (
        <FormSelection
          formType={formType}
          onSelectFormType={setFormType}
        />
      )}
      <PaperPage disableBottomPadding>
        { formType === 'patient' && (
          <>
            <HeaderPaper title={patient.name} />
            <Edit
              orgaId={orgaId}
              patientResourceId={patientResourceId}
              patient={patient}
              user={user}
              auth={auth}
              onCancel={handleCancel}
              onDone={handleDone}
            />
          </>
        )}
        { formType === 'program' && (
          <ProgramForm
            orgaId={orgaId}
            patientResourceId={patientResourceId}
            patient={patient}
            userId={user.key}
            program={program}
            onDone={handleDone}
          />
        )}
      </PaperPage>
    </Stack>
  );
}

TabPatientEdit.propTypes = {
  orgaId: PropTypes.string.isRequired,
  patientResourceId: PropTypes.string.isRequired,
  user: PropTypes.shape({
    key: PropTypes.string,
  }).isRequired,
  patient: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  auth: PropTypes.object,
  program: PropTypes.object,
};

export default TabPatientEdit;
