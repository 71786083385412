import SvgIcon from '@mui/material/SvgIcon';

function Hip(props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
        <g clipPath="url(#clip0_2389_16148)">
          <path d="M4.1434 23.9999C4.06438 23.9404 3.98851 23.8741 3.9051 23.8215C2.66033 23.0414 1.41744 22.2588 0.165764 21.4894C-0.0273799 21.3705 -0.0342779 21.2753 0.0560231 21.0837C1.22743 18.587 2.53366 16.1635 3.94085 13.792C4.68646 12.5349 5.35682 11.2383 5.80958 9.84281C6.08362 9.00014 6.2824 8.14369 6.1689 7.24404C6.10807 6.76072 5.93249 6.32185 5.66535 5.9218C5.49415 5.66512 5.50857 5.44725 5.63964 5.1649C5.99457 4.40173 6.29996 3.6154 6.63169 2.84159C6.7546 2.55485 6.88943 2.27313 7.02864 1.9939C7.83132 0.383051 9.75147 -0.208575 11.3261 0.666656C11.8993 0.985321 12.1877 1.64644 12.041 2.28314C11.9682 2.59993 11.913 2.92047 11.8441 3.26731C10.5447 3.56531 9.72011 4.50378 9.03533 5.60063C8.31229 6.75884 7.83257 8.031 7.33403 9.29439C6.85933 10.4989 6.37082 11.6978 5.88357 12.8974C5.6597 13.4477 5.80268 13.9892 6.26108 14.281C6.72764 14.5777 7.28951 14.4807 7.68897 14.0193C8.88107 12.6413 10.0826 11.2702 11.4415 10.0525C12.0153 9.53855 12.6493 9.08904 13.2663 8.62513C13.4312 8.50117 13.6357 8.4298 13.8244 8.33338C13.9643 8.69525 13.8821 8.9895 13.5767 9.12973C12.4919 9.62682 11.6936 10.4564 10.9555 11.3554C9.99415 12.5261 9.24791 13.8365 8.51359 15.1537C6.91576 18.0198 5.49791 20.9723 4.23746 24.0005H4.1434V23.9999Z" />
          <path d="M23.9998 4.03142C23.8976 4.37262 23.8249 4.72572 23.6875 5.05252C23.073 6.51813 21.6125 7.33827 20.0385 7.13167C19.8767 7.11038 19.8065 7.04465 19.7795 6.89063C19.4246 4.83779 19.0671 2.78556 18.7066 0.733338C18.6783 0.573067 18.7379 0.492931 18.8727 0.417804C20.606 -0.545702 22.8397 0.216839 23.6618 2.07373C23.818 2.42558 23.8895 2.81499 23.9998 3.18687V4.03079V4.03142Z" />
          <path d="M12.1384 4.08301C12.6012 5.2312 13.0608 6.37188 13.5199 7.51131C12.1108 8.15427 11.024 9.19291 9.97742 10.266C8.98599 11.2833 8.05225 12.357 7.0928 13.4063C7.06647 13.4351 7.04703 13.4808 7.01567 13.4921C6.92349 13.524 6.79556 13.5904 6.74038 13.554C6.6783 13.5127 6.63252 13.3556 6.66199 13.2823C7.53867 11.1318 8.40969 8.97817 9.31897 6.84143C9.64381 6.07826 10.1242 5.39585 10.7418 4.82614C11.1363 4.4624 11.5727 4.17003 12.1371 4.08363L12.1384 4.08301Z" />
          <path d="M18.0509 2.05176C18.2961 3.41845 18.5351 4.75007 18.779 6.11113C18.1569 6.21568 17.6189 6.06543 17.1718 5.65661C16.5509 5.08877 16.3227 4.37632 16.5315 3.55681C16.7309 2.77673 17.2407 2.2834 18.0509 2.05176Z" />
          <path d="M15.5716 4.32666C15.7076 4.74862 15.855 5.2044 16.0074 5.67645C15.3383 5.92562 14.7313 6.33882 14.2114 6.94234C13.859 6.07024 13.5178 5.22693 13.1924 4.4212C13.9969 4.38927 14.7877 4.35796 15.5722 4.32729L15.5716 4.32666Z" />
        </g>
        <defs>
          <clipPath id="clip0_2389_16148">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default Hip;
