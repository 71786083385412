import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import LandingPage from '../components/landing-page';

import useLinkProcess from '../utils/hooks/useLinkProcess';
import { useAppContext } from '~/components/AppContext';
import { translate } from '../utils/i18n';

function LinkProcess({ linkId }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { file } = useAppContext();
  const [, isLoading, err] = useLinkProcess(linkId);

  const action = key => (
    <IconButton onClick={() => closeSnackbar(key)} size="large">
      <CloseIcon style={{ color: 'white' }} />
    </IconButton>
  );

  if (!isLoading && err !== undefined && err.code === 'functions/permission-denied') {
    enqueueSnackbar(translate('unauthorizedLinkUsed', file), {
      variant: 'error',
      persist: true,
      action,
    });
  }

  if (!isLoading) {
    return (<Navigate to="/" replace />);
  }

  return (
    <LandingPage />
  );
}

LinkProcess.propTypes = {
  linkId: PropTypes.string.isRequired,
};

export default LinkProcess;
