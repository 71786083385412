import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';

import ComplianceMetric from '../ComplianceMetric';
import Translate from '../display/translate';
import EyeIcon from '../icons/eye';
import MetricValue from '~/components/MetricValue';

import { ComplianceLevel } from '../../utils/metrics/index';
import { KlingonBlack } from '../../utils/colors';

function CompliancePhaseInfo({ percentage = null, recordsDone, recordsPlanned }) {
  return (
    <Grid container direction="column" sx={{ width: 1 }}>
      <Grid>
        <Typography sx={{ fontWeight: 600, fontSize: '16px', mb: 6 }}>
          <Translate>testCompliance</Translate>
        </Typography>
      </Grid>
      <Grid container justifyContent="space-evenly">
        <Grid>
          <ComplianceMetric
            Icon={EyeIcon}
            subtitle={<Translate>percentageOfCompliancePhase</Translate>}
          >
            <MetricValue metric={ComplianceLevel}>
              {percentage}
            </MetricValue>
          </ComplianceMetric>
        </Grid>
        <Grid>
          <ComplianceMetric
            Icon={FolderOutlinedIcon}
            subtitle={<Translate>testsDoneAsScheduled</Translate>}
          >
            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
              <Typography sx={{ fontSize: '2.5rem', fontWeight: 600 }}>
                { recordsDone }
              </Typography>
              <Typography
                sx={{
                  fontSize: '0.875rem',
                  fontWeight: 400,
                  color: KlingonBlack[600],
                  pl: 1,
                }}
              >
                { ` /${recordsPlanned}` }
              </Typography>
            </Box>
          </ComplianceMetric>
        </Grid>
      </Grid>
    </Grid>
  );
}

CompliancePhaseInfo.propTypes = {
  percentage: PropTypes.number,
  recordsDone: PropTypes.number.isRequired,
  recordsPlanned: PropTypes.number.isRequired,
};

export default CompliancePhaseInfo;
