import PropTypes from 'prop-types';

import MemberList from '~/components/MemberList';

function TabMember({ orgaId }) {
  return (
    <MemberList orgaId={orgaId} />
  );
}

TabMember.propTypes = {
  orgaId: PropTypes.string.isRequired,
};

export default TabMember;
