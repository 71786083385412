import { fetchMembers } from '@/firebase/firestore';
import useFirestore from './useFirestore';

import { useAppContext } from '~/components/AppContext';

function useMembers(orgasId = [], blockedMembers = false) {
  const { isProjectAdmin } = useAppContext();
  return useFirestore(
    () => fetchMembers(orgasId.filter(i => i !== undefined), isProjectAdmin),
    [],
    orgasId,
    members => members.filter(i => (blockedMembers ? i.status === 'inactive' : i.status === 'active')),
  );
}

export default useMembers;
