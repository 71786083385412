import PropTypes from 'prop-types';
import MuiCardHeader from '@mui/material/CardHeader';

import { KlingonBlack } from '../../utils/colors';

function CardHeader({
  title,
  subheader = null,
  isPdf = false,
  ...props
}) {
  return (
    <MuiCardHeader
      sx={[isPdf && { padding: 0 }]}
      title={title}
      titleTypographyProps={{ fontSize: isPdf ? '0.75rem' : '1.25rem', fontWeight: 600 }}
      subheader={subheader}
      subheaderTypographyProps={{ color: KlingonBlack[600], fontSize: isPdf ? '0.5rem' : '0.875rem', fontWeight: 500 }}
      {...props}
    />
  );
}

CardHeader.propTypes = {
  title: PropTypes.node.isRequired,
  subheader: PropTypes.node,
  isPdf: PropTypes.bool,
};

export default CardHeader;
