import { useNavigate } from 'react-router-dom';

import Breadcrumbs from '~/components/Breadcrumbs';
import PaperPage from '../components/display/paper-page';
import HeaderPaper from '../components/display/header-paper';
import Create from '../components/form/member/create';
import Translate from '../components/display/translate';

import { useAppContext } from '~/components/AppContext';

function NewMember() {
  const navigate = useNavigate();
  const { isStudyProject } = useAppContext();

  const handleCancel = () => navigate('/members');
  const handleDone = () => navigate('/members');

  return (
    <>
      <Breadcrumbs
        links={[
          { type: 'member', to: '/members/' },
          { type: 'createMember', to: '/members/new' },
        ]}
      />
      <PaperPage disableBottomPadding>
        <HeaderPaper title={<Translate>{ isStudyProject ? 'newInvestigator' : 'newMember' }</Translate>} />
        <Create onCancel={handleCancel} onDone={handleDone} />
      </PaperPage>
    </>
  );
}

export default NewMember;
