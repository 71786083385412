import { useState } from 'react';
import Grid from '@mui/material/Grid';

import PaperPage from '../components/display/paper-page';
import HeaderPaper from '../components/display/header-paper';
import SectionHeaderPaper from '../components/display/section-header-paper';
import Translate from '../components/display/translate';
import Select from '../components/display/select';
import ButtonsForm from '../components/form/buttons';

import { updateSettings } from '@/firebase/firestore';
import { useAppContext } from '~/components/AppContext';

import Velocity, { VELOCITY_UNITS } from '../utils/metrics/velocity';
import Cadence, { CADENCE_UNITS } from '../utils/metrics/cadence';
import StrideLength, { STRIDE_LENGTH_UNITS } from '~/utils/metrics/stride-length';
import Load, { LOAD_UNITS } from '~/utils/metrics/load';

function Metrics() {
  const { userSettings, updateUserSettings } = useAppContext();
  const { unitMetrics } = userSettings;

  const [velocityUnit, setVelocityUnit] = useState(unitMetrics[Velocity.key]);
  const [cadenceUnit, setCadenceUnit] = useState(unitMetrics[Cadence.key]);
  const [strideLengthUnit, setStrideLengthUnit] = useState(unitMetrics[StrideLength.key]);
  const [loadUnit, setLoadUnit] = useState(unitMetrics[Load.key]);

  const [inProgress, setInProgress] = useState(false);

  const handleChange = (change) => {
    switch (change.name) {
      case Velocity.key: {
        setVelocityUnit(change.value);
        break;
      }
      case Cadence.key: {
        setCadenceUnit(change.value);
        break;
      }
      case StrideLength.key: {
        setStrideLengthUnit(change.value);
        break;
      }
      case Load.key: {
        setLoadUnit(change.value);
        break;
      }
      default:
        throw new Error(`Unknown change type: ${change.name}`);
    }
  };

  const handleCancel = () => {
    setVelocityUnit(unitMetrics[Velocity.key]);
    setCadenceUnit(unitMetrics[Cadence.key]);
    setStrideLengthUnit(unitMetrics[StrideLength.key]);
    setLoadUnit(unitMetrics[Load.key]);
  };

  const handleSubmit = async () => {
    setInProgress(true);

    const settings = {
      unitMetrics: {
        ...unitMetrics,
        [Velocity.key]: velocityUnit,
        [Cadence.key]: cadenceUnit,
        [StrideLength.key]: strideLengthUnit,
        [Load.key]: loadUnit,
      },
    };

    updateUserSettings(settings);
    await updateSettings(settings);

    setInProgress(false);
  };

  return (
    <PaperPage disableBottomPadding>
      <HeaderPaper
        title={<Translate>metricPreference</Translate>}
        subtitle={<Translate>manageMetricsUsed</Translate>}
      />
      <form onSubmit={handleSubmit}>
        <SectionHeaderPaper title={<Translate>metricsUnit</Translate>} />
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={4}>
          <Grid item>
            <Select
              label={<Translate>{Velocity.name}</Translate>}
              value={velocityUnit}
              options={VELOCITY_UNITS.map(i => ({ key: i, label: i }))}
              onChange={ev => handleChange({ name: Velocity.key, value: ev.target.value })}
            />
          </Grid>
          <Grid item>
            <Select
              label={<Translate>{Cadence.name}</Translate>}
              value={cadenceUnit}
              options={CADENCE_UNITS.map(i => ({ key: i, label: (<Translate>{i}</Translate>) }))}
              onChange={ev => handleChange({ name: Cadence.key, value: ev.target.value })}
            />
          </Grid>
          <Grid item sx={{ minWidth: '175px' }}>
            <Select
              label={<Translate>{StrideLength.name}</Translate>}
              value={strideLengthUnit}
              options={STRIDE_LENGTH_UNITS.map(i => ({ key: i, label: i }))}
              onChange={ev => handleChange({ name: StrideLength.key, value: ev.target.value })}
            />
          </Grid>
          <Grid item>
            <Select
              label={<Translate>{Load.name}</Translate>}
              value={loadUnit}
              options={LOAD_UNITS.map(i => ({ key: i, label: i }))}
              onChange={ev => handleChange({ name: Load.key, value: ev.target.value })}
            />
          </Grid>
        </Grid>
      </form>
      <ButtonsForm
        inProgress={inProgress}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        sticky
      />
    </PaperPage>
  );
}

export default Metrics;
