import { useMemo } from 'react';
import PropTypes from 'prop-types';

import Translate from '../../display/translate';
import TableHead from '../head';

import { useAppContext } from '~/components/AppContext';

const headers = [
  { key: 'name', label: (<Translate>name</Translate>), order: true },
  { key: 'labels', label: (<Translate>labels</Translate>), order: false },
  { key: 'complianceLevel', label: (<Translate>complianceSite</Translate>), order: true },
  { key: 'patients', label: (<Translate>patients</Translate>), order: true },
  { key: 'status', label: (<Translate>status</Translate>), order: true },
];

function Head(props) {
  const {
    orderBy,
    order,
    onRequestSort,
    showCompliance,
    ...rest
  } = props;
  const { isHealthProject } = useAppContext();

  const filteredHeaders = useMemo(() => {
    let newHeaders = headers;

    if (!showCompliance) {
      newHeaders = headers.filter(i => i.key !== 'complianceLevel');
    }

    if (isHealthProject) {
      newHeaders = headers.filter(i => i.key !== 'labels');
    }

    return newHeaders;
  }, [showCompliance]);

  return (
    <TableHead
      {...rest}
      headers={filteredHeaders}
      orderBy={orderBy}
      order={order}
      onRequestSort={onRequestSort}
    />
  );
}

Head.propTypes = {
  orderBy: PropTypes.oneOf(headers.filter(i => i.order === true).map(i => i.key)).isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  onRequestSort: PropTypes.func.isRequired,
  showCompliance: PropTypes.bool.isRequired,
};

export default Head;
