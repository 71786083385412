import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers';
import { addDays } from 'date-fns/addDays';
import HelpIcon from '@mui/icons-material/Help';

import Translate from '../display/translate';
import Phase from '../display/phase';
import Tooltip from '~/components/display/tooltip';

import { computePhaseEndDate, computePhaseStartDate } from '~/utils/program';
import { toISOFormat } from '~/utils/date';
import { StarfleetBlue, KlingonBlack } from '~/utils/colors';

function PhasesFields({
  refDate,
  phases,
  onChange,
  startDates = [],
  userProgramPhases = [],
}) {
  const [errors, setErrors] = useState(phases.map(() => false));

  const handleChange = (startDate, index) => {
    const newStartDates = [];
    startDates.forEach((elt, i) => {
      if (i < index) {
        newStartDates.push(elt);
      } else if (i === index) {
        newStartDates.push(startDate);
      } else {
        newStartDates.push(computePhaseStartDate(phases[i], newStartDates, refDate));
      }
    });

    const newErrors = [];
    newStartDates.forEach((elt, i) => {
      if (i === 0) {
        newErrors.push(false);
      } else {
        const previousEndDate = computePhaseEndDate(phases[i - 1], newStartDates[i - 1]);
        newErrors.push(new Date(elt) <= new Date(previousEndDate));
      }
    });

    setErrors(newErrors);

    onChange({ name: 'startDates', error: newErrors.some(i => i), value: newStartDates });
  };

  const isDatePickerDisabled = (index) => {
    // we don't want to disable the field when the user is setting up the
    // program, ie when there is no program yet setup
    if (userProgramPhases.length === 0) {
      return false;
    }

    // the program has probably be changed, we don't want to restrict the date
    // edition
    if (index >= userProgramPhases.length) {
      return false;
    }

    // if the date, in the program of the user, is different than the new date,
    // due for example to a reference date change, we don't want to block the
    // field
    if (userProgramPhases[index].startDate !== startDates[index]) {
      return false;
    }

    // we want to prevent update date change on past date, on the current
    // program
    const { startDate } = userProgramPhases[index];
    const today = new Date();
    return (new Date(startDate) < today) && (startDate !== toISOFormat(today));
  };

  return (
    <Grid container xs direction="column" spacing={2}>
      <Grid container display="flex" justifyContent="flex-start" alignItems="center">
        <Grid xs={4}>
          <Typography sx={{ fontWeight: 500 }}><Translate>phase</Translate></Typography>
        </Grid>
        <Grid xs={4}>
          <Typography sx={{ fontWeight: 500 }}><Translate>startDate</Translate></Typography>
        </Grid>
        <Grid xs={4}>
          <Typography sx={{ fontWeight: 500 }}><Translate>scheduledEndDate</Translate></Typography>
        </Grid>
      </Grid>
      { (startDates.length > 0) && phases.map((p, index) => (
        <Grid key={p.name} container>
          <Grid xs={4}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box>
                <Phase value={p.name} />
              </Box>
              <Tooltip
                placement="right"
                arrow
                title={(
                  <>
                    <Typography sx={{ color: KlingonBlack[500] }}>
                      <Translate input={{ PHASE_NAME: p.name }}>phaseMinimumContentX</Translate>
                    </Typography>
                    <ul>
                      {p.actions.map(action => (
                        <li key={`${p.name}-${action.connectionType}-${action.recordType}`}>
                          <Typography sx={{ fontWeight: 700 }}>
                            {`${action.quantity * p.nbPeriodsToValidate} × ${action.recordType}`}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              >
                <HelpIcon sx={{ color: StarfleetBlue[200] }} />
              </Tooltip>
            </Stack>
          </Grid>
          <Grid xs={4}>
            <DatePicker
              value={new Date(startDates[index])}
              onChange={value => handleChange(toISOFormat(value), index)}
              disabled={isDatePickerDisabled(index)}
              minDate={index > 0
                ? addDays(new Date(computePhaseEndDate(p, startDates[index - 1])), 1)
                : null}
              slotProps={{
                textField: {
                  error: errors[index],
                  helperText: errors[index]
                    && (<Translate>dateConflictWithAnotherPhase</Translate>),
                },
              }}
            />
          </Grid>
          <Grid xs={4}>
            <DatePicker
              value={new Date(computePhaseEndDate(p, startDates[index]))}
              disabled
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

PhasesFields.propTypes = {
  refDate: PropTypes.string.isRequired,
  phases: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    startDate: PropTypes.string,
  })).isRequired,
  startDates: PropTypes.arrayOf(PropTypes.string),
  userProgramPhases: PropTypes.arrayOf(PropTypes.shape({
    startDate: PropTypes.string,
  })),
  onChange: PropTypes.func.isRequired,
};

export default PhasesFields;
