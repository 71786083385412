import SvgIcon from '@mui/material/SvgIcon';

function Ankle(props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
        <path d="M7.06072 21.0823C7.01563 21.0823 6.97546 21.0823 6.93601 21.0823C6.54777 21.0823 6.15954 21.0929 5.77131 21.0794C5.42253 21.0667 5.10898 21.1615 4.82785 21.3552C4.53262 21.5595 4.25008 21.7836 3.9626 21.9986C3.79209 22.1258 3.62298 22.2552 3.45106 22.3811C3.2418 22.5331 3.01632 22.6031 2.74858 22.5798C2.23422 22.5352 1.74241 22.4285 1.30133 22.1471C0.78768 21.819 0.459337 21.346 0.254298 20.7797C0.0577154 20.236 -0.0134487 19.6718 0.00205252 19.0955C0.0112123 18.7625 0.161292 18.5158 0.467792 18.3772C0.705947 18.2697 0.95467 18.1828 1.18719 18.0647C1.34643 17.9841 1.48664 17.8639 1.63602 17.7642C1.66209 17.7465 1.69732 17.7274 1.7262 17.7303C2.16799 17.767 2.61118 17.808 3.0276 17.9699C3.30028 18.076 3.55605 18.2245 3.82379 18.3433C4.131 18.4797 4.44032 18.6112 4.75316 18.7342C5.10616 18.8735 5.45424 18.8431 5.80019 18.6897C5.97282 18.6133 6.15461 18.5561 6.33639 18.5023C6.43433 18.4733 6.54002 18.4719 6.64219 18.4557C6.70419 18.4458 6.7514 18.4691 6.77959 18.5221C6.83595 18.6289 6.89937 18.7335 6.93883 18.8467C7.07481 19.2341 7.13188 19.6378 7.13048 20.0458C7.12907 20.3618 7.09736 20.6786 7.07834 20.9946C7.07693 21.0229 7.06777 21.0505 7.06072 21.083V21.0823Z" />
        <path d="M2.99039 0.0192619H4.11352V0.1741C4.11352 3.18886 4.11775 6.20433 4.11071 9.21909C4.10859 10.018 4.22626 10.7951 4.46794 11.5523C4.661 12.1561 4.88013 12.7514 5.08798 13.351C5.18945 13.6444 5.27823 13.9399 5.21763 14.2559C5.20988 14.2948 5.1824 14.3408 5.14999 14.3627C4.87308 14.5508 4.56165 14.5974 4.23542 14.5692C3.8796 14.5381 3.54562 14.4235 3.20952 14.3104C2.94882 14.2227 2.68248 14.1499 2.42037 14.0643C2.39219 14.0552 2.36471 14.012 2.35485 13.9788C2.21675 13.5122 2.19983 13.0392 2.32384 12.569C2.44785 12.0981 2.59089 11.6322 2.72476 11.1641C2.8995 10.5533 2.99251 9.93319 2.9918 9.29545C2.98687 6.24322 2.98969 3.19028 2.98969 0.137335C2.98969 0.10057 2.98969 0.0638044 2.98969 0.0185547L2.99039 0.0192619Z" />
        <path d="M4.86053 0H5.98718C5.98718 0.0318162 5.98718 0.0622187 5.98718 0.0926208C5.98718 2.12674 5.99282 4.16085 5.98436 6.19426C5.98084 6.97765 6.07596 7.74689 6.22111 8.51401C6.36062 9.25074 6.50788 9.98604 6.61568 10.7277C6.74885 11.6405 6.78126 12.5603 6.67698 13.4802C6.6488 13.7276 6.58539 13.9708 6.53677 14.224C6.34512 14.1752 6.17883 14.1342 6.01325 14.0896C5.99845 14.0854 5.97943 14.0564 5.98014 14.0401C5.99564 13.6887 5.8829 13.3635 5.77228 13.039C5.56935 12.4444 5.35163 11.8547 5.16139 11.2559C4.95353 10.604 4.857 9.93372 4.85841 9.24579C4.86476 6.19921 4.86123 3.15263 4.86123 0.106054C4.86123 0.0742378 4.86123 0.0431289 4.86123 0.00141436L4.86053 0Z" />
        <path d="M2.55703 15.9817C2.66554 16.085 2.76419 16.1861 2.86988 16.278C3.26234 16.6188 3.70341 16.8535 4.22411 16.9348C4.70676 17.0098 5.05836 16.8252 5.31976 16.4321C5.57412 16.0496 5.71293 15.6197 5.81721 15.1771C5.84328 15.0654 5.86653 14.953 5.89472 14.8208C6.1061 14.8858 6.31114 14.9474 6.51476 15.0145C6.5352 15.0209 6.5507 15.0569 6.55986 15.0817C6.65921 15.3645 6.82408 15.6014 7.06576 15.7767C7.40044 16.0199 7.39763 16.1642 7.28207 16.5014C7.13833 16.92 6.93964 17.3074 6.65427 17.6468C6.62961 17.6765 6.58734 17.7062 6.5507 17.709C6.22306 17.7359 5.90599 17.8144 5.61429 17.9607C5.33738 18.0993 5.07668 18.0936 4.8047 17.9664C4.45099 17.8002 4.08883 17.651 3.74217 17.4707C3.31166 17.2473 2.85719 17.1186 2.38159 17.0508C2.22869 17.0288 2.07509 17.0119 1.91162 16.9907C2.02013 16.5827 2.25969 16.2695 2.55703 15.981V15.9817Z" />
        <path d="M14.5055 21.5537C14.2941 21.6909 14.0835 21.8294 13.87 21.9645C13.8502 21.9772 13.8143 21.9744 13.7889 21.9666C13.4289 21.8641 13.0604 21.8316 12.6884 21.8309C11.7773 21.8287 10.8663 21.8287 9.95521 21.8316C9.84107 21.8316 9.73397 21.8167 9.62969 21.7686C9.53034 21.7234 9.42747 21.6873 9.32178 21.6456C9.33023 21.6223 9.33516 21.6032 9.34362 21.5862C9.51977 21.2235 9.69733 20.8608 9.87136 20.4974C9.89462 20.4493 9.91857 20.4359 9.97001 20.4451C11.0579 20.6452 12.1472 20.8389 13.2337 21.0468C13.4345 21.0849 13.6255 21.1769 13.8178 21.2504C14.0454 21.3374 14.2702 21.4307 14.4963 21.5219C14.4992 21.5325 14.502 21.5431 14.5048 21.5537H14.5055Z" />
        <path d="M15.7056 19.6502C15.4076 19.5385 15.1251 19.4338 14.8425 19.3278C14.4712 19.1885 14.1048 19.0337 13.7271 18.9135C13.3995 18.8095 13.0606 18.7403 12.7245 18.6632C12.1023 18.5204 11.4795 18.3825 10.8573 18.2425C10.8355 18.2376 10.8136 18.2305 10.7861 18.2227C10.7897 18.2001 10.7911 18.181 10.7967 18.1626C10.8714 17.9017 10.9454 17.6415 11.0208 17.3813C11.0482 17.2866 11.1004 17.259 11.1885 17.2887C11.817 17.503 12.4462 17.7151 13.074 17.9314C13.7074 18.1499 14.3352 18.3839 14.9306 18.6957C15.1983 18.8364 15.4576 18.9912 15.6775 19.2034C15.7183 19.2429 15.7352 19.2832 15.7289 19.3405C15.7176 19.4374 15.7141 19.5349 15.7056 19.6488V19.6502Z" />
        <path d="M10.5316 18.9341C10.9875 19.0359 11.4328 19.1356 11.8788 19.236C12.3389 19.3392 12.8019 19.4297 13.257 19.5499C13.5473 19.627 13.8278 19.745 14.1103 19.8497C14.5394 20.0081 14.9664 20.17 15.3941 20.3312C15.4209 20.3411 15.4462 20.3531 15.4962 20.3736C15.3673 20.5645 15.2454 20.7483 15.1179 20.9286C15.1073 20.9435 15.0559 20.9413 15.0298 20.9314C14.7008 20.802 14.3668 20.6826 14.0462 20.5341C13.6129 20.334 13.1499 20.2697 12.6898 20.1848C11.8929 20.0385 11.0953 19.8935 10.2977 19.7486C10.2723 19.7443 10.2477 19.7373 10.2139 19.7288C10.3196 19.4629 10.4238 19.2028 10.5309 18.9341H10.5316Z" />
        <path d="M9.54911 19.4285C9.23626 20.0867 8.92906 20.733 8.62115 21.382C8.34072 21.3014 8.07931 21.2265 7.80945 21.1487C7.91303 20.4127 7.90669 19.6795 7.74463 18.9435C7.91796 18.9095 8.08847 18.9095 8.25194 18.947C8.44641 18.9916 8.63947 19.0502 8.82689 19.1209C9.06293 19.21 9.29193 19.3182 9.54911 19.4285Z" />
        <path d="M22.4292 21.7773C22.4503 21.9152 22.4081 22.0298 22.3165 22.1075C22.1882 22.2164 22.0522 22.3225 21.9036 22.3981C21.5182 22.5933 21.0982 22.6859 20.6755 22.7594C19.7637 22.9185 18.8428 22.9588 17.9191 22.9567C17.9085 22.9567 17.8979 22.9538 17.8726 22.9503C17.9 22.9248 17.9191 22.9058 17.9395 22.8881C18.0938 22.7502 18.2467 22.6102 18.4038 22.4759C18.4398 22.4455 18.4905 22.4236 18.5377 22.4158C18.9513 22.3479 19.3663 22.2871 19.7799 22.2185C20.6416 22.0757 21.5026 21.9286 22.3637 21.7837C22.3827 21.7802 22.4031 21.7802 22.4285 21.7781L22.4292 21.7773Z" />
        <path d="M8.08237 16.4177C8.34025 16.4827 8.58686 16.5457 8.84404 16.6107C8.79119 16.7839 8.74328 16.955 8.68621 17.1233C8.57277 17.4584 8.45369 17.7922 8.33743 18.1266C8.32123 18.1732 8.30009 18.1902 8.24513 18.1909C8.01332 18.193 7.7815 18.2001 7.5504 18.2128C7.49473 18.2157 7.46021 18.2128 7.43202 18.1591C7.40384 18.1061 7.36368 18.0601 7.32422 18.0043C7.68356 17.5284 7.93017 16.9968 8.08166 16.417L8.08237 16.4177Z" />
        <path d="M9.8036 18.7253C9.54572 18.6143 9.29347 18.5055 9.03418 18.3938C9.0835 18.2601 9.13282 18.1343 9.1758 18.007C9.27727 17.7037 9.3745 17.399 9.47596 17.0957C9.53444 16.9196 9.69368 16.8143 9.86631 16.8326C10.1016 16.8574 10.2538 16.9861 10.275 17.184C10.2799 17.2293 10.2792 17.2781 10.2658 17.3205C10.12 17.7793 9.9713 18.2368 9.82263 18.6942C9.81981 18.702 9.81417 18.7084 9.8036 18.7253Z" />
        <path d="M5.03858 15.2208C4.9491 15.5284 4.84834 15.8055 4.67994 16.0544C4.59891 16.1746 4.49533 16.2198 4.34878 16.1986C4.07257 16.1583 3.82174 16.0586 3.59486 15.8996C3.26017 15.6655 2.98115 15.3792 2.79091 15.0129C2.77682 14.9861 2.76343 14.9592 2.73877 14.9111C3.49199 15.1862 4.22688 15.4357 5.03858 15.2201V15.2208Z" />
        <path d="M18.507 21.0596C18.4471 21.1692 18.3921 21.2717 18.3351 21.3735C18.3139 21.411 18.2893 21.4471 18.2639 21.4817C18.0997 21.7065 18.1716 21.6889 17.8975 21.633C17.231 21.4965 16.5679 21.3452 15.9035 21.1996C15.8929 21.1975 15.8831 21.1911 15.8711 21.1862C15.9564 21.037 16.0381 20.8892 16.1255 20.7457C16.136 20.7287 16.1804 20.7231 16.2072 20.7266C16.6166 20.7839 17.0259 20.8432 17.4346 20.9019C17.7672 20.95 18.0997 20.9974 18.4316 21.0455C18.4513 21.0483 18.4704 21.0525 18.5056 21.0596H18.507Z" />
        <path d="M17.4708 22.3306C17.2355 22.4776 17.0079 22.6212 16.7775 22.7619C16.7592 22.7732 16.7268 22.771 16.7035 22.7647C16.0708 22.6063 15.438 22.4472 14.806 22.2881C14.7983 22.286 14.7919 22.279 14.7842 22.274C14.9512 22.1319 15.116 21.9898 15.2844 21.8512C15.2999 21.8385 15.3345 21.8392 15.357 21.8441C16.0532 21.9961 16.7493 22.1489 17.4454 22.3023C17.4476 22.3023 17.449 22.3058 17.4708 22.3306Z" />
        <path d="M16.4067 19.9998C16.4265 19.8463 16.4448 19.7021 16.4645 19.5444C16.5533 19.5557 16.6371 19.5656 16.721 19.5784C17.3333 19.6682 17.9259 19.823 18.4747 20.12C18.5431 20.1567 18.61 20.1963 18.6741 20.2401C18.6953 20.2543 18.7037 20.2861 18.7185 20.3094C18.7122 20.3158 18.7051 20.3222 18.6988 20.3278C17.9399 20.2189 17.1804 20.1101 16.4067 19.9998Z" />
        <path d="M19.4511 20.6064C19.9225 20.8214 20.403 21.0399 20.8829 21.2583C20.8829 21.264 20.8814 21.2696 20.8814 21.276C20.2882 21.3757 19.6956 21.4761 19.1094 21.5744C19.2214 21.2562 19.3327 20.9416 19.4511 20.6072V20.6064Z" />
      </svg>
    </SvgIcon>
  );
}

export default Ankle;
