import PropTypes from 'prop-types';

import AsymmetryBoxGraph from '../../components/graph/asymmetry-box';

import DoubleSupportPercentageMetric from '../../utils/metrics/double-support-percentage';

function DoubleSupportPercentage({ meanLeft = null, meanRight = null, ...props }) {
  if (meanLeft === null && meanRight === null) {
    return null;
  }

  return (
    <AsymmetryBoxGraph
      meanLeft={meanLeft}
      meanRight={meanRight}
      metric={DoubleSupportPercentageMetric}
      {...props}
    />
  );
}

DoubleSupportPercentage.propTypes = {
  meanLeft: PropTypes.number,
  meanRight: PropTypes.number,
};

export default DoubleSupportPercentage;
