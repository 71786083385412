import PropTypes from 'prop-types';

import Button from '~/components/display/button';
import Translate from '~/components/display/translate';
import SuccessDialog from '~/components/dialog/success';

import { useAppContext } from '~/components/AppContext';

function Dialog({ open, onClose, onAddProgram }) {
  const { hasPrograms } = useAppContext();

  return (
    <SuccessDialog
      open={open}
      title={<Translate>patientCreated</Translate>}
      description={<Translate>{hasPrograms ? 'patientCreatedDescriptionProgram' : 'patientCreatedDescription'}</Translate>}
      onClick={onClose}
      button={hasPrograms ? (
        <>
          <Button fullWidth variant="contained" onClick={() => onClose()}>
            <Translate>skipThisStep</Translate>
          </Button>
          <Button fullWidth color="primary" variant="contained" onClick={() => onAddProgram()}>
            <Translate>addAProgram</Translate>
          </Button>
        </>
      ) : null}
    />
  );
}

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddProgram: PropTypes.func.isRequired,
};

export default Dialog;
