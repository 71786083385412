import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Link } from 'react-router-dom';
import { differenceInDays, endOfDay } from 'date-fns';

import Button from '~/components/display/button';
import Translate from '~/components/display/translate';
import LinearProgress from '~/components/display/linear-progress';
import Tooltip from '~/components/display/tooltip';
import DateDisplay from '~/components/display/date';
import CriticalityLevel from '~/components/CriticalityLevel';
import DownloadIcon from '~/components/icons/download';

import { computeCriticalityLevelPhase } from '~/utils/metrics/criticality-level';
import { getNbActionsTodo } from '~/utils/program';

function PhaseReportProgress({
  programId,
  phase,
  phaseIndex,
  showButtons,
}) {
  const level = computeCriticalityLevelPhase(phase);
  const {
    thresholds,
    duration,
  } = phase;
  const startDate = new Date(phase.startDate);
  const endDate = endOfDay(new Date(phase.endDate));
  const today = new Date();

  const recordsPlanned = getNbActionsTodo(phase);
  const recordsDone = Math.min(phase.nbActionsDone, recordsPlanned);

  const [progressPercentage, progressDays] = useMemo(() => {
    if (today > endDate) {
      return [100, duration];
    }

    if (today < startDate) {
      return [0, 0];
    }

    const progress = differenceInDays(today, startDate);
    return [(progress / duration) * 100 || 0, progress];
  }, []);

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={6}
      sx={{ width: 1 }}
    >
      <Grid xs={1}>
        <Typography>{phase.name}</Typography>
      </Grid>
      <Grid>
        <CriticalityLevel level={level} thresholds={thresholds} />
      </Grid>
      <Grid>
        <Typography>
          {`${startDate > today ? '-' : recordsDone}/${recordsPlanned} `}
          <Translate input={{ NB: recordsPlanned }}>Xtests</Translate>
        </Typography>
      </Grid>
      <Grid xs>
        <Tooltip
          placement="top"
          arrow
          title={(
            <>
              <Typography>
                <Translate>startDate</Translate>
                {': '}
                <DateDisplay>{startDate}</DateDisplay>
              </Typography>
              <Typography>
                <Translate>endDate</Translate>
                {': '}
                <DateDisplay>{endDate}</DateDisplay>
              </Typography>
              <Typography>
                <Translate>days</Translate>
                {`: ${progressDays}/${duration}`}
              </Typography>
            </>
          )}
        >
          <LinearProgress
            variant="determinate"
            value={progressPercentage}
            sx={{ height: '1.19rem' }}
          />
        </Tooltip>
      </Grid>
      { showButtons && (
        <>
          <Grid>
            <Button
              disabled={recordsDone === 0 || today < startDate}
              startIcon={<VisibilityOutlinedIcon />}
              color="primary"
              component={Link}
              to={`programs/${programId}/phases/${phaseIndex}`}
            >
              <Translate>viewReport</Translate>
            </Button>
          </Grid>
          <Grid>
            <Button
              disabled={recordsDone === 0 || today < endDate}
              startIcon={<DownloadIcon />}
              color="primary"
            >
              <Translate>download</Translate>
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
}

PhaseReportProgress.propTypes = {
  programId: PropTypes.string.isRequired,
  phase: PropTypes.shape({
    name: PropTypes.string,
    thresholds: PropTypes.arrayOf(PropTypes.number),
    nbActionsDone: PropTypes.number,
    duration: PropTypes.number,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  phaseIndex: PropTypes.number.isRequired,
  showButtons: PropTypes.bool.isRequired,
};

export default PhaseReportProgress;
