import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { translate } from '../../utils/i18n';
import { useAppContext } from '~/components/AppContext';

function Translate({
  children,
  input = undefined,
  parseHtml = false,
  canFail = false,
}) {
  const { file } = useAppContext();

  const value = useMemo(() => translate(children, file, input, canFail), [children, file, input]);

  if (parseHtml) {
    // eslint-disable-next-line react/no-danger
    return (<span dangerouslySetInnerHTML={{ __html: value }} />);
  }

  return value;
}

Translate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  input: PropTypes.object,
  children: PropTypes.string.isRequired,
  parseHtml: PropTypes.bool,
  canFail: PropTypes.bool,
};

export default Translate;
