import { useMemo } from 'react';
import PropTypes from 'prop-types';

import Table from '../index';
import Head from './head';
import Row from './row';

import { translate } from '~/utils/i18n';
import { getRecordName } from '~/utils/record';
import { useAppContext } from '~/components/AppContext';

function Records({
  records,
  hasCheckbox = false,
  onClick,
  showOrganisation = false,
  ...rest
}) {
  const { file } = useAppContext();

  // we add specific fields to the records objects to ease the sort on the table
  const modifiedRecords = useMemo(() => records.map(record => ({
    nameTranslated: translate(getRecordName(record), file),
    parametersTranslated: record.parameters ? record.parameters.map(i => translate(i.value, file)).join(', ') : '-',
    duration: record.stopTime - record.startTime,
    recordedByTranslated: record.recordedBy === 'patient' ? translate('patient', file) : record.recordedBy,
    ...record,
  })), [records.length]);

  return (
    <Table
      {...rest}
      defaultOrderBy="startTime"
      defaultOrder="desc"
      Head={Head}
      Row={Row}
      HeadProps={{ showOrganisation, hasCheckbox }}
      RowProps={{ onClick, showOrganisation, hasCheckbox }}
      data={modifiedRecords}
      hasPagination
    />
  );
}

Records.propTypes = {
  records: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired,
  hasCheckbox: PropTypes.bool,
  showOrganisation: PropTypes.bool,
};

export default Records;
