import PropTypes from 'prop-types';
import MuiButton from '@mui/material/Button';

function Button({
  sx = [],
  color = undefined,
  variant = 'contained',
  ...props
}) {
  return (
    <MuiButton
      disableElevation
      sx={[
        { borderRadius: '6px', textTransform: 'none' },
        ((color === undefined || color === 'default') && variant === 'contained') && {
          bgcolor: '#e0e0e0',
          color: '#1d1d1d',
          '&:hover': {
            bgcolor: '#d5d5d5',
          },
        },
        ((color === undefined || color === 'default') && variant === 'outlined') && {
          bgcolor: 'inherit',
          borderColor: '#c4c4c4',
          color: '#1d1d1d',
          '&:hover': {
            bgcolor: '#f5f5f5',
            borderColor: '#c4c4c4',
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      color={color}
      variant={variant}
      {...props}
    />
  );
}

Button.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default Button;
