import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';

function SnackbarProviderWrapper({ children }) {
  return (
    <SnackbarProvider maxSnack={1}>
      { children }
    </SnackbarProvider>
  );
}

SnackbarProviderWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

export default SnackbarProviderWrapper;
