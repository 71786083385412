import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';

import FormHelperText from './form-helper-text';
import InputLabel from './input-label';
import InputBase from './input-base';

import useUniqueId from '../../utils/hooks/useUniqueId';
import { translate } from '../../utils/i18n';
import { useAppContext } from '~/components/AppContext';

function TextField({
  label = undefined,
  disabled = false,
  required = false,
  error = false,
  helperText = undefined,
  placeholder = undefined,
  disablePlaceholderTranslate = false,
  InputLabelProps = {},
  InputProps = {},
  ...others
}) {
  const { file } = useAppContext();

  const inputBaseId = useUniqueId('input-base-id');

  // <InputBase /> does not accept an object for the `placeholder` props.
  // Instead of having all the parent manually use the translate method, we're
  // performing it.
  let translatedPlaceholder = placeholder;
  if (placeholder !== undefined && !disablePlaceholderTranslate) {
    translatedPlaceholder = translate(placeholder, file);
  }

  return (
    <FormControl disabled={disabled} required={required} error={error} fullWidth>
      { label && (
        <InputLabel shrink htmlFor={inputBaseId} {...InputLabelProps}>{label}</InputLabel>
      )}
      <InputBase {...others} placeholder={translatedPlaceholder} id={inputBaseId} {...InputProps} />
      { helperText && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

TextField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  placeholder: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.bool]),
  disablePlaceholderTranslate: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
};

export default TextField;
