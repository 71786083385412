import Velocity from '~/utils/metrics/velocity';
import Cadence from '~/utils/metrics/cadence';
import StrideLength from '~/utils/metrics/stride-length';
import StrideDuration from '~/utils/metrics/stride-duration';
import Load from '~/utils/metrics/load';

const DEFAULT_SETTINGS = {
  unitMetrics: {
    [Velocity.key]: Velocity.defaultUnit,
    [StrideLength.key]: StrideLength.defaultUnit,
    [StrideDuration.key]: StrideDuration.defaultUnit,
    [Cadence.key]: Cadence.defaultUnit,
    [Load.key]: Load.defaultUnit,
  },
};

function mergeDefaultSettings(userSettings) {
  return ({
    ...DEFAULT_SETTINGS,
    ...userSettings,
    unitMetrics: {
      ...DEFAULT_SETTINGS.unitMetrics,
      ...(userSettings || {}).unitMetrics,
    },
  });
}

export {
  // eslint-disable-next-line import/prefer-default-export
  mergeDefaultSettings,
};
