import PropTypes from 'prop-types';

import SvgIcon from '@mui/material/SvgIcon';
import { PhaserRed } from '../../utils/colors';

function CloudFail({ color = PhaserRed[700], ...props }) {
  return (
    <SvgIcon {...props}>
      <path d="m 1.9977695,14.491644 c 0,0.588368 0.116386,1.170979 0.3425191,1.714559 0.2261199,0.543633 0.5575521,1.037469 0.9753822,1.453563 0.8438352,0.840201 1.9883264,1.312264 3.1816935,1.312264 H 18.496195 c 0.885886,0.0016 1.739445,-0.331406 2.388498,-0.931836 0.648919,-0.60043 1.045109,-1.423534 1.108559,-2.303395 C 22.056702,14.856978 21.7826,13.98592 21.226384,13.299372 20.670167,12.612811 19.873245,12.16184 18.996175,12.037413 19.005927,10.343598 18.395879,8.7042294 17.280107,7.4257334 16.164201,6.1472373 14.618978,5.3171339 12.933084,5.0905866 11.24727,4.8640527 9.5362769,5.2565833 8.1199825,6.1948042 6.7036746,7.1330385 5.678989,8.5527393 5.2374756,10.188515 c -0.934788,0.27183 -1.7558482,0.838158 -2.3399822,1.614029 -0.584134,0.775871 -0.8998308,1.719435 -0.8997239,2.6891 z" stroke={color} strokeWidth="2" strokeLinejoin="round" fill="transparent" />
      <path d="m 11.996519,15.487169 h 0.01 v 0.0099 h -0.01 z" stroke={color} strokeWidth="2" strokeLinejoin="round" />
      <path d="M 11.996519,12.001307 V 9.0144095" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}

CloudFail.propTypes = {
  color: PropTypes.string,
};

export default CloudFail;
