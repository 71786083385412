import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

import Button from './button';

/**
 * Button with circular progress element
 */
function ButtonProgress({
  inProgress,
  disabled = false,
  children,
  ...other
}) {
  return (
    <Button
      {...other}
      disabled={inProgress || disabled}
    >
      { inProgress && <CircularProgress sx={{ mr: 1, color: 'inherit' }} size={16} /> }
      { children }
    </Button>
  );
}

ButtonProgress.propTypes = {
  /**
   * Is the action in progress, show a progress circular component and
   * disable the button
   */
  inProgress: PropTypes.bool.isRequired,

  /**
   * Is the button disabled
   */
  disabled: PropTypes.bool,

  /**
   * Children of the button
   */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default ButtonProgress;
