import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Translate from '../components/display/translate';

import {
  correctLength,
  containsLowerCaseLetter,
  containsUpperCaseLetter,
  containsNumber,
  containsSymbol,
  isPassword,
} from '../utils/validate/password';
import { PhaserRed, RomulanGreen } from '../utils/colors';

function PasswordHelperText({ password = '' }) {
  const validPassword = isPassword(password);
  const validLength = correctLength(password);
  const validUpperCase = containsUpperCaseLetter(password);
  const validLowerCase = containsLowerCaseLetter(password);
  const validNumber = containsNumber(password);
  const validSymbol = containsSymbol(password);

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ mt: '3px' }}>
      { validPassword && (
        <Grid item>
          <Typography sx={{ color: RomulanGreen[800], fontWeight: 400, fontSize: '0.75rem' }}>
            <Translate>goodPasswordStrength</Translate>
          </Typography>
        </Grid>
      )}
      { !validUpperCase && (
        <Grid item>
          <Typography sx={{ color: PhaserRed[800], fontWeight: 400, fontSize: '0.75rem' }}>
            <Translate>containsUppercase</Translate>
          </Typography>
        </Grid>
      )}
      { !validLowerCase && (
        <Grid item>
          <Typography sx={{ color: PhaserRed[800], fontWeight: 400, fontSize: '0.75rem' }}>
            <Translate>containsLowerCase</Translate>
          </Typography>
        </Grid>
      )}
      { !validNumber && (
        <Grid item>
          <Typography sx={{ color: PhaserRed[800], fontWeight: 400, fontSize: '0.75rem' }}>
            <Translate>containsNumber</Translate>
          </Typography>
        </Grid>
      )}
      { !validSymbol && (
        <Grid item>
          <Typography sx={{ color: PhaserRed[800], fontWeight: 400, fontSize: '0.75rem' }}>
            <Translate>containsSymbol</Translate>
          </Typography>
        </Grid>
      )}
      { !validLength && (
        <Grid item>
          <Typography sx={{ color: PhaserRed[800], fontWeight: 400, fontSize: '0.75rem' }}>
            <Translate>8charMinimum</Translate>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

PasswordHelperText.propTypes = {
  password: PropTypes.string,
};

export default PasswordHelperText;
