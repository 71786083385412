import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import HelpIcon from '@mui/icons-material/Help';

import Translate from '~/components/display/translate';
import CardHeader from '~/components/CardHeader';
import Tooltip from '~/components/display/tooltip';
import AverageDailyStepsGraph from './AverageDailyStepsGraph';
import DailyDistributionGraph from './DailyDistributionGraph';
import WeekendLegend from './WeekendLegend';

import { KlingonBlack, StarfleetBlue } from '~/utils/colors';

function FreeWalkingParametersCard({
  phases,
  means,
  stds,
  startDate,
  steps,
  weekends,
}) {
  return (
    <Card sx={{ width: 1 }}>
      <CardHeader
        title={(
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Translate>freeWalkingParameters</Translate>
            <Tooltip
              placement="right"
              title={(
                <>
                  <Typography sx={{ fontSize: '0.8125rem', fontWeight: 600, color: KlingonBlack[500] }}>
                    <Translate>podometerWHO</Translate>
                  </Typography>
                  <ul>
                    {['highlyActivePodometer', 'activePodometer', 'somewhatActivePodometer', 'lowActivePodometer', 'sedentaryPodometer'].map(level => (
                      <li key={level}>
                        <Typography sx={{ fontSize: '0.8125rem', fontWeight: 500, color: 'white' }}>
                          <Translate>{level}</Translate>
                        </Typography>
                      </li>
                    ))}
                  </ul>
                  <Typography sx={{ fontSize: '0.5rem', fontWeight: 600, color: 'white' }}>
                    <Translate>podometerWHOSource</Translate>
                  </Typography>
                </>
              )}
            >
              <HelpIcon sx={{ color: StarfleetBlue[200] }} />
            </Tooltip>
          </Box>
        )}
        action={<Box sx={{ mt: 2, mr: 2 }}><WeekendLegend /></Box>}
      />
      <CardContent>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
          <Grid xs={2}>
            <Typography
              sx={{
                color: KlingonBlack[600],
                fontSize: '1.19rem',
                fontWeight: 500,
              }}
            >
              <Translate>averageDailySteps</Translate>
            </Typography>
            <AverageDailyStepsGraph
              phases={phases}
              means={means}
              stds={stds}
            />
          </Grid>
          <Grid xs>
            <Typography
              sx={{
                color: KlingonBlack[600],
                fontSize: '1.19rem',
                fontWeight: 500,
              }}
            >
              <Translate>dailyDistribution</Translate>
            </Typography>
            <DailyDistributionGraph
              startDate={startDate}
              steps={steps}
              weekends={weekends}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

FreeWalkingParametersCard.propTypes = {
  phases: PropTypes.arrayOf(PropTypes.string).isRequired,
  means: PropTypes.arrayOf(PropTypes.number).isRequired,
  stds: PropTypes.arrayOf(PropTypes.number).isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  steps: PropTypes.arrayOf(PropTypes.number).isRequired,
  weekends: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default FreeWalkingParametersCard;
