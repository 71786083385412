import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import Label from '../../display/label';
import Status from '../../display/status';
import MetricValue from '~/components/MetricValue';

import { ComplianceLevel } from '../../../utils/metrics';
import { useAppContext } from '~/components/AppContext';

function Row({ elt, onClick, showCompliance }) {
  const { isStudyProject } = useAppContext();

  return (
    <TableRow sx={{ cursor: 'pointer' }} hover onClick={() => onClick(elt.key)}>
      <TableCell>{ elt.name }</TableCell>
      { isStudyProject && (
        <TableCell sx={{ '& > *': { m: 0.25 } }}>
          { (elt.labels || []).map(i => <Label key={i.key} labelId={i.key} value={i.name} />) }
        </TableCell>
      )}
      { showCompliance && (
        <TableCell>
          { elt.complianceLevel === null
            ? 'N/A'
            : (
              <MetricValue metric={ComplianceLevel} showUnit>
                {elt.complianceLevel}
              </MetricValue>
            )}
        </TableCell>
      )}
      <TableCell>
        { elt.patients }
      </TableCell>
      <TableCell>{ <Status status={elt.status || 'active'} /> }</TableCell>
    </TableRow>
  );
}

Row.propTypes = {
  elt: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    labels: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
    complianceLevel: PropTypes.number,
    patients: PropTypes.number,
    status: PropTypes.oneOf(['active', 'inactive']),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  showCompliance: PropTypes.bool.isRequired,
};

export default Row;
