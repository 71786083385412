import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import { KlingonBlack } from '~/utils/colors';

function ColormapPressure({
  colorInterpolation,
  ticks,
  unit,
}) {
  const ref = useRef(null);
  const width = 300;
  const height = 20;

  useEffect(() => {
    const canvas = ref.current;
    const ctx = canvas.getContext('2d');
    for (let i = 0; i < canvas.width; i += 1) {
      const color = colorInterpolation(i / (canvas.width - 1));
      ctx.fillStyle = color;
      ctx.fillRect(i, 0, 1, height);
    }
  }, []);

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="center">
      <Grid sx={{ mb: 2 }}>
        <Typography sx={{ color: KlingonBlack[700], fontSize: '0.75rem' }}><em>{unit}</em></Typography>
      </Grid>
      <Grid sx={{ height: '10px', borderRadius: '10px', overflow: 'hidden' }}>
        <canvas height={height} width={width} ref={ref} />
      </Grid>
      <Grid container sx={{ width }} direction="row" justifyContent="space-between" alignItems="flex-start">
        { ticks.map(tick => (
          <Grid key={tick}>
            <Typography variant="caption">{tick}</Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

ColormapPressure.propTypes = {
  colorInterpolation: PropTypes.func.isRequired,
  ticks: PropTypes.arrayOf(PropTypes.number).isRequired,
  unit: PropTypes.string.isRequired,
};

export default ColormapPressure;
