import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2';

import WarningDialog from '../dialog/warning';
import Translate from '../display/translate';
import Button from '../display/button';

function UpdateComplianceDialog({ onCancel, onValidate, ...props }) {
  return (
    <WarningDialog
      {...props}
      title={<Translate>areYouSureYouWantToConfirm</Translate>}
      description={<Translate>youHaveMadeModificationsOnPatientCompliance</Translate>}
      button={(
        <Grid xs container spacing={2}>
          <Grid xs>
            <Button fullWidth onClick={onCancel}>
              <Translate>refuse</Translate>
            </Button>
          </Grid>
          <Grid xs>
            <Button fullWidth color="primary" onClick={onValidate}>
              <Translate>save</Translate>
            </Button>
          </Grid>
        </Grid>
      )}
    />
  );
}

UpdateComplianceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
};

export default UpdateComplianceDialog;
