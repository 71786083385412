import { useAppContext } from '~/components/AppContext';

function useUnit(metric, overrideUnit = null) {
  const { userSettings } = useAppContext();
  if (overrideUnit !== null) {
    return overrideUnit;
  }

  let unit = metric.defaultUnit;
  const storeUnit = userSettings.unitMetrics[metric.key];
  if (storeUnit !== undefined) {
    unit = storeUnit;
  }
  return unit;
}

export default useUnit;
