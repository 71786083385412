import usePressures from './usePressures';

function useStridePressureIndex(recordId, stridePressureIndexes, index) {
  const selected = stridePressureIndexes[index] || [];
  const [leftPressures, leftInProgress] = usePressures(recordId, selected[0]);
  const [rightPressures, rightInProgress] = usePressures(recordId, selected[1]);

  return [leftPressures, rightPressures, leftInProgress || rightInProgress];
}

export default useStridePressureIndex;
