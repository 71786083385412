import PropTypes from 'prop-types';

import AsymmetryBoxGraph from '../../components/graph/asymmetry-box';

import SingleSupportPercentageMetric from '../../utils/metrics/single-support-percentage';

function SingleSupportPercentage({ meanLeft = null, meanRight = null, ...props }) {
  if (meanLeft === null && meanRight === null) {
    return null;
  }

  return (
    <AsymmetryBoxGraph
      meanLeft={meanLeft}
      meanRight={meanRight}
      metric={SingleSupportPercentageMetric}
      {...props}
    />
  );
}

SingleSupportPercentage.propTypes = {
  meanLeft: PropTypes.number,
  meanRight: PropTypes.number,
};

export default SingleSupportPercentage;
