import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';

import GeneralMetrics from '~/record/general-metrics';
import WalkingMetricsCard from '~/components/WalkingMetricsCard';
import VelocityCurve from '~/record/velocity-curve';
import VelocityHistogram from '~/record/velocity-histogram';
import StrideLengthHistogram from '~/record/stride-length-histogram';
import GaitCycle from '~/record/gait-cycle';
import Asymmetry from '~/record/asymmetry';

function BaseMetric({ recordData, record, children = null }) {
  const { isRecordLongerThan2Hours } = recordData.generalMetrics;

  return (
    <Stack justifyContent="stretch" alignItems="flex-start" spacing={3}>
      <GeneralMetrics
        duration={recordData.generalMetrics.duration}
        distance={recordData.generalMetrics.distance}
        strideCounter={recordData.generalMetrics.strideCounter}
        activeTime={recordData.generalMetrics.activeTime}
        inactivity={recordData.generalMetrics.inactivity}
        isRecordLongerThan2Hours={isRecordLongerThan2Hours}
      />
      <WalkingMetricsCard
        velocity={recordData.walkingMetrics.velocity.mean}
        stdVelocity={recordData.walkingMetrics.velocity.std}
        maxVelocity={recordData.walkingMetrics.velocity.max}
        cadence={recordData.walkingMetrics.cadence.mean}
        stdCadence={recordData.walkingMetrics.cadence.std}
        strideLength={recordData.walkingMetrics.strideLength.mean}
        stdStrideLength={recordData.walkingMetrics.strideLength.std}
        maxStrideLength={recordData.walkingMetrics.strideLength.max}
      />
      { !isRecordLongerThan2Hours && (
        <VelocityCurve data={recordData.velocityCurve} timeZone={record.timeZone} />
      )}
      { isRecordLongerThan2Hours && (
        <>
          <VelocityHistogram
            data={recordData.velocityHistogram.velocityHistogram}
            timeZone={record.timeZone}
          />
          <StrideLengthHistogram
            data={recordData.velocityHistogram.strideLengthHistogram}
            timeZone={record.timeZone}
          />
        </>
      )}
      <GaitCycle data={recordData.gaitCycle} />
      <Asymmetry
        supportPhase={recordData.asymmetry.supportPhase}
        singleSupportPercentage={recordData.asymmetry.singleSupportPercentage}
        doubleSupportPercentage={recordData.asymmetry.doubleSupportPercentage}
      />
      { children }
    </Stack>
  );
}

BaseMetric.propTypes = {
  record: PropTypes.shape({
    timeZone: PropTypes.string,
  }).isRequired,
  recordData: PropTypes.shape({
    generalMetrics: PropTypes.shape({
      duration: PropTypes.number,
      distance: PropTypes.number,
      strideCounter: PropTypes.number,
      activeTime: PropTypes.number,
      inactivity: PropTypes.number,
      isRecordLongerThan2Hours: PropTypes.bool,
    }),
    walkingMetrics: PropTypes.shape({
      velocity: PropTypes.shape({
        mean: PropTypes.number,
        std: PropTypes.number,
        max: PropTypes.number,
      }),
      cadence: PropTypes.shape({
        mean: PropTypes.number,
        std: PropTypes.number,
      }),
      strideLength: PropTypes.shape({
        mean: PropTypes.number,
        std: PropTypes.number,
        max: PropTypes.number,
      }),
    }),
    velocityCurve: PropTypes.arrayOf(PropTypes.object),
    velocityHistogram: PropTypes.shape({
      velocityHistogram: PropTypes.arrayOf(PropTypes.object),
      strideLengthHistogram: PropTypes.arrayOf(PropTypes.object),
    }),
    gaitCycle: PropTypes.object,
    asymmetry: PropTypes.shape({
      supportPhase: PropTypes.object,
      singleSupportPercentage: PropTypes.object,
      doubleSupportPercentage: PropTypes.object,
    }),
  }).isRequired,
  children: PropTypes.node,
};

export default BaseMetric;
