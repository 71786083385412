import { useState } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Translate from '~/components/display/translate';
import ButtonProgress from '~/components/display/button-progress';
import DateDisplay from '~/components/display/date';
import Status from '~/components/display/status-invitation';

import { convertFirestoreTimestampToDate } from '@/firebase/firestore';
import { newInvitation } from '@/firebase/functions';
import { KlingonBlack, PhaserRed } from '~/utils/colors';
import { isLinkValid } from '~/utils/link';

function Invitation(props) {
  const {
    createdAt,
    counter,
    expiration,
    email,
    isValid,
    userId,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [emailAlreadyExists, setEmailAlreadyExists] = useState(false);

  const linkObj = {
    isValid,
    createdAt,
    counter,
    expiration,
  };

  const handleClick = async () => {
    setIsLoading(true);
    setEmailAlreadyExists(false);
    try {
      await newInvitation({ componentUserId: userId, email });
    } catch (err) {
      if (err.code === 'functions/already-exists') {
        setEmailAlreadyExists(true);
      } else {
        throw err;
      }
    }
    setIsLoading(false);
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={3}>
      <Stack sx={{ flexGrow: 1 }}>
        <Stack direction="row">
          <Typography sx={{ fontWeight: 500 }}>
            <Translate>invitationStatus</Translate>
          </Typography>
          { emailAlreadyExists && (
            <Typography sx={{ fontWeight: 500, color: PhaserRed[700] }}>
              <>
                &nbsp;-&nbsp;
                <Translate>emailAlreadyExists</Translate>
              </>
            </Typography>
          )}
        </Stack>
        <Typography sx={{ color: KlingonBlack[600] }}>
          <>
            <Translate>issued</Translate>
            { ' ' }
            <DateDisplay>{convertFirestoreTimestampToDate(createdAt)}</DateDisplay>
          </>
        </Typography>
      </Stack>
      <Status type={isLinkValid(linkObj) ? 'pending' : 'expired'} />
      <ButtonProgress inProgress={isLoading} color="primary" onClick={handleClick}>
        <Translate>inviteAgain</Translate>
      </ButtonProgress>
    </Stack>
  );
}

Invitation.propTypes = {
  createdAt: PropTypes.shape({
    seconds: PropTypes.number,
    nanoseconds: PropTypes.number,
  }).isRequired,
  userId: PropTypes.string.isRequired,
  counter: PropTypes.number.isRequired,
  expiration: PropTypes.number.isRequired,
  isValid: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
};

export default Invitation;
