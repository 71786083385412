import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { scaleBand, scaleLinear } from 'd3-scale';

import {
  Graph,
  Rects,
  useGraphDimensions,
} from '@feetme/d3act';

import Axis from '~/components/graph/axis';
import Translate from '~/components/display/translate';

import { StarfleetBlue, KlingonBlack } from '~/utils/colors';

function AverageDailyStepsGraph({
  phases,
  means,
  stds,
  height = 250,
  width = 0,
}) {
  const [ref, dimensions] = useGraphDimensions({
    height,
    width,
    marginRight: 0,
    marginLeft: 60,
  });

  const xAccessor = (_, i) => i;
  const yAccessor = d => d;

  const xScale = scaleBand()
    .domain([...Array(means.length).keys()])
    .range([0, dimensions.boundedWidth])
    .paddingInner([0.5])
    .paddingOuter([0.1]);

  const yScale = scaleLinear()
    .domain([0, Math.max(...means.map((d, i) => d + stds[i]))])
    .range([dimensions.boundedHeight, 0])
    .nice();

  const xAccessorScaled = (d, i) => xScale(xAccessor(d, i));
  const yAccessorScaled = d => yScale(yAccessor(d));

  const yTickValues = yScale.ticks(3);

  const hasData = index => means[index] !== 0 || stds[index] !== 0;

  return (
    <div ref={ref} style={{ position: 'relative', height }}>
      <Graph dimensions={dimensions}>
        <Axis
          dimension="y"
          scale={yScale}
          fullTick={false}
          keyAccessor={(_, i) => i}
          strokeDasharray="1,10"
          textTickStyle={() => ({ fontSize: '10px' })}
          tickValues={yTickValues}
        />
        <text
          transform="translate(-60, -20)"
          style={{
            fontSize: '10px',
            fill: KlingonBlack[600],
            strokeWidth: 0,
          }}
        >
          <tspan x="0" dy="0em"><Translate>strides</Translate></tspan>
          <tspan x="0" dy="1.2em"><Translate>meanSD</Translate></tspan>
        </text>
        {yTickValues.map((d) => {
          // we manually add the `fullTick` to prevent adding a tick line on
          // the 0 value
          if (d === 0) {
            return null;
          }
          return (
            <line
              key={d}
              x1={0}
              x2={dimensions.boundedWidth}
              y1={yScale(d)}
              y2={yScale(d)}
              stroke={KlingonBlack[500]}
              strokeDasharray="1,10"
            />
          );
        })}
        <Rects
          data={means}
          keyAccessor={(_, i) => i}
          xAccessor={xAccessorScaled}
          yAccessor={yAccessorScaled}
          widthAccessor={() => xScale.bandwidth()}
          heightAccessor={d => yScale(0) - yAccessorScaled(d)}
          fill={StarfleetBlue[400]}
        />
        <Axis
          dimension="x"
          scale={xScale}
          showDomainLine
          keyAccessor={(_, i) => i}
        />
        {phases.map((phase, i) => (
          <text
            transform={`translate(${xScale(i) + xScale.bandwidth() / 2}, ${dimensions.boundedHeight + 15})`}
            key={phase}
            style={{
              fill: KlingonBlack[600],
              strokeWidth: 0,
              textAnchor: 'middle',
              fontSize: '12px',
            }}
          >
            <tspan x="0" dy="0em">{phase}</tspan>
            { !hasData(i) && (
              <tspan x="0" dy="1.2em">
                {' ('}
                <Translate>noDataAvailable</Translate>
                )
              </tspan>
            )}
          </text>
        ))}
        {stds.map((std, i) => {
          if (means[i] === 0 && std === 0) {
            return null;
          }

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={i}>
              <line
                x1={xScale(i) + xScale.bandwidth() / 2}
                x2={xScale(i) + xScale.bandwidth() / 2}
                y1={yScale(means[i])}
                y2={yScale(means[i] + std)}
                stroke={StarfleetBlue[400]}
                strokeWidth={2}
              />
              <line
                x1={xScale(i)}
                x2={xScale(i) + xScale.bandwidth()}
                y1={yScale(means[i] + std)}
                y2={yScale(means[i] + std)}
                stroke={StarfleetBlue[400]}
                strokeWidth={2}
              />
            </Fragment>
          );
        })}
      </Graph>
    </div>
  );
}

AverageDailyStepsGraph.propTypes = {
  phases: PropTypes.arrayOf(PropTypes.string).isRequired,
  means: PropTypes.arrayOf(PropTypes.number).isRequired,
  stds: PropTypes.arrayOf(PropTypes.number).isRequired,

  height: PropTypes.number,
  width: PropTypes.number,
};

export default AverageDailyStepsGraph;
