import NoData from '../components/display/no-data';

import svg from '../assets/images/organisation.svg';

import { useAppContext } from '~/components/AppContext';

function NoOrganisations() {
  const { isStudyProject } = useAppContext();
  return (
    <NoData
      svg={svg}
      svgAlt="organisation"
      title="whatIsOrganisation"
      description={isStudyProject ? 'studySiteDescription' : 'siteDescription'}
      linkTo="/organisations/new"
      buttonText="createSite"
    />
  );
}

export default NoOrganisations;
