import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz/toZonedTime';

// Note: when possible prefer the Date methods
function toISOFormat(date) {
  return format(date, 'yyyy-MM-dd');
}

function toFileDateFormat(date) {
  return format(date, 'yyyy-MM-dd-HH-mm-ss');
}

function applyTimeZone(timestamp, timeZone) {
  return toZonedTime(timestamp, timeZone);
}

// re-used the function from `src/firebase/firestore.js` since the firestore.js
// is not imported when generating pdf
// we also don't use the `Timestamp` object here
function firestoreTimestampToDate(timestamp) {
  if ('_seconds' in timestamp) {
    // eslint-disable-next-line no-underscore-dangle
    return new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000);
  }
  return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
}

export {
  toISOFormat,
  toFileDateFormat,
  applyTimeZone,
  firestoreTimestampToDate,
};
