import { useMemo } from 'react';
import { scaleLinear } from 'd3-scale';
import { interpolateRgbBasis } from 'd3-interpolate';

import { PRESSURE_GRADIENT } from '~/utils/palette';

function usePressureScale(min, max) {
  return useMemo(() => {
    const colorInterpolation = interpolateRgbBasis(PRESSURE_GRADIENT);
    const scale = scaleLinear().domain([min, max]).range([0, 1]);
    const colorScale = i => colorInterpolation(scale(i));

    return [colorScale, colorInterpolation];
  }, [min, max]);
}

export default usePressureScale;
