import { useState } from 'react';
import PropTypes from 'prop-types';

import HeaderPaper from '~/components/display/header-paper';
import Translate from '~/components/display/translate';
import PageProgress from '~/components/display/page-progress';

import NoProgram from './NoProgram';
import Form from './Form';

import useProjectPrograms from '~/utils/hooks/useProjectPrograms';
import { programHasEnded } from '~/utils/program';
import { useAppContext } from '~/components/AppContext';

function ProgramForm({
  orgaId,
  patientResourceId,
  userId,
  onDone,
  patient,
  program = undefined,
}) {
  const { projectId } = useAppContext();
  const hasEnded = programHasEnded(program);
  const [showForm, setShowForm] = useState(program !== undefined && !hasEnded);
  const [programs, isProgramsPending] = useProjectPrograms(projectId);

  const handleNoProgramClick = () => {
    setShowForm(true);
  };

  if (isProgramsPending) {
    return <PageProgress />;
  }

  if (!showForm) {
    return <NoProgram onClick={handleNoProgramClick} />;
  }

  return (
    <>
      <HeaderPaper title={<Translate>complianceInformation</Translate>} />
      <Form
        orgaId={orgaId}
        patientResourceId={patientResourceId}
        userId={userId}
        programId={hasEnded ? undefined : program?.programId}
        refDate={hasEnded ? undefined : program?.refDate}
        startDates={hasEnded ? undefined : program?.phases?.map(i => i.startDate)}
        userProgramPhases={hasEnded ? undefined : program?.phases}
        interventionType={hasEnded ? undefined : program?.surgeryInformation?.interventionType}
        prosthesisManufacturer={hasEnded
          ? undefined : program?.surgeryInformation?.prosthesisManufacturer}
        limb={hasEnded ? undefined : program?.surgeryInformation?.limb}
        side={hasEnded ? undefined : program?.surgeryInformation?.side}
        insuranceInfo={hasEnded ? undefined : program?.additionalPatientInformation?.insuranceInfo}
        phoneNumber={patient.phoneNumber}
        programs={programs}
        onCancelForm={() => setShowForm(false)}
        onDone={onDone}
      />
    </>
  );
}

ProgramForm.propTypes = {
  userId: PropTypes.string.isRequired,
  onDone: PropTypes.func.isRequired,
  orgaId: PropTypes.string.isRequired,
  patientResourceId: PropTypes.string.isRequired,
  patient: PropTypes.shape({
    phoneNumber: PropTypes.string,
  }).isRequired,
  program: PropTypes.object,
};

export default ProgramForm;
