import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';

import MetricRecord from '~/components/MetricRecord';
import Translate from '../../components/display/translate';
import CardHeader from '../../components/CardHeader';

import {
  Distance,
  Duration,
  StrideCounter,
  ActiveTime,
  Inactivity,
} from '../../utils/metrics/index';

function GeneralMetrics({
  duration,
  distance,
  strideCounter,
  activeTime = 0,
  inactivity = 0,
  isRecordLongerThan2Hours,
  isPdf = false,
}) {
  let child;

  if (!isRecordLongerThan2Hours) {
    child = (
      <>
        <Grid>
          <MetricRecord value={duration} metric={Duration} isPdf={isPdf} />
        </Grid>
        <Grid>
          <MetricRecord value={distance} metric={Distance} showUnit isPdf={isPdf} />
        </Grid>
        <Grid>
          <MetricRecord value={strideCounter} metric={StrideCounter} isPdf={isPdf} />
        </Grid>
      </>
    );
  } else {
    child = (
      <>
        <Grid>
          <MetricRecord value={duration} metric={Duration} isPdf={isPdf} />
        </Grid>
        <Grid>
          <MetricRecord value={activeTime} metric={ActiveTime} isPdf={isPdf} />
        </Grid>
        <Grid>
          <MetricRecord value={inactivity} metric={Inactivity} showUnit isPdf={isPdf} />
        </Grid>
        <Grid>
          <MetricRecord value={distance} metric={Distance} showUnit isPdf={isPdf} />
        </Grid>
        <Grid>
          <MetricRecord value={strideCounter} metric={StrideCounter} isPdf={isPdf} />
        </Grid>
      </>
    );
  }

  return (
    <Card sx={{ width: 1 }}>
      <CardHeader title={<Translate>generalMetrics</Translate>} isPdf={isPdf} />
      <CardContent sx={[isPdf && { padding: 0, paddingTop: '1.5rem' }]}>
        <Grid container direction="row" justifyContent="space-around" flexWrap="wrap" rowSpacing={7} columnSpacing={2}>
          {child}
        </Grid>
      </CardContent>
    </Card>
  );
}

GeneralMetrics.propTypes = {
  duration: PropTypes.number.isRequired,
  distance: PropTypes.number.isRequired,
  strideCounter: PropTypes.number.isRequired,
  activeTime: PropTypes.number,
  inactivity: PropTypes.number,
  isRecordLongerThan2Hours: PropTypes.bool.isRequired,
  isPdf: PropTypes.bool,
};

export default GeneralMetrics;
