import SvgIcon from '@mui/material/SvgIcon';

import { StarfleetBlue } from '~/utils/colors';

function Cadence(props) {
  return (
    <SvgIcon sx={{ color: StarfleetBlue[400] }} {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" strokeWidth="2" stroke="currentColor">
        <path d="M21.9995 4.9082V10.3627H16.5449" strokeLinecap="round" strokeLinejoin="round" fill="transparent" />
        <path d="M2 19.4526V13.998H7.45455" strokeLinecap="round" strokeLinejoin="round" fill="transparent" />
        <path d="M4.28182 9.45239C4.74288 8.14947 5.52648 6.98458 6.55952 6.06641C7.59255 5.14824 8.84133 4.50673 10.1894 4.20172C11.5374 3.89671 12.9407 3.93816 14.2684 4.32219C15.596 4.70622 16.8048 5.42032 17.7818 6.39785L22 10.3615M2 13.9978L6.21818 17.9615C7.19523 18.939 8.40398 19.6531 9.73165 20.0371C11.0593 20.4212 12.4626 20.4626 13.8106 20.1576C15.1587 19.8526 16.4075 19.2111 17.4405 18.2929C18.4735 17.3748 19.2571 16.2099 19.7182 14.9069" strokeLinecap="round" strokeLinejoin="round" fill="transparent" />
      </svg>
    </SvgIcon>
  );
}

export default Cadence;
