import Typography from '@mui/material/Typography';

import {
  isProdTarget,
  TARGET,
  VERSION,
  COMMIT_HASH,
} from '../utils/env';

import { PhaserRed } from '../utils/colors';

function Watermark() {
  return (
    <Typography
      variant="caption"
      sx={{
        position: 'fixed',
        right: '8px',
        bottom: '8px',
        color: 'rgba(98, 98, 98, 0.6)',
        zIndex: 'appBar',
      }}
    >
      { !isProdTarget() && (
        <span>
          <b style={{ color: PhaserRed[600] }}>
            { TARGET.toUpperCase() }
          </b>
          {' '}
        </span>
      )}
      { `${VERSION} - ${COMMIT_HASH}` }
    </Typography>
  );
}

export default Watermark;
