import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import Translate from '../../components/display/translate';
import MetricValue from '~/components/MetricValue';
import BaseMetric from '../../utils/metrics/base';

function GaitCycleMetric({
  metric,
  mean,
  std,
  color,
  isPdf = false,
}) {
  return (
    <Box sx={{ fontWeight: 500, fontSize: isPdf ? '0.4rem' : '0.75rem' }} component="span">
      <span style={{ color }}>
        <Translate>{metric.name}</Translate>
      </span>
      {' '}
      <span style={{ color: '#9097A4' }}>
        <MetricValue metric={metric} showUnit>{mean}</MetricValue>
        {' [σ: '}
        <MetricValue metric={metric}>{std}</MetricValue>
        {']'}
      </span>
    </Box>
  );
}

GaitCycleMetric.propTypes = {
  metric: PropTypes.instanceOf(BaseMetric).isRequired,
  mean: PropTypes.number.isRequired,
  std: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  isPdf: PropTypes.bool,
};

export default GaitCycleMetric;
