import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';

import PaperPage from '../display/paper-page';
import HeaderPaper from '../display/header-paper';
import Button from '../display/button';
import Translate from '../display/translate';
import CompliancePhaseInfo from '../CompliancePhaseInfo';
import ComplianceImport from '../ComplianceImport';
import ComplianceRecordHistory from '../ComplianceRecordHistory/ComplianceRecordHistory';

import { fetchLastImport } from '@/firebase/firestore';
import { computeComplianceLevelPhase, computeComplianceLevelUser } from '../../utils/metrics/compliance-level';
import { getNbActionsTodo } from '../../utils/program';

import { StarfleetBlue } from '../../utils/colors';
import EyeIcon from '../icons/eye';

function ComplianceObservance({
  phase,
  phases,
  user,
  program,
}) {
  const [isOverall, setIsOverall] = useState(false);
  const [lastImportInfo, setLastImportInfo] = useState(undefined);

  const complianceLevel = useMemo(() => (isOverall
    ? computeComplianceLevelUser(program)
    : computeComplianceLevelPhase(phase)), [isOverall, phase.name]);

  const [recordsDone, recordsPlanned] = useMemo(() => {
    if (isOverall) {
      const today = new Date();
      return phases
        .filter(elt => new Date(elt.startDate) < today)
        .reduce((acc, cur) => {
          const nbActionsToDo = getNbActionsTodo(cur);

          return [acc[0] + Math.min(cur.nbActionsDone, nbActionsToDo), acc[1] + nbActionsToDo];
        }, [0, 0]);
    }

    const nbActionsToDo = getNbActionsTodo(phase);
    return [Math.min(phase.nbActionsDone, nbActionsToDo), nbActionsToDo];
  }, [isOverall, phase.name]);

  const [successful, wrong, incomplete] = useMemo(() => {
    if (isOverall) {
      const today = new Date();
      return phases
        .filter(elt => new Date(elt.startDate) < today)
        .reduce((acc, cur) => ([
          acc[0] + cur.nbActionsDone,
          acc[1] + cur.nbActionsWrong,
          acc[2] + cur.nbActionsIncomplete,
        ]), [0, 0, 0]);
    }

    return [
      phase.nbActionsDone,
      phase.nbActionsWrong,
      phase.nbActionsIncomplete,
    ];
  }, [isOverall, phase.name]);

  useEffect(() => {
    async function load() {
      const lastImport = await fetchLastImport(user.key);

      if (lastImport !== undefined) {
        setLastImportInfo({
          startTime: (lastImport || {}).startTime,
          timeZone: (lastImport || {}).timeZone,
        });
      }
    }

    load();
  }, []);

  const nbSynchro = isOverall
    ? phases.reduce((acc, cur) => acc + cur.nbSynchroDone, 0)
    : phase.nbSynchroDone;

  return (
    <PaperPage fullWidth>
      <Box sx={{ display: 'flex' }}>
        <HeaderPaper title={<Translate>complianceObservance</Translate>} />
        <Box
          sx={{
            ml: 2,
            width: 32,
            height: 32,
            bgcolor: StarfleetBlue['000'],
            borderRadius: '6px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <EyeIcon fontSize="small" sx={{ color: StarfleetBlue[400] }} />
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <ButtonGroup>
          <Button
            variant={!isOverall ? 'contained' : 'outlined'}
            color={!isOverall ? 'primary' : undefined}
            onClick={() => setIsOverall(false)}
          >
            <Translate>selectedPhase</Translate>
          </Button>
          <Button
            variant={isOverall ? 'contained' : 'outlined'}
            color={isOverall ? 'primary' : undefined}
            onClick={() => setIsOverall(true)}
          >
            <Translate>overall</Translate>
          </Button>
        </ButtonGroup>
      </Box>
      <Container maxWidth="lg">
        <CompliancePhaseInfo
          percentage={complianceLevel}
          recordsDone={recordsDone}
          recordsPlanned={recordsPlanned}
        />
        <Divider sx={{ my: 6 }} />
        <ComplianceRecordHistory
          objectiveRecords={recordsPlanned}
          successfulRecords={successful}
          wrongRecords={wrong}
          incompleteRecords={incomplete}
          missingRecords={recordsPlanned - recordsDone}
        />
        { (lastImportInfo !== undefined) && (
          <React.Fragment>
            <Divider sx={{ my: 6 }} />
            <ComplianceImport
              nbSynchro={nbSynchro}
              lastImportStartTime={lastImportInfo.startTime}
              lastImportTimeZone={lastImportInfo.timeZone}
            />
          </React.Fragment>
        )}
      </Container>
    </PaperPage>
  );
}

ComplianceObservance.propTypes = {
  phase: PropTypes.shape({
    name: PropTypes.string,
    nbActionsToDo: PropTypes.number,
    nbActionsDone: PropTypes.number,
    nbActionsWrong: PropTypes.number,
    nbActionsIncomplete: PropTypes.number,
    nbSynchroDone: PropTypes.number,
  }).isRequired,
  phases: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    nbActionsToDo: PropTypes.number,
    nbActionsDone: PropTypes.number,
    nbActionsWrong: PropTypes.number,
    nbActionsIncomplete: PropTypes.number,
    nbSynchroDone: PropTypes.number,
    startDate: PropTypes.string,
  })).isRequired,
  user: PropTypes.shape({
    key: PropTypes.string,
  }).isRequired,
  program: PropTypes.object.isRequired,
};

export default ComplianceObservance;
