import PropTypes from 'prop-types';

import NumberDisplay from '~/components/display/number';
import DurationDisplay from '~/components/display/duration';

import Translate from '~/components/display/translate';
import BaseMetric from '~/utils/metrics/base';
import useUnit from '~/utils/hooks/useUnit';

function MetricValue({
  metric,
  children = null,
  overrideUnit = null,
  displayOptions = {},
  skipConversion = false,
  showUnit = false,
}) {
  const unit = useUnit(metric, overrideUnit);
  const mergedOptions = { ...metric.formatOptions(unit), ...displayOptions };

  if (children === null) {
    return '-';
  }

  let value = children;
  if (skipConversion === false) {
    value = metric.convert(children, unit);
  }

  let valueComponent = null;
  if (metric.formatType === 'number') {
    valueComponent = <NumberDisplay options={mergedOptions}>{value}</NumberDisplay>;
  } else if (metric.formatType === 'duration') {
    valueComponent = <DurationDisplay>{value}</DurationDisplay>;
  } else {
    // default to number
    valueComponent = <NumberDisplay options={mergedOptions}>{value}</NumberDisplay>;
  }

  if (unit === null || showUnit === false) {
    return valueComponent;
  }

  return (
    <>
      { valueComponent }
      { ' ' }
      <Translate canFail>{unit}</Translate>
    </>
  );
}

MetricValue.propTypes = {
  metric: PropTypes.instanceOf(BaseMetric).isRequired,
  children: PropTypes.number,
  overrideUnit: PropTypes.string,
  displayOptions: PropTypes.object,
  showUnit: PropTypes.bool,
  skipConversion: PropTypes.bool,
};

export default MetricValue;
