import PropTypes from 'prop-types';
import {
  bin,
  extent,
  mean,
  max,
} from 'd3-array';
import { scaleLinear } from 'd3-scale';
import { timeFormat } from 'd3-time-format';
import {
  Graph,
  Rects,
  useGraphDimensions,
} from '@feetme/d3act';

import Axis from '../axis';
import { StarfleetBlue } from '../../../utils/colors';
import { applyTimeZone } from '../../../utils/date';

function VelocityHistogram({
  data,
  timeZone,
  height = 250,
  interval = 30 * 60 * 1000, // 30 minutes
  isPdf = false,
}) {
  const [ref, dimensions] = useGraphDimensions({
    marginTop: isPdf ? 0 : 40,
    marginBottom: isPdf ? 20 : 40,
    marginRight: isPdf ? 80 : 60,
    marginLeft: isPdf ? 40 : 60,
    height,
  });

  const duration = data[data.length - 1].x - data[0].x;
  const nbBins = Math.ceil(duration / interval) + 1;

  const xAccessor = d => d.x;
  const yAccessor = d => d.y;

  const rects = (bin()
    .value(xAccessor)
    .thresholds([...Array(nbBins).keys()].map(i => i * interval + xAccessor(data[0])))(data))
    .filter(bucket => bucket.length > 0)
    // compute the mean of each bin
    .map((bucket) => {
      const newBucket = [...bucket];
      newBucket.x0 = bucket.x0;
      newBucket.x1 = bucket.x1;
      newBucket.y = mean(bucket, yAccessor) || 0;
      newBucket.length = bucket.length;
      return newBucket;
    });

  const xExtent = extent(data, xAccessor);
  const xScale = scaleLinear()
    .domain(xExtent)
    .range([0, dimensions.boundedWidth]);

  const yScale = scaleLinear()
    .domain([0, max(data, yAccessor)])
    .range([dimensions.boundedHeight, 0]);

  const colorScale = scaleLinear()
    .domain(yScale.domain())
    .range([StarfleetBlue[100], StarfleetBlue[600]])
    .clamp(true);

  const yAccessorScaled = d => yScale(yAccessor(d));

  return (
    <div ref={ref} style={{ position: 'relative', height }}>
      <Graph dimensions={dimensions}>
        <Axis
          dimension="x"
          scale={xScale}
          keyAccessor={d => d}
          formatTick={d => timeFormat('%H:%M')(applyTimeZone(d, timeZone))}
        />
        <Axis
          dimension="y"
          scale={yScale}
          keyAccessor={d => d}
          strokeDasharray="1, 10"
          strokeWidth={2}
        />
        <Rects
          data={rects}
          keyAccessor={d => d[0].x}
          xAccessor={d => xScale(d.x0)}
          yAccessor={yAccessorScaled}
          widthAccessor={d => xScale(d.x1) - xScale(d.x0) - 1}
          heightAccessor={d => yScale(0) - yAccessorScaled(d)}
          fillAccessor={d => colorScale(yAccessor(d))}
        />
      </Graph>
    </div>
  );
}

VelocityHistogram.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  })).isRequired,
  timeZone: PropTypes.string.isRequired,
  height: PropTypes.number,
  interval: PropTypes.number,
  isPdf: PropTypes.bool,
};

export default VelocityHistogram;
