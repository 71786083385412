import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import { StarfleetBlue, KlingonBlack } from '~/utils/colors';

function MetricRecordView({
  children,
  title,
  tooltip = null,
  icon = null,
  isPdf = false,
  sx = {},
}) {
  return (
    <Stack
      direction="column"
      alignItems="stretch"
      justifyContent="flex-end"
      sx={{
        borderLeft: `2px solid ${StarfleetBlue[100]}`,
        pl: 2,
        width: isPdf ? '11rem' : '21rem',
        height: 1,
        ...sx,
      }}
    >
      { children }
      <Grid container direction="row" gap={1} alignItems="center">
        <Grid>
          <Typography
            sx={{
              fontSize: isPdf ? '0.5rem' : '0.875rem',
              fontWeight: 500,
              color: KlingonBlack[600],
            }}
          >
            { title }
          </Typography>
        </Grid>
        { (tooltip && !isPdf) && (
          <Grid>
            { tooltip }
          </Grid>
        )}
        <Grid xs />
        <Grid sx={{ display: 'flex' }}>
          { icon }
        </Grid>
      </Grid>
    </Stack>
  );
}

MetricRecordView.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  tooltip: PropTypes.node,
  icon: PropTypes.node,
  isPdf: PropTypes.bool,
  sx: PropTypes.object,
};

export default MetricRecordView;
