import PropTypes from 'prop-types';
import ButtonGroup from '@mui/material/ButtonGroup';

import Translate from '~/components/display/translate';
import Button from '~/components/display/button';

import { StarfleetBlue, KlingonBlack } from '~/utils/colors';

function FormSelection({ formType, onSelectFormType }) {
  const values = [{
    display: 'profileInformation',
    value: 'patient',
  }, {
    display: 'complianceInformation',
    value: 'program',
  }];

  return (
    <ButtonGroup
      disableElevation
      orientation="vertical"
      sx={{ minWidth: 195 }}
    >
      {values.map(({ display, value }) => (
        <Button
          key={value}
          variant="outlined"
          sx={{
            bgcolor: value === formType ? StarfleetBlue['000'] : undefined,
            borderColor: StarfleetBlue[100],
            color: value === formType ? StarfleetBlue[600] : KlingonBlack[900],
            justifyContent: 'flex-start',
          }}
          onClick={() => onSelectFormType(value)}
        >
          <Translate>{display}</Translate>
        </Button>
      ))}
    </ButtonGroup>
  );
}

FormSelection.propTypes = {
  formType: PropTypes.string.isRequired,
  onSelectFormType: PropTypes.func.isRequired,
};

export default FormSelection;
