import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';

import BaseMetric from '~/utils/metrics/base';
import { RomulanGreen, PhaserRed, KlingonBlack } from '~/utils/colors';

function MetricValueComparisonIcon({ metric, values, showColor = false }) {
  if (values.filter(i => i !== 0).length !== 2) {
    return null;
  }

  const { maximumFractionDigits } = metric.formatOptions();
  const round = (value) => {
    const decimals = 10 ** maximumFractionDigits;
    return Math.round(value * decimals) / decimals;
  };

  const difference = round(values[1]) - round(values[0]);

  const baseColor = KlingonBlack[700];

  if (difference > 0) {
    return <ArrowUpwardRoundedIcon sx={{ color: showColor ? RomulanGreen[700] : baseColor }} />;
  }

  if (difference < 0) {
    return <ArrowDownwardRoundedIcon sx={{ color: showColor ? PhaserRed[500] : baseColor }} />;
  }

  return (
    <Typography
      sx={{
        color: KlingonBlack[700],
        fontSize: '1.9rem',
      }}
    >
      =
    </Typography>
  );
}

MetricValueComparisonIcon.propTypes = {
  metric: PropTypes.instanceOf(BaseMetric).isRequired,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  showColor: PropTypes.bool,
};

export default MetricValueComparisonIcon;
