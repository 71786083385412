import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2';
import RefreshIcon from '@mui/icons-material/Refresh';

import Translate from '~/components/display/translate';
import InfoBox from '~/components/display/info-box';
import Button from '~/components/display/button';
import QrCodeStatus from './Status';
import QrCodeDisplay from './Display';
import QrCodeDownload from './Download';

import usePatientLinks from '~/utils/hooks/usePatientLinks';
import { convertFirestoreTimestampToDate } from '@/firebase/firestore';
import { getPatientLinks, resetPatientLink } from '@/firebase/functions';

function QrCode({ orgaId, patientResourceId, patientId }) {
  const [links, loading] = usePatientLinks(orgaId, patientResourceId);
  const [link, setLink] = useState(undefined);

  useEffect(() => {
    setLink(links[0]);
  }, [loading]);

  const handleReset = async () => {
    // call link
    await resetPatientLink({
      invalidateOthers: true,
      componentUserId: link.componentUserId,
      orgaId,
    });

    const { data } = await getPatientLinks({ orgaId, patientId: patientResourceId });

    if (data && data.length > 0) {
      setLink(data[0]);
    } else {
      setLink(undefined);
    }
  };

  if (loading || link === undefined) {
    return null;
  }

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
      <Grid>
        <InfoBox>
          <Translate>qrCodeTimeValidityInfo</Translate>
        </InfoBox>
      </Grid>
      <Grid sx={{ mt: 5 }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid xs>
            { link && (
              <QrCodeDisplay linkId={link.id} />
            )}
          </Grid>
          <Grid xs>
            <Grid container direction="column" justifyContent="center" alignItems="flex-start" spacing={1}>
              <Grid>
                <Button onClick={handleReset} startIcon={<RefreshIcon />} color="primary">
                  <Translate>generateQrCode</Translate>
                </Button>
              </Grid>
              <Grid>
                <QrCodeDownload
                  orgaId={orgaId}
                  patientId={patientId}
                  patientResourceId={patientResourceId}
                  createdAt={convertFirestoreTimestampToDate(link.createdAt)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid sx={{ mt: 5 }}>
        { link && (
          <QrCodeStatus link={link} />
        )}
      </Grid>
    </Grid>
  );
}

QrCode.propTypes = {
  orgaId: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired,
  patientResourceId: PropTypes.string.isRequired,
};

export default QrCode;
