import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import Translate from '../components/display/translate';
import Button from '../components/display/button';
import LinearProgress from '../components/display/linear-progress';
import FileIcon from '../components/icons/file';

import { PhaserRed, RomulanGreen, KlingonBlack } from '../utils/colors';

function FileStatus({
  progress,
  name,
  onCancel,
  error = false,
}) {
  const statusChild = () => {
    if (error) {
      return (
        <Avatar
          sx={theme => ({
            width: theme.spacing(3),
            height: theme.spacing(3),
            backgroundColor: PhaserRed[700],
          })}
        >
          <CloseIcon fontSize="small" />
        </Avatar>
      );
    }

    if (progress < 100) {
      return (
        <Button
          variant="text"
          size="small"
          disableRipple
          onClick={onCancel}
          sx={{ color: KlingonBlack[600], fontWeight: 500 }}
        >
          <Translate>cancel</Translate>
        </Button>
      );
    }

    return (
      <Avatar
        sx={theme => ({
          width: theme.spacing(3),
          height: theme.spacing(3),
          backgroundColor: RomulanGreen[500],
        })}
      >
        <CheckIcon fontSize="small" />
      </Avatar>
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ minHeight: 35 }}>
        <Grid item sx={{ color: KlingonBlack[600], fontWeight: 500 }}>
          { `${Math.round(progress)}% •` }
        </Grid>
        <Grid item>
          <FileIcon fontSize="small" />
        </Grid>
        <Grid item xs sx={{ color: KlingonBlack[500], fontWeight: 500 }}>
          { name }
        </Grid>
        <Grid item>
          { statusChild() }
        </Grid>
      </Grid>
      <LinearProgress value={progress} variant="determinate" />
    </div>
  );
}

FileStatus.propTypes = {
  progress: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

export default FileStatus;
