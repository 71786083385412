import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

function TabPanel({
  value,
  index,
  active = undefined,
  children,
}) {
  return (
    <div
      role="tabpanel"
      hidden={(value !== index && value !== active)}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      { (value === index || value === active) && (
        <Box sx={{ pt: 5 }}>
          { children }
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  active: PropTypes.string,
  index: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired,
};

export default TabPanel;
