import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { QRCodeCanvas } from 'qrcode.react';

import { StarfleetBlue } from '~/utils/colors';

function QrCodeDisplay({ linkId }) {
  return (
    <Box
      sx={{
        width: 202,
        height: 202,
        borderRadius: '8px',
        p: 2,
        border: `1px solid ${StarfleetBlue['000']}`,
      }}
    >
      <QRCodeCanvas id="patient-qr-code" value={linkId} size={168} />
    </Box>
  );
}

QrCodeDisplay.propTypes = {
  linkId: PropTypes.string.isRequired,
};

export default QrCodeDisplay;
