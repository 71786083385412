import { useState, useMemo } from 'react';

import Table from '../components/table/organisations/index';

import useLabels from '../utils/hooks/useLabels';
import useOrganisations from '../utils/hooks/useOrganisations';
import useFuzzy from '../utils/hooks/useFuzzy';
import { useAppContext } from '~/components/AppContext';

function List() {
  const { hasPrograms } = useAppContext();
  const [search, setSearch] = useState('');

  const [organisations, isLoading] = useOrganisations(true);
  const [labels] = useLabels();
  const organisationsWithLabels = useMemo(() => organisations.map((orga) => {
    // the organisations only have the labelsId in `organisation.labels`
    // we update the labels field to add the label name
    const foundLabels = (orga.labels || [])
      .map(labelId => labels.find(label => label.key === labelId))
      .filter(i => i !== undefined)
      .map(i => ({ key: i.key, name: i.name }));

    return ({
      ...orga,
      labels: foundLabels,
    });
  }), [organisations.length, labels.length]);

  const [filteredOrganisations] = useFuzzy(organisationsWithLabels, search, 'name');

  const handleChange = (change) => {
    if (change.name === 'search') {
      setSearch(change.value);
    }
  };

  return (
    <Table
      hasSearch
      organisations={filteredOrganisations}
      onChange={handleChange}
      inProgress={isLoading}
      showCompliance={hasPrograms}
    />
  );
}

export default List;
