import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Translate from '../components/display/translate';
import VelocityHistogramGraph from '../components/graph/velocity-histogram';
import CardHeader from '../components/CardHeader';

import useUnit from '~/utils/hooks/useUnit';
import { Velocity } from '../utils/metrics';

function VelocityHistogram({ data, timeZone, isPdf = false }) {
  const unit = useUnit(Velocity);

  const convertedData = useMemo(() => data.map(i => ({
    x: i.x,
    y: Velocity.convert(i.y, unit),
  })), [data.length]);

  return (
    <Card sx={{ width: 1 }}>
      <CardHeader
        title={<Translate>velocityVariation</Translate>}
        subheader={(
          <>
            <Translate>walkingSpeed</Translate>
            {' '}
            <Translate canFail>{unit}</Translate>
          </>
        )}
        isPdf={isPdf}
      />
      <CardContent sx={[isPdf && { padding: 0 }]}>
        <VelocityHistogramGraph data={convertedData} timeZone={timeZone} isPdf />
      </CardContent>
    </Card>
  );
}

VelocityHistogram.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  })).isRequired,
  timeZone: PropTypes.string.isRequired,
  isPdf: PropTypes.bool,
};

export default VelocityHistogram;
