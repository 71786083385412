import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';

import ShowPanel from './show-panel';
import LoginForm from './login-form';

function LoginColumns(props) {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="stretch" style={{ height: '100%' }}>
      <Hidden mdDown>
        <Grid item xs>
          <ShowPanel />
        </Grid>
      </Hidden>
      <Grid
        item
        xs
        sx={{
          bgcolor: 'white',
          textAlign: 'left',
          pl: { xs: '10px', sm: '100px' },
        }}
      >
        <Box sx={{ maxWidth: '350px', height: 1 }}>
          <LoginForm {...props} />
        </Box>
      </Grid>
    </Grid>
  );
}

export default LoginColumns;
