import Avatar from '@mui/material/Avatar';

import User from '~/components/icons/user';
import CrossIcon from '~/components/icons/cross';

import { useAppContext } from '~/components/AppContext';
import { KlingonBlack, OrionTeal } from '~/utils/colors';

function MemberAvatar() {
  const { isHealthProject } = useAppContext();

  if (isHealthProject) {
    return (
      <Avatar sx={{ bgcolor: OrionTeal[100] }}>
        <CrossIcon sx={{ color: OrionTeal[400] }} />
      </Avatar>
    );
  }

  return (
    <Avatar sx={{ backgroundColor: KlingonBlack[300] }}>
      <User />
    </Avatar>
  );
}

export default MemberAvatar;
