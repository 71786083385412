import { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import Visibility from '../icons/visibility';
import VisibilityOff from '../icons/visibility-off';

import TextField from '../display/textfield';
import Translate from '../display/translate';

function Password({ label = 'password', ...props }) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <TextField
      placeholder="atLeast8characters"
      type={showPassword ? 'text' : 'password'}
      label={<Translate>{ label }</Translate>}
      endAdornment={(
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            size="large"
          >
            { showPassword ? <Visibility /> : <VisibilityOff /> }
          </IconButton>
        </InputAdornment>
      )}
      {...props}
    />
  );
}

Password.propTypes = {
  label: PropTypes.string,
};

export default Password;
