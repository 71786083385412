import { fetchNumberPatients } from '@/firebase/firestore';
import useFirestore from '~/utils/hooks/useFirestore';
import { useAppContext } from '~/components/AppContext';

function useNumberPatients(orgaId = undefined, blockedPatients = false) {
  const { isProjectAdmin } = useAppContext();

  return useFirestore(() => fetchNumberPatients(isProjectAdmin, orgaId, blockedPatients), 0);
}

export default useNumberPatients;
