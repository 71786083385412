import { useState } from 'react';
import PropTypes from 'prop-types';

import Table from '../table/patients/index';

import usePatients from './usePatients';
import useFuzzy from '../../utils/hooks/useFuzzy';

function List({ orgaId = undefined, blockedPatients = false }) {
  const [search, setSearch] = useState('');
  const [patients, inProgress] = usePatients(orgaId, blockedPatients);
  const [filteredPatients] = useFuzzy(patients, search, 'name');

  const handleChange = (change) => {
    if (change.name === 'search') {
      setSearch(change.value);
    }
  };

  return (
    <Table
      data={filteredPatients}
      inProgress={inProgress}
      onChange={handleChange}
      blockedPatients={blockedPatients}
      showOrga={orgaId === undefined}
    />
  );
}

List.propTypes = {
  blockedPatients: PropTypes.bool,
  orgaId: PropTypes.string,
};

export default List;
