import PropTypes from 'prop-types';

import ProjectAdminAvatar from './project-admin';
import SiteAdminAvatar from './site-admin';
import MemberAvatar from './member';

function RoleAvatar({ role = 'member' }) {
  if (role === 'projectAdmin') {
    return (<ProjectAdminAvatar />);
  }

  if (role === 'orgaAdmin') {
    return (<SiteAdminAvatar />);
  }

  return (<MemberAvatar />);
}

RoleAvatar.propTypes = {
  role: PropTypes.oneOf(['projectAdmin', 'orgaAdmin', 'member']),
};

export default RoleAvatar;
