import PropTypes from 'prop-types';

import TextField from '~/components/display/textfield';
import Translate from '~/components/display/translate';

function PatientName({ onChange, ...props }) {
  return (
    <TextField
      minLength={2}
      placeholder="addFirstnameAndLastname"
      onChange={ev => onChange({
        name: 'name',
        value: ev.target.value,
        error: !ev.target.value || ev.target.value.length < 2,
      })}
      label={<Translate>name</Translate>}
      {...props}
    />
  );
}

PatientName.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default PatientName;
