import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { frFR as frFRMUI, enUS as enUSMUI } from '@mui/x-date-pickers/locales';
import { enUS } from 'date-fns/locale/en-US';
import { fr } from 'date-fns/locale/fr';

import { getDefaultLocale, htmlLangFormat } from '~/utils/l10n';
import { fetchLangFile } from '~/utils/i18n';
import {
  fetchUserProfile,
  fetchSettings,
  fetchProjectSettings,
  projectHasPrograms,
} from '@/firebase/firestore';
import { getProjectLangUrl } from '@/firebase/storage';
import { mergeDefaultSettings } from '~/utils/user-settings';

const AppContext = createContext();

function AppContextWrapper({ children }) {
  // state
  const [isPending, setIsPending] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(0);
  /// locale
  const [locale, setLocale] = useState(undefined);
  const [translateFile, setTranslateFile] = useState({});
  const [projectTranslateFile, setProjectTranslateFile] = useState({});
  /// project
  const [projectId, setProjectId] = useState(undefined);
  const [project, setProject] = useState({});
  const [hasPrograms, setHasPrograms] = useState(false);
  /// user
  const [userId, setUserId] = useState(undefined);
  const [userProfile, setUserProfile] = useState({});
  const [userSettings, setUserSettings] = useState(mergeDefaultSettings({}));

  useEffect(() => {
    async function firstLocaleLoad() {
      const defaultLocale = await getDefaultLocale();
      setTranslateFile(await fetchLangFile(defaultLocale));
      setLocale(defaultLocale);
      document.documentElement.lang = htmlLangFormat(defaultLocale);
    }

    firstLocaleLoad();
  }, []);

  // actions
  const updateProjectLangFile = async (newLocale) => {
    const url = await getProjectLangUrl(newLocale);
    const response = await fetch(url);
    let json = {};
    try {
      json = await response.json();
      // eslint-disable-next-line no-empty
    } catch (err) { }
    setProjectTranslateFile(json);
    setForceUpdate(prev => prev + 1);
  };

  const updateLocale = async (newLocale) => {
    if (newLocale === locale || newLocale === undefined) {
      return;
    }

    setIsPending(true);
    setTranslateFile(await fetchLangFile(newLocale));
    setLocale(newLocale);
    document.documentElement.lang = htmlLangFormat(newLocale);
    if (projectId !== undefined) {
      await updateProjectLangFile(newLocale);
    }
    setIsPending(false);
  };

  const updateProjectId = async (newProjectId) => {
    if (newProjectId === projectId || newProjectId === undefined) {
      return;
    }

    setIsPending(true);
    setProjectId(newProjectId);
    setProject(await fetchProjectSettings(newProjectId));
    setHasPrograms(await projectHasPrograms(newProjectId));
    await updateProjectLangFile(locale);
    setIsPending(false);
  };

  const updateUserId = async (newUserId) => {
    if (newUserId === userId || newUserId === undefined) {
      return;
    }

    setIsPending(true);
    setUserId(newUserId);
    setUserProfile(await fetchUserProfile(newUserId));
    const settings = mergeDefaultSettings(await fetchSettings());
    setUserSettings(settings);
    if ('locale' in settings) {
      await updateLocale(settings.locale);
    }
    setIsPending(false);
  };

  const updateUserSettings = async (settings) => {
    setUserSettings(settings);
    setForceUpdate(prev => prev + 1);
  };

  const flushContext = () => {
    setUserSettings({});
    setUserProfile({});
    setUserId(undefined);
  };

  const contextValue = useMemo(() => ({
    isPending,
    // locale
    locale,
    file: { ...translateFile, ...projectTranslateFile },
    // project
    projectLocales: project.locales || [],
    isStudyProject: project.type === 'study',
    isHealthProject: project.type === 'health',
    projectId,
    patientIdRegExp: project.patientIdRegExp || '',
    patientIdExamples: project.patientIdExamples || null,
    projectName: project.name,
    hasPrograms,
    // user
    isProjectAdmin: userProfile.role === 'projectAdmin',
    isOrgaAdmin: userProfile.role === 'orgaAdmin',
    isAdmin: userProfile.role === 'projectAdmin' || userProfile.role === 'orgaAdmin',
    isPatient: userProfile.role === 'patient',
    isMember: userProfile.role === 'member',
    role: userProfile.role,
    userSettings,

    updateLocale,
    updateProjectId,
    updateUserId,
    updateUserSettings,
    flushContext,
  }), [isPending, locale, projectId, userId, forceUpdate]);

  return (
    <AppContext.Provider value={contextValue}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={locale === 'en-US' ? enUS : fr}
        localeText={(locale === 'en-US' ? enUSMUI : frFRMUI).components.MuiLocalizationProvider.defaultProps.localeText}
      >
        { children }
      </LocalizationProvider>
    </AppContext.Provider>
  );
}

AppContextWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

const useAppContext = () => useContext(AppContext);

export default AppContextWrapper;
export {
  useAppContext,
  AppContext,
};
