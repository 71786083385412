import { fetchOrganisations, fetchPatients, fetchProgram } from '@/firebase/firestore';
import useFirestore from './useFirestore';

import { useAppContext } from '~/components/AppContext';
import { computeComplianceLevelSite } from '~/utils/metrics/compliance-level';

function loadPatientsInfo(organisations) {
  return Promise.all(organisations.map(async (orga) => {
    const patients = await fetchPatients(false, orga.key, false);
    const programs = (await Promise.all(patients.map((patient) => {
      if (!('programDocumentId' in patient)) {
        return Promise.resolve(undefined);
      }
      return fetchProgram(patient.key, patient.programDocumentId);
    }))).filter(i => i !== undefined);
    const complianceLevel = computeComplianceLevelSite(programs);

    return ({
      ...orga,
      patients: patients.length,
      complianceLevel,
    });
  }));
}

function useOrganisations(patientInfo = false) {
  const { isProjectAdmin } = useAppContext();
  return useFirestore(
    () => fetchOrganisations(isProjectAdmin),
    [],
    [],
    patientInfo ? loadPatientsInfo : undefined,
  );
}

export default useOrganisations;
