import { useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Unstable_Grid2';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';

import Translate from '~/components/display/translate';
import CardHeader from '~/components/CardHeader';
import Button from '~/components/display/button';
import InterpolationLoadingWrapper from '~/components/InterpolationLoadingWrapper';

import DisplaySelection from './DisplaySelection';
import DynamicHeatmapGraph from './DynamicHeatmapGraph';
import StrideSlider from './StrideSlider';

function DynamicHeatmapCard({
  record,
  insoles,
  processedRecord,
  stridePressureIndexes,
  isInterpolationPending,
  isInterpolationReady,
  hasInterpolationTimeout,
  isProcessedRecordPending,
}) {
  // 'max-pressures' or 'pti'
  const [selectedGraph, setSelectedGraph] = useState('max-pressures');
  // mean or stride by stride
  const [showMean, setShowMean] = useState(true);
  const [strideIndex, setStrideIndex] = useState(0);

  return (
    <Card sx={{ width: 1 }}>
      <CardHeader
        title={(
          <Translate>
            { selectedGraph === 'max-pressures' ? 'maximumPressureDistribution' : 'pressureTimeIntegral'}
          </Translate>
        )}
        subheader={<Translate>dynamicHeatmapCardSubheader</Translate>}
        action={isInterpolationReady && (
          <ButtonGroup>
            <Button
              color={selectedGraph === 'max-pressures' ? 'primary' : undefined}
              variant={selectedGraph === 'max-pressures' ? 'contained' : 'outlined'}
              onClick={() => {
                setSelectedGraph('max-pressures');
                setShowMean(true);
              }}
            >
              <Translate>pressure</Translate>
            </Button>
            <Button
              color={selectedGraph === 'pti' ? 'primary' : undefined}
              variant={selectedGraph === 'pti' ? 'contained' : 'outlined'}
              onClick={() => {
                setSelectedGraph('pti');
                setShowMean(true);
              }}
            >
              <Translate>PTI</Translate>
            </Button>
          </ButtonGroup>
        )}
      />
      <CardContent>
        <InterpolationLoadingWrapper
          isInterpolationPending={isInterpolationPending || isProcessedRecordPending}
          hasInterpolationTimeout={hasInterpolationTimeout}
        >
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
            <Grid xs={2}>
              { stridePressureIndexes.length > 1 && (
                <DisplaySelection showMean={showMean} onSelectMean={setShowMean} />
              )}
            </Grid>
            <Grid xs>
              { !isProcessedRecordPending && (
                <DynamicHeatmapGraph
                  showMean={showMean}
                  selectedGraph={selectedGraph}
                  insoles={insoles}
                  stridePressureIndexes={stridePressureIndexes}
                  strideIndex={strideIndex}
                  processedRecord={processedRecord}
                  record={record}
                />
              )}
            </Grid>
            <Grid xs={2} />
          </Grid>
          <Box sx={{ mx: 8, mt: 2 }}>
            { !showMean && (
              <StrideSlider
                maxNbStrides={(stridePressureIndexes.length) - 1}
                index={strideIndex}
                setIndex={setStrideIndex}
              />
            )}
          </Box>
        </InterpolationLoadingWrapper>
      </CardContent>
    </Card>
  );
}

DynamicHeatmapCard.propTypes = {
  record: PropTypes.shape({
    key: PropTypes.string,
  }).isRequired,
  insoles: PropTypes.arrayOf(PropTypes.object).isRequired,
  stridePressureIndexes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  isInterpolationPending: PropTypes.bool.isRequired,
  isInterpolationReady: PropTypes.bool.isRequired,
  hasInterpolationTimeout: PropTypes.bool.isRequired,
  isProcessedRecordPending: PropTypes.bool.isRequired,
  processedRecord: PropTypes.object.isRequired,
};

export default DynamicHeatmapCard;
