import BaseMetric from './base';

const UNITS = ['kg', 'N'];

function convert(v, unit) {
  switch (unit) {
    case 'kg':
      return v;
    case 'N':
      return v * 9.80665;
    default:
      return v;
  }
}

export default new BaseMetric({
  name: 'load',
  key: 'load',
  defaultUnit: 'kg',
  convert,
});

export {
  UNITS as LOAD_UNITS,
  convert as convertLoad,
};
