import { useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ButtonGroup from '@mui/material/ButtonGroup';

import Translate from '~/components/display/translate';
import CardHeader from '~/components/CardHeader';
import Button from '~/components/display/button';
import InterpolationLoadingWrapper from '~/components/InterpolationLoadingWrapper';
import Ellipse from '~/components/Ellipse';
import StaticHeatmapGraph from '~/components/StaticHeatmapGraph';

function StaticHeatmapCard({
  record,
  insoles,
  processedRecord,
  bipodalCops,
  isInterpolationPending,
  isInterpolationReady,
  hasInterpolationTimeout,
  isInputDataPending,
}) {
  // 'mean-pressures' or 'cop'
  const [selectedGraph, setSelectedGraph] = useState('mean-pressures');

  return (
    <Card sx={{ width: 1 }}>
      <CardHeader
        title={(
          <Translate>
            { selectedGraph === 'mean-pressures' ? 'pressureDistribution' : 'globalCenterOfPressure'}
          </Translate>
        )}
        subheader={(
          <Translate>
            { selectedGraph === 'mean-pressures' ? 'pressureDistributionSubheader' : 'globalCenterOfPressureSubheader'}
          </Translate>
        )}
        action={isInterpolationReady && (
          <ButtonGroup>
            <Button
              color={selectedGraph === 'mean-pressures' ? 'primary' : undefined}
              variant={selectedGraph === 'mean-pressures' ? 'contained' : 'outlined'}
              onClick={() => setSelectedGraph('mean-pressures')}
            >
              <Translate>distribution</Translate>
            </Button>
            <Button
              color={selectedGraph === 'cop' ? 'primary' : undefined}
              variant={selectedGraph === 'cop' ? 'contained' : 'outlined'}
              onClick={() => setSelectedGraph('pti')}
            >
              <Translate>COP</Translate>
            </Button>
          </ButtonGroup>
        )}
      />
      <CardContent>
        <InterpolationLoadingWrapper
          isInterpolationPending={isInterpolationPending || isInputDataPending}
          hasInterpolationTimeout={hasInterpolationTimeout}
        >
          { selectedGraph === 'mean-pressures' && (
            <StaticHeatmapGraph
              insoles={insoles}
              processedRecord={processedRecord}
              meshX={record.interpolation.meshX}
              meshY={record.interpolation.meshY}
            />
          )}
          { selectedGraph === 'cop' && (
            <Ellipse
              bipodalCops={bipodalCops}
              ellipse={processedRecord.ellipseBipodalCop}
            />
          )}
        </InterpolationLoadingWrapper>
      </CardContent>
    </Card>
  );
}

StaticHeatmapCard.propTypes = {
  record: PropTypes.shape({
    key: PropTypes.string,
    interpolation: PropTypes.shape({
      meshX: PropTypes.number,
      meshY: PropTypes.number,
    }),
  }).isRequired,
  insoles: PropTypes.arrayOf(PropTypes.object).isRequired,
  bipodalCops: PropTypes.arrayOf(PropTypes.object).isRequired,
  processedRecord: PropTypes.object.isRequired,
  isInterpolationPending: PropTypes.bool.isRequired,
  isInterpolationReady: PropTypes.bool.isRequired,
  hasInterpolationTimeout: PropTypes.bool.isRequired,
  isInputDataPending: PropTypes.bool.isRequired,
};

export default StaticHeatmapCard;
