import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';

import DownloadButtons from '~/components/DownloadButtons';
import ReportLayout from '~/components/ReportLayout';
import Translate from '~/components/display/translate';
import StaticHeatmapCard from '~/components/StaticHeatmapCard';

import Header from './Header';

import { getInterpolationState } from '~/utils/record';
import useProcessedRecord from '~/utils/hooks/useProcessedRecord';
import useBipodalCops from '~/utils/hooks/useBipodalCops';
import useInsoles from '~/utils/hooks/useInsoles';

function Static({ record, listRecordsPath }) {
  const [insoles, insolesPending] = useInsoles(record.key);

  const [
    isInterpolationPending,
    isInterpolationReady,
    hasInterpolationTimeout,
  ] = getInterpolationState(record);

  const [
    processedRecord,
    isProcessedRecordPending,
  ] = useProcessedRecord(record.key, [isInterpolationReady]);

  const [
    bipodalCops,
    bipodalCopsPending,
  ] = useBipodalCops(record.key, [isInterpolationReady]);

  return (
    <ReportLayout
      backTo={listRecordsPath}
      buttonBackChildren={<Translate>backToRecords</Translate>}
      Header={<Header record={record} />}
      DownloadButton={<DownloadButtons record={record} recordId={record.key} />}
    >
      <Stack justifyContent="stretch" alignItems="flex-start" spacing={3}>
        <StaticHeatmapCard
          record={record}
          insoles={insoles}
          processedRecord={processedRecord}
          bipodalCops={bipodalCops}
          isInterpolationPending={isInterpolationPending}
          isInterpolationReady={isInterpolationReady}
          hasInterpolationTimeout={hasInterpolationTimeout}
          isInputDataPending={insolesPending
            || isProcessedRecordPending || Object.keys(processedRecord).length === 0
            || bipodalCopsPending || bipodalCops.length === 0}
        />
      </Stack>
    </ReportLayout>
  );
}

Static.propTypes = {
  record: PropTypes.shape({
    key: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  listRecordsPath: PropTypes.string.isRequired,
};

export default Static;
