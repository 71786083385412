import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import Translate from '../display/translate';
import Button from '../display/button';
import ButtonProgress from '../display/button-progress';
import StickyWrapper from './sticky-wrapper';

function Buttons({
  onCancel,
  onSubmit,
  inProgress = false,
  isCreate = false,
  disabled = false,
  sticky = false,
}) {
  return (
    <StickyWrapper sticky={sticky}>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Button variant="contained" onClick={() => onCancel()}>
            <Translate>cancel</Translate>
          </Button>
        </Grid>
        <Grid item>
          <ButtonProgress
            variant="contained"
            color="primary"
            inProgress={inProgress}
            disabled={disabled}
            onClick={() => onSubmit()}
          >
            <Translate>{ isCreate ? 'create' : 'save' }</Translate>
          </ButtonProgress>
        </Grid>
      </Grid>
    </StickyWrapper>
  );
}

Buttons.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  inProgress: PropTypes.bool,
  isCreate: PropTypes.bool,
  disabled: PropTypes.bool,
  sticky: PropTypes.bool,
};

export default Buttons;
