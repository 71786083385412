import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import Translate from '../display/translate';

import useOrganisations from '../../utils/hooks/useOrganisations';

function populateIds(organisationsId, organisations) {
  return organisationsId.map((key) => {
    const foundOrganisations = organisations.find(orga => orga.key === key);
    return ({
      key,
      name: foundOrganisations ? foundOrganisations.name : key,
    });
  });
}

function Organisations({ organisationsId, onChange }) {
  const [organisations] = useOrganisations();

  return (
    <Autocomplete
      multiple
      fullWidth
      value={populateIds(organisationsId, organisations)}
      options={organisations}
      getOptionLabel={option => option.name}
      isOptionEqualToValue={(option, value) => option.key === value.key}
      onChange={(_, value) => onChange({ name: 'organisationsId', value: value.map(i => i.key) })}
      filterSelectedOptions
      renderOption={(props, option) => {
        // eslint-disable-next-line react/prop-types
        const { key, ...rest } = props;
        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...rest} key={option.key}>
            {option.name}
          </li>
        );
      }}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label={<Translate>organisations</Translate>}
          helperText={(
            <Translate>addOneOrSeveralSites</Translate>
          )}
        />
      )}
    />
  );
}

Organisations.propTypes = {
  organisationsId: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Organisations;
