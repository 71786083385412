import { getFunctions, httpsCallable } from 'firebase/functions';

import { coreApp, getComponentApp, getCloudFunctionsLocation } from './index';

const coreFunctions = getFunctions(coreApp, 'europe-west1');

function getComponentFunction() {
  return getFunctions(getComponentApp(), getCloudFunctionsLocation());
}

// Core functions
const authDispatch = httpsCallable(coreFunctions, 'api-v2/auth/dispatch');
const resetPassword = httpsCallable(coreFunctions, 'api-v2/auth/resetPassword');
const linkProcess = httpsCallable(coreFunctions, 'api-v2/link/process');
const linkCheck = httpsCallable(coreFunctions, 'api-v2/link/check');

// Components function
// api-v2
const resetPatientLink = (...i) => httpsCallable(getComponentFunction(), 'api-v2/link/resetPatientLink')(...i);
const createOrganisation = (...i) => httpsCallable(getComponentFunction(), 'api-v2/organisation/createOrganisation')(...i);
const createMember = (...i) => httpsCallable(getComponentFunction(), 'api-v2/user/createMember')(...i);
const getLastInvitation = (...i) => httpsCallable(getComponentFunction(), 'api-v2/user/getLastInvitation')(...i);
const newInvitation = (...i) => httpsCallable(getComponentFunction(), 'api-v2/user/newInvitation')(...i);
const updateProgram = (...i) => httpsCallable(getComponentFunction(), 'api-v2/user/updateProgram')(...i);
const getUserEmail = (...i) => httpsCallable(getComponentFunction(), 'api-v2/auth/getUserEmail')(...i);
const createPasscodeReset = (...i) => httpsCallable(getComponentFunction(), 'api-v2/passcodeReset/create')(...i);
const listPatients = (...i) => httpsCallable(getComponentFunction(), 'api-v2/porfic/listPatients')(...i);
const getPatient = (...i) => httpsCallable(getComponentFunction(), 'api-v2/porfic/getPatient')(...i);
const createPatient = (...i) => httpsCallable(getComponentFunction(), 'api-v2/porfic/createPatient')(...i);
const updatePatient = (...i) => httpsCallable(getComponentFunction(), 'api-v2/porfic/updatePatient')(...i);
const getPatientLinks = (...i) => httpsCallable(getComponentFunction(), 'api-v2/porfic/getPatientLinks')(...i);
const listRecordsPatient = (...i) => httpsCallable(getComponentFunction(), 'api-v2/porfic/listRecordsPatient')(...i);
const listRecordsNotLinked = (...i) => httpsCallable(getComponentFunction(), 'api-v2/porfic/listRecordsNotLinked')(...i);
const searchPatients = (...i) => httpsCallable(getComponentFunction(), 'api-v2/porfic/searchPatientPagination')(...i);
const linkRecordsToPatient = (...i) => httpsCallable(getComponentFunction(), 'api-v2/porfic/linkUnlinkedRecordsToPatient')(...i);
const getCloudRunURL = (...i) => httpsCallable(getComponentFunction(), 'api-v2/meta/cloudRunHostname')(...i);
// api-v2-compute
const generateReportPdf = (...i) => httpsCallable(getComponentFunction(), 'api-v2-compute/pdf/generateReport')(...i);
const generateQrCodePdf = (...i) => httpsCallable(getComponentFunction(), 'api-v2-compute/pdf/generateQrCode')(...i);
const recordProcess = (...i) => httpsCallable(getComponentFunction(), 'api-v2-compute/record/process', { timeout: 300000 })(...i);
const phaseProcess = (...i) => httpsCallable(getComponentFunction(), 'api-v2-compute/program/phaseProcess', { timeout: 300000 })(...i);
const generateStridesCSV = (...i) => httpsCallable(getComponentFunction(), 'api-v2-compute/record/generateStridesCSV', { timeout: 300000 })(...i);

export {
  authDispatch,
  resetPassword,
  linkProcess,
  linkCheck,

  resetPatientLink,
  createOrganisation,
  createMember,
  getLastInvitation,
  newInvitation,
  updateProgram,
  getUserEmail,
  createPasscodeReset,
  createPatient,
  updatePatient,
  getPatientLinks,
  listPatients,
  searchPatients,
  getPatient,
  listRecordsPatient,
  listRecordsNotLinked,
  linkRecordsToPatient,
  generateReportPdf,
  generateQrCodePdf,
  getCloudRunURL,
  recordProcess,
  phaseProcess,
  generateStridesCSV,
};
