import NoData from '~/components/display/no-data';

import svg from '~/assets/images/member.svg';

import { useAppContext } from '~/components/AppContext';

function NoMembers() {
  const { isStudyProject, isProjectAdmin } = useAppContext();

  return (
    <NoData
      svg={svg}
      svgAlt={isStudyProject ? 'investigator' : 'member'}
      title={isStudyProject ? 'whatIsInvestigator' : 'whatIsMember'}
      description={isStudyProject ? 'investigatorDescription' : 'memberDescription'}
      linkTo={isProjectAdmin ? '/members/new' : undefined}
      buttonText={isStudyProject ? 'createInvestigator' : 'createMember'}
    />
  );
}

export default NoMembers;
