import { Navigate, useLocation } from 'react-router-dom';

import LandingPage from '../components/landing-page';
import LoginColumns from './login-columns';

import useAuth from '../utils/hooks/useAuth';

function Login() {
  const location = useLocation();
  const [loggedIn, loading] = useAuth();

  if (!loading && loggedIn) {
    const { from } = location.state || { from: { pathname: '/' } };
    return (<Navigate to={from} replace />);
  }

  const isSignUp = location.state && location.state.isNewAccount;
  const email = location.state && location.state.email;

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      { loading ? <LandingPage /> : <LoginColumns isSignUp={isSignUp} defaultEmail={email} /> }
    </div>
  );
}

export default Login;
