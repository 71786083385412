import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import Translate from '~/components/display/translate';

import { ellipseArea, ellipseMedioLateralRange, ellipseAnteroPosteriorRange } from '~/utils/ellipse';

function EllipseStats({ ellipse }) {
  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
      <Grid>
        <Typography variant="body1">
          <Translate>ellipseConfidenceArea</Translate>
          {': '}
          {Math.round(ellipseArea(ellipse.rx, ellipse.ry))}
          {' mm²'}
        </Typography>
      </Grid>
      <Grid>
        <Typography variant="body1">
          <Translate>medioLateralRange</Translate>
          {': '}
          {Math.round(ellipseMedioLateralRange(ellipse.medioLateralWidth))}
          {' mm'}
        </Typography>
      </Grid>
      <Grid>
        <Typography variant="body1">
          <Translate>anteroPosteriorRange</Translate>
          {': '}
          {Math.round(ellipseAnteroPosteriorRange(ellipse.anteroPosteriorWidth))}
          {' mm'}
        </Typography>
      </Grid>
    </Grid>
  );
}

EllipseStats.propTypes = {
  ellipse: PropTypes.shape({
    rx: PropTypes.number,
    ry: PropTypes.number,
    medioLateralWidth: PropTypes.number,
    anteroPosteriorWidth: PropTypes.number,
  }).isRequired,
};

export default EllipseStats;
