import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import CircularProgress from './display/circular-progress';
import Translate from './display/translate';
import researchLogo from '../assets/images/research-logo.svg';

import { KlingonBlack } from '../utils/colors';

function LandingPage({ showText = false }) {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ height: '100vh' }}
    >
      <Grid item>
        <img src={researchLogo} alt="FeetMe Research Logo" />
      </Grid>
      <Grid item>
        <Typography
          sx={{
            mt: 2,
            minHeight: 30,
            fontWeight: 600,
            fontSize: '1.25rem',
            color: KlingonBlack[800],
          }}
        >
          { showText && (<Translate>welcomeOnFeetMeDashboard</Translate>) }
        </Typography>
      </Grid>
      <Grid item sx={{ mt: 2 }}>
        <CircularProgress />
      </Grid>
    </Grid>
  );
}

LandingPage.propTypes = {
  showText: PropTypes.bool,
};

export default LandingPage;
