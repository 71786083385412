import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import InputLabel from '~/components/display/input-label';
import Translate from '~/components/display/translate';

function Birthdate({
  required = false,
  onChange,
  value = null,
  ...props
}) {
  return (
    <FormControl>
      {/* There is no clear solution to add an InputLabel to the DatePicker component */}
      <InputLabel required={required}><Translate>birthdate</Translate></InputLabel>
      <DatePicker
        value={value !== null ? new Date(value) : null}
        onChange={v => onChange({ name: 'birthdate', value: v, error: v === null })}
        disableFuture
        sx={{ mt: '30px' }}
        {...props}
      />
    </FormControl>
  );
}

Birthdate.propTypes = {
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date),
};

export default Birthdate;
