import PropTypes from 'prop-types';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';

import TableContainer from './container';
import TablePagination from './pagination';

// todo could be rename to base table
function TableBase({
  Head,
  Row,
  data,
  HeadProps = {},
  RowProps = [],
  rowKey = i => i.key,
  hasPagination = false,
  hasSearch = false,
  inProgress = false,
  rowsPerPage = 10,
  total,
  onSelectAll = () => {},
  onSelect = undefined,
  selected = [],
  orderBy,
  order,
  page = 0,
  onRequestSort = () => {},
  placeholder = undefined,
  onChange = undefined,
}) {
  return (
    <TableContainer
      inProgress={inProgress}
      hasSearch={hasSearch}
      placeholder={placeholder}
      onChange={onChange}
    >
      <Head
        orderBy={orderBy}
        order={order}
        onRequestSort={onRequestSort}
        rowCount={data.length}
        numSelected={selected.length}
        onSelectAll={onSelectAll}
        {...HeadProps}
      />
      <TableBody>
        { data.map(i => (
          <Row
            key={rowKey(i)}
            isSelected={selected.find(j => rowKey(i) === rowKey(j)) !== undefined}
            onSelect={onSelect}
            elt={i}
            {...RowProps}
          />
        ))}
      </TableBody>
      { hasPagination && (
        <TableFooter>
          <TablePagination
            onChange={onChange}
            rowsPerPage={rowsPerPage}
            page={page}
            count={total}
          />
        </TableFooter>
      )}
    </TableContainer>
  );
}

TableBase.propTypes = {
  Head: PropTypes.func.isRequired,
  Row: PropTypes.func.isRequired,

  data: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
  })).isRequired,

  // eslint-disable-next-line react/forbid-prop-types
  HeadProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  RowProps: PropTypes.object,
  rowKey: PropTypes.func,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  page: PropTypes.number,
  hasPagination: PropTypes.bool,
  hasSearch: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  inProgress: PropTypes.bool,
  rowsPerPage: PropTypes.number,
  total: PropTypes.number.isRequired,
  onSelectAll: PropTypes.func,
  onSelect: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.object),
  onRequestSort: PropTypes.func,
};

export default TableBase;
