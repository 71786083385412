import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Common from './common';

import {
  setMemberOrga,
  updateMemberOrga,
  updateUser,
  fetchOrganisationsFromLabel,
  fetchOrga,
} from '@/firebase/firestore';

import useProfile from '../../../utils/hooks/useProfile';
import useLastInvitation from '~/utils/hooks/useLastInvitation';
import { difference } from '../../../utils/array';
import { useAppContext } from '~/components/AppContext';
import useUserEmail from '~/utils/hooks/useUserEmail';

function addAccessOrganisations(organisationsId, userId) {
  return Promise.all(organisationsId.map(orgaId => setMemberOrga(orgaId, userId)));
}

function removeAccessOrganisations(organisationsId, userId) {
  return Promise.all(organisationsId.map(orgaId => updateMemberOrga(
    orgaId,
    userId,
    { isValid: false },
  )));
}

// return only the organisation thare are not dealt with the labels
function filterOrgaFromLabels(memberOrganisationsId, organisationsIdFromLabels) {
  return (memberOrganisationsId || [])
    .filter(orgaId => !organisationsIdFromLabels.includes(orgaId));
}

function Edit({ member, onCancel, onDone }) {
  // the organisations of the member that are not controlled by a label, and
  // that the user has access to.
  const [memberOrganisations, setMemberOrganisations] = useState([]);
  // The current user might not have access to all labels of the member.
  // We're saving those labels to re-inject them when the user is updated.
  const [labelsWithoutAccess, setLabelsWithoutAccess] = useState([]);
  const [user] = useProfile();
  const [isLoading, setIsLoading] = useState(true);
  const [memberEmail, emailLoading] = useUserEmail(member.key);
  const [lastInvitation] = useLastInvitation(member.key);
  const { isProjectAdmin } = useAppContext();

  const handleSubmit = async ({
    organisations,
    email,
    labels,
    ...updatedMember
  }) => {
    // We do not need a specific workflow for labels update, it will be done
    // on the backend via a firestore watcher function.

    // Only retrieve the added and removed organisations
    const { added, removed } = difference(memberOrganisations, organisations);
    await removeAccessOrganisations(removed, member.key);
    await addAccessOrganisations(added, member.key);

    // merge back the labels that the user don't have access to
    await updateUser(member.key, { labels: [...labels, ...labelsWithoutAccess], ...updatedMember });

    onDone();
  };

  useEffect(() => {
    async function wrapper() {
      if (isProjectAdmin) {
        // retrieve all orgas from each labels
        const orgasFromLabels = (
          await Promise.all((member.labels || [])
            .map(labelId => fetchOrganisationsFromLabel(labelId)))
        ).flat().map(i => i.key);

        // exclude the organisations that are tracked with the labels
        setMemberOrganisations(filterOrgaFromLabels(member.organisations, orgasFromLabels));
      } else {
        // keep track of the organisations (with `memberOrganisations` state)
        // that the user can see (has access) and that are not tracked by a label
        const commonOrgas = difference(member.organisations, user.organisations).intersection;
        const orgaFetched = await Promise.all(commonOrgas.map(orgaId => fetchOrga(orgaId)));
        const memberLabels = member.labels || [];
        setMemberOrganisations(orgaFetched.filter(i => i !== undefined)
          .filter(orga => (orga.labels || [])
            .filter(labelId => memberLabels.includes(labelId)).length === 0)
          .map(i => i.key));

        setLabelsWithoutAccess(difference(member.labels, user.labels).removed);
      }
    }

    setIsLoading(true);
    wrapper();
    setIsLoading(false);
  }, [user, member.labels, member.organisations]);

  if (isLoading === true) {
    return null;
  }

  const showInvitation = (emailLoading === false && memberEmail === '' && Boolean(lastInvitation));

  return (
    <Common
      isCreate={false}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      name={member.name}
      email={showInvitation ? lastInvitation.email : memberEmail}
      organisationsId={memberOrganisations}
      labelsId={(member.labels || []).filter(labelId => !labelsWithoutAccess.includes(labelId))}
      role={member.role}
      status={member.status}
      userId={member.key}
      showInvitation={showInvitation}
      lastInvitation={lastInvitation}
    />
  );
}

Edit.propTypes = {
  member: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    organisations: PropTypes.arrayOf(PropTypes.string),
    labels: PropTypes.arrayOf(PropTypes.string),
    role: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
};

export default Edit;
