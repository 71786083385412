import PropTypes from 'prop-types';

import Dispatcher from './Dispatcher';

import { createPatient } from '@/firebase/functions';

function Create({ onCancel, onDone, orgaId }) {
  const handleSubmit = async (data) => {
    const res = await createPatient({ orgaId, ...data });
    onDone(res.data.patientId);
  };

  return (
    <Dispatcher
      isCreate
      onCancel={onCancel}
      onSubmit={handleSubmit}
      orgaId={orgaId}
    />
  );
}

Create.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  orgaId: PropTypes.string.isRequired,
};

export default Create;
