import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';

import SectionHeaderPaper from '~/components/display/section-header-paper';
import PatientName from '~/components/PatientName';
import Email from '~/components/field/email';
import Invitation from '~/components/Invitation';
import Birthdate from '~/components/Birthdate';
import Translate from '~/components/display/translate';
import Gender from '~/components/field/gender';
import Status from '~/components/field/status';
import Common from './Common';

import { toISOFormat } from '~/utils/date';

function Health({
  isCreate,
  onCancel,
  onSubmit,
  name: nameInit = '',
  email: emailInit = '',
  birthdate: birthdateInit = null,
  gender: genderInit = 'unknown',
  status: statusInit = 'active',
  userId = '',
  showInvitation = false,
  lastInvitation = undefined,
  inProgress: inProgressInit = false,
}) {
  const [name, setName] = useState(nameInit);
  const [email, setEmail] = useState(emailInit);
  const [birthdate, setBirthdate] = useState(birthdateInit);
  const [gender, setGender] = useState(genderInit);
  const [status, setStatus] = useState(statusInit);
  const [inProgress, setInProgress] = useState(inProgressInit);
  const [isUniqueEmail, setIsUniqueEmail] = useState(true);
  const [errors, setErrors] = useState({});

  // return true if the form is not valid, false otherwise
  const validate = () => {
    // check each field passed its own validation
    if (Object.values(errors).find(i => i === true)) {
      return true;
    }

    if (!name) return true;
    if (!email) return true;
    if (!birthdate) return true;
    if (['male', 'female'].includes(gender) === false) return true;

    return false;
  };

  const handleSubmit = async () => {
    if (validate()) {
      return;
    }

    setInProgress(true);
    setIsUniqueEmail(true);
    try {
      await onSubmit({
        patient: {
          isAnonymous: false,
          name,
          birthdate,
          gender,
        },
        email,
        status,
      });
    } catch (err) {
      if (err.code === 'functions/already-exists') {
        setErrors({ ...errors, email: true });
        setIsUniqueEmail(false);
      } else {
        throw err;
      }
    }
    setInProgress(false);
  };

  const handleChange = (change) => {
    setErrors({ ...errors, [change.name]: change.error });

    switch (change.name) {
      case 'name':
        setName(change.value);
        break;
      case 'email':
        setEmail(change.value);
        break;
      case 'birthdate':
        setBirthdate(change.value !== null ? toISOFormat(change.value) : null);
        break;
      case 'gender':
        setGender(change.value);
        break;
      case 'status':
        setStatus(change.value);
        break;
      default:
        throw new Error(`Unknown change type: ${change.name}`);
    }
  };

  return (
    <Common
      isCreate={isCreate}
      disabled={validate()}
      inProgress={inProgress}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    >
      <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        <Grid>
          <PatientName
            required
            value={name}
            error={errors.name}
            onChange={handleChange}
          />
        </Grid>
        <Grid>
          <Email
            required
            error={errors.email}
            value={email}
            disabled={isCreate === false && showInvitation === false}
            onChange={handleChange}
            helperText={isUniqueEmail === false
              ? <Translate>emailAlreadyExists</Translate> : undefined}
          />
        </Grid>
        { (showInvitation && lastInvitation) && (
          <Grid>
            <Invitation {...lastInvitation} userId={userId} email={email} />
          </Grid>
        )}
        <Grid>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Birthdate
              required
              value={birthdate !== null ? new Date(birthdate) : null}
              onChange={handleChange}
            />
            <Box sx={{ width: '50%' }}>
              <Gender required value={gender} onChange={handleChange} />
            </Box>
          </Stack>
        </Grid>
        { (isCreate === false) && (
          <>
            <Grid>
              <SectionHeaderPaper title={<Translate>status</Translate>} />
            </Grid>
            <Grid>
              <Status value={status} onChange={handleChange} />
            </Grid>
          </>
        )}
      </Grid>
    </Common>
  );
}

Health.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isCreate: PropTypes.bool.isRequired,

  name: PropTypes.string,
  email: PropTypes.string,
  birthdate: PropTypes.string,
  gender: PropTypes.string,
  status: PropTypes.string,
  inProgress: PropTypes.bool,
  showInvitation: PropTypes.bool,
  lastInvitation: PropTypes.object,
  userId: PropTypes.string,
};

export default Health;
