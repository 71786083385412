import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/browser';

import theme from './material-ui-theme';
import Root from './root/index';
import AppContext from '~/components/AppContext';
import SnackbarProviderWrapper from './components/snackbar-provider-wrapper';
import {
  getLongTargetName,
  isProductionBuild,
  SENTRY_DSN,
  VERSION,
  COMMIT_HASH,
  BASE_URL,
} from './utils/env';

if (isProductionBuild()) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: getLongTargetName(),
    release: `${VERSION}@${COMMIT_HASH}`,
  });
}

const root = createRoot(document.getElementById('feetme-dial-app'));
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <SnackbarProviderWrapper>
      <BrowserRouter basename={BASE_URL}>
        <AppContext>
          <Root />
        </AppContext>
      </BrowserRouter>
    </SnackbarProviderWrapper>
  </ThemeProvider>,
);
