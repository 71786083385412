import PropTypes from 'prop-types';

import { formatTime } from '../../utils/display';
import { useAppContext } from '~/components/AppContext';

function TimeDisplay({ children, options = undefined }) {
  const { locale } = useAppContext();
  return formatTime(children, locale, options);
}

TimeDisplay.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.object,
};

export default TimeDisplay;
