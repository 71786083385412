import SvgIcon from '@mui/material/SvgIcon';

function Google(props) {
  return (
    <SvgIcon style={{ color: 'rgba(0, 0, 0, 0)' }} {...props}>
      <path d="M22.0013 12.2299C22.0013 11.5501 21.9451 10.8667 21.8251 10.198H12.2021V14.0487H17.7128C17.4841 15.2906 16.7493 16.3892 15.6735 17.0874V19.5859H18.9611C20.8917 17.8443 22.0013 15.2722 22.0013 12.2299Z" fill="#4285F4" />
      <path d="M12.2019 22C14.9535 22 17.2739 21.1145 18.9646 19.586L15.677 17.0874C14.7623 17.6974 13.5815 18.0428 12.2057 18.0428C9.5441 18.0428 7.28738 16.2828 6.47766 13.9165H3.08508V16.4922C4.81699 19.8689 8.34452 22 12.2019 22Z" fill="#34A853" />
      <path d="M6.47408 13.9166C6.04673 12.6746 6.04673 11.3298 6.47408 10.0879V7.51221H3.08525C1.63825 10.3378 1.63825 13.6667 3.08525 16.4923L6.47408 13.9166Z" fill="#FBBC04" />
      <path d="M12.2019 5.95792C13.6564 5.93587 15.0622 6.47233 16.1156 7.45705L19.0283 4.6021C17.184 2.90456 14.7361 1.97128 12.2019 2.00067C8.34452 2.00067 4.81699 4.13178 3.08508 7.51216L6.47391 10.0879C7.27989 7.71792 9.54036 5.95792 12.2019 5.95792Z" fill="#EA4335" />
    </SvgIcon>
  );
}

export default Google;
