import {
  doc,
  collection,
  getDoc,
  getDocs,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  query,
  orderBy,
  where,
  arrayUnion,
  arrayRemove,
  documentId,
  getFirestore,
  writeBatch,
  onSnapshot,
  Timestamp,
  getCountFromServer,
} from 'firebase/firestore';

import { getComponentApp } from './index';
import { getComponentUserId } from './auth';
import { saveDocumentKey, snapshotToArray } from './utils';

let DB = getFirestore(getComponentApp());
function refreshFirebaseApp() {
  DB = getFirestore(getComponentApp());
}

const FETCH_RECORDS_LIMIT = 20;

// COLLECTIONS
const USERS = 'users';
const SETTINGS = 'settings';
const INSOLES = 'insoles';
const RECORDS = 'records';
const WALKING_AIDS = 'walkingAids';
const STRIDES = 'dios';
const PRESSURES = 'pressures';
const COP = 'COP';
const BIPODAL_COP = 'bipodalCOP';
const ORGANISATIONS = 'organisations';
const MEMBERS = 'members';
const PROCESSED_RECORDS = 'processedRecords';
const PROJECT_SETTINGS = 'settings';
const LABELS = 'labels';
const PASSCODE_RESET = 'passcodeResets';
const DIAL_SETTINGS_DOCUMENT = 'dial';
const PROGRAMS = 'programs';

function convertFirestoreTimestampToDate(timestamp) {
  if ('_seconds' in timestamp) {
    // on previous version of firebase, the timestamp was an object with _seconds and _nanoseconds
    // eslint-disable-next-line no-underscore-dangle
    return new Timestamp(timestamp._seconds, timestamp._nanoseconds).toDate();
  }
  return new Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate();
}

//
// FETCH
//

// Records

async function fetchRecord(recordId) {
  const docSnap = await getDoc(doc(DB, RECORDS, recordId));
  return saveDocumentKey(docSnap);
}

function watchRecord(recordId, callback) {
  return onSnapshot(doc(DB, RECORDS, recordId), docSnap => callback(saveDocumentKey(docSnap)));
}

async function fetchInsoles(recordId) {
  const querySnapshot = await getDocs(collection(DB, RECORDS, recordId, INSOLES));
  return snapshotToArray(querySnapshot, 'macAddress');
}

async function fetchWalkingAids(recordId) {
  const querySnapshot = await getDocs(collection(DB, RECORDS, recordId, WALKING_AIDS));
  return snapshotToArray(querySnapshot);
}

async function fetchStride(recordId, strideId) {
  const docSnap = await getDoc(doc(DB, RECORDS, recordId, STRIDES, strideId));
  return saveDocumentKey(docSnap);
}

async function fetchPressures(recordId, strideId) {
  const docSnap = await getDoc(doc(DB, RECORDS, recordId, PRESSURES, strideId));
  return saveDocumentKey(docSnap);
}

async function fetchCops(recordId) {
  const copsRef = collection(DB, RECORDS, recordId, COP);
  const q = query(copsRef, orderBy('clientTimestamp', 'asc'));
  const querySnapshot = await getDocs(q);
  return snapshotToArray(querySnapshot);
}

async function fetchBipodalCops(recordId) {
  const querySnapshot = await getDocs(collection(DB, RECORDS, recordId, BIPODAL_COP));
  return snapshotToArray(querySnapshot);
}

async function fetchUserProfile(userId) {
  const docSnap = await getDoc(doc(DB, USERS, userId));
  return saveDocumentKey(docSnap);
}

function fetchCurrentUserProfile() {
  return fetchUserProfile(getComponentUserId());
}

async function fetchLastImport(userId) {
  const user = await fetchUserProfile(userId);
  if (!('lastImportId' in user)) {
    return undefined;
  }
  return fetchRecord(user.lastImportId);
}

async function fetchSettings() {
  const docSnap = await getDoc(
    doc(DB, USERS, getComponentUserId(), SETTINGS, DIAL_SETTINGS_DOCUMENT),
  );
  return saveDocumentKey(docSnap);
}

async function fetchOrga(orgaId) {
  const docSnap = await getDoc(doc(DB, ORGANISATIONS, orgaId));
  return saveDocumentKey(docSnap);
}

async function fetchOrganisations(isProjectAdmin = false) {
  const orgasRef = collection(DB, ORGANISATIONS);
  let q = query(orgasRef);

  if (!isProjectAdmin) {
    const user = await fetchCurrentUserProfile();
    // `in` supports up to 10 comparison values.
    if (user.organisations.length >= 10) {
      return Promise.all(user.organisations.map(orgaId => fetchOrga(orgaId)));
    }
    q = query(orgasRef, where(documentId(), 'in', user.organisations));
  }

  const querySnapshot = await getDocs(q);
  return snapshotToArray(querySnapshot);
}

async function fetchValidOrgaMembers(orgaId) {
  const membersRef = collection(DB, ORGANISATIONS, orgaId, MEMBERS);
  const q = query(membersRef, where('isValid', '==', true));
  const querySnapshot = await getDocs(q);
  return snapshotToArray(querySnapshot);
}

async function fetchProcessedRecord(recordId) {
  const docSnap = await getDoc(doc(DB, PROCESSED_RECORDS, recordId));
  return saveDocumentKey(docSnap);
}

async function fetchLabels(isProjectAdmin = false) {
  const labelsRef = collection(DB, LABELS);
  let q = query(labelsRef);

  if (!isProjectAdmin) {
    const userProfile = await fetchCurrentUserProfile();
    if (userProfile.labels !== undefined && userProfile.labels.length > 0) {
      q = query(labelsRef, where(documentId(), 'in', userProfile.labels));
    }
  }

  const querySnapshot = await getDocs(q);
  return snapshotToArray(querySnapshot);
}

async function fetchLabel(labelId) {
  const docSnap = await getDoc(doc(DB, LABELS, labelId));
  return saveDocumentKey(docSnap);
}

async function fetchMember(userId) {
  const docSnap = await getDoc(doc(DB, USERS, userId));
  return saveDocumentKey(docSnap);
}

async function fetchMembers(orgasId, isProjectAdmin = false) {
  let organisations = orgasId;

  if (isProjectAdmin) {
    const usersRef = collection(DB, USERS);
    let q;

    // a project admin can list all roles, except patients
    if (orgasId !== undefined && orgasId.length > 0) {
      q = query(usersRef, where('role', '!=', 'patient'), where('organisations', 'array-contains-any', orgasId));
    } else {
      q = query(usersRef, where('role', '!=', 'patient'));
    }

    const querySnapshot = await getDocs(q);
    return snapshotToArray(querySnapshot);
  }

  // no organisation is send, we retrieve the organisation of the user
  if (!orgasId || orgasId.length === 0) {
    const userProfile = await fetchCurrentUserProfile();
    organisations = userProfile.organisations || [];
  }

  // For all organisations, fetch the list of members
  // and then fetch the user document
  // We cannnot retrieve the list of users from the users/ collection because
  // we would want to do:
  // `where('role', 'in', ['member', 'orgaAdmin'])
  //  .where('organisations', 'array-contains-any', organisations)`
  // This is not allowed by Firestore, we cannot perform a query with a `in`
  // and a `array-contains-any`.
  // reference: https://firebase.google.com/docs/firestore/query-data/queries#array_membership

  const usersId = Array.from([...new Set((
    await Promise.all(organisations.map(orgaId => fetchValidOrgaMembers(orgaId).catch(() => [])))
  ).flat().map(i => i.key))]);

  return (
    await Promise.all(usersId.map(userId => fetchUserProfile(userId).catch(() => undefined)))
  ).filter(i => i !== undefined);
}

async function fetchPatients(isProjectAdmin, orgaId, blockedPatients) {
  const usersRef = collection(DB, USERS);
  let q = query(usersRef, where('role', '==', 'patient'), where('status', '==', blockedPatients ? 'inactive' : 'active'));
  if (orgaId !== undefined) {
    q = query(q, where('organisations', 'array-contains', orgaId));
  } else if (!isProjectAdmin && orgaId === undefined) {
    const userProfile = await fetchCurrentUserProfile();
    const organisations = userProfile.organisations || [];
    q = query(q, where('organisations', 'array-contains-any', organisations));
  }

  const querySnapshot = await getDocs(q);
  return snapshotToArray(querySnapshot);
}

async function fetchNumberPatients(isProjectAdmin, orgaId, blockedPatients) {
  const usersRef = collection(DB, USERS);
  let q = query(usersRef, where('role', '==', 'patient'), where('status', '==', blockedPatients ? 'inactive' : 'active'));
  if (orgaId !== undefined) {
    q = query(q, where('organisations', 'array-contains', orgaId));
  } else if (!isProjectAdmin && orgaId === undefined) {
    const userProfile = await fetchCurrentUserProfile();
    const organisations = userProfile.organisations || [];
    q = query(q, where('organisations', 'array-contains-any', organisations));
  }

  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
}

async function fetchProjectSettings(projectId) {
  const docSnap = await getDoc(doc(DB, PROJECT_SETTINGS, projectId));
  return saveDocumentKey(docSnap);
}

async function fetchOrgaLocales(orgaId) {
  const orga = await fetchOrga(orgaId);
  return orga.locales;
}

async function fetchOrganisationsFromLabel(labelId) {
  const orgasRef = collection(DB, ORGANISATIONS);
  const q = query(orgasRef, where('labels', 'array-contains', labelId));
  const querySnapshot = await getDocs(q);
  return snapshotToArray(querySnapshot);
}

async function fetchUsersFromLabel(labelId) {
  const usersRef = collection(DB, USERS);
  const q = query(usersRef, where('labels', 'array-contains', labelId));
  const querySnapshot = await getDocs(q);
  return snapshotToArray(querySnapshot);
}

async function fetchPasscodeReset(userId) {
  const docSnap = await getDoc(doc(DB, PASSCODE_RESET, userId));
  return saveDocumentKey(docSnap);
}

async function projectHasPrograms(projectId) {
  const programsRef = collection(DB, PROJECT_SETTINGS, projectId, PROGRAMS);
  const q = query(programsRef, where('isEnabled', '==', true));
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count > 0;
}

async function fetchProjectPrograms(projectId) {
  const programsRef = collection(DB, PROJECT_SETTINGS, projectId, PROGRAMS);
  const q = query(programsRef, where('isEnabled', '==', true));
  const querySnapshot = await getDocs(q);
  return snapshotToArray(querySnapshot);
}

async function fetchUserPrograms(userId) {
  const ref = collection(DB, USERS, userId, PROGRAMS);
  const q = query(ref, orderBy('refDate', 'desc'));
  const querySnapshot = await getDocs(q);
  return snapshotToArray(querySnapshot);
}

async function fetchProgram(userId, programId) {
  const docSnap = await getDoc(doc(DB, USERS, userId, PROGRAMS, programId));
  return saveDocumentKey(docSnap);
}

//
// SET
//
function setLabel(label) {
  return addDoc(collection(DB, LABELS), {
    createdAt: new Date(),
    ...label,
  });
}

function setMember(userId, member) {
  return setDoc(doc(DB, USERS, userId), {
    createdAt: new Date(),
    ...member,
  });
}

function setMemberOrga(orgaId, userId) {
  return setDoc(doc(DB, ORGANISATIONS, orgaId, MEMBERS, userId), {
    createdAt: new Date(),
    isValid: true,
  });
}

//
// UPDATE
//
function updateSettings(data) {
  const settingsRef = doc(DB, USERS, getComponentUserId(), SETTINGS, DIAL_SETTINGS_DOCUMENT);
  return setDoc(settingsRef, data, { merge: true });
}

function updateLocaleSettings(locale) {
  return updateSettings({ locale });
}

function updateOrga(orgaId, orga) {
  return updateDoc(doc(DB, ORGANISATIONS, orgaId), {
    updatedAt: new Date(),
    ...orga,
  });
}

function updateAddLabelToOrga(orgaId, labelId) {
  return updateDoc(doc(DB, ORGANISATIONS, orgaId), {
    labels: arrayUnion(labelId),
  });
}

function updateRemoveLabelToOrga(orgaId, labelId) {
  return updateDoc(doc(DB, ORGANISATIONS, orgaId), {
    labels: arrayRemove(labelId),
  });
}

function updateLabel(labelId, label) {
  return updateDoc(doc(DB, LABELS, labelId), {
    updatedAt: new Date(),
    ...label,
  });
}

function updateUser(userId, member) {
  return updateDoc(doc(DB, USERS, userId), {
    updatedAt: new Date(),
    ...member,
  });
}

function updateProfile(data) {
  return updateUser(getComponentUserId(), data);
}

function updateMemberOrga(orgaId, userId, data) {
  return updateDoc(doc(DB, ORGANISATIONS, orgaId, MEMBERS, userId), {
    updatedAt: new Date(),
    ...data,
  });
}

//
// Delete
//
function deleteLabel(labelId) {
  return deleteDoc(doc(DB, LABELS, labelId));
}

//
// Batch
//
function getBatch() {
  return writeBatch(DB);
}

function commitBatch(batch) {
  return batch.commit();
}

export {
  refreshFirebaseApp,
  FETCH_RECORDS_LIMIT,

  convertFirestoreTimestampToDate,

  fetchRecord,
  watchRecord,
  fetchLastImport,
  fetchStride,
  fetchPressures,
  fetchCops,
  fetchBipodalCops,
  fetchInsoles,
  fetchWalkingAids,
  fetchOrganisations,
  fetchOrga,
  fetchValidOrgaMembers,
  fetchCurrentUserProfile,
  fetchUserProfile,
  fetchSettings,
  fetchProcessedRecord,
  fetchLabels,
  fetchLabel,
  fetchMember,
  fetchMembers,
  fetchPatients,
  fetchNumberPatients,
  fetchProjectSettings,
  fetchOrgaLocales,
  fetchOrganisationsFromLabel,
  fetchUsersFromLabel,
  fetchPasscodeReset,
  projectHasPrograms,
  fetchProjectPrograms,
  fetchUserPrograms,
  fetchProgram,

  setLabel,
  setMember,
  setMemberOrga,

  updateSettings,
  updateLocaleSettings,
  updateOrga,
  updateAddLabelToOrga,
  updateRemoveLabelToOrga,
  updateLabel,
  updateUser,
  updateProfile,
  updateMemberOrga,

  deleteLabel,

  getBatch,
  commitBatch,
};
