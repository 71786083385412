//
// Internationalization file
//
// used to fetch the langfile, using the locale, and translate a string
//
import MessageFormat from '@messageformat/core';

import { getPathLangs } from './assets';
import { isProdTarget } from './env';

const mf = new MessageFormat();

// Fetch the langfile
//
// Use the current langFile if the locales match
function fetchLangFile(locale, isPdfEnv = false) {
  return fetch(`${getPathLangs(isPdfEnv)}/${locale}.json`)
    .then(response => response.json());
}

// Find the translated string from a key
//
// if a langfile[key] match returns it
// otherwise return the key
function findValue(key, langfile, canFail = false) {
  if (isProdTarget()) {
    return langfile[key] || key;
  }

  const translation = langfile[key];
  if (translation === undefined) {
    if (canFail === false) {
      // eslint-disable-next-line no-console
      console.error(`Missing translate for [${key}]`);
    }
    return key;
  }

  return translation;
}

function formatMessage(message, input) {
  if (input === undefined) {
    return message;
  }

  return mf.compile(message)(input);
}

function translate(key, langfile, input = undefined, canFail = false) {
  return formatMessage(findValue(key, langfile, canFail), input);
}

export {
  fetchLangFile,
  translate,
};
