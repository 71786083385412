import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';

import InsolePressures from '~/components/InsolePressures';
import LoadDisplay from './LoadDisplay';

import { getLeft, getRight } from '~/utils/insoles';

function InsolesPressuresDynamic({
  insoles,
  leftPressures = [],
  rightPressures = [],
  leftLoadHeelStrike = undefined,
  leftLoadToeOff = undefined,
  rightLoadHeelStrike = undefined,
  rightLoadToeOff = undefined,
  ...otherProps
}) {
  const leftInsole = getLeft(insoles);
  const rightInsole = getRight(insoles);

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        gap: '30px',
      }}
    >
      <Grid container direction="column" justifyContent="flex-start" alignItems="center">
        <Grid sx={{ minHeight: '52px' }}>
          <LoadDisplay value={leftLoadHeelStrike} />
        </Grid>
        <Grid>
          <InsolePressures
            insole={leftInsole}
            pressures={leftPressures}
            {...otherProps}
          />
        </Grid>
        <Grid sx={{ minHeight: '52px' }}>
          <LoadDisplay value={leftLoadToeOff} />
        </Grid>
      </Grid>
      <Grid container direction="column" justifyContent="flex-start" alignItems="center">
        <Grid sx={{ minHeight: '52px' }}>
          <LoadDisplay value={rightLoadHeelStrike} />
        </Grid>
        <Grid>
          <InsolePressures
            insole={rightInsole}
            pressures={rightPressures}
            {...otherProps}
          />
        </Grid>
        <Grid sx={{ minHeight: '52px' }}>
          <LoadDisplay value={rightLoadToeOff} />
        </Grid>
      </Grid>
    </Box>
  );
}

InsolesPressuresDynamic.propTypes = {
  insoles: PropTypes.arrayOf(PropTypes.object).isRequired,
  leftPressures: PropTypes.arrayOf(PropTypes.number),
  rightPressures: PropTypes.arrayOf(PropTypes.number),
  leftLoadHeelStrike: PropTypes.number,
  leftLoadToeOff: PropTypes.number,
  rightLoadHeelStrike: PropTypes.number,
  rightLoadToeOff: PropTypes.number,
};

export default InsolesPressuresDynamic;
