import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import Translate from '~/components/display/translate';
import MetricValue from '~/components/MetricValue';

import { Load } from '~/utils/metrics';
import { KlingonBlack } from '~/utils/colors';
import useUnit from '~/utils/hooks/useUnit';

function LoadDisplay({ value = undefined }) {
  const unit = useUnit(Load);

  if (value === undefined) {
    return null;
  }

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
      <Grid>
        <MetricValue metric={Load}>{value}</MetricValue>
      </Grid>
      <Grid>
        <Typography sx={{ color: KlingonBlack[600], fontSize: '0.875rem', fontWeight: 500 }}>
          <Translate canFail>{unit}</Translate>
        </Typography>
      </Grid>
    </Grid>
  );
}

LoadDisplay.propTypes = {
  value: PropTypes.number,
};

export default LoadDisplay;
