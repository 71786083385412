import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import Dialog from './dialog';
import Button from '../display/button';
import Translate from '../display/translate';

import { KlingonBlack } from '../../utils/colors';
import useUniqueId from '../../utils/hooks/useUniqueId';

function DefaultDialog({
  icon = null,
  open,
  title,
  description,
  onClick = () => undefined,
  buttonText = (<Translate>gotIt</Translate>),
  button = null,
  onScroll = undefined,
}) {
  const dialogTitleId = useUniqueId('dialog-title');

  return (
    <Dialog open={open} labelledby={dialogTitleId}>
      <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
        { icon && (
          <Grid item sx={{ mt: 5, mx: 'auto', mb: 0 }}>
            { icon }
          </Grid>
        )}
        <Grid item>
          <DialogTitle id={dialogTitleId} sx={{ fontWeight: 600, color: KlingonBlack[900], pt: 3 }}>
            { title }
          </DialogTitle>
        </Grid>
      </Grid>
      <DialogContent sx={{ color: KlingonBlack[700] }} onScroll={onScroll}>
        { description }
      </DialogContent>
      <DialogActions sx={{ pt: 3.5, pb: 3, px: 3 }}>
        { (button === null) ? (
          <Button fullWidth color="primary" variant="contained" onClick={() => onClick()}>
            { buttonText }
          </Button>
        ) : (button)}
      </DialogActions>
    </Dialog>
  );
}

DefaultDialog.propTypes = {
  icon: PropTypes.node,
  open: PropTypes.bool.isRequired,
  title: PropTypes.element.isRequired,
  description: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  buttonText: PropTypes.element,
  button: PropTypes.node,
  onScroll: PropTypes.func,
};

export default DefaultDialog;
