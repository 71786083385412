import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import Organisation from '../../display/organisation';
import Edit from '../../icons/edit';
import Trash from '../../icons/trash';

import { useAppContext } from '~/components/AppContext';

function Row({ elt, onDelete, onEdit }) {
  const { isProjectAdmin } = useAppContext();

  return (
    <TableRow sx={{ '&:hover td:last-child button': { visibility: 'visible' } }}>
      <TableCell>{ elt.name }</TableCell>
      <TableCell
        sx={{
          maxWidth: 250,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        { elt.description }
      </TableCell>
      <TableCell sx={{ '& > *': { m: 0.25 } }}>
        { (elt.organisations || []).map(i => <Organisation key={i.key} value={i.name} />) }
      </TableCell>
      <TableCell align="right" sx={{ '& button': { visibility: 'hidden' } }}>
        { isProjectAdmin && (
          <React.Fragment>
            <IconButton aria-label="edit" onClick={() => onEdit(elt)} size="large">
              <Edit />
            </IconButton>
            <IconButton aria-label="delete" onClick={() => onDelete(elt)} size="large">
              <Trash />
            </IconButton>
          </React.Fragment>
        )}
      </TableCell>
    </TableRow>
  );
}

Row.propTypes = {
  elt: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    organisations: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default Row;
