import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Translate from '../../components/display/translate';
import GaitCycleGraph from '../../components/graph/gait-cycle';
import GaitCycleMetric from './metric';
import CardHeader from '../../components/CardHeader';

import { computeMainBoxCoordinate, addErrorBox, emptyErrorBox } from '../../utils/graph';
import SingleSupportDuration from '../../utils/metrics/single-support-duration';
import SingleSupportPercentage from '../../utils/metrics/single-support-percentage';
import SupportPhase from '../../utils/metrics/support-phase';
import OscillationPhase from '../../utils/metrics/oscillation-phase';
import StrideDuration from '../../utils/metrics/stride-duration';
import { StarfleetBlue } from '../../utils/colors';
import { useAppContext } from '~/components/AppContext';

function GaitCycle({ data, isPdf = false }) {
  const { isStudyProject } = useAppContext();

  const showErrors = !isStudyProject;

  const lines = [
    {
      main: {
        isSupport: true,
        value: data.singleSupportPercentage.mean,
        deviation: data.singleSupportPercentage.std,
        fix: 10,
        widthNorm: 40,
        metric: SingleSupportPercentage,
      },
    }, {
      main: {
        isSupport: true,
        value: data.supportPhase.mean,
        deviation: data.supportPhase.std,
        fix: 0,
        widthNorm: [60, 65],
        metric: SupportPhase,
      },
    }, {
      main: {
        isSupport: false,
        value: data.oscillationPhase.mean,
        deviation: data.oscillationPhase.std,
        fix: 100,
        widthNorm: 40,
        reverse: true,
        metric: OscillationPhase,
      },
    }].map(line => ({ ...line, main: computeMainBoxCoordinate(line.main, showErrors) }))
    .map(line => (showErrors ? addErrorBox(line) : emptyErrorBox(line)))
    .filter(i => i.main.value !== 0 && i.main.value);

  const meanSingleSupportDuration = data.singleSupportDuration.mean;
  const stdSingleSupportDuration = data.singleSupportDuration.std;

  const meanStrideDuration = data.strideDuration.mean;
  const stdStrideDuration = data.strideDuration.std;

  return (
    <Card sx={{ width: 1 }}>
      <CardHeader
        title={<Translate>gaitCycle</Translate>}
        subheader={<Translate>averageParameters</Translate>}
        isPdf={isPdf}
      />
      <CardContent sx={[isPdf && { padding: 0 }]}>
        <GaitCycleGraph lines={lines} height={isPdf ? 275 : undefined} isPdf={isPdf} />
      </CardContent>
      <CardContent sx={[isPdf && { padding: 0 }]}>
        <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
          { meanSingleSupportDuration && (
            <Grid item>
              <GaitCycleMetric
                color={StarfleetBlue[400]}
                metric={SingleSupportDuration}
                mean={meanSingleSupportDuration}
                std={stdSingleSupportDuration}
                isPdf={isPdf}
              />
            </Grid>
          )}
          <Grid item>
            <GaitCycleMetric
              color={StarfleetBlue[600]}
              metric={StrideDuration}
              mean={meanStrideDuration}
              std={stdStrideDuration}
              isPdf={isPdf}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

GaitCycle.propTypes = {
  data: PropTypes.shape({
    singleSupportDuration: PropTypes.shape({
      mean: PropTypes.number,
      std: PropTypes.number,
    }).isRequired,
    singleSupportPercentage: PropTypes.shape({
      mean: PropTypes.number,
      std: PropTypes.number,
    }).isRequired,
    supportPhase: PropTypes.shape({
      mean: PropTypes.number,
      std: PropTypes.number,
    }).isRequired,
    oscillationPhase: PropTypes.shape({
      mean: PropTypes.number,
      std: PropTypes.number,
    }).isRequired,
    strideDuration: PropTypes.shape({
      mean: PropTypes.number,
      std: PropTypes.number,
    }).isRequired,
  }).isRequired,
  isPdf: PropTypes.bool,
};

export default GaitCycle;
