import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import PaperPage from '../components/display/paper-page';
import HeaderPaper from '../components/display/header-paper';
import Translate from '../components/display/translate';
import ButtonsForm from '../components/form/buttons';
import MemberName from '../components/field/member-name';
import Language from '../components/field/language';
import Email from '../components/field/email';
import SectionHeaderPaper from '../components/display/section-header-paper';
import PasscodeReset from '../components/passcode/passcode-reset';

import { updateProfile, updateLocaleSettings } from '@/firebase/firestore';
import { getCoreUser } from '@/firebase/auth';
import { useAppContext } from '~/components/AppContext';
import { LIST_LOCALES } from '../utils/l10n';

function Profile({
  userId = '',
  name: nameInit = '',
}) {
  const { locale, updateLocale, isStudyProject } = useAppContext();
  const [inProgress, setInProgress] = useState(false);
  const [name, setName] = useState(nameInit);
  const [localeField, setLocaleField] = useState(locale);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setName(nameInit);
    setLocaleField(locale);
  }, [nameInit, locale]);

  // return true if the form is not valid, false otherwise
  const validate = () => {
    // check each field passed its own valdation
    if (Object.values(errors).find(i => i === true)) {
      return true;
    }

    // check the initial state
    if (!name) return true;

    return false;
  };

  const handleChange = (change) => {
    setErrors({ ...errors, [change.name]: change.error });
    switch (change.name) {
      case 'name':
        setName(change.value);
        break;
      case 'locale':
        setLocaleField(change.value);
        break;
      default:
        throw new Error(`Unknown change type: ${change.name}`);
    }
  };

  const handleCancel = () => {
    setName(nameInit);
    setLocaleField(locale);
  };

  const handleSubmit = async () => {
    if (validate()) {
      return;
    }

    setInProgress(true);
    await updateProfile({ name });
    updateLocale(localeField);
    await updateLocaleSettings(localeField);
    setInProgress(false);
  };

  return (
    <PaperPage disableBottomPadding>
      <HeaderPaper
        title={<Translate>information</Translate>}
        subtitle={<Translate>defineWhichCredentialToUse</Translate>}
      />
      <form onSubmit={handleSubmit}>
        <MemberName
          required
          value={name}
          error={errors.name}
          onChange={handleChange}
        />
        <SectionHeaderPaper title={<Translate>credentials</Translate>} />
        <Email
          disabled
          value={getCoreUser().email}
          onChange={() => undefined}
        />
        <SectionHeaderPaper
          title={<Translate>languagePreference</Translate>}
          subtitle={<Translate>manageLanguageOnPlatform</Translate>}
        />
        <Language
          required
          options={LIST_LOCALES}
          value={localeField}
          onChange={handleChange}
        />
        { isStudyProject && (
          <>
            <SectionHeaderPaper
              title={<Translate>resetAccessToMobileApp</Translate>}
              subtitle={<Translate>youWillNeedInternetConnection</Translate>}
            />
            <PasscodeReset userId={userId} />
          </>
        )}
        <ButtonsForm
          inProgress={inProgress}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          sticky
        />
      </form>
    </PaperPage>
  );
}

Profile.propTypes = {
  userId: PropTypes.string,
  name: PropTypes.string,
};

export default Profile;
