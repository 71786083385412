import PropTypes from 'prop-types';

import RadioBlock from '../display/radio-block';
import Translate from '../display/translate';

function Gender({ onChange, ...props }) {
  return (
    <RadioBlock
      label={<Translate>gender</Translate>}
      onChange={ev => onChange({ name: 'gender', value: ev.target.value })}
      name="gender"
      radios={[
        // { key: 'unknown', label: (<Translate>notSpecified</Translate>) },
        { key: 'male', label: (<Translate>man</Translate>) },
        { key: 'female', label: (<Translate>woman</Translate>) },
      ]}
      {...props}
    />
  );
}

Gender.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf(['male', 'female', 'unknown']).isRequired,
};

export default Gender;
