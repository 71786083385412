import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { scaleLinear } from 'd3-scale';
import { bisector, extent } from 'd3-array';
import { curveMonotoneX } from 'd3-shape';
import { timeFormat } from 'd3-time-format';
import {
  Area,
  Graph,
  Rects,
  Gradient,
  MouseContainer,
  useGraphDimensions,
} from '@feetme/d3act';

import Axis from '../axis';
import Path from '../path';
import TooltipLine from './tooltip-line';
import TooltipRect from './tooltip-rect';

import { bisectData } from '../../../utils/d3';
import { KlingonBlack, StarfleetBlue } from '../../../utils/colors';
import useUniqueId from '../../../utils/hooks/useUniqueId';
import { applyTimeZone } from '../../../utils/date';

function VelocityCurve({
  data,
  timeZone,
  height = 250,
  minInactivity = 5 * 1000, // 5 seconds
  isPdf = false,
}) {
  const [ref, dimensions] = useGraphDimensions({ height });
  const patternId = useUniqueId('pattern');
  const gradientId = useUniqueId('gradient');
  // line tooltip/focus
  const [lineFocusOpacity, setLineFocusOpacity] = useState(0);
  const [tooltipTransform, setTooltipTransform] = useState('');
  const [tooltipLineData, setTooltipLineData] = useState({ duration: 0, velocity: 0 });
  const [circlePosition, setCirclePosition] = useState({});
  // rect tooltip/focus
  const [rectFocusOpacity, setRectFocusOpacity] = useState(0);
  const [tooltipRectTransform, setTooltipRectTransform] = useState('');
  const [tooltipRectData, setTooltipRectData] = useState({ start: 0, end: 0 });

  const xAccessor = d => d.x;
  const yAccessor = d => d.y;

  const xExtent = extent(data, xAccessor);
  const xScale = scaleLinear()
    .domain(xExtent)
    .rangeRound([0, dimensions.boundedWidth]);

  const yExtent = extent(data, yAccessor);
  const yScale = scaleLinear()
    .domain(yExtent)
    .rangeRound([dimensions.boundedHeight, 0]);

  const xAccessorScaled = (d, i) => xScale(xAccessor(d, i));
  const yAccessorScaled = (d, i) => yScale(yAccessor(d, i));

  const bisect = bisector(xAccessor).left;

  const handleMouseMouse = (evt, mouseX) => {
    const [d] = bisectData(bisect, data, mouseX, xScale.invert(mouseX), xAccessorScaled);

    if (d !== undefined) {
      const x = xAccessorScaled(d) + dimensions.marginLeft;
      const y = yAccessorScaled(d) + dimensions.marginTop;
      setLineFocusOpacity(1);
      setTooltipTransform(`translate(calc(-50% + ${x}px), calc(-100% + ${y}px - 15px))`);
      setCirclePosition({ x: d.x, y: d.y });
      setTooltipLineData({ duration: d.x - data[0].x, velocity: d.y });
    }
  };

  const handleRectEnter = (d) => {
    setLineFocusOpacity(0);
    setRectFocusOpacity(1);
    const x = xAccessorScaled(d) + dimensions.marginLeft;
    setTooltipRectTransform(`translate(calc(-50% + ${x}px + ${d.width / 2}px), calc(-50% - 15px))`);
    setTooltipRectData({ start: d.start, end: d.end });
  };

  const [paths, rects] = useMemo(() => {
    const resPaths = [];
    const resRects = [];
    let currentChunk = [];
    let previous;

    data.forEach((i) => {
      if (currentChunk.length === 0) {
        currentChunk = [i];
        previous = i;
        return;
      }

      if ((xAccessor(i) - xAccessor(previous)) < minInactivity) {
        currentChunk.push(i);
        previous = i;
        return;
      }

      resPaths.push(currentChunk);
      resRects.push({
        x: xAccessor(previous),
        y: 0,
        start: xAccessor(previous),
        end: xAccessor(i),
        width: xAccessorScaled(i) - xAccessorScaled(previous),
      });
      currentChunk = [i];
      previous = i;
    });
    resPaths.push(currentChunk);

    return [resPaths, resRects];
  }, [data.length, ...xScale.range()]);

  return (
    <div ref={ref} style={{ position: 'relative', height }}>
      { (data.length > 0) && (
        <TooltipLine
          opacity={lineFocusOpacity}
          transform={tooltipTransform}
          {...tooltipLineData}
        />
      )}
      { (rects.length > 1) && (
        <TooltipRect
          opacity={rectFocusOpacity}
          transform={tooltipRectTransform}
          {...tooltipRectData}
        />
      )}
      <Graph dimensions={dimensions}>
        <defs>
          <pattern id={patternId} patternUnits="userSpaceOnUse" width="10" height="10">
            <path
              d="M 0,10 l 10,-10 M -2.5,2.5 l 5,-5 M 7.5,12.5 l 5,-5"
              strokeWidth={2}
              stroke={KlingonBlack[300]}
              shapeRendering="auto"
              strokeLinecap="square"
            />
          </pattern>
          <Gradient
            id={gradientId}
            stops={[
              { offset: '0%', color: '#A7D0FF', opacity: '1' },
              { offset: '100%', color: StarfleetBlue['000'], opacity: '0' },
            ]}
            x1={0}
            x2={0}
            y1={0}
            y2={1}
          />
        </defs>
        <Axis
          dimension="x"
          scale={xScale}
          keyAccessor={d => d}
          formatTick={d => timeFormat('%H:%M')(applyTimeZone(d, timeZone))}
        />
        <Axis
          dimension="y"
          scale={yScale}
          keyAccessor={d => d}
          strokeDasharray="1, 10"
          strokeWidth={2}
        />
        { paths.map(path => (
          <g key={path[0].x}>
            <Area
              data={path}
              xAccessor={xAccessorScaled}
              y0Accessor={dimensions.boundedHeight}
              y1Accessor={yAccessorScaled}
              interpolation={curveMonotoneX}
              fill={`url(#${gradientId})`}
            />
            <Path
              data={path}
              stroke={StarfleetBlue[400]}
              xAccessor={xAccessorScaled}
              yAccessor={yAccessorScaled}
              strokeWidth={isPdf ? 1.5 : 3}
            />
          </g>
        ))}
        <circle
          cx={xAccessorScaled(circlePosition)}
          cy={yAccessorScaled(circlePosition)}
          opacity={lineFocusOpacity}
          r={4}
          strokeWidth={3}
          stroke={StarfleetBlue[400]}
          fill="white"
        />
        { (data.length > 0) && (
          <MouseContainer
            rootRef={ref}
            onMouseEnter={() => setLineFocusOpacity(1)}
            onMouseLeave={() => setLineFocusOpacity(0)}
            onMouseMove={handleMouseMouse}
          />
        )}
        <Rects
          data={rects}
          keyAccessor={d => d.x}
          xAccessor={xAccessorScaled}
          yAccessor={yAccessor}
          widthAccessor={d => d.width}
          heightAccessor={dimensions.boundedHeight}
          opacity="0.7"
          fillAccessor={`url(#${patternId})`}
          onMouseEnter={handleRectEnter}
          onMouseLeave={() => setRectFocusOpacity(0)}
        />
      </Graph>
    </div>
  );
}

VelocityCurve.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  })).isRequired,
  timeZone: PropTypes.string.isRequired,
  height: PropTypes.number,
  minInactivity: PropTypes.number,
  isPdf: PropTypes.bool,
};

export default VelocityCurve;
