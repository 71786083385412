import PropTypes from 'prop-types';

import DownloadIcon from '~/components/icons/download';
import Translate from '~/components/display/translate';
import PDFGenerateButton from '~/components/PDFGenerateButton';

import { generateQrCodePdf } from '@/firebase/functions';
import { useAppContext } from '~/components/AppContext';
import { toISOFormat } from '~/utils/date';
import { StarfleetBlue } from '~/utils/colors';

function QrCodeDownload({ orgaId, patientId, patientResourceId }) {
  const { locale } = useAppContext();

  const generate = () => generateQrCodePdf({
    orgaId,
    patientResourceId,
    locale,
  });

  return (
    <PDFGenerateButton
      generate={generate}
      filename={`qr-code_${patientId}_${toISOFormat(new Date())}.pdf`}
      startIcon={<DownloadIcon />}
      sx={{
        background: StarfleetBlue['000'],
        color: StarfleetBlue[400],
        '&:hover': {
          background: StarfleetBlue[100],
        },
      }}
    >
      <Translate>downloadPDFVersion</Translate>
    </PDFGenerateButton>
  );
}

QrCodeDownload.propTypes = {
  orgaId: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired,
  patientResourceId: PropTypes.string.isRequired,
};

export default QrCodeDownload;
