import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Translate from '~/components/display/translate';
import {
  OrionTeal,
  ContinuumYellow,
  CadetOrange,
  PhaserRed,
} from '~/utils/colors';

function LimpingIndexLegend() {
  const values = [
    { name: 'noLimping', color: OrionTeal[200] },
    { name: 'slight', color: ContinuumYellow[300] },
    { name: 'moderate', color: CadetOrange[300] },
    { name: 'severe', color: PhaserRed[500] },
  ];

  return (
    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
      { values.map(({ name, color }) => (
        <Stack key={name} direction="row" gap={0.5} alignItems="center">
          <Box
            sx={{
              width: 16,
              height: 16,
              bgcolor: color,
              borderRadius: '2px',
            }}
          />
          <Typography>
            <Translate>{name}</Translate>
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
}

export default LimpingIndexLegend;
