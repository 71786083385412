import { amber } from '@mui/material/colors';

import {
  StarfleetBlue,
  KlingonBlack,
} from './colors';

const COLORMAP_GRADIENT = [
  { value: 0, color: 'white' },
  { value: 0.4, color: 'blue' },
  { value: 0.6, color: 'cyan' },
  { value: 0.7, color: 'lime' },
  { value: 0.8, color: 'yellow' },
  { value: 1, color: 'red' },
];

const PRESSURE_GRADIENT = [
  'white',
  '#22FFFF',
  '#04FF73',
  '#39FC00',
  '#DCF300',
  '#FFB600',
  '#FF6900',
  '#FF0000',
];

const palette = {
  primary: {
    main: StarfleetBlue[400],
  },
  secondary: {
    main: amber[700],
    light: '#ffd149',
    dark: '#c67100',
    contrastText: '#000000',
  },
  background: {
    default: KlingonBlack['000'],
  },
};

function getLeftColor() {
  return StarfleetBlue[400];
}

function getRightColor() {
  return StarfleetBlue[100];
}

function getCompliancePhaseColor(phaseIndex) {
  if (phaseIndex === 0) {
    return StarfleetBlue[400];
  }

  return StarfleetBlue[900];
}

export default palette;

export {
  COLORMAP_GRADIENT,
  PRESSURE_GRADIENT,
  getLeftColor,
  getRightColor,
  getCompliancePhaseColor,
};
