import PropTypes from 'prop-types';

import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import CancelIcon from '@mui/icons-material/Cancel';

import Panel from '../Panel';
import Translate from '../display/translate';

function CriticalityPanel({ level, thresholds = null }) {
  if (level < thresholds[0]) {
    return (
      <Panel
        Icon={CancelIcon}
        color="black"
        title={<Translate>outCriticalityForPatient</Translate>}
        text={<Translate>outCriticalityForPatientText</Translate>}
      />
    );
  }

  if (level >= thresholds[0] && level < thresholds[1]) {
    return (
      <Panel
        Icon={WarningIcon}
        color="error"
        title={<Translate>highCriticalityForPatient</Translate>}
        text={<Translate>highCriticalityForPatientText</Translate>}
      />
    );
  }

  if (level >= thresholds[1] && level < thresholds[2]) {
    return (
      <Panel
        Icon={WarningIcon}
        color="warning"
        title={<Translate>mediumCriticalityForPatient</Translate>}
        text={<Translate>mediumCriticalityForPatientText</Translate>}
      />
    );
  }

  if (level >= thresholds[2]) {
    return (
      <Panel
        Icon={InfoIcon}
        color="success"
        title={<Translate>lowCriticalityForPatient</Translate>}
        text={<Translate>lowCriticalityForPatientText</Translate>}
      />
    );
  }
}

CriticalityPanel.propTypes = {
  level: PropTypes.number.isRequired,
  thresholds: PropTypes.arrayOf(PropTypes.number),
};

export default CriticalityPanel;
