import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import { KlingonBlack } from '../../utils/colors';

function ComplianceRecordValue({
  title,
  Icon,
  iconProps = null,
  value,
}) {
  return (
    <Grid container direction="column" alignItems="center">
      <Grid>
        <Typography sx={{ fontWeight: 700, fontSize: '2rem' }}>{ value }</Typography>
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Icon {...iconProps} />
        </Grid>
        <Grid>
          <Typography sx={{ fontWeight: 500, color: KlingonBlack[900] }}>{title}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

ComplianceRecordValue.propTypes = {
  title: PropTypes.node.isRequired,
  Icon: PropTypes.any.isRequired,
  iconProps: PropTypes.object,
  value: PropTypes.number.isRequired,
};

export default ComplianceRecordValue;
