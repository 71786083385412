import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InsoleView } from '@feetme/insole-view-react';
import { InsoleMesh } from '@feetme/pressure-interpolation';

import useStaticRotation from '~/utils/hooks/useStaticRotation';
import { getInsoleDataUrl, getInputCoordinateUrl } from '~/utils/insoles';

async function fetchInputCoordinate(size) {
  const response = await fetch(getInputCoordinateUrl(size));
  return response.json();
}

async function fetchInsoleData(size) {
  const response = await fetch(getInsoleDataUrl(size));
  return response.json();
}

function InsolePressures({
  insole = {},
  pressures,
  scale,
  isStatic = false,
  meshX,
  meshY,
  arcRadius = 1.7,
  height = 400,
  ...otherProps
}) {
  const [insoleMesh, setInsoleMesh] = useState(undefined);
  const [rawOutline, setRawOutline] = useState([]);
  const [coordinatesLoaded, setCoordinatesLoaded] = useState(false);
  const { side, size } = insole;

  useEffect(() => {
    async function loadInsoleData() {
      const [insoleData, inputCoordinates] = await Promise.all([
        fetchInsoleData(size),
        fetchInputCoordinate(size),
      ]);
      setRawOutline(insoleData.outline);
      setInsoleMesh(new InsoleMesh(inputCoordinates, 3, meshX, meshY));
      setCoordinatesLoaded(true);
    }

    if (size !== undefined) {
      loadInsoleData();
    }
  }, [size]);

  const outline = useStaticRotation(
    rawOutline,
    insoleMesh,
    isStatic,
  );

  const outputCoordinates = useStaticRotation(
    insoleMesh === undefined ? [] : insoleMesh.getOutputCoordinates(),
    insoleMesh,
    isStatic,
  );

  if (!coordinatesLoaded) {
    return null;
  }

  return (
    <InsoleView
      insoleOutline={outline}
      sensorOutline={[]}
      cells={[]}
      side={side}
      disableMask
      enableMaskInsole
      disableSensorOutline
      enableInsoleOutline
      disableCOP
      displayCopStd={false}
      heatmapValues={pressures}
      heatmapPosition="interpolation"
      cellColorScale={scale}
      arcRadius={arcRadius}
      interpolationCoordinates={outputCoordinates}
      height={height}
      {...otherProps}
    />
  );
}

InsolePressures.propTypes = {
  insole: PropTypes.shape({
    side: PropTypes.string,
    size: PropTypes.number,
  }),
  pressures: PropTypes.arrayOf(PropTypes.number).isRequired,
  cop: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  scale: PropTypes.func.isRequired,
  copStd: PropTypes.arrayOf(PropTypes.shape({
    x0: PropTypes.number,
    x1: PropTypes.number,
    y: PropTypes.number,
  })),
  isStatic: PropTypes.bool,
  meshX: PropTypes.number.isRequired,
  meshY: PropTypes.number.isRequired,
  arcRadius: PropTypes.number,
  height: PropTypes.number,
};

export default InsolePressures;
