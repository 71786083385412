import PropTypes from 'prop-types';

import NoData from '../display/no-data';

import svg from '../../assets/images/patient.svg';

function NoPatients({ linkTo = undefined, blockedPatients = false }) {
  return (
    <NoData
      svg={svg}
      svgAlt="patient"
      title={blockedPatients ? 'youHaveNoBlockedPatients' : 'youHaveNoPatients'}
      description={blockedPatients ? 'noBlockedPatientsDescription' : 'patientProfileDescription'}
      buttonText={blockedPatients ? undefined : 'createPatient'}
      linkTo={blockedPatients ? undefined : linkTo}
    />
  );
}

NoPatients.propTypes = {
  linkTo: PropTypes.string,
  blockedPatients: PropTypes.bool,
};

export default NoPatients;
