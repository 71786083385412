import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import SupportPhaseGraph from './support-phase';
import SingleSupportPercentageGraph from './single-support-percentage';
import DoubleSupportPercentageGraph from './double-support-percentage';
import CardHeader from '../../components/CardHeader';

import Translate from '../../components/display/translate';
import { KlingonBlack } from '~/utils/colors';
import { getLeftColor, getRightColor } from '~/utils/palette';

function Asymmetry({
  supportPhase,
  singleSupportPercentage,
  doubleSupportPercentage,
  isPdf = false,
}) {
  return (
    <Card sx={{ width: 1 }}>
      <CardHeader
        title={<Translate>asymmetry</Translate>}
        subheader={<Translate>averageParameters</Translate>}
        isPdf={isPdf}
      />
      <CardContent sx={[isPdf && { padding: 0 }]}>
        <Grid container direction="row" justifyContent="space-around" alignItems="flex-start">
          <Grid item xs={3}>
            <SupportPhaseGraph
              meanLeft={supportPhase.meanLeft}
              meanRight={supportPhase.meanRight}
              stdLeft={supportPhase.stdLeft}
              stdRight={supportPhase.stdRight}
              height={isPdf ? 200 : undefined}
              isPdf={isPdf}
            />
          </Grid>
          <Grid item xs={3}>
            <SingleSupportPercentageGraph
              meanLeft={singleSupportPercentage.meanLeft}
              meanRight={singleSupportPercentage.meanRight}
              stdLeft={singleSupportPercentage.stdLeft}
              stdRight={singleSupportPercentage.stdRight}
              height={isPdf ? 200 : undefined}
              isPdf={isPdf}
            />
          </Grid>
          <Grid item xs={3}>
            <DoubleSupportPercentageGraph
              meanLeft={doubleSupportPercentage.meanLeft}
              meanRight={doubleSupportPercentage.meanRight}
              stdLeft={doubleSupportPercentage.stdLeft}
              stdRight={doubleSupportPercentage.stdRight}
              height={isPdf ? 200 : undefined}
              isPdf={isPdf}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
          <Grid item sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <svg width="6" height="6">
              <circle cx="3" cy="3" r="3" fill={getLeftColor()} />
            </svg>
            <Typography sx={{ color: KlingonBlack[600], fontSize: isPdf ? '0.5rem' : '0.8125rem' }}>
              <Translate>Left</Translate>
            </Typography>
          </Grid>
          <Grid item sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <svg width="6" height="6">
              <circle cx="3" cy="3" r="3" fill={getRightColor()} />
            </svg>
            <Typography sx={{ color: KlingonBlack[600], fontSize: isPdf ? '0.5rem' : '0.8125rem' }}>
              <Translate>Right</Translate>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

Asymmetry.propTypes = {
  supportPhase: PropTypes.shape({
    meanLeft: PropTypes.number,
    meanRight: PropTypes.number,
    stdLeft: PropTypes.number,
    stdRight: PropTypes.number,
  }).isRequired,
  singleSupportPercentage: PropTypes.shape({
    meanLeft: PropTypes.number,
    meanRight: PropTypes.number,
    stdLeft: PropTypes.number,
    stdRight: PropTypes.number,
  }).isRequired,
  doubleSupportPercentage: PropTypes.shape({
    meanLeft: PropTypes.number,
    meanRight: PropTypes.number,
    stdLeft: PropTypes.number,
    stdRight: PropTypes.number,
  }).isRequired,
  isPdf: PropTypes.bool,
};

export default Asymmetry;
