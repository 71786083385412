import PropTypes from 'prop-types';
import { Axis } from '@feetme/d3act';

import { KlingonBlack } from '../../utils/colors';

function CustomAxis({
  dimension,
  textTickStyle = () => {},
  ...otherProps
}) {
  return (
    <Axis
      fullTick={dimension === 'y'}
      textTickStyle={(d, i) => ({
        letterSpacing: '0.03em',
        fontSize: '12px',
        fill: KlingonBlack[600],
        strokeWidth: 0,
        ...textTickStyle(d, i),
      })}
      labelStyle={{
        fill: KlingonBlack[600],
        strokeWidth: 0,
      }}
      stroke={KlingonBlack[500]}
      dimension={dimension}
      {...otherProps}
    />
  );
}

CustomAxis.propTypes = {
  dimension: PropTypes.oneOf(['x', 'y']).isRequired,
  textTickStyle: PropTypes.func,
};

export default CustomAxis;
