import { useMemo } from 'react';
import {
  Link,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import Grid from '@mui/material/Grid';

import Breadcrumbs from '~/components/Breadcrumbs';
import Tabs from '../components/display/tabs';
import Tab from '../components/display/tab';
import TabPanel from '../components/display/tab-panel';
import Translate from '../components/display/translate';

import NewMenu from './new-menu';
import ContextMenu from './context-menu';
import TabMember from './tab-member';
import PatientList from '../components/PatientList';
import TabOrganisationEdit from './tab-organisation-edit';

import useOrganisation from '../utils/hooks/useOrganisation';
import { useAppContext } from '~/components/AppContext';

function Organisation() {
  const { orgaId } = useParams();
  const { pathname } = useLocation();
  const [organisation, isLoadingOrga] = useOrganisation(orgaId);
  const { isStudyProject, isAdmin, isProjectAdmin } = useAppContext();
  const navigate = useNavigate();
  const match = useMemo(() => ([
    ['patients', matchPath({ path: '/organisations/:orgaId/patients/', end: true }, pathname)],
    ['blocked-patients', matchPath({ path: '/organisations/:orgaId/blocked-patients/', end: true }, pathname)],
    ['members', matchPath({ path: '/organisations/:orgaId/members/', end: true }, pathname)],
    ['edit', matchPath({ path: '/organisations/:orgaId/edit', end: true }, pathname)],
  ].find(i => i[1] !== null)[0]), [pathname]);

  if (isLoadingOrga === false && Object.keys(organisation).length === 0) {
    navigate('/organisations/', { replace: true });
  }

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Grid item>
          <Breadcrumbs
            links={[
              { type: 'organisation', to: '/organisations/' },
              { type: 'id', value: organisation.name, to: `/organisations/${orgaId}` },
            ]}
          />
        </Grid>
        <Grid item xs />
        <Grid item>
          <NewMenu orgaId={orgaId} />
        </Grid>
        { !isProjectAdmin && (
          <Grid item>
            <ContextMenu orgaId={orgaId} />
          </Grid>
        )}
      </Grid>
      <Tabs value={match}>
        <Tab
          label={<Translate>patientActive</Translate>}
          value="patients"
          to={`/organisations/${orgaId}/patients/`}
          component={Link}
        />
        <Tab
          label={<Translate>{ isStudyProject ? 'patientBlocked' : 'patientArchived' }</Translate>}
          value="blocked-patients"
          to={`/organisations/${orgaId}/blocked-patients/`}
          component={Link}
        />
        { isAdmin && (
          <Tab
            label={<Translate>member</Translate>}
            value="members"
            to={`/organisations/${orgaId}/members/`}
            component={Link}
          />
        )}
        { isProjectAdmin && (
          <Tab
            label={<Translate>about</Translate>}
            value="edit"
            to={`/organisations/${orgaId}/edit`}
            component={Link}
          />
        )}
      </Tabs>
      <TabPanel value={match} active="patients" index={0}>
        <PatientList orgaId={orgaId} />
      </TabPanel>
      <TabPanel value={match} active="blocked-patients" index={1}>
        <PatientList orgaId={orgaId} blockedPatients />
      </TabPanel>
      { isAdmin && (
        <TabPanel value={match} active="members" index={2}>
          <TabMember orgaId={orgaId} />
        </TabPanel>
      )}
      { isProjectAdmin && (
        <TabPanel value={match} active="edit" index={3}>
          <TabOrganisationEdit organisation={organisation} />
        </TabPanel>
      )}
    </>
  );
}

export default Organisation;
