import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Table from '../index';
import Head from './head';
import Row from './row';

import { translate } from '../../../utils/i18n';
import { useAppContext } from '~/components/AppContext';

function Members({ members, blockedMembers = false, ...props }) {
  const { file } = useAppContext();
  const navigate = useNavigate();
  const translatedPlaceholder = translate('search', file);

  const handleClick = userId => navigate(`/members/${userId}/edit`);

  return (
    <Table
      {...props}
      defaultOrderBy="name"
      placeholder={translatedPlaceholder}
      Head={Head}
      Row={Row}
      data={members}
      hasPagination
      HeadProps={{ blockedMembers }}
      RowProps={{ onClick: handleClick, blockedMembers }}
    />
  );
}

Members.propTypes = {
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
  blockedMembers: PropTypes.bool,
};

export default Members;
