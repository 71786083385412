import PropTypes from 'prop-types';

import NoData from '~/components/display/no-data';

import timeoutSvg from '~/assets/images/interpolation-timeout.svg';
import pendingSvg from '~/assets/images/interpolation-loading.svg';

function InterpolationLoadingWrapper({
  children,
  isRecordAvailable = true,
  isInterpolationPending,
  hasInterpolationTimeout,
}) {
  if (!isRecordAvailable) {
    return (
      <NoData
        svg={pendingSvg}
        svgAlt="no record available"
        title="noDataAvailable"
        description="noDataAvailableDescription"
      />
    );
  }

  if (hasInterpolationTimeout) {
    return (
      <NoData
        svg={timeoutSvg}
        svgAlt="interpolation has timeout"
        title="heatmapGenerationImpossible"
        description="recordDataToolLargeToProcess"
      />
    );
  }

  if (isInterpolationPending) {
    return (
      <NoData
        svg={pendingSvg}
        svgAlt="interpolation is pending"
        title="generatingHeatmap"
        description="heatmapGenerationCanTakeSomeTime"
      />
    );
  }

  return children;
}

InterpolationLoadingWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isRecordAvailable: PropTypes.bool,
  isInterpolationPending: PropTypes.bool.isRequired,
  hasInterpolationTimeout: PropTypes.bool.isRequired,
};

export default InterpolationLoadingWrapper;
