import { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Translate from '~/components/display/translate';
import SectionHeaderPaper from '~/components/display/section-header-paper';
import ButtonsForm from '~/components/form/buttons';
import TextField from '~/components/display/textfield';
import Select from '~/components/display/select';
import PhasesFields from '~/components/PhasesFields';
import UpdateComplianceDialog from './UpdateComplianceDialog';
import InterventionTypeField, { interventionsWithProsthesis } from './InterventionTypeField';
import ProsthesisManufacturerField from './ProsthesisManufacturerField';
import SideSelectionField from './SideSelectionField';

import { updateProgram } from '~/firebase/functions';
import { toISOFormat } from '~/utils/date';
import { computePhasesStartDate, programHasRTM } from '~/utils/program';
import LimbSelectionField from './LimbSelectionField';

function Form({
  orgaId,
  patientResourceId,
  userId,
  programId: programIdInit = undefined,
  refDate: refDateInit = undefined,
  startDates: startDatesInit = [],
  userProgramPhases = [],
  interventionType: interventionTypeInit = null,
  prosthesisManufacturer: prosthesisManufacturerInit = '',
  limb: limbInit = '',
  side: sideInit = '',
  insuranceInfo: insuranceInfoInit = '',
  phoneNumber: phoneNumberInit = '',
  programs,
  onCancelForm,
  onDone,
}) {
  const [programId, setProgramId] = useState(programIdInit === undefined
    ? programs[0].key : programIdInit);
  const [refDate, setRefDate] = useState(refDateInit);
  const [startDates, setStartDates] = useState(startDatesInit);
  const [interventionType, setInterventionType] = useState(interventionTypeInit);
  const [prosthesisManufacturer, setProsthesisManufacturer] = useState(prosthesisManufacturerInit);
  const [limb, setLimb] = useState(limbInit);
  const [side, setSide] = useState(sideInit);
  const [insuranceInfo, setInsuranceInfo] = useState(insuranceInfoInit);
  const [phoneNumber, setPhoneNumber] = useState(phoneNumberInit);
  const [inProgress, setInProgress] = useState(false);
  const [errors, setErrors] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);

  const selectedProgram = programs.find(p => p.key === programId);

  // return true if the form is not valid, false otherwise
  const validate = () => {
    // check each field passed its own validation
    if (Object.values(errors).find(i => i === true)) {
      return true;
    }

    if (!refDate) return true;
    if (programHasRTM(selectedProgram)) {
      if (!interventionType) return true;
      if (interventionsWithProsthesis.includes(interventionType) && !prosthesisManufacturer) {
        return true;
      }
      if (!insuranceInfo) return true;
      if (!phoneNumber) return true;
    }

    return false;
  };

  const dateHasChanged = () => {
    if (refDate !== refDateInit) return true;
    if (startDates.some((startDate, idx) => startDate !== startDatesInit[idx])) return true;

    return false;
  };

  const handleDialogValidate = async () => {
    setInProgress(true);
    setDialogOpen(false);
    const data = {
      orgaId,
      patientResourceId,
      patientUserId: userId,
      refDate,
      programId,
      startDates,
    };
    if (programHasRTM(selectedProgram)) {
      data.interventionType = interventionType;
      if (prosthesisManufacturer !== '') {
        data.prosthesisManufacturer = prosthesisManufacturer;
      }
      if (limb !== '') {
        data.limb = limb;
      }
      if (side !== '') {
        data.side = side;
      }
      data.insuranceInfo = insuranceInfo;
      data.phoneNumber = phoneNumber;
    }
    await updateProgram(data);
    setInProgress(false);
    onDone();
  };

  const handleSubmit = async () => {
    if (validate()) {
      return;
    }

    if (programIdInit === undefined || !dateHasChanged()) {
      // no need to show the dialog, we're adding the program
      handleDialogValidate();
    } else {
      setDialogOpen(true);
    }
  };

  const handleCancel = () => {
    // the user don't have a default program we can clear the form
    if (programIdInit === undefined) {
      onCancelForm();
      return;
    }

    setRefDate(refDateInit);
    setStartDates(startDatesInit);
    setInterventionType(interventionTypeInit);
    setProsthesisManufacturer(prosthesisManufacturerInit);
    setLimb(limbInit);
    setSide(sideInit);
    setInsuranceInfo(insuranceInfoInit);
    setPhoneNumber(phoneNumberInit);
  };

  const handleChange = (change) => {
    setErrors({ ...errors, [change.name]: change.error });

    switch (change.name) {
      case 'refDate':
        setRefDate(change.value !== null ? toISOFormat(change.value) : null);
        setStartDates(computePhasesStartDate(
          programs.find(p => p.key === programId).phases,
          change.value,
        ));
        break;
      case 'programId':
        setProgramId(change.value);
        if (refDate !== undefined) {
          setStartDates(computePhasesStartDate(
            programs.find(p => p.key === change.value).phases,
            refDate,
          ));
        }
        break;
      case 'startDates':
        setStartDates(change.value);
        break;
      case 'interventionType':
        setInterventionType(change.value);
        setProsthesisManufacturer('');
        break;
      case 'prosthesisManufacturer':
        setProsthesisManufacturer(change.value);
        break;
      case 'limb':
        setLimb(change.value);
        break;
      case 'side':
        setSide(change.value);
        break;
      case 'insuranceInfo':
        setInsuranceInfo(change.value);
        break;
      case 'phoneNumber':
        setPhoneNumber(change.value);
        break;
      default:
        throw new Error(`Unknown change type: ${change.name}`);
    }
  };

  // the program refDate date picker is disabled if
  // the `refDateInit` is in the past
  const isDatePickerDisabled = (date) => {
    if (date === undefined) {
      return false;
    }
    const today = new Date();
    return (new Date(date) < today) && (date !== toISOFormat(today));
  };

  return (
    <>
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <SectionHeaderPaper title={<Translate>complianceTemplate</Translate>} />
        <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid xs={4}>
              <Select
                label={<Translate>template</Translate>}
                value={programId}
                onChange={ev => handleChange({ name: 'programId', value: ev.target.value })}
                options={programs.map(i => ({ key: i.key, label: i.name }))}
                disabled={programs.filter(i => i.isEnabled).length === 1}
                helperText={<Translate>templateCannotBeModified</Translate>}
              />
            </Grid>
            <Grid xs={4}>
              <DatePicker
                label={<Translate>referenceDate</Translate>}
                value={refDate !== undefined ? new Date(refDate) : null}
                disabled={isDatePickerDisabled(refDateInit)}
                onChange={value => handleChange({ name: 'refDate', value, error: refDate !== undefined && value === null })}
                slotProps={{
                  textField: {
                    error: errors.refDate,
                  },
                }}
              />
            </Grid>
          </Grid>
          { refDate !== undefined && (
            <Grid>
              <PhasesFields
                refDate={refDate}
                phases={selectedProgram.phases}
                startDates={startDates}
                userProgramPhases={userProgramPhases}
                onChange={handleChange}
              />
            </Grid>
          )}
        </Grid>
        { programHasRTM(selectedProgram) && (
          <>
            <SectionHeaderPaper title={<Translate>surgeryInformation</Translate>} />
            <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" rowSpacing={2}>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid xs={4}>
                  <InterventionTypeField
                    error={errors.interventionType}
                    value={interventionType}
                    onChange={handleChange}
                  />
                </Grid>
                { interventionsWithProsthesis.includes(interventionType) && (
                  <Grid xs={4}>
                    <ProsthesisManufacturerField
                      required
                      error={errors.prosthesisManufacturer}
                      value={prosthesisManufacturer}
                      onChange={handleChange}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid xs={4}>
                  <LimbSelectionField
                    value={limb}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid xs={4}>
                  <SideSelectionField
                    value={side}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <SectionHeaderPaper title={<Translate>additionalPatientInformation</Translate>} />
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid xs={4}>
                <TextField
                  required
                  error={errors.insuranceInfo}
                  value={insuranceInfo}
                  onChange={ev => handleChange({
                    name: 'insuranceInfo',
                    value: ev.target.value,
                    error: !ev.target.value,
                  })}
                  label={<Translate>insuranceInformation</Translate>}
                />
              </Grid>
              <Grid xs={4}>
                <MuiTelInput
                  required
                  defaultCountry="US"
                  error={errors.phoneNumber}
                  value={phoneNumber}
                  onChange={value => handleChange({
                    name: 'phoneNumber',
                    value,
                    error: !matchIsValidTel(value),
                  })}
                  label={<Translate>phoneNumber</Translate>}
                />
              </Grid>
            </Grid>
          </>
        )}
        <ButtonsForm
          inProgress={inProgress}
          disabled={validate()}
          isCreate={false}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          sticky
        />
      </form>
      <UpdateComplianceDialog
        open={dialogOpen}
        onCancel={() => setDialogOpen(false)}
        onValidate={handleDialogValidate}
      />
    </>
  );
}

Form.propTypes = {
  orgaId: PropTypes.string.isRequired,
  patientResourceId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  programId: PropTypes.string,
  refDate: PropTypes.string,
  startDates: PropTypes.arrayOf(PropTypes.string),
  userProgramPhases: PropTypes.arrayOf(PropTypes.object),
  programs: PropTypes.arrayOf(PropTypes.object).isRequired,
  insuranceInfo: PropTypes.string,
  phoneNumber: PropTypes.string,
  interventionType: PropTypes.string,
  prosthesisManufacturer: PropTypes.string,
  limb: PropTypes.string,
  side: PropTypes.string,
  onCancelForm: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
};

export default Form;
