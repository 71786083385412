import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';

import Button from '../components/display/button';
import Breadcrumbs from '~/components/Breadcrumbs';
import Translate from '../components/display/translate';
import PageProgress from '../components/display/page-progress';
import NoLabels from './no-labels';
import ListLabels from './list';

import useLabels from '../utils/hooks/useLabels';
import { useAppContext } from '~/components/AppContext';

function Labels() {
  const [labels, isLoading] = useLabels();
  const { isProjectAdmin } = useAppContext();

  const renderPage = () => {
    if (isLoading) {
      return (<PageProgress />);
    }

    if (labels.length === 0) {
      return (<NoLabels />);
    }

    return (<ListLabels />);
  };

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Grid item>
          <Breadcrumbs links={[{ type: 'label', to: '/labels/' }]} />
        </Grid>
        { (isProjectAdmin && labels.length > 0) && (
          <Grid item>
            <Button color="primary" component={Link} to="/labels/new">
              <Translate>new</Translate>
            </Button>
          </Grid>
        )}
      </Grid>
      { renderPage() }
    </>
  );
}

export default Labels;
