import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import Translate from '~/components/display/translate';
import DateDisplay from '~/components/display/date';
import RecordType from '~/components/display/record-type';

import { KlingonBlack } from '~/utils/colors';

function Header({ phaseName, startDate, endDate }) {
  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
      <Grid>
        <RecordType type="compliance-report" />
      </Grid>
      <Grid container direction="column" justifyContent="center" alignItems="flex-start">
        <Grid>
          <Typography
            component="span"
            sx={{
              fontSize: '1.5rem',
              fontWeight: 600,
              color: KlingonBlack[900],
              verticalAlign: 'middle',
            }}
          >
            <Translate input={{ PHASE_NAME: phaseName }}>phaseReportX</Translate>
          </Typography>
        </Grid>
        <Grid>
          <Typography sx={{ color: KlingonBlack[600], fontWeight: 500, fontSize: '0.875rem' }}>
            <Translate>from</Translate>
            {' '}
            <DateDisplay>{ new Date(startDate) }</DateDisplay>
            {' '}
            <Translate>to</Translate>
            {' '}
            <DateDisplay>{ new Date(endDate) }</DateDisplay>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  phaseName: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default Header;
