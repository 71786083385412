import PropTypes from 'prop-types';

import { formatDate } from '../../utils/display';
import { useAppContext } from '~/components/AppContext';

/**
 * Wrapper around Date().toLocaleDateString using stored locale
 */
export function DateDisplay({ children, options = undefined }) {
  const { locale } = useAppContext();
  return formatDate(children, locale, options);
}

DateDisplay.propTypes = {
  /**
   * The date to display
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),

  /**
   * Specific configuration in the formating of the date, see
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString for more info
   */
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.object,
};

export default DateDisplay;
