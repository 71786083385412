import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';

import FormHelperText from './form-helper-text';
import InputLabel from './input-label';
import InputBase from './input-base';

import useUniqueId from '../../utils/hooks/useUniqueId';

function Select({
  value,
  onChange,
  options,
  label = undefined,
  helperText = undefined,
  disabled = false,
  required = false,
  displayEmpty = false,
  fullWidth = true,
}) {
  const inputLabelId = useUniqueId('input-base-id');

  return (
    <FormControl disabled={disabled} required={required} fullWidth={fullWidth}>
      { label && (
        <InputLabel shrink id={inputLabelId}>{label}</InputLabel>
      )}
      <MuiSelect
        value={value}
        onChange={onChange}
        labelId={inputLabelId}
        displayEmpty={displayEmpty}
        input={<InputBase />}
      >
        { options.map(option => (
          <MenuItem
            key={option.key}
            value={option.key}
            disabled={option.disabled}
          >
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
      { helperText && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

Select.propTypes = {
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  })).isRequired,
  helperText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default Select;
