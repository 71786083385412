import PropTypes from 'prop-types';

import SvgIcon from '@mui/material/SvgIcon';
import { CadetOrange } from '../../utils/colors';

function StepCross({ color = CadetOrange[700], ...props }) {
  return (
    <SvgIcon {...props}>
      <path d="M 6.3049576,17.446831 10.099963,13.667972" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="m 6.3532341,13.667972 3.7950049,3.778859" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M 4.566911,11.116777 2.0023573,8.4191812 c -0.3363266,-0.3537791 -0.3363266,-0.9728959 0,-1.3266877 L 5.4077401,3.5104428 c 0.3363394,-0.3537792 0.9249236,-0.3537792 1.2612628,0 l 1.5975895,1.680467 c 0,0 0.7987886,0.9729086 1.303291,0.9729086 0.5045016,0 2.3543366,0 2.3543366,0 0.756811,0.044221 1.597627,0.7960126 1.933878,1.6362461 0,0 2.732717,5.4393885 3.279362,6.1912305 0.33625,0.442208 1.639554,1.238158 2.35426,1.459325 l 1.891901,0.928649 c 0.37833,0.221168 0.924974,0.84031 0.882895,1.990001 0,0.751842 -0.756785,2.388101 -2.01801,2.388101 -1.681633,0 -5.423355,0 -5.423355,0 0,0 -1.849821,0 -2.774809,-1.105584" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" fill="transparent" />
      <path d="m 14.519826,10.693771 h -2.20445" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M 16.445314,13.72374 H 14.41577" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}

StepCross.propTypes = {
  color: PropTypes.string,
};

export default StepCross;
