import SvgIcon from '@mui/material/SvgIcon';

function DoubleSupport(props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#clip0_3422_732)">
          <path d="M10.2629 11.0295L11.1021 15.1151C11.1021 15.1151 11.4497 16.7577 11.0811 18.5632C10.8245 19.8205 11.0473 21.0495 12.8654 20.8941C12.8654 20.8941 13.8745 20.7987 14.3761 20.7C15.0288 20.5718 16.8604 20.6786 17.725 21.0173C18.2242 21.1784 19.0866 21.3118 19.5813 21.0623C20.8072 20.4442 22.2457 19.7244 22.2457 19.7244C22.2457 19.7244 22.6484 19.4179 22.385 18.9072C22.1286 18.4096 21.8126 18.3989 21.687 18.3697C21.4017 18.3535 21.1582 18.4741 20.8616 18.5401C20.5158 18.6171 20.1465 18.4122 19.9865 18.3201L17.0956 16.9116C17.0956 16.9116 15.2648 16.1047 14.8031 14.0522L12.7292 3.99555" stroke="#B9D8FF" strokeWidth="1.26581" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <g clipPath="url(#clip1_3422_732)">
          <path d="M2.80759 9.75573L3.07076 13.8346C3.07076 13.8346 3.18677 15.4765 2.56592 17.1198C2.13369 18.264 2.18327 19.4845 4.0176 19.7281C4.0176 19.7281 5.03695 19.8545 5.55088 19.8684C6.2196 19.8868 8.03049 20.3834 8.8449 20.8929C9.32004 21.1542 10.161 21.4673 10.6893 21.3359C11.9981 21.0104 13.5331 20.6337 13.5331 20.6337C13.5331 20.6337 13.9776 20.428 13.7867 19.884C13.6009 19.354 13.2875 19.2757 13.1663 19.2208C12.8842 19.1439 12.6245 19.2064 12.3195 19.2055C11.9641 19.2044 11.6246 18.9293 11.478 18.8069L8.79375 16.8402C8.79375 16.8402 7.08176 15.6758 6.9096 13.6181L6.2536 3.57639" stroke="#2986FF" strokeWidth="1.26581" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_3422_732">
            <rect width="15" height="19" fill="white" transform="translate(9 3.06201)" />
          </clipPath>
          <clipPath id="clip1_3422_732">
            <rect width="15.2993" height="18.3224" fill="white" transform="matrix(0.977423 0.211292 -0.145543 0.989352 2.66675 1.88208)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default DoubleSupport;
