import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import Translate from '../components/display/translate';
import SignOut from '../components/icons/signout';
import RoleAvatar from '../components/avatar/role';
import Button from '~/components/display/button';

import useUniqueId from '../utils/hooks/useUniqueId';
import { useAppContext } from '~/components/AppContext';
import { signOutAll } from '@/firebase/apps';
import { KlingonBlack } from '../utils/colors';

function MenuAccount() {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const id = useUniqueId('menu-account');
  const { role, flushContext } = useAppContext();

  const handleClick = (ev) => {
    setAnchorEl(ev.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    await signOutAll();
    flushContext();
    navigate('/');
  };

  return (
    <React.Fragment>
      <Button
        onClick={handleClick}
        variant="text"
        endIcon={<RoleAvatar role={role} />}
        sx={{
          color: KlingonBlack[600],
          '&:hover': {
            bgcolor: '#f5f5f5',
            borderColor: '#c4c4c4',
          },
        }}
      >
        <Translate>{role}</Translate>
      </Button>
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <SignOut />
          </ListItemIcon>
          <Translate>signOut</Translate>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default MenuAccount;
