import SvgIcon from '@mui/material/SvgIcon';

function Apple(props) {
  return (
    <SvgIcon style={{ color: 'rgba(0, 0, 0, 0)' }} {...props}>
      <path d="M20.4933 15.5861C20.1908 16.2848 19.8328 16.928 19.418 17.5193C18.8526 18.3255 18.3896 18.8835 18.0329 19.1934C17.4798 19.702 16.8872 19.9625 16.2527 19.9773C15.7971 19.9773 15.2478 19.8477 14.6083 19.5847C13.9667 19.323 13.3771 19.1934 12.838 19.1934C12.2726 19.1934 11.6662 19.323 11.0175 19.5847C10.3679 19.8477 9.84462 19.9847 9.44451 19.9983C8.83601 20.0242 8.22949 19.7563 7.62408 19.1934C7.23767 18.8563 6.75435 18.2786 6.17536 17.4601C5.55415 16.586 5.04342 15.5725 4.64331 14.417C4.21481 13.1689 4 11.9603 4 10.7902C4 9.44984 4.28962 8.29383 4.86972 7.32509C5.32563 6.54696 5.93216 5.93316 6.69127 5.48255C7.45038 5.03195 8.2706 4.80233 9.1539 4.78763C9.63722 4.78763 10.271 4.93714 11.0587 5.23096C11.8441 5.52576 12.3484 5.67526 12.5695 5.67526C12.7348 5.67526 13.295 5.50045 14.2447 5.15195C15.1428 4.82874 15.9008 4.69492 16.5218 4.74764C18.2045 4.88343 19.4686 5.54675 20.3093 6.74177C18.8044 7.6536 18.06 8.93072 18.0748 10.5691C18.0884 11.8452 18.5514 12.9071 19.4612 13.7503C19.8735 14.1417 20.334 14.4441 20.8464 14.6589C20.7352 14.9812 20.618 15.2898 20.4933 15.5861ZM16.6341 0.400114C16.6341 1.40034 16.2687 2.33425 15.5404 3.19867C14.6614 4.22629 13.5982 4.8201 12.4453 4.7264C12.4306 4.60641 12.4221 4.48011 12.4221 4.3474C12.4221 3.38718 12.8401 2.35956 13.5824 1.51934C13.953 1.09392 14.4243 0.74019 14.9959 0.458013C15.5663 0.180046 16.1058 0.0263242 16.6132 0C16.628 0.133715 16.6341 0.267438 16.6341 0.400101V0.400114Z" fill="#343A40" />
    </SvgIcon>
  );
}

export default Apple;
