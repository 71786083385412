import { endOfDay } from 'date-fns';

import BaseMetric from './base';

const ONE_DAY_MS = 24 * 60 * 60 * 1000;

// for a level and a thresholds, return a value between [0, 3]
// 0: red
// 1: yellow
// 2: green
// 3: black
// this simplify the sort which need the black level (negatif level) to be at
// the end of the sort, in ascending order
function sameScale(level, thresholds) {
  if (level === null || thresholds === null) {
    return null;
  }

  if (level < thresholds[0]) {
    return 3;
  }

  if (level >= thresholds[0] && level < thresholds[1]) {
    return 0;
  }

  if (level >= thresholds[1] && level < thresholds[2]) {
    return 1;
  }

  return 2;
}

function computeLevelPhase(phase) {
  const {
    nbPeriodsDone,
    nbPeriodsToValidate,
    thresholds,
    startDate,
  } = phase;
  const today = new Date();

  // the phase hasn't started yet
  if (new Date(startDate) > today) {
    return null;
  }

  // if the user has validated the period, we want to return the highest
  // thresholds
  if (nbPeriodsDone >= nbPeriodsToValidate) {
    return thresholds[thresholds.length - 1];
  }

  const nbPeriods = phase.duration / phase.periodDuration;
  const currentPeriodIndex = Math.floor(
    (today - new Date(phase.startDate)) / (phase.periodDuration * ONE_DAY_MS),
  );
  let nbRemainingPeriods = nbPeriods - currentPeriodIndex;
  // if the current period has already been validated, we dont take it into
  // account in the `nbRemainingPeriods` since we already count it in the
  // `nbPeriodsToValidate`
  // See: https://feetme.atlassian.net/browse/SOF-593 for more info
  if (phase.periods
    && phase.periods[currentPeriodIndex]
    && phase.periods[currentPeriodIndex].validated) {
    nbRemainingPeriods -= 1;
  }

  return nbRemainingPeriods - (nbPeriodsToValidate - nbPeriodsDone);
}

function computeLevel(program) {
  if (program === undefined) {
    return null;
  }

  const today = new Date();
  const phase = program.phases.find(i => today >= new Date(i.startDate)
    && today <= endOfDay(new Date(i.endDate)));
  if (phase === undefined) {
    return null;
  }

  return computeLevelPhase(phase);
}

export default new BaseMetric({
  name: 'criticalityLevel',
});

export {
  sameScale as sameScaleCriticalityLevel,
  computeLevel as computeCriticalityLevel,
  computeLevelPhase as computeCriticalityLevelPhase,
};
