import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';

import Translate from '~/components/display/translate';
import CardHeader from '~/components/CardHeader';
import MetricRecord from '~/components/MetricRecord';

import TrackingAmount from '~/utils/metrics/tracking-amount';
import DoubleSupportParameter from './DoubleSupportParameter';
import SingleSupportParameter from './SingleSupportParameter';

function WalkingParametersCard({
  nbDays,
  phases,
  doubleSupportMeans,
  doubleSupportStds,
  singleSupportMeans,
  singleSupportStds,
}) {
  return (
    <Card sx={{ width: 1 }}>
      <CardHeader
        title={<Translate>walkingParameters</Translate>}
      />
      <CardContent>
        <Grid container direction="row" justifyContent="space-around">
          <Grid>
            <MetricRecord metric={TrackingAmount} value={nbDays} showUnit />
          </Grid>
          <Grid>
            <DoubleSupportParameter
              phases={phases}
              means={doubleSupportMeans}
              stds={doubleSupportStds}
            />
          </Grid>
          <Grid>
            <SingleSupportParameter
              phases={phases}
              means={singleSupportMeans}
              stds={singleSupportStds}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

WalkingParametersCard.propTypes = {
  nbDays: PropTypes.number.isRequired,
  phases: PropTypes.arrayOf(PropTypes.string).isRequired,
  doubleSupportMeans: PropTypes.arrayOf(PropTypes.number).isRequired,
  doubleSupportStds: PropTypes.arrayOf(PropTypes.number).isRequired,
  singleSupportMeans: PropTypes.arrayOf(PropTypes.object).isRequired,
  singleSupportStds: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default WalkingParametersCard;
