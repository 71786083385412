import { BASE_URL } from './env';

function getPathAssets(isPdfEnv = false) {
  let prefix = '';
  if (isPdfEnv && window.pdfData) {
    prefix = `https://${window.pdfData.dialDNS}`;
  }

  return `${prefix}${BASE_URL}assets`;
}

function getPathLangs(isPdfEnv) {
  return `${getPathAssets(isPdfEnv)}/lang`;
}

export {
  getPathAssets,
  getPathLangs,
};
