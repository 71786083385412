import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import Translate from '../display/translate';

import useOrganisations from '../../utils/hooks/useOrganisations';

function Organisation({ organisationId = '', onChange }) {
  const [organisations] = useOrganisations();

  return (
    <Autocomplete
      fullWidth
      value={organisations.find(({ key }) => key === organisationId) || { name: '', key: '' }}
      options={organisations}
      getOptionLabel={option => option.name}
      isOptionEqualToValue={(option, value) => option.key === value.key}
      onChange={(ev, value) => onChange({ name: 'organisationId', value: value.key })}
      filterSelectedOptions
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label={<Translate>organisation</Translate>}
        />
      )}
    />
  );
}

Organisation.propTypes = {
  onChange: PropTypes.func.isRequired,
  organisationId: PropTypes.string,
};

export default Organisation;
