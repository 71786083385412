import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';

import Chip from './chip';

import { KlingonBlack } from '../../utils/colors';

function Label({
  labelId,
  value,
  selected = false,
  onClick = undefined,
}) {
  let chipProps = {};

  if (onClick !== undefined) {
    chipProps = {
      variant: 'outlined',
      onClick: () => onClick(labelId),
      onDelete: (selected === true) ? (() => onClick(labelId)) : undefined,
      deleteIcon: (<CloseIcon />),
    };
  }

  return (
    <Chip
      label={value}
      sx={[
        (onClick !== undefined) && {
          color: KlingonBlack[700],
          borderColor: KlingonBlack[400],
          bgcolor: selected ? KlingonBlack[300] : 'white',
        },
      ]}
      {...chipProps}
    />
  );
}

Label.propTypes = {
  labelId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Label;
