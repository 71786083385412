import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import MUIBreadcrumbs from '@mui/material/Breadcrumbs';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import Translate from '~/components/display/translate';
import ImportIcon from '~/components/icons/file';
import LabelIcon from '~/components/icons/label';
import MemberIcon from '~/components/icons/member';
import OrganisationIcon from '~/components/icons/organisation';
import PatientIcon from '~/components/icons/patient';
import SettingsIcon from '~/components/icons/settings';

import { useAppContext } from '~/components/AppContext';
import { KlingonBlack, OrionTeal } from '~/utils/colors';

const TYPES = [
  'createLabel',
  'createMember',
  'createPatient',
  'createSite',
  'id',
  'import',
  'label',
  'member',
  'organisation',
  'patient',
  'settings',
];

function getLinkIcon(type) {
  if (type === 'import') {
    return <ImportIcon sx={{ fontSize: 32 }} />;
  }

  if (type === 'label') {
    return <LabelIcon sx={{ fontSize: 32 }} />;
  }

  if (type === 'member') {
    return <MemberIcon sx={{ fontSize: 32, color: OrionTeal[400] }} />;
  }

  if (type === 'organisation') {
    return <OrganisationIcon sx={{ fontSize: 32 }} />;
  }

  if (type === 'patient') {
    return <PatientIcon sx={{ fontSize: 32 }} />;
  }

  if (type === 'settings') {
    return <SettingsIcon sx={{ fontSize: 32 }} />;
  }

  return null;
}

function getDisplayText(link, isStudyProject) {
  if (link.type === 'id') {
    return link.value || '…';
  }

  if (link.type === 'member') {
    if (isStudyProject) {
      return <Translate>investigator</Translate>;
    }

    return <Translate>medicalTeam</Translate>;
  }

  return <Translate>{link.type}</Translate>;
}

function Breadcrumbs({ links }) {
  const { isStudyProject } = useAppContext();
  return (
    <MUIBreadcrumbs
      aria-label="breadcrumb"
      separator={<KeyboardArrowRightIcon sx={{ color: KlingonBlack[400], fontSize: 32, verticalAlign: 'middle' }} />}
      sx={{ mb: 5 }}
    >
      { links.map((link, index) => (
        <Link
          key={link.type}
          component={RouterLink}
          underline="none"
          color={index === (links.length - 1) ? 'text.primary' : KlingonBlack[600]}
          aria-current={index === (links.length - 1) ? 'page' : null}
          to={link.to}
          sx={{
            fontSize: '1.5rem',
            fontWeight: '600',
            maxWidth: 300,
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
          }}
          noWrap
        >
          { index === 0 && getLinkIcon(link.type) }
          <Box
            sx={{
              width: 1,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              display: 'block',
            }}
          >
            { getDisplayText(link, isStudyProject) }
          </Box>
        </Link>
      ))}
    </MUIBreadcrumbs>
  );
}

Breadcrumbs.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf(TYPES).isRequired,
    to: PropTypes.string.isRequired,
    value: PropTypes.string,
  })).isRequired,
};

export default Breadcrumbs;
