import { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';

import Email from '../components/field/email';
import Password from '../components/field/password';
import Button from '../components/display/button';
import Translate from '../components/display/translate';
import GoogleSignIn from './google-signin';
import AppleSignIn from './apple-signin';
import PasswordHelperText from './password-helper-text';
import Select from '../components/display/select';
import ResetForm from './reset-form';
import SetResetPasswordForm from './set-reset-password-form';

import researchLogo from '../assets/images/research-logo.svg';

import { useAppContext } from '~/components/AppContext';
import { getCoreAuth } from '@/firebase/auth';
import { isPassword } from '../utils/validate/password';
import { isDevTarget } from '../utils/env';
import { LIST_LOCALES } from '../utils/l10n';
import { KlingonBlack } from '../utils/colors';

function LoginForm({ isSignUp = false, defaultEmail = '' }) {
  const { locale, updateLocale } = useAppContext();
  const [signUpMode, setSignUpMode] = useState(isSignUp);
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState(defaultEmail);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resetPassword, setResetPassword] = useState(false);
  const { search } = useLocation();
  const navigate = useNavigate();

  const handleSwitch = () => {
    // remove email/password errors
    setErrors({
      ...errors,
      email: false,
      password: ((!signUpMode) ? !isPassword(password) : false),
      confirmPassword: false,
    });

    setConfirmPassword('');
    setSignUpMode(!signUpMode);
  };

  // return true if the form is not valid, false otherwise
  const validate = () => {
    if (signUpMode === false) {
      return false;
    }

    // check each field passed its own valdation
    if (Object.values(errors).find(i => i === true)) {
      return true;
    }

    if (!email) return true;
    if (!password) return true;
    if (!isPassword(password)) return true;
    if (!confirmPassword) return true;
    // if (password !== confirmPassword) return true;

    return false;
  };

  const handleChange = (change) => {
    setErrors({ ...errors, [change.name]: change.error });
    switch (change.name) {
      case 'email':
        setEmail(change.value);
        break;
      case 'password':
        setPassword(change.value);
        // prevent UI issue if the confirm password was updated before the
        // password
        setErrors(e => ({ ...e, confirmPassword: change.value !== confirmPassword }));
        break;
      case 'confirmPassword':
        setConfirmPassword(change.value);
        break;
      default:
        throw new Error(`Unknown change type: ${change.name}`);
    }
  };

  const handleLogin = async (ev) => {
    ev.preventDefault();

    if (validate()) {
      return;
    }

    if (signUpMode) {
      await createUserWithEmailAndPassword(getCoreAuth(), email, password);
    } else {
      try {
        await signInWithEmailAndPassword(getCoreAuth(), email, password);
      } catch (err) {
        setErrors({ email: true, password: true });
      }
    }
  };

  const searchParams = new URLSearchParams(search);
  if (searchParams.get('mode') === 'resetPassword') {
    return (<SetResetPasswordForm onCancel={() => navigate({ search: '' }, { replace: true })} />);
  }

  if (resetPassword) {
    return (<ResetForm email={email} onCancel={() => setResetPassword(false)} />);
  }

  return (
    <form onSubmit={handleLogin} style={{ height: '100%' }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        sx={{ height: 1, textAlign: 'start' }}
        wrap="nowrap"
      >
        <Grid item>
          <img src={researchLogo} alt="FeetMe Logo" />
        </Grid>
        <Grid item>
          <Typography variant="h1" sx={{ fontWeight: 700, fontSize: '2rem', mt: 2 }}>
            <Translate>{ signUpMode ? 'signUpForAccount' : 'loginToYourAccount' }</Translate>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" component="span" gutterBottom sx={{ color: KlingonBlack[700] }}>
            <Translate>{ signUpMode ? 'alreadyHaveAccount' : 'dontHaveAccount' }</Translate>
          </Typography>
          <Button variant="text" color="primary" onClick={handleSwitch}>
            <Translate>{ signUpMode ? 'login' : 'signUp' }</Translate>
          </Button>
        </Grid>
        <Grid item sx={{ mt: 2 }}>
          <Email
            autoFocus={defaultEmail === ''}
            disabled={defaultEmail !== ''}
            error={errors.email}
            value={email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item sx={{ mt: 2 }}>
          <Password
            autoFocus={defaultEmail !== ''}
            minLength={8}
            error={errors.password}
            value={password}
            onChange={ev => handleChange({
              name: 'password',
              value: ev.target.value,
              error: !ev.target.value || (signUpMode && !isPassword(ev.target.value)),
            })}
            helperText={(signUpMode === false && errors.email)
                && <Translate>incorrectLoginInformation</Translate>}
          />
        </Grid>
        { signUpMode === true && (
          <Grid>
            <PasswordHelperText password={password} />
          </Grid>
        )}
        { signUpMode && (
          <Grid item sx={{ mt: 2 }}>
            <Password
              minLength={8}
              error={errors.confirmPassword}
              label="confirmPassword"
              value={confirmPassword}
              onChange={ev => handleChange({
                name: 'confirmPassword',
                value: ev.target.value,
                error: !ev.target.value || password !== ev.target.value,
              })}
              helperText={errors.confirmPassword
                ? (<Translate>passwordsDontMatch</Translate>)
                : undefined}
            />
          </Grid>
        )}
        <Grid
          item
          direction="row-reverse"
          justifyContent="flex-start"
          alignItems="center"
          container
          sx={{ mt: 3 }}
        >
          <Grid item>
            <Button onClick={() => setResetPassword(true)} variant="text" color="primary">
              <Translate>passwordForgot</Translate>
            </Button>
          </Grid>
        </Grid>
        <Grid item sx={{ mt: 3 }}>
          <Button type="submit" color="primary" disabled={validate()} onClick={handleLogin} fullWidth>
            <Translate>{ signUpMode ? 'createAccount' : 'connectDashboard' }</Translate>
          </Button>
        </Grid>
        { isDevTarget() && (
          <Grid item sx={{ mt: 1 }}>
            <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={1}>
              <Grid item xs>
                <GoogleSignIn>
                  <Translate>{ signUpMode ? 'signIn' : 'login' }</Translate>
                </GoogleSignIn>
              </Grid>
              <Grid item xs>
                <AppleSignIn>
                  <Translate>{ signUpMode ? 'signIn' : 'login' }</Translate>
                </AppleSignIn>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item sx={{ mt: 2 }}>
          <Typography variant="caption">
            <Translate>
              acceptTerms
            </Translate>
            { ' ' }
            <Link href="https://feetmehealth.com/terms-of-service/" target="_blank" rel="noopener">
              <Translate>
                termsOfService
              </Translate>
            </Link>
            { ' ' }
            <Translate>and</Translate>
            { ' ' }
            <Link href="https://feetmehealth.com/privacy-policy/" target="_blank" rel="noopener">
              <Translate>
                privacyPolicy
              </Translate>
            </Link>
          </Typography>
        </Grid>
        { locale !== undefined && (
          <Grid item sx={{ mt: 3 }}>
            <Select
              fullWidth={false}
              value={locale}
              options={LIST_LOCALES.map(i => ({ key: i, label: (<Translate>{i}</Translate>) }))}
              onChange={ev => updateLocale(ev.target.value)}
            />
          </Grid>
        )}
      </Grid>
    </form>
  );
}

LoginForm.propTypes = {
  isSignUp: PropTypes.bool,
  defaultEmail: PropTypes.string,
};

export default LoginForm;
