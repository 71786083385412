import PropTypes from 'prop-types';

import Table from '../index';
import Head from './head';
import Row from './row';

import { translate } from '../../../utils/i18n';
import { useAppContext } from '~/components/AppContext';

function Labels({
  labels,
  onDelete,
  onEdit,
  ...props
}) {
  const { file } = useAppContext();
  const translatedPlaceholder = translate('search', file);

  return (
    <Table
      {...props}
      defaultOrderBy="name"
      Head={Head}
      Row={Row}
      data={labels}
      placeholder={translatedPlaceholder}
      RowProps={{ onDelete, onEdit }}
      hasPagination
    />
  );
}

Labels.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default Labels;
